<i18n>
  {
    "en": {
      "title":"Probe data conversion",
      "conversionParameter": "Conversion parameters:",
      "options": {
        "gain": "Gain",
        "fullscale": "Fullscale"
      },
      "info":{
        "message":"Depending on the interface, specific parameters need to be set in order to convert the raw data into the actual measure. You can usually find those parameters in your probe's datasheet or instruction manual."
      },
      "fullscale":{
        "maxRawValue": "Maximum raw value",
        "maxOutputValue": "Maximum output value"
      },
      "gain": {
        "title": "Set the Gain"
      }
    },
    "it": {
      "title":"Conversione dati della sonda",
      "conversionParameter": "Parametro di conversione",
      "options": {
        "gain": "Gain",
        "fullscale": "Fondoscala"
      },
      "info":{
        "message":"A seconda dell'interfaccia, specifici parametri devono essere settati per convertire il dato grezzo nella misura effettiva. Solitamente puoi trovare quei parametri nel datasheet o del manuale istruzioni della sonda."
      },
      "fullscale":{
        "maxRawValue": "Valore massimo dei dati grezzi",
        "maxOutputValue": "Valore massimo dei dati convertiti"
      },
      "gain": {
        "title": "Imposta il Guadagno"
      }
    }
  }
</i18n>
<template>
  <div>
    <div class="d-flex align-items-center mb16">
      <move-text p b class="mr16">{{ $t("title") }}:</move-text>
      <move-info rigth>
        <template #icon>
          <font-awesome-icon icon="fa-solid fa-circle-info" />
        </template>
        <div class="w400 p16">
          <move-text p> {{ $t("info.message") }}</move-text>
        </div>
      </move-info>
    </div>
    <move-text caption class="mb4">{{ $t("conversionParameter") }}:</move-text>
    <move-select
      :options="getOptions"
      label="label"
      v-model="vMode"
      class="mb16 w300"
      placeholder="select parameter"
      :valid="getValidation.mode"
      :disabled="getIsMappedMode"
    ></move-select>
    <div v-if="this.getMode && this.getMode.value === 'fullscale'">
      <div class="d-flex align-items-center">
        <div>
          <move-text caption
            >{{ $t("fullscale.maxRawValue") }}:</move-text
          >
          <move-input-number
            tertiary
            v-model="vFullscaleMaxRaw"
            :valid="getValidation.fullscaleMaxRaw"
            class="w300"
            :disabled="getIsMappedMode"
          >
            <move-text p> {{ getUnit.fullscaleMaxRaw }} </move-text>
          </move-input-number>
        </div>
        <font-awesome-icon class="px16 mt16" icon="fa-solid fa-arrow-right"/>
        <div>
          <move-text caption
            >{{ $t("fullscale.maxOutputValue") }}:</move-text
          >
          <move-input-number
            tertiary
            v-model="vFullscaleMaxOutput"
            :valid="getValidation.fullscaleMaxOutput"
            class="w300"
            :disabled="getIsMappedMode"
          >
            <move-text p> {{ getUnit.fullscaleMaxOutput }} </move-text>
          </move-input-number>
        </div>
      </div>
    </div>
    <div v-else-if="this.getMode && this.getMode.value === 'gain'">
      <div class="d-flex">
        <div >
          <move-text caption>{{ $t("gain.title") }}:</move-text>
          <move-input-number
            tertiary
            v-model="vGain"
            :valid="getValidation.gain"
            class="w300"
            :disabled="getIsMappedMode"
          >
            <move-text p> {{ getUnit.gain }} </move-text>
          </move-input-number>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "scn-data-conversion-mvv",
  props: {
    rawUnit: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapGetters({
      getTempSensor: "settings/getTempSensor",
      getTempSensorUserConfig: "settings/getTempSensorUserConfig",
      getTempSensorChannel0: "settings/getTempSensorChannel0",
      getSensorType: "settings/getSensorType",
      getProbeName: "settings/getProbeName",
      getConversionFormula: "settings/getConversionFormula",
      getMesUnit: "settings/getMesUnit",
      getIsMappedMode: "settings/getIsMappedMode",
    }),
    getGainMode() {
      return {
        label: this.$t('options.gain'),
        value: "gain"
      };
    },
    getFullscaleMode() {
      return {
        label: this.$t('options.fullscale'),
        value: "fullscale"
      };
    },
    getMode() {
      const G =
        this.getConversionFormula && this.getConversionFormula.G !== undefined;
      const fullscale =
        this.getConversionFormula &&
        this.getConversionFormula.xMax !== undefined &&
        this.getConversionFormula.yMax !== undefined;
      if (G) {
        return this.getGainMode;
      }
      if (fullscale) {
        return this.getFullscaleMode;
      }
      return null;
    },
    getOptions() {
      return [this.getGainMode, this.getFullscaleMode];
    },
    getValidation() {
      let result = {
        gain: false,
        fullscaleMaxRaw: false,
        fullscaleMaxOutput: false,
        mode: this.getMode !== null
      };
      if (this.getMode && this.getMode.value === "gain") {
        result.gain = typeof this.vGain === 'number';
        return result;
      } else if (this.getMode && this.getMode.value === "fullscale") {
        result.fullscaleMaxRaw = typeof this.vFullscaleMaxRaw === 'number';
        result.fullscaleMaxOutput = typeof this.vFullscaleMaxOutput === 'number';
        return result;
      } else {
        (result.gain = false),
          (result.fullscaleMaxRaw = false),
          (result.fullscaleMaxOutput = false);
      }
      return result;
    },
    getEntireValidation() {
      if (!this.getMode) {
        return false;
      }
      if (this.getMode.value === "fullscale") {
        return (
          this.getValidation.fullscaleMaxRaw &&
          this.getValidation.fullscaleMaxOutput
        );
      }
      if (this.getMode.value === "gain") {
        return this.getValidation.gain;
      }
      return false;
    },
    getUnit() {
      return {
        gain: null,
        fullscaleMaxRaw: this.rawUnit,
        fullscaleMaxOutput: this.getMesUnit
      };
    },
    vFullscaleMaxRaw: {
      get() {
        return (
          (this.getConversionFormula && this.getConversionFormula.xMax)
        );
      },
      set(maxRaw) {
        this.updateConversionFormula({ xMax: this.validateField(maxRaw) });
      }
    },
    vFullscaleMaxOutput: {
      get() {
        return (
          (this.getConversionFormula && this.getConversionFormula.yMax)
        );
      },
      set(maxOutput) {
        this.updateConversionFormula({ yMax: this.validateField(maxOutput) });
      }
    },
    vGain: {
      get() {
        return (
          (this.getConversionFormula && this.getConversionFormula.G)
        );
      },
      set(gain) {
        this.updateConversionFormula({ G: this.validateField(gain) });
      }
    },
    vMode: {
      get() {
        return this.getMode;
      },
      set(
        mode = {
          value: null,
          label: null
        }
      ) {
        if (mode.value === "gain") {
          this.updateConversionFormula({ G: null }, { clean: true });
        } else {
          this.updateConversionFormula(
            { xMax: null, yMax: null },
            { clean: true }
          );
        }
      }
    }
  },
  methods: {
    ...mapActions({
      setTempSensor: "settings/setTempSensor",
      updateValidationTempSensor: "settings/updateValidationTempSensor"
    }),
    validateField(value) {
      return value === "" ? null : value;
    },
    /*
    update conversion formula.
    - if options.clean = true, clean the conversion formula
    */
    updateConversionFormula(fields = {}, options = { clean: false }) {
      const sensor = this.getTempSensor;
      let conversionFormula =
        sensor.userConfig.channels[0].conversionFormula || {};
      conversionFormula = options.clean
        ? fields
        : { ...conversionFormula, ...fields };
      sensor.userConfig.channels[0].conversionFormula = conversionFormula;
      this.setTempSensor(conversionFormula);
    },
    updateValidation(valid) {
      this.updateValidationTempSensor({
        field: "dataConversionMVV",
        valid
      });
    }
  },
  watch: {
    getEntireValidation(valid) {
      this.updateValidation(valid);
    }
  },
  mounted() {
    this.updateValidation(this.getEntireValidation);
  }
};
</script>

<style scoped>
.select {
  width: 100%;
  min-width: 200px;
}
</style>
