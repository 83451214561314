<template>
  <div>
    <transition name="fade">
      <ModalEulaAcceptView v-if="view === 'ACCEPT_VIEW'" @acceptEula="$emit('acceptEula')" @declineEula="view = 'DECLINE_VIEW'" />
    </transition>
    <transition name="fade">
      <ModalEulaRejectView v-if="view === 'DECLINE_VIEW'" @goOut="$emit('goOut')" @comeBack="view = 'ACCEPT_VIEW'" />
    </transition>
  </div>
</template>

<script>
import ModalEulaAcceptView from "./ModalEulaAcceptView.vue";
import ModalEulaRejectView from "./ModalEulaRejectView.vue";
export default {
  name: "ModalEula",
  components: {
    ModalEulaAcceptView,
    ModalEulaRejectView,
  },
  data() {
    return {
      view: "ACCEPT_VIEW",
    };
  },
};
</script>

<style scoped>

</style>
