<i18n>
  {
    "en": {
      "usernameHint": "The username must meet the following requirements",
      "usernameHint-li-1": "Must not already exist in the system (unique username)",
      "usernameHint-li-2": "Alphanumeric characters only (uppercase or lowercase letters and numbers)",
      "usernameHint-li-3": "Length between 3 and 24 characters",
      "passwordHint": "The password must respect the following format",
      "passwordHint-li-1": "Minimum 7 characters",
      "passwordHint-li-2": "At least one number",
      "passwordHint-li-3": "At least one special character among: #?!@$%^&*-"
    },
    "it": {
      "usernameHint": "Il nome utente deve rispettare i seguenti requisiti",
      "usernameHint-li-1": "Non deve essere già presente nel sistema (nome utente univoco)",
      "usernameHint-li-2": "Solo caratteri alfanumerici (lettere maiuscole o minuscole e numeri)",
      "usernameHint-li-3": "Lunghezza compresa tra 3 e 24 caratteri",
      "passwordHint": "La password deve rispettare il seguente formato",
      "passwordHint-li-1": "Minimo 7 caratteri",
      "passwordHint-li-2": "Almeno un numero",
      "passwordHint-li-3": "Almeno un carattere speciale tra: #?!@$%^&*-"
    }
  }
  </i18n>
<template>
  <SettingsContentTemplate>
    <template v-slot:tabs>
      <a @click="curTab = 'users'" :class="{ active: curTab === 'users' }"
        >{{ $t("message.users") }}
      </a>
    </template>
    <template v-slot:content>
      <!-- Sotto Utenti -->
      <div>
        <div class="notify-heading-container">
          <div class="title">
            <h4>{{ $t("message.users") }}</h4>
            <div class="d-flex">
              <p >{{ $t("message.manageUsers") }}</p>
            </div>
          </div>
        </div>
        <div id="sr-content">
          <form
            class="item-box"
            v-for="(subUser, index) in tempSubUsers"
            :key="index"
            autocomplete="off"
          >
            <input
              style="display: none"
              type="text"
              name="fakeusernameremembered"
            />
            <input
              style="display: none"
              type="password"
              name="fakepasswordremembered"
            />
            <input
              autocomplete="off"
              tabindex="2"
              readonly
              onfocus="this.removeAttribute('readonly');"
              type="text"
              :class="[
                validateUsername(subUser.username) && backendValidation && !duplicateUsernames && editingIndex == index
                  ? 'valid'
                  : 'not-valid',
                'input-text-username',
                'mail-name'
              ]"
              spellcheck="false"
              placeholder="Username"
              @input="onChangeUsername($event, subUser)"
              v-model="subUser.username"
              :disabled="editingIndex !== index"
              required
            />
            <!-- -->
            <input
              autocomplete="off"
              tabindex="3"
              readonly
              onfocus="this.removeAttribute('readonly');"
              type="password"
              :class="['input-text', 'mail-addr', !validatePassword(subUser.password) && editingIndex == index ? 'not-valid' : '']"
              spellcheck="false"
              :placeholder="
                editingIndex == index
                  ? editing
                    ? $t('message.modifyPsw')
                    : $t('message.insertPsw')
                  : ''
              "
              minlength="4"
              v-model="subUser.password"
              :disabled="editingIndex !== index"

          />
            <!-- Username hint -->
            <div class="d-flex align-items-center" :style="{alignSelf: 'center'}">
              <move-info rigth v-if="editingIndex == index" class="mr8">
                <template #icon>
                  <font-awesome-icon icon="fa-solid fa-circle-info" />
                </template>
                <div class="w400 p16">
                  <move-text p>
                    {{ $t('usernameHint') }}:
                  </move-text>
                  <ul>
                    <li>
                      <move-text p>
                        {{ $t('usernameHint-li-1') }}
                      </move-text>
                    </li>
                    <li>
                      <move-text p>
                        {{ $t('usernameHint-li-2') }}
                      </move-text>
                    </li>
                    <li>
                      <move-text p>
                        {{ $t('usernameHint-li-3') }}
                      </move-text>
                    </li>
                  </ul>
                </div>
              </move-info>

              <!-- Loader on backend validation -->
              <div v-if="editingIndex == index && timerBackendValidation">
                <move-text p b>{{waitTxt}}</move-text>
              </div>
            </div>

            <!-- Password hint -->
            <move-info rigth v-if="editingIndex == index" :style="{alignSelf: 'center'}" >
              <template #icon>
                <font-awesome-icon icon="fa-solid fa-circle-info" />
              </template>
              <div class="w400 p16">
                <move-text p>
                  {{ $t('passwordHint') }}: 
                </move-text>
                <ul>
                  <li>
                    <move-text p> {{ $t('passwordHint-li-1') }} </move-text>
                  </li>
                  <li>
                    <move-text p> {{ $t('passwordHint-li-2') }} </move-text>
                  </li>
                  <li>
                    <move-text p> {{ $t('passwordHint-li-3') }}</move-text>
                  </li>
                </ul>
              </div>
            </move-info>
            <img
              v-if="editingIndex === index && backendValidation && !someInvalid && !duplicateUsernames"
              class="mail-edit-icon icon"
              src="../../../../public/assets/icons/accept.svg"
              @click="acceptSubUser(index, subUser)"
            />
            <img
              v-else
              class="mail-edit-icon icon"
              src="../../../../public/assets/icons/edit.svg"
              @click="editSubUser(index, subUser)"
            />

            <img
              v-if="editingIndex === index"
              class="mail-delete-icon icon"
              src="../../../../public/assets/icons/trash.svg"
              @click="deleteSubUser(subUser)"
            />
          </form>
          <div class="add-box">
            <img
              class="mail-new-icon icon"
              src="../../../../public/assets/icons/newavatar.svg"
              @click="insertNewSubUser"
            />
            <h5 @click="insertNewSubUser">
              {{ $t("message.addUser") }}
            </h5>
          </div>
        </div>
      </div>
    </template>
  </SettingsContentTemplate>
</template>
<script>
import SettingsContentTemplate from "./SettingsContentTemplate.vue";
import {
  validateEmail,
  validatePassword,
  validateUsername
} from "../../../helpers/validate";
import { validateSubUser } from "../../../services/api";
export default {
  name: "subusers-settings",
  components: {
    SettingsContentTemplate
  },
  props: {
    subUsers: Array
  },
  data() {
    return {
      curTab: "users",
      tempSubUsers: null,
      editingIndex: null,
      editUsername: "",
      backendValidation: false,
      timerBackendValidation: null,
      timeoutBackendValidation: 1000,
      editing: false,
      waitTxt: '...',
      intervalAnimationWait: null
    };
  },
  methods: {
    validateEmail: validateEmail,
    validatePassword: validatePassword,
    validateUsername: validateUsername,
    // Emette un evento con lo stato di validazione di tutti i sotto-utenti
    updateValidation() {
      this.$emit("validation", !this.someInvalid && this.backendValidation && !this.duplicateUsernames);
    },
    // Se non ci sono item invalidi, inserisce un nuovo sotto-utente
    insertNewSubUser() {
      if (!this.someInvalid  && !this.duplicateUsernames) {
        this.editUsername = "";
        let emptyUser = {
          username: "",
          password: null
        };
        this.editingIndex = this.tempSubUsers.length;
        this.tempSubUsers.push(emptyUser);
      }
    },
    // Se non ci sono item invalidi, abilita/disabilita editing
    async editSubUser(index, subUser) {
      if (!this.someInvalid && !this.duplicateUsernames && !this.editing) {
        this.editing = true;
        this.editUsername = subUser.username;
        this.backendValidation = true;
        if (this.editingIndex === index) {
          this.editingIndex = null;
        } else {
          this.editingIndex = index;
        }
      }
    },
    async acceptSubUser(index) {
      this.editing = false;
      if (!this.someInvalid) {
        if (this.editingIndex === index) {
          this.editingIndex = null;
        }
      }
    },
    onChangeUsername(_evt, subUser) {
      if (this.editUsername === subUser.username) {
        this.backendValidation = true;
        return;
      }
      this.backendValidation = false;
      if (this.timerBackendValidation) {
        clearTimeout(this.timerBackendValidation);
        this.timerBackendValidation = null;
      }
      if (subUser.username.length < 3) {
        return;
      }
      this.timerBackendValidation = setTimeout(() => {
        validateSubUser(subUser.username)
          .then(response => {
            this.backendValidation = response.data.available;
            this.timerBackendValidation = null;
          })
          .catch(error => {
            this.timerBackendValidation = null;
            this.backendValidation = false;
            console.log(error);
          });
      }, this.timeoutBackendValidation);
    },
    // Elimina un sotto-utente
    deleteSubUser(user) {
      // Elimina mail
      this.editing = false;
      const index = this.tempSubUsers.indexOf(user);
      if (index > -1) {
        this.tempSubUsers.splice(index, 1);
      }
      this.editingIndex = null;
    }
  },
  computed: {
    // Ritorna lo stato attuale di validazione di tutti i sotto-utente
    someInvalid() {
      return this.tempSubUsers
        ? this.tempSubUsers.some(
            su =>
              !(
                validateUsername(su.username) &&
                (validatePassword(su.password) || su.password === undefined)
              )
          )
        : false;

    },
    duplicateUsernames(){
      const usernames = this.tempSubUsers.map(u => u.username);
      const duplicates = usernames.filter((item, index) => usernames.indexOf(item) !== index)
      return duplicates.length > 0;
    },

  },
  mounted() {
    // Deep copy
    this.tempSubUsers = JSON.parse(JSON.stringify(this.subUsers));
  },
  watch: {
    tempSubUsers: {
      handler: function(newSubUsers, oldSubUsers) {
        if (newSubUsers && oldSubUsers) {
          this.updateValidation();
          if (!this.someInvalid && this.backendValidation) {
            // Elimino il campo 'editing' creato in fase di mount
            const subUsersToEmit = JSON.parse(JSON.stringify(newSubUsers));
            this.$emit("update", subUsersToEmit);
          }
        }
      },
      deep: true
    },
    backendValidation: {
      handler: function(isValidate) {
        this.updateValidation();
        if (!this.someInvalid && isValidate) {
          // Elimino il campo 'editing' creato in fase di mount
          const subUsersToEmit = JSON.parse(JSON.stringify(this.tempSubUsers));
          this.$emit("update", subUsersToEmit);
        }
      }
    },
  }
};
</script>

<style scoped>
input > .validate {
  border: solid 1.2px green !important;
}
</style>
