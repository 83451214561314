<i18n>
{
  "en": {
    "title": "Unit of measurement",
    "info": {
      "message": "Set the Unit of measurement of the probe. In Raw data mode it's automatically set as the unit of measurement of the input signal before conversion."
    }
  },
  "it": {
    "title": "Unità di misura",
    "info": {
      "message": "Imposta l'unità di misura della sonda. In modalità Dati grezzi è impostata automaticamente all'unità di misura del segnale in ingresso  pre-conversione."
    }
  }
}
</i18n>
<template>
  <div>
    <div class="d-flex align-items-center mb4">
      <move-text p b class="mr16">{{ $t("title") }}:</move-text>
      <move-info rigth>
        <template #icon>
          <font-awesome-icon icon="fa-solid fa-circle-info" />
        </template>
        <div class="w400 p16">
          <move-text p> {{ $t("info.message") }}</move-text>
        </div>
      </move-info>
    </div>
    <move-input
      tertiary
      v-model="vUnitOfMeasurement"
      :disabled="getMode !== 'custom'"
      class="w300"
      :valid="getValidation"
    ></move-input>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "scn-unit-of-measurement",
  computed: {
    ...mapGetters({
      getTempSensor: "settings/getTempSensor",
      getMesUnit: "settings/getMesUnit",
      getMode: "settings/getMode",
    }),
    getValidation(){
      return this.vUnitOfMeasurement !== null
    },
    vUnitOfMeasurement: {
      get() {
        return this.getMesUnit;
      },
      set(value) {
        this.updateRscConfig({mesUnit: value});
      }
    }
  },
  methods: {
    /* --------------------------------------------------------------- */
    /* VUEX ACTIONS */
    /* --------------------------------------------------------------- */
    ...mapActions({
      setTempSensor: "settings/setTempSensor",
      updateValidationTempSensor: "settings/updateValidationTempSensor",
      updateRscConfig: "settings/updateRscConfig",
    }),
  },
  watch:{
    getValidation(valid){
      this.updateValidationTempSensor({field: 'mesUnit', valid})
    }
  },
  mounted() {
    this.updateValidationTempSensor({field: 'mesUnit', valid: this.getValidation});
  }
};
</script>

<style scoped></style>
