<i18n>
{
  "en": {
    "warningMessage": "You have unsaved changes!"
  },
  "it": {
    "warningMessage": "Hai apportato modifiche che devono essere salvate!"
  }
}
</i18n>
<template>
  <div id="message">
    <p>{{ $t("warningMessage") }}</p>
  </div>
</template>
<script>
export default {
  name: "settings-warning-message",
};
</script>
<style scoped>
#message {
  width: 100%;
  border-radius: 8px;
  background-color: #f8cf96;
  border: solid 2px #f49a57;
  box-sizing: border-box;
}
p {
  padding: 6px 0;
  margin: 0;
  color: black;
  font-weight: 500;
}
</style>
