<template>
  <div class="outer-wrap">
    <div class="dark-overlay"></div>
    <div id="monitor-outerwrap">
      <div class="monitor-container">
        <!-- Loading -->
        <transition name="load">
          <SmallLoadingLogo v-if="!this.loaded" />
        </transition>
        <!-- Heading -->
        <div id="monitor-header" class="noselect">
          <h1>{{ $t("message.statusMonitor") }}</h1>
          <a @click="closeMe" class="close"></a>
        </div>
        <!-- Opzioni sulla sinistra -->
        <div id="side-options" class="noselect">
          <div class="options">
            <a
              @click="
                curOption = 'deck';
                curTab = 'summary';
              "
              :class="{ active: curOption === 'deck', disabled: !checkSensType('deck') }"
              >{{ $t("message.decks") }}
            </a>
            <a
              @click="
                curOption = 'tiltmeter';
                curTab = 'summary';
              "
              :class="{ active: curOption === 'tiltmeter', disabled: !checkSensType('tiltmeter') }"
              >{{ $t("message.tiltmeters") }}
            </a>
            <a
              @click="
                curOption = 'accelerometer';
                curTab = 'summary';
              "
              :class="{ active: curOption === 'accelerometer', disabled: !checkSensType('accelerometer') }"
              >{{ $t("message.accelerometers") }}
            </a>
            <a
              @click="
                curOption = 'crackmeter';
                curTab = 'summary';
              "
              :class="{ active: curOption === 'crackmeter', disabled: !checkSensType('crackmeter') }"
              >{{ $t("message.crackmeters") }}
            </a>
            <a
              @click="
                curOption = 'analog-node';
                curTab = 'summary';
              "
              :class="{ active: curOption === 'analog-node', disabled: !checkSensType('analog-node') }"
              >{{ $t("message.analogNodes") }}
            </a>
            <a
              @click="
                curOption = 'vibrometer';
                curTab = 'summary';
              "
              :class="{ active: curOption === 'vibrometer', disabled: !checkSensType('vibrometer') }"
              >{{ $t("message.vibrometers") }}
            </a>
            <a
              v-if="gateways.length > 0"
              @click="
                curOption = 'gateway';
                curTab = 'summary';
              "
              :class="{ active: curOption === 'gateway' }"
              >Gateway
            </a>
          </div>
        </div>
        <!-- Tabs -->
        <div id="tab">
          <a @click="curTab = 'summary'" :class="{ active: curTab === 'summary' }" v-if="checkIfTabAvailable('summary')">{{ $t("message.summary") }} </a>
        </div>

        <!-- Contento principale   -->
        <div id="main-content">
          <!-- Stato -->
          <div v-if="this.curTab === 'summary' && this.curOption != 'gateway'">
            <div class="sr-heading">
              <p>{{ $t("message.globalStatus") }}:</p>
            </div>
            <div id="sr-content">
              <div class="sensor-box" v-for="sensor in filteredSensors" :key="sensor.eui">
                <div class="side-color" :style="{ backgroundColor: sensor.userConfig.color }"></div>
                <div class="sensor-head">
                  <h5>
                    {{ sensor.type + " " + sensor.eui.substr(sensor.eui.length - 5) + " - " + sensor.userConfig.name }}
                  </h5>
                  <span :class="[checkIfSensorOnline(sensor) ? 'online' : 'offline', 'dot']"></span>
                </div>
                <div class="info-container">
                  <!-- Soglia di attivazione -->
                  <p v-if="sensor.type !== 'vibrometer'">
                    {{ $t("message.activationThreshold") }}: <br /><span class="value">{{
                      sensor.userConfig.activationThreshold ? sensor.userConfig.activationThreshold + " " + mesaurementUnit.unit : " - "
                    }}</span>
                  </p>
                  <!-- Normativa -->
                  <p v-if="sensor.type === 'vibrometer'">
                    {{ $t("message.regulation") }}: <br /><span class="value">{{ sensor.userConfig.threshold.normativeName || " - " }}</span>
                  </p>
                  <!-- Sample rate (cadenza) -->
                  <p>
                    {{ $t("message.sampleRate") }}: <br /><span class="value">{{ sensor.userConfig.sampleRate ? sensor.userConfig.sampleRate + " " + mesaurementUnit.period : " - " }}</span>
                  </p>
                  <!-- Risoluzione -->
                  <p v-if="sensor.type !== 'vibrometer'">
                    {{ $t("message.resolution") }}: <br /><span class="value">{{ sensor.userConfig.resolution ? sensor.userConfig.resolution + " " + mesaurementUnit.unit : " - " }}</span>
                  </p>
                  <!-- Type acq. -->
                  <p v-if="sensor.type === 'vibrometer'">
                    {{ $t("message.acqType") }}: <br /><span class="value">{{ sensor.userConfig.typeAcqu || " - " }}</span>
                  </p>
                  <!-- Last TX -->
                  <p>
                    {{ $t("message.lastTX") }}: <br /><span class="value">{{ sensor.stats.lastTX ? new Date(sensor.stats.lastTX).toLocaleString() : " - " }}</span>
                  </p>
                  <!-- Signal Quality -->
                  <p>
                    {{ $t("message.signalQuality") }}: <br /><span class="value" :style="{ color: getColorBySignalQuality(sensor.signalQuality) }">{{
                      sensor.signalQuality != undefined ? sensor.signalQuality + 1 + "/4" : " - "
                    }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- Gateway -->
          <div v-if="this.curTab === 'summary' && this.curOption === 'gateway'">
            <div class="sr-heading">
              <p>{{ $t("message.globalStatus") }}:</p>
            </div>
            <div id="sr-content">
              <div class="sensor-box" v-for="gw in gateways" :key="gw.MAC">
                <div class="side-color"></div>
                <div class="sensor-head">
                  <h5>
                    {{ "Gateway" + " " + gw.MAC }}
                  </h5>
                  <span :class="[checkIfGwOnline(gw) ? 'online' : 'offline', 'dot']"></span>
                </div>
                <div class="info-container">
                  <p>
                    Last Data: <br /><span class="value">{{ new Date(gw.lastData).toLocaleString() }}</span>
                  </p>
                  <p>
                    Last Pong: <br /><span class="value">{{ new Date(gw.lastPong).toLocaleString() }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Footer -->
        <div id="footer"></div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../services/api";
import sensorsUtils from "../helpers/sensors";
import gwUitls from "../helpers/gateway";
import SmallLoadingLogo from "../components/ui/SmallLoadingLogo";
import $ from "jquery";
import { mapGetters } from "vuex"

export default {
  name: "monitor",
  props: {
    selectedStructure: Object,
  },
  components: {
    SmallLoadingLogo,
  },
  data() {
    return {
      loaded: true,
      curOption: "deck",
      curTab: "summary",
      sensors: [],
      gateways: [],
      sensorPriority: ["deck", "tiltmeter", "accelerometer", "crackmeter", "vibrometer"],
      tabPerOptions: [
        { type: "deck", active: ["summary"] },
        { type: "tiltmeter", active: ["summary"] },
        { type: "accelerometer", active: ["summary"] },
        { type: "crackmeter", active: ["summary"] },
        { type: "vibrometer", active: ["summary"] },
        { type: "gateway", active: ["summary"] },
      ],
    };
  },
  methods: {
    checkIfTabAvailable(tab) {
      // Controlla che la tab sia disponibile per l'opzione selezionata
      let found = false;
      this.tabPerOptions.forEach((option) => {
        if (this.curOption === option.type && option.active.includes(tab)) found = true;
      });
      return found;
    },
    checkSensType(type) {
      // Ritorna true se esiste almeno un sensore di tipo 'type'
      return this.sensors.some((sensor) => sensor.type === type);
    },
    checkIfSensorOnline: sensorsUtils.checkIfOnline,
    checkIfGwOnline: gwUitls.checkIfOnline,
    closeMe() {
      this.$emit("monitortoggle");
    },
    getColorBySignalQuality(sq) {
      if (sq === 3) return "green";
      if (sq === 2) return "yellow";
      if (sq === 1) return "orange";
      if (sq === 0) return "red";
    },
  },
  computed: {

    ...mapGetters({
      userInterface: "user/getInterface",
    }),
    getVelocityUnit(){
      return this.userInterface && this.userInterface.dimensions && this.userInterface.dimensions.velocity
        ? this.userInterface.dimensions.velocity
        : 'mm/s';
    },
    filteredSensors() {
      return this.sensors.filter((sensor) => sensor.type === this.curOption);
    },
    mesaurementUnit() {
      let unit;
      let period;
      if (this.curOption === "deck") {
        unit = "mm";
      }
      if (this.curOption === "crackmeter") {
        unit = "mm";
      }
      if (this.curOption === "accelerometer") {
        unit = "mg";
        period = "hr";
      }
      if (this.curOption === "tiltmeter") {
        unit = "angle";
        period = "min";
      }
      if (this.curOption === "vibrometer") {
        unit = this.getVelocityUnit;
        period = "min";
      }
      return { unit: unit, period: period };
    },
  },
  watch: {
    curTab: function() {
      // Quando cambio tab disattivo l'editing dei sensori
      this.sensors.map((a) => (a.userConfig.editing = false));
    },
    curOption: function() {
      // Quando cambio opzione disattivo l'editing dei sensori
      this.sensors.map((a) => (a.userConfig.editing = false));
    },
  },
  async mounted() {
    $(document.body).addClass("modal-open"); // Blocco scorrimento body
    /* Deep copy dei sensor */
    const [sensorsFromApi, gatewaysFromApi] = await Promise.all([api.getSensorsSettings(this.selectedStructure._id), api.getGatewayList(this.selectedStructure._id)]);
    //const sensorsFromApi = await api.getSensorsSettings(this.selectedStructure._id);
    this.gateways = gatewaysFromApi || [];
    this.sensors = sensorsFromApi
      ? sensorsFromApi.map((sensor) => ({
          eui: sensor.eui,
          type: sensor.type,
          stats: {
            lastTX: sensor.stats ? sensor.stats.lastTX : sensor.lastTX,
          },
          signalQuality: sensor.signalQuality,
          userConfig: {
            ...sensor.userConfig,
            editing: false,
            valid: true,
          },
        }))
      : [];

    /* 
    Scorro i sensori per selezionare la prima optin in base alla
    priorità in sensorPriority
    */
    for (const type of this.sensorPriority) {
      if (this.sensors.some((a) => a.type == type)) {
        this.curOption = type;
        break;
      }
    }
    this.loaded = true;
  },
  beforeDestroy() {
    $(document.body).removeClass("modal-open"); // Consento al body di scorrere di nuovo
  },
};
</script>
<style lang="postcss" scoped>
* {
  font-style: normal;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: white;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.dark-overlay {
  background-color: rgba(0, 0, 0, 0.27);
  width: 100%;
  height: 100%;
  position: absolute;
}
body {
  background-color: white !important;
}
h1 {
  font-size: 1em;
}
h5 {
  margin: 0;
  font-weight: 700;
}
p {
  color: rgb(198, 198, 198);
  padding: 0px;
}
.horiz-divider {
  background-color: grey;
  height: 2px;
}
.input-text {
  background-color: rgba(0, 0, 0, 0);
  width: 120px;
  outline-width: 0;
  outline: none !important;
  border: none;
  display: inline;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  padding-bottom: 1%;
  white-space: nowrap;
  display: flex;
  align-items: center;
  align-self: center;
  -webkit-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
}
.input-text:enabled {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}
.input-text:invalid {
  border: solid 1.2px red;
  padding: 1px;
}
.input-text:valid {
  border: solid 1.2px green;
  padding: 1px;
}
.monitor-container {
  width: 82vw;
  height: 70vh;
  min-width: 1180px;
  min-height: 480px;
  top: 12vh;
  left: 0;
  position: sticky;
  grid-template-columns: 1.8fr 8.2fr;
  grid-template-rows: 1fr 1.1fr 6.9fr 1fr;
  border-radius: 10px;
  overflow: hidden;
  display: grid;
  background-color: rgb(45, 48, 65);
  -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
}
.options {
  height: 40%;
  display: grid;
  text-align: left;
  padding-top: 0.8vw;
  height: 40%;
  display: grid;
  text-align: left;
  grid-template: none;
  grid-template-rows: repeat(7, 25%);
  border-top: solid 2px grey;
  align-items: center;
}
.options > a {
  padding-left: 1.2vw;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: default;
}
.options > a.disabled {
  color: gray;
}
/* selected link */
.options > a.active {
  background-color: rgb(32, 34, 47);
}
#monitor-header {
  background-color: rgb(45, 48, 65);
  height: 100%;
  min-height: 45px;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row: 1;
  z-index: 11;
  display: flex;
  align-items: center;
  padding-left: 1.2vw;
  border-bottom: solid 2px white;
}
#side-options {
  height: 100%;
  min-width: 120px;
  background-color: rgb(45, 48, 65);
  grid-row-start: 2;
  grid-row-end: 5;
  grid-column: 1;
  z-index: 10;
  position: relative;
  border-right: solid 2px white;
  display: grid;
  grid-template-rows: auto;
}
#main-content {
  background-color: rgb(45, 48, 65);
  grid-row: 3;
  grid-column: 2;
  padding-left: 4%;
  padding-right: 4%;
  padding-top: 1.8vw;
  overflow-y: scroll;
  overflow-x: hidden;
  /*   -webkit-box-shadow: inset 0px 16px 8px -10px rgba(0, 0, 0, 0.4), inset 0px -16px 6px -10px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: inset 0px 16px 8px -10px rgba(0, 0, 0, 0.4), inset 0px -16px 6px -10px rgba(0, 0, 0, 0.4);
  box-shadow: inset 0px 16px 8px -10px rgba(0, 0, 0, 0.4), inset 0px -16px 6px -10px rgba(0, 0, 0, 0.4); */
}
#tab {
  background-color: rgb(45, 48, 65);
  grid-row: 2;
  width: 100%;
  border-bottom: solid 1px gray;
  align-items: center;
  display: grid;
  grid-auto-rows: 100%;
  z-index: 9;
  grid-template-columns: 25% 25% 25% 25%;
  -webkit-box-shadow: 1px - 10px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 1px 8px 10px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 1px 8px 10px 0px rgba(0, 0, 0, 0.4);
}
#tab > a {
  white-space: nowrap;
  align-self: center;
  cursor: default;
  margin-left: 10%;
  margin-right: 10%;
  align-self: end;
  font-weight: 600;
  padding-bottom: 7px;
  padding-top: 7px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
#tab > a:hover {
  background-color: rgb(80, 84, 105);
}
#tab > a.active {
  border-bottom: solid 3px rgb(21, 146, 230);
  padding-bottom: 4px;
  color: rgb(21, 146, 230);
}
#tab > a.disabled {
  color: gray;
  pointer-events: none;
  cursor: default;
}
#footer {
  background-color: rgb(45, 48, 65);
  border-top: solid 0.8px gray;
  height: 100%;
  grid-column: 2;
  grid-row: 4;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  -webkit-box-shadow: 1px -8px 10px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 1px -8px 10px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 1px -8px 10px 0px rgba(0, 0, 0, 0.4);
}
#sr-content {
  height: 100%;
  padding-bottom: 20px;
}
.info-container {
  grid-row: 2;
  grid-column: 2;
  display: flex;
  /* flex-direction: column; */
  /* grid-template-columns: repeat(5, 20%); */
  align-content: center;
  text-align: center;
}
.info-container > p {
  margin: 0;
  width: 20%;
}
.sensor-box {
  height: auto;
  text-align: left;
  border-bottom: solid 1px grey;
  display: grid;
  grid-template-columns: 2% 98%;
  grid-template-rows: max-content 10fr;
}
.sensor-head {
  margin: 0;
  margin-top: 5px;
  grid-column: 2;
  grid-row: 1;
  display: flex;
}
.value {
  font-weight: 700;
}
.sensor-box > .sensor-caption {
  margin: 0;
  grid-column: 2;
  grid-row: 2;
}
.side-color {
  width: 6px;
  height: 80%;
  align-self: center;
  grid-column: 1;
  background-color: green;
  grid-row-start: 1;
  grid-row-end: 3;
  display: flex;
  align-items: center;
}

::-webkit-scrollbar {
  width: 0.5em;
}
::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
.close {
  align-self: center;
  cursor: pointer;
  right: 2%;
  width: 3%;
}
input[type="range"]::-moz-range-track {
  padding: 0 10px;
  background: repeating-linear-gradient(to right, #ccc, #ccc 10%, #000 10%, #000 11%, #ccc 11%, #ccc 20%);
}

/* Control Buttons */
.footer-btn {
  display: flex;
  height: 35px;
}
.button {
  width: 158px;
  padding: 2%;
  line-height: 100%;
  margin: auto;
  font-weight: 600;
  margin-right: 20px;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: rgb(80, 84, 105);
  border: solid 2px white;
  border-radius: 7px;
  transition: transform 0.1s linear;
}
.button:active {
  background-color: rgb(47, 112, 177);
  transform: translateY(1px);
}
.button:disabled {
  background-color: grey;
}
.button:disabled:hover {
  background-color: grey;
}
.bottom-buttons {
  position: absolute;
  top: 405px;
  left: 458px;
}
.save {
  background-color: rgb(21, 146, 230);
}
.save:hover {
  background-color: rgba(21, 146, 230, 0.8);
}
/* Color Dropdown */
.dropbtn {
  background-color: #4caf50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}
.dropdown {
  position: relative;
  grid-row: 1;
  grid-column: 4;
}
.dropdown-content {
  transition: visibility 0.2s linear, opacity 0.2s linear;
  -ms-transition: visibility 0.2s linear, opacity 0.2s linear;
  -webkit-transition: visibility 0.2s linear, opacity 0.2s linear;
  display: grid;
  visibility: hidden;
  opacity: 0;
  align-content: center;
  align-items: center;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: 33% 33% 33%;
  position: relative;
  top: -50%;
  right: 74px;
  background-color: rgb(80, 84, 105);
  width: 100px;
  height: 85px;
  border-radius: 5px;
  padding: 5px;
  transition-delay: 0.2s;
}
.dot {
  height: 12px;
  width: 12px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin-left: 8px;
  margin-bottom: 5px;
  align-self: center;
}
.online {
  background-color: green;
}
.offline {
  background-color: red;
}
.dropdown:hover .dropdown-content {
  opacity: 1;
  visibility: visible;
}

/* Edit Icon */
.icon {
  padding: 5px;
  border-radius: 5px;
  -webkit-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
}
.icon:hover {
  background-color: rgb(80, 84, 105);
}
#monitor-outerwrap {
  z-index: 16;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

#monitor-outerwrapheader {
  cursor: move;
}

/* Loading Transition */
.load-enter,
.load-leave-to {
  opacity: 0;
}
.load-enter-active {
  transition: all 0.1s ease;
}
.load-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
</style>
