<i18n>
{
  "en": {
    "alertHeading": "An email notification will be sent when the thresholds set according to the reference regulation are exceeded. Set the alarm parameters of the following devices",
    "workingThreshold": "Working threshold",
    "workingThresholdHeading": "Set the save threshold for devices in programmed operating mode, and activation threshold for those in threshold mode",
    "regulation": "Regulation",
    "workingMode": "Working mode",
    "workingModeHeading": "Set the working mode of the following devices (available only with custom type regulation)",
    "cadenceHeading": "Set the sampling cadence of the following devices (available only for those in programmed mode)",
    "timeTriggered": "Time triggering",
    "thresholdTriggered": "Threshold triggering",
    "thresholdTriggeringMode": "Threshold triggered acquisition mode"
  },
  "it": {
    "alertHeading": "Al superamento delle soglie impostate in base alla normativa di riferimento verrà inviata un’email di notifica. Imposta i parametri di allarme dei seguenti dispositivi",
    "workingThreshold": "Soglia di funzionamento",
    "workingThresholdHeading": "Imposta la soglia di salvataggio per i dispositivi in modalità di funzionamento programmata, e di attivazione per quelli in modalità a soglia",
    "regulation": "Normativa",
    "workingMode": "Modalità di funzionamento",
    "workingModeHeading": "Imposta la modalità di funzionamento dei seguenti dispositivi (disponibile solo con normativa di tipo custom)",
    "cadenceHeading":"Imposta la cadenza di campionamento dei seguenti dispositivi (disponibile solo per quelli in modalità programmata)",
    "timeTriggered": "Programmata",
    "thresholdTriggered": "A soglia",
    "thresholdTriggeringMode": "Modalità di acquisizione a soglia"
  }
}
</i18n>

<template>
  <SettingsContentTemplate :readOnly="readOnly">
    <template v-slot:tabs>
      <a @click="curTab = 'manage'" :class="{ active: curTab === 'manage' }">{{ $t("message.manage") }} </a>
      <a @click="curTab = 'threshold'" :class="{ active: curTab === 'threshold' }">{{ $t("regulation") }} </a>
      <a @click="curTab = 'acq-type'" :class="{ active: curTab === 'acq-type' }">{{ $t("message.acqType") }}</a>
      <a @click="curTab = 'cadence'" :class="{ active: curTab === 'cadence' }">{{ $t("message.cadence") }}</a>
      <a @click="curTab = 'unit'" :class="{ active: curTab === 'unit' }">{{ $t("message.mesUnit") }}</a>
      <a @click="curTab = 'workingThreshold'" :class="{ active: curTab === 'workingThreshold' }">{{ $t("workingThreshold") }}</a>
    </template>
    <template v-slot:content>
      <!-- Gestione -->
      <div v-if="curTab === 'manage'">
        <div class="sr-heading">
          <p>{{ $t("message.manageDevices") }}:</p>
        </div>
        <div id="sr-content">
          <div class="sensor-manage-box" v-for="sensor in tempSensors" :key="sensor.eui">
            <div class="side-color" :style="{ backgroundColor: sensor.userConfig.color }"></div>
            <h4 class="sensor-manage-eui">
              {{ sensor.type + " - " + sensor.eui.substr(sensor.eui.length - 5) }}
            </h4>
            <input
              type="text"
              :class="[validateSensorName(sensor.userConfig.name) ? 'valid' : 'invalid', 'sensor-manage-name input-text']"
              :ref="sensor.eui"
              spellcheck="false"
              maxlength="16"
              minlength="1"
              v-model="sensor.userConfig.name"
              :disabled="!sensor.userConfig.editing"
            />
            <img class="sensor-manage-edit icon" :src="editIcon" @click="editUserConfig(sensor)" />
            <img class="sensor-manage-changecolor icon" :src="changeColorIcon" />
            <div class="dropdown">
              <div class="dropdown-content">
                <div v-for="(color, key, index) in colors" :key="index" class="dot" :style="{ backgroundColor: color }" @click="sensor.userConfig.color = color"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Soglia di Allarme -->
      <div v-if="curTab === 'threshold'">
        <div class="sr-heading">
          <p>{{ $t("alertHeading") }}:</p>
        </div>
        <div id="sr-content">
          <div class="sensor-manage-box expanded-sensor-manage-box" v-for="sensor in tempSensors" :key="sensor.eui">
            <div class="side-color" :style="{ backgroundColor: sensor.userConfig.color }"></div>
            <h4 class="sensor-manage-eui">
              {{ sensor.type + " - " + sensor.eui.substr(sensor.eui.length - 5) }}
            </h4>
            <div class="threshold-field">
              <VibrometerThreshold :interfaceSettings="interfaceSettings && interfaceSettings.dimensions" :normatives="getValidNormatives(sensor)" :sensor="sensor" :editing="true" v-model="sensor.userConfig.threshold" @fullScale="onChangeFullScale($event, sensor)" />
            </div>
          </div>
        </div>
      </div>
      <!-- Type Acqu -->
      <div v-if="curTab === 'acq-type'">
        <div class="sr-heading">
          <p>{{ $t("workingModeHeading") }}:</p>
        </div>
        <div id="sr-content">
          <div class="sensor-box" v-for="sensor in tempSensors" :key="sensor.eui">
            <div class="side-color" :style="{ backgroundColor: sensor.userConfig.color }"></div>
            <h4 :class="[isWorkingModeSettingAvailable(sensor) ? '' : 'textdisabled', 'sensor-manage-eui']">
              {{ sensor.type + " " + sensor.eui.substr(sensor.eui.length - 5) + " - " + sensor.userConfig.name }}
            </h4>
            <p :class="[isWorkingModeSettingAvailable(sensor) ? '' : 'textdisabled', 'sensor-caption']">{{ $t("workingMode") }}:</p>
            <div class="options-wrap">
              <div class="checkbox-container" v-for="sr in workingModes" :key="sr.value">
                <label class="container">
                  <input type="radio" :name="'radio_' + sensor.eui" :value="sr.value" v-model.number="sensor.userConfig.typeAcqu" :disabled="!isWorkingModeSettingAvailable(sensor)" />
                  <span :class="[isWorkingModeSettingAvailable(sensor) ? '' : 'disabled', 'checkmark']"></span>
                </label>
                <label :class="[isWorkingModeSettingAvailable(sensor) ? '' : 'textdisabled', 'value']">{{ sr.label }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Cadenza di campionamento -->
      <div v-if="curTab === 'cadence'">
        <div class="sr-heading">
          <p>{{ $t("cadenceHeading") }}:</p>
        </div>
        <div id="sr-content">
          <div class="sensor-box" v-for="sensor in tempSensors" :key="sensor.eui">
            <div class="side-color" :style="{ backgroundColor: sensor.userConfig.color }"></div>
            <h4 :class="[isCadenceSettingAvailable(sensor) ? '' : 'textdisabled', 'sensor-manage-eui']">
              {{ sensor.type + " " + sensor.eui.substr(sensor.eui.length - 5) + " - " + sensor.userConfig.name }}
            </h4>
            <p :class="[isCadenceSettingAvailable(sensor) ? '' : 'textdisabled', 'sensor-caption']">{{ $t("message.cadence") }}:</p>
            <div v-if="sensor.userConfig.typeAcqu === 'time_triggered'" class="options-wrap">
              <div class="checkbox-container" v-for="sr in cadences" :key="sr.value">
                <label class="container">
                  <input type="radio" :name="'radio_' + sensor.eui" :value="sr.value" v-model.number="sensor.userConfig.sampleRate" :disabled="!isCadenceSettingAvailable(sensor)" />
                  <span :class="[isCadenceSettingAvailable(sensor) ? '' : 'disabled', 'checkmark']"></span>
                </label>
                <label :class="[isCadenceSettingAvailable(sensor) ? '' : 'textdisabled', 'value']">{{ sr.label }}</label>
              </div>
            </div>
            <div v-else class="options-wrap">
              <div class="checkbox-container">
                <p style="font-style: italic;">{{ $t("thresholdTriggeringMode") }}.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Unità di misura -->
      <div v-if="curTab === 'unit'">
        <div class="sr-heading">
          <p>{{ $t("message.mesUnitSelect") }}:</p>
        </div>
        <div id="sr-content">
          <div class="radio-box">
            <div class="options-wrap">
              <div class="checkbox-container" v-for="unit in mesUnits" :key="unit.value">
                <label class="container">
                  <input v-if="interfaceSettings && interfaceSettings.dimensions" type="radio" :value="unit.value" :name="'radio_unit_' + unit.value" @change="$emit('needToReloadEvents')" v-model="interfaceSettings.dimensions.velocity" />
                  <span class="checkmark"></span>
                </label>
                <label class="value">{{ unit.label }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Soglia attivazione/salvataggio -->
      <div v-if="curTab === 'workingThreshold'">
        <div class="sr-heading">
          <p>{{ $t("workingThresholdHeading") }}:</p>
        </div>
        <div id="sr-content">
          <div class="sensor-manage-box" v-for="sensor in tempSensors" :key="sensor.eui">
            <div class="side-color" :style="{ backgroundColor: sensor.userConfig.color }"></div>
            <h4 class="sensor-manage-eui">
              {{ sensor.type + " - " + sensor.eui.substr(sensor.eui.length - 5) }}
            </h4>
            <VibrometerWorkingThreshold :sensor=sensor :velocityUnit=getVelocityUnit :threshold_mms="sensor.userConfig.workingThreshold" @change="onChangeVibrometerWorkingThreshold($event, sensor)" />
            <img class="sensor-manage-edit icon" :src="editIcon" @click="editUserConfig(sensor)" />
          </div>
        </div>
      </div>
    </template>
  </SettingsContentTemplate>
</template>
<script>
import SettingsContentTemplate from "./SettingsContentTemplate.vue";
import { validateEntireSensor, validateSensorName, validateThreshold, validateActivationTh } from "../../../helpers/validate";
import VibrometerThreshold from "./VibrometerThreshold.vue";
import { getManifests } from "../../../services/api";
import VibrometerWorkingThreshold from "./VibrometerWorkingThreshold.vue";

export default {
  name: "vibrometer-settings",
  components: {
    SettingsContentTemplate,
    VibrometerThreshold,
    VibrometerWorkingThreshold
  },
  props: {
    sensors: Array,
    interfaceSettings: Object,
    readOnly: Boolean
  },
  data() {
    return {
      normatives: null,
      normatives1_0: null,
      normatives2_0: null,
      cadences: [
        { value: 1, label: "1 min." },
        { value: 2, label: "2 min." },
        { value: 5, label: "5 min." },
        { value: 15, label: "15 min." },
        { value: 30, label: "30 min." },
      ],
      mesUnits: [
        { label: "mm/s", value: "mm/s" },
        { label: "inch/s", value: "inch/s" },
      ],
      editIcon: require("../../../../public/assets/icons/edit.svg"),
      changeColorIcon: require("../../../../public/assets/icons/changecolor.svg"),
      tempSensors: null,
      curTab: "",
      colors: ["white", "silver", "gray", "black", "red", "yellow", "green", "aqua", "blue", "navy", "fuchsia", "purple"],
    };
  },
  methods: {
    validateSensorName: validateSensorName,
    validateThreshold: validateThreshold,
    validateActivationTh: validateActivationTh,
    // Ritorna true se l'utente può settare la cadenza di campionamento
    isCadenceSettingAvailable(sensor) {
      return sensor.userConfig.typeAcqu === "time_triggered";
    },
    // Ritorna true se l'utente può settare la modalità di funzionamento
    isWorkingModeSettingAvailable(sensor) {
      return sensor.userConfig.threshold.normativeName === "custom";
    },
    // Emette un evento con lo stato di validazione di tutti i sensori
    updateValidation() {
      const validation = !this.tempSensors.some((sensor) => !validateEntireSensor(sensor))
      this.$emit("validation", validation);
    },
    // Se non ci sono item invalidi, abilita/disabilita editing
    editUserConfig(sensor) {
      if (sensor.userConfig.editing) {
        sensor.userConfig.editing = false;
      } else {
        this.tempSensors.forEach((sensor) => {
          sensor.userConfig.editing = false;
        });
        sensor.userConfig.editing = true;
      }
    },
    getValidNormatives(sensor) {
      return this.normatives.filter(n =>  n.supportedSensorRevs.includes(sensor.rev));
    },
    onChangeFullScale(fullScale, sensor) {
      if (sensor.userConfig.fullScale) {
        sensor.userConfig.fullScale = fullScale
      }
    },
    onChangeVibrometerWorkingThreshold(value, sensor){
      sensor.userConfig.workingThreshold = value
    }
  },
  async mounted() {
    // All'avvio prendo i manifest TODO: gestire altre revs
    this.normatives = await getManifests({deviceType: "vibrometer", deviceRev: null, manifestType: "norm"});
    // All'avvio seleziono la prima tab disponibile
    this.curTab = "manage";
    // Deep copy del sensore
    const sensorsFromParent = JSON.parse(JSON.stringify(this.sensors));
    // Aggiungo il campo 'editing'
    this.tempSensors = (sensorsFromParent || []).map((sensor) => ({
      eui: sensor.eui,
      type: sensor.type,
      rev: sensor.rev,
      userConfig: {
        ...sensor.userConfig,
        editing: false,
      },
    }));
    // Se il sensore ha una workingThreshold troppo alta la sistemo
    this.tempSensors.filter(sn => sn.userConfig.fullScale !== undefined).forEach((sn) => {
      if (sn.userConfig.workingThreshold > sn.userConfig.fullScale){
        sn.userConfig.workingThreshold = sn.userConfig.fullScale;
      }
    })
  },
  computed: {
    workingModes() {
      return [
        {
          value: "time_triggered",
          label: this.$t("timeTriggered"),
        },
        {
          value: "threshold_triggered",
          label: this.$t("thresholdTriggered"),
        },
      ];
    },
    getVelocityUnit(){
      return this.interfaceSettings && this.interfaceSettings.dimensions && this.interfaceSettings.dimensions.velocity
        ? this.interfaceSettings.dimensions.velocity
        : 'mm/s'
    }
  },
  watch: {
    /* curTab: function() {
      // Quando cambio tab disattivo l'editing dei sensori
      this.tempSensors.map((a) => (a.userConfig.editing = false));
    }, */
    tempSensors: {
      handler: function(newSensors, oldSensors) {
        if (newSensors && oldSensors) {
          // Mi assicuto che i sensori in modalità non custom acquisicano in modalità programmata
          this.tempSensors.map((sn) => {
            if (sn.userConfig.threshold.normativeName !== "custom") {
              sn.userConfig.typeAcqu = "time_triggered";
            }
            // Se l'uente setta 'threshold_triggered' il sampelRate va forzato a zero
            if (sn.userConfig.typeAcqu === 'threshold_triggered'){
              sn.userConfig.sampleRate = 0;
            }
            // Se l'utente setta 'time_triggered' e precedenemente aveva il sampleRate a zero -> lo metto a 2
            if (sn.userConfig.typeAcqu === 'time_triggered'){
              if (sn.userConfig.sampleRate === 0){
                sn.userConfig.sampleRate = 2;
              }
            }
          });
          this.updateValidation();
          // Se non ci sono sensori in stato 'NON VALIDO' emetto un evento con il nuovo array di sensori
          let someInvalid = this.tempSensors.some((sensor) => !validateEntireSensor(sensor));
          if (!someInvalid) {
            // Elimino il campo 'editing' creato in fase di mount
            const sensorsToEmit = JSON.parse(JSON.stringify(newSensors));
            sensorsToEmit.map((sensor) => {
              delete sensor.userConfig.editing;
              return sensor;
            });
            this.$emit("update", sensorsToEmit);
          }
        }
      },
      deep: true,
    },
  },
  /* beforeDestroy() {
    // Prima del del destroy disattivo editing dei sensori
    this.tempSensors.map((a) => (a.userConfig.editing = false));
  }, */
};
</script>
<style scoped>
.expanded-sensor-manage-box {
  height: fit-content;
  grid-template-rows: unset;
  grid-template-columns: 2% 10fr;
}
</style>
