<template>
  <div id="root" :class="[type === 'danger' ? 'danger' : '']">
    <p><slot name="caption"></slot></p>
    <button :class="[type === 'danger' ? 'red-text' : '']" @click="$emit('action')"><slot name="action"></slot></button>
  </div>
</template>
<script>
export default {
  name: "badge-notifications-template",
  props: {
    type: {
      type: String,
      default: "danger",
    },
  },
};
</script>
<style scoped>
/* Root Wrapper */
#root {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 700px;
  width: 96%;
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  min-height: 30px;
  border: solid 3px white;
  -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  padding: 0 40px;
  box-sizing: border-box;
}
.danger {
  background-color: rgb(46,48,64);;
}
p {
  font-weight: 600;
}
button {
  padding: 6px 8px;
  height: min-content;
  border: none;
  background-color: white;
  color: black;
  font-family: inherit;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
}
.red-text {
  color: rgb(46,48,64);;
}
</style>
