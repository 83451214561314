<template>
  <div class="threshold-field">
    <h2>{{ "[" + (velocityUnit || " - ") + "]" }}</h2>
    <input
      type="number"
      min="0"
      step="any"
      class="sensor-manage-threshold input-text"
      :class="[valid ? 'valid' : 'invalid']"
      spellcheck="false"
      maxlength="10e"
      :value="threshold"
      @input="onChangeThreshold"
      :disabled="!sensor.userConfig.editing"
    />
  </div>
</template>

<script>
import { inchToMm, mmToInch } from "../../../helpers/math";
import { validateActivationTh } from "../../../helpers/validate";
import { truncateVibrometerThreshold } from "../../../helpers/sensors";

export default {
  name: "VibrometerWorkingThreshold",
  props: {
    sensor: {
      type: Object,
      default: () => {}
    },
    velocityUnit: {
      type: String,
      default: "mm/s"
    },
    threshold_mms: {
      type: Number //sempre in mm/s
    }
  },

  data() {
    return {
      threshold: null,
    };
  },

  methods: {
    validateActivationTh: validateActivationTh,
    onChangeThreshold(evt) {
      this.threshold = parseFloat(evt.target.value);
      this.threshold = truncateVibrometerThreshold(
        this.threshold,
        this.velocityUnit
      );
      const threshold_mms = this.toThresholdMms(this.threshold);

      this.$emit("change", threshold_mms);
    },
    toActualThreshold(value) {
      return this.velocityUnit === "inch/s" ? mmToInch(value, 5) : value;
    },
    toThresholdMms(value) {
      return this.velocityUnit === "inch/s" ? inchToMm(value, 3) : value;
    }
  },
  computed:{
    valid(){
      return this.sensor && validateActivationTh(this.sensor, this.sensor.userConfig.workingThreshold)
    }
  },
  watch: {
    threshold_mms: function(newVal, oldVal) {
      if (newVal && oldVal === null) {
        this.threshold = this.toActualThreshold(newVal);
      }
    }
  },
  mounted() {
    this.threshold = this.toActualThreshold(this.threshold_mms);
  }
};
</script>

<style scoped>
.input-text {
  background-color: rgba(0, 0, 0, 0);
  width: 120px;
  height: 30px;
  outline-width: 0;
  outline: none !important;
  border: none;
  display: inline;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  padding-bottom: 0;
  white-space: nowrap;
  display: flex;
  align-items: center;
  align-self: center;
  -webkit-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
  color: white;
  padding: 1px 2px 0px 6px;
}
.input-text:enabled {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}
.input-text:enabled.valid {
  border: solid 1.2px green !important;
}
.input-text.invalid {
  border: solid 1.2px red !important;
}

.threshold-field {
  align-items: center;
}

.threshold-field > .input-text {
  margin-left: 8px;
}
</style>
