<template>
  <div class="line-plot-wrapper" v-if="axisNames && axisValues">
    <div :ref="this.uuid" class="peaks-plot"></div>
  </div>
</template>

<script>
import Plotly from 'plotly.js-dist-min';
export default {
  name: "vibrometer-lnorm-plot",
  props: {
    axisNames: Array,
    axisValues: Array,
    height: Number,
    yTitle: {
      type: String,
      default: "[mm/s]"
    }
  },
  data() {
    return {
      uuid: Math.random()
        .toString(36)
        .substr(2, 15),
      firstExec: true,
      loading: false,
      config: {
        displayModeBar: false,
        responsive: true,
      },
      /* Trace di base a cui vengono aggiunti attributi in base al grafico */
      baseLineTrace: {
        connectgaps: true,
        line: {
          dash: "dashdot",
          color: "rgb(17, 205, 239)",
          width: 2,
        },
        name: "Peaks Line",
        type: "scatter",
        xaxis: "x",
        yaxis: "y",
        opacity: 0.9,
        textfont: {},
      },
    };
  },
  computed: {
    traces: function() {
      const freqs = this.axisValues.map((av) => av.freq);
      const amplitudes = this.axisValues.map((av) => av.amplitude);
      let trace = JSON.parse(JSON.stringify(this.baseLineTrace));
      trace.x = freqs;
      trace.y = amplitudes;
      trace.line.color = "orange";
      return [trace]
    },
    chart: function() {
      return {
        layout: {
          showlegend: false,
          margin: {
            l: 45,
            r: 10,
            b: 40,
            t: 10,
            pad: 0,
          },
          title: {
            text: this.title || "",
            font: {
              family: "Poppins",
              size: 22,
            },
          },
          autosize: true,
          xaxis: {
            tickangle: 0,
            tickfont: { family: "Poppins", size: 12 },
            title: {
              text: this.xTitle || "[Hz]",
              font: {
                family: "Poppins",
                size: 12,
              },
            },
            gridcolor: "rgba(150,153,162,0.6)",
            gridwidth: 1,
            linecolor: "rgba(150,153,162,0.6)",
            linewidth: 2,
            range: this.xRange || null,
          },
          yaxis: {
            title: {
              text: this.yTitle,
              font: {
                family: "Poppins",
                size: 12,
              },
            },
            gridcolor: "rgba(150,153,162,0.4)",
            gridwidth: 1,
            range: this.yRange || null,
          },
          height: this.height || 200,
          width: this.height || 300,
          bargap: 0.8,
          //boxgap: 0.3
          hovermode: "closest",
          paper_bgcolor: "rgba(45,48,65,1)",
          plot_bgcolor: "rgba(45,48,65,1)",
          font: {
            family: "Poppins",
            size: 12,
            color: "rgb(255,255,255)",
          },
        },
      };
    },
  },
  mounted() {
    Plotly.newPlot(this.$refs[this.uuid], this.traces, this.chart.layout, this.config);
  },
  watch: {
    traces: {
      handler: function() {
        if (this.$refs[this.uuid]) {
          Plotly.react(this.$refs[this.uuid], this.traces, this.chart.layout, this.config);
        }
      },
      deep: true,
    },
    chart: {
      handler: function() {
        if (this.$refs[this.uuid]) {
          Plotly.newPlot(this.$refs[this.uuid], this.traces, this.chart.layout, this.config);
        }
      },
      deep: true,
    },
    height: {
      handler: function() {
        if (this.$refs[this.uuid]) {
          Plotly.newPlot(this.$refs[this.uuid], this.traces, this.chart.layout, this.config);
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
p {
  color: white;
}
.hovertext > rect {
  fill: rgba(0, 0, 0, 0.116) !important;
}
.line-plot-wrapper {
  height: fit-content;
}
</style>
