<template>
  <ProbeConfiguration
    v-if="curTab === 'probe-configuration'"
    :rawDataModeSettings="[
      'header',
      'probe-name',
      'setting-mode',
      'warmup',
      'unit-of-measurement',
      'ntc'
    ]"
    :rawDataModeOptions="{
      disableMesUnitInput: true,
      disableABCFormula: true,
      disableNtcCompensation: true,
    }"
    :customDataModeSettings="[
      'header',
      'probe-name',
      'setting-mode',
      'warmup',
      'unit-of-measurement',
      'data-conversion-4-params',
      'ntc'
    ]"
    :customDataModeOptions="{
      disableMesUnitInput: false,
      disableABCFormula: false,
      disableNtcCompensation: true,
      rawUnit: 'mA'
    }"
    :curTab="curTab"
    :sensor="sensor"
  />
  <AcquisitionParameter
    v-else-if="curTab === 'acquisition-parameters'"
    :curTab="curTab"
    :sensor="sensor"
    :settings="[
      'header',
      'cadence',
      'threshold-sampling',
      'advance-options',
      'zeroing'
    ]"
  />
  <Alarm v-else-if="curTab === 'alarm'" :curTab="curTab" :sensor="sensor" />
</template>

<script>
import ProbeConfiguration from "./ProbeConfiguration.vue";
import AcquisitionParameter from "./AcquisitionParameter.vue";
import Alarm from "./Alarm.vue";
export default {
  name: "typo-420-config",
  components: { ProbeConfiguration, AcquisitionParameter, Alarm },
  props: {
    curTab: null,
    sensor: null
  }
};
</script>

<style scoped></style>
