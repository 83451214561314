<i18n>
{
  "en": {
    "validationThreshold": {
      "message": "The threshold value must be between a minimum of {minTh} and a maximum of {maxTh} mm"
      } 
    },
  "it": {
    "validationThreshold": {
      "message": "Il valore di soglia deve essere compreso tra un minimo di {minTh} e un massimo di {maxTh} mm"
      } 
    }
  }
</i18n>

<template>
  <SettingsContentTemplate :readOnly="readOnly">
    <template v-slot:tabs>
      <a @click="curTab = 'manage'" :class="{ active: curTab === 'manage' }">{{ $t("message.manage") }} </a>
      <a @click="curTab = 'threshold'" :class="{ active: curTab === 'threshold' }">{{ $t("message.alert") }} </a>
      <a @click="curTab = 'resolution'" :class="{ active: curTab === 'resolution' }">{{ $t("message.resolution") }} </a>
      <a @click="curTab = 'activationThreshold'" :class="{ active: curTab === 'activationThreshold' }">{{ $t("message.activationThreshold") }} </a>
    </template>
    <template v-slot:content>
      <!-- Gestione -->
      <div v-if="curTab === 'manage'">
        <div class="sr-heading">
          <p>{{ $t("message.manageDevices") }}:</p>
        </div>
        <div id="sr-content">
          <div class="sensor-manage-box" v-for="sensor in tempSensors" :key="sensor.eui">
            <div class="side-color" :style="{ backgroundColor: sensor.userConfig.color }"></div>
            <h4 class="sensor-manage-eui">
              {{ sensor.type + " - " + sensor.eui.substr(sensor.eui.length - 5) }}
            </h4>
            <input
              type="text"
              :class="[validateSensorName(sensor.userConfig.name) ? 'valid' : 'invalid', 'sensor-manage-name input-text']"
              :ref="sensor.eui"
              spellcheck="false"
              maxlength="16"
              minlength="1"
              v-model="sensor.userConfig.name"
              :disabled="!sensor.userConfig.editing"
            />
            <img class="sensor-manage-edit icon" :src="editIcon" @click="editUserConfig(sensor)" />
            <img class="sensor-manage-changecolor icon" :src="changeColorIcon" />
            <div class="dropdown">
              <div class="dropdown-content">
                <div v-for="(color, key, index) in colors" :key="index" class="dot" :style="{ backgroundColor: color }" @click="sensor.userConfig.color = color"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Soglia di Allarme -->
      <div v-if="curTab === 'threshold'">
        <div class="sr-heading">
          <p>{{ $t("message.alertDevices") }}:</p>
        </div>
        <div id="sr-content">
          <div class="sensor-manage-box" v-for="sensor in tempSensors" :key="sensor.eui">
            <div class="side-color" :style="{ backgroundColor: sensor.userConfig.color }"></div>
            <h4 class="sensor-manage-eui">
              {{ sensor.type + " - " + sensor.eui.substr(sensor.eui.length - 5) }}
            </h4>
            <div class="threshold-field">
              <h2>{{ "[" + (mesaurementUnit || " - ") + "]" }}</h2>
              <input
                type="number"
                min="0"
                step="any"
                :ref="sensor.eui"
                :class="[validateThreshold(sensor, sensor.userConfig.threshold) ? 'valid' : 'invalid', 'sensor-manage-threshold input-text']"
                spellcheck="false"
                maxlength="10e"
                v-model.number="sensor.userConfig.threshold"
                :disabled="!sensor.userConfig.editing"
                @keydown.enter="editUserConfig(sensor)"
              />
              
            </div>
            <img class="sensor-manage-edit icon" :src="editIcon" @click="editUserConfig(sensor)" />
          </div>
        </div>
      </div>
      <!-- Risoluzione -->
      <div v-if="curTab === 'resolution'">
        <div class="sr-heading">
          <p>{{ $t("message.setResolution") }}:</p>
        </div>
        <div id="sr-content">
          <div class="sensor-box" v-for="sensor in tempSensors" :key="sensor.eui">
            <div class="side-color" :style="{ backgroundColor: sensor.userConfig.color }"></div>
            <h4 class="sensor-manage-eui">
              {{ sensor.type + " " + sensor.eui.substr(sensor.eui.length - 5) + " (" + sensor.rev + ")" + " - " + sensor.userConfig.name }}
            </h4>
            <p class="sensor-caption">Resolution [{{ mesaurementUnit }}] / Full Scale ({{ mesaurementUnit }}-pp):</p>
            <div class="options-wrap" v-if="sensor.rev != '1.0'">
              <div class="checkbox-container" v-for="sr in resolutions" :key="sr.value">
                <label class="container">
                  <input type="radio" :name="'radio_' + sensor.eui" :value="sr.value" v-model.number="sensor.userConfig.resolution" />
                  <span class="checkmark"></span>
                </label>
                <label class="value">{{ sr.label }}</label>
              </div>
            </div>
            <div class="options-wrap" v-else>
              <p><i>Funzione non supportata da questa versione</i></p>
            </div>
          </div>
        </div>
      </div>
      <!-- Soglia attivazione -->
      <div v-if="curTab === 'activationThreshold'">
        <div class="sr-heading">
          <p>{{ $t("message.setActivationThreshold") }}:</p>
        </div>
        <div id="sr-content">
          <div class="sensor-manage-box" v-for="sensor in tempSensors" :key="sensor.eui">
            <div class="side-color" :style="{ backgroundColor: sensor.userConfig.color }"></div>
            <h4 class="sensor-manage-eui">
              {{ sensor.type + " - " + sensor.eui.substr(sensor.eui.length - 5) }}
            </h4>
            <div class="threshold-field">
              <h2>{{ "[" + (mesaurementUnit || " - ") + "]" }}</h2>
              <input
                type="number"
                min="0"
                step="any"
                :ref="sensor.eui"
                class="sensor-manage-threshold"
                :class="[validateActivationTh(sensor, sensor.userConfig.activationThreshold) ? 'valid-field' : 'invalid-field']"
                spellcheck="false"
                maxlength="10e"
                v-model.number="sensor.userConfig.activationThreshold"
                :disabled="!sensor.userConfig.editing"
                @keydown.enter="editUserConfig(sensor)"
              />
            </div>
            <MoveText v-if="!validateActivationTh(sensor, sensor.userConfig.activationThreshold)" 
              class="errorMessageBox" color="red" p>
              {{ 
              `${$t('validationThreshold.message', {
                  minTh: getDeckThreshold(sensor.userConfig.resolution).min,
                  maxTh: getDeckThreshold(sensor.userConfig.resolution).max
                })
              }`
              }}
            </MoveText>
            <img class="sensor-manage-edit icon" :src="editIcon" @click="editUserConfig(sensor)" />
          </div>
        </div>
      </div>
    </template>
  </SettingsContentTemplate>
</template>
<script>
import SettingsContentTemplate from "./SettingsContentTemplate.vue";
import MoveText from "../../../components/ui_V2/MoveText.vue";
import { validateEntireSensor, validateSensorName, validateThreshold, validateActivationTh } from "../../../helpers/validate";
import { getDeckThreshold } from "../../../helpers/sensors";


export default {
  name: "deck-settings",
  components: {
    SettingsContentTemplate,
    MoveText
  },
  props: {
    sensors: Array,
    readOnly: Boolean
  },
  data() {
    return {
      editIcon: require("../../../../public/assets/icons/edit.svg"),
      changeColorIcon: require("../../../../public/assets/icons/changecolor.svg"),
      mesaurementUnit: "mm",
      resolutions: [
        { value: 0.012, label: "0.012/3" },
        { value: 0.024, label: "0.024/6" },
      ],
      tempSensors: null,
      curTab: "",
      colors: ["white", "silver", "gray", "black", "red", "yellow", "green", "aqua", "blue", "navy", "fuchsia", "purple"],
    };
  },
  methods: {
    validateSensorName: validateSensorName,
    validateThreshold: validateThreshold,
    validateActivationTh: validateActivationTh,
    getDeckThreshold: getDeckThreshold,
    // Emette un evento con lo stato di validazione di tutti i sensori
    updateSensorsValidation() {
      this.$emit("validation", !this.tempSensors.some((sensor) => !validateEntireSensor(sensor)));
    },
    editUserConfig(sensor) {
      if (sensor.userConfig.editing) {
        sensor.userConfig.editing = false;
      } else {
        this.tempSensors.forEach((sensor) => {
          sensor.userConfig.editing = false;
        });
        sensor.userConfig.editing = true;
        setTimeout(() => {
          this.$refs[sensor.eui][0].focus();
        }, 0);
      }
    },
  },
  mounted() {
    // All'avvio seleziono la prima tab disponibile
    this.curTab = "manage";
    // Deep copy del sensore
    const sensorsFromParent = JSON.parse(JSON.stringify(this.sensors));
    // Aggiungo il campo 'editing'
    this.tempSensors = (sensorsFromParent || []).map((sensor) => ({
      eui: sensor.eui,
      type: sensor.type,
      rev: sensor.rev,
      userConfig: {
        ...sensor.userConfig,
        editing: false,
      },
    }));
  },
  watch: {
    /* curTab: function() {
      // Quando cambio tab disattivo l'editing dei sensori
      this.tempSensors.map((a) => (a.userConfig.editing = false));
    }, */
    tempSensors: {
      handler: function(newSensors, oldSensors) {
        if (newSensors && oldSensors) {
          this.updateSensorsValidation();
          // Se non ci sono sensori in stato 'NON VALIDO' emetto un evento con il nuovo array di sensori
          let someInvalid = this.tempSensors.some((sensor) => !validateEntireSensor(sensor));
          if (!someInvalid) {
            // Elimino il campo 'editing' creato in fase di mount
            const sensorsToEmit = JSON.parse(JSON.stringify(newSensors));
            sensorsToEmit.map((sensor) => {
              delete sensor.userConfig.editing;
              return sensor;
            });
            this.$emit("update", sensorsToEmit);
          }
        }
      },
      deep: true,
    },
  },
  /* beforeDestroy() {
    // Prima del del destroy disattivo editing dei sensori
    this.tempSensors.map((a) => (a.userConfig.editing = false));
  }, */
};
</script>

<style scoped>


.errorMessageBox {
  grid-column: 3;
  grid-row: 2;
  align-self:center;
  font-size: 0.7em;
}

</style>
