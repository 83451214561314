<i18n>
  {
    "en": {
        "message": "The sensor settings are in read-only mode. To modify the settings, please visit"
      },
    "it": {
        "message": "Le impostazioni per il sensore sono in modalità di sola lettura. Per modificare le impostazioni visita"
      }
    }
  </i18n>

<template>
  <div id="root">
    <div id="tab" class="scroll-thin">
      <slot name="tabs"></slot>
    </div>
    <div id="wrapper">
      <div id="side">
        <slot name="side"></slot>
      </div>
      <div id="main-content" class="scroll-thin" @scroll="updateModalScroll">
        <div class="overlay" v-if="readOnly">
          <div class="item">
            <font-awesome-icon icon="fa-solid fa-warning"/>
            <div>
              {{ $t("message") }}
              <a :href="myMoveUrl" target="_blank" style="color: var(--primary-color);font-size: 1rem;"
                >MyMove</a
              >
            </div>
          </div>
        </div>

        <div :class="{ 'read-only': readOnly }">
          <slot name="content"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { NEW_PLATFORM_WEBAPP_URL } from "../../../config";
export default {
  name: "settings-content-template",
  props: {
    readOnly: Boolean
  },
  data() {
    return {
      curTab: ["manage"],
      tabs: ["manage", "threshold", "resolution", "activationThreshold"],
      myMoveUrl: NEW_PLATFORM_WEBAPP_URL
    };
  },
  methods: {
    /* --------------------------------------------------------------- */
    /* VUEX ACTIONS */
    /* --------------------------------------------------------------- */
    ...mapActions({
      updateModalScroll: "settings/updateModalScroll"
    })
  }
};
</script>

<style scoped>
.read-only {
  pointer-events: none;
  opacity: 0.5;
}

.overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--card-bg-color);
  z-index: 10;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);

}

.overlay .item {
  font-size: 0.8em;
  width: 500px;
  height: 100%;
  
  padding: 20px 12px;
}

#root {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}
#wrapper {
  display: grid;
  grid-template-rows: 100%;
  height: 100%;
  grid-template-columns: min-content 10fr;
  overflow-y: auto;
  height: calc(90vh - 160px);
}
#side {
  height: 100%;
  border-right: solid 1px grey;
}
#tab {
  height: 50px;
  position: relative;
  background-color: rgb(45, 48, 65);
  width: 100%;
  border-bottom: solid 0.8px gray;
  align-items: center;
  display: grid;
  grid-auto-rows: 100%;
  z-index: 2;
  grid-template-columns: repeat(7, 10fr);
  box-sizing: border-box;
  padding-left: 12px;
  padding-right: 12px;
  -webkit-box-shadow: 1px - 10px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 1px 8px 10px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 1px 8px 10px 0px rgba(0, 0, 0, 0.4);
  overflow-x: auto;
}
#tab > a {
  white-space: nowrap;
  align-self: center;
  cursor: default;
  align-self: end;
  font-weight: 600;
  padding-bottom: 7px;
  padding-top: 7px;
  padding-left: 64px;
  padding-right: 64px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  box-sizing: border-box;
}
#tab > a:hover {
  background-color: rgb(80, 84, 105);
}
#tab > a.active {
  border-bottom: solid 3px rgb(21, 146, 230);
  padding-bottom: 4px;
  color: rgb(21, 146, 230);
}
#tab > a.disabled {
  color: gray;
  pointer-events: none;
  cursor: default;
}

#main-content {
  background-color: rgb(45, 48, 65);
  grid-row: 1;
  grid-column: 2;
  padding-left: 4%;
  padding-right: 4%;
  padding-top: 1.2vw;
  padding-bottom: 60px;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
}

* {
  font-style: normal;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: white;
}
input[disabled] {
  pointer-events: none;
}
.dark-overlay {
  background-color: rgba(0, 0, 0, 0.27);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9;
}
h1 {
  font-size: 1em;
}
a {
  color: white;
  font-size: 0.75em;
}
p {
  color: rgb(198, 198, 198);
  padding: 0px;
}
label {
  font-size: 0.7em;
}
.horiz-divider {
  background-color: grey;
  height: 2px;
  margin: 4px 0;
}
.input-text {
  background-color: rgba(0, 0, 0, 0);
  width: 120px;
  height: 30px;
  outline-width: 0;
  outline: none !important;
  border: none;
  display: inline;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  padding-bottom: 0;
  white-space: nowrap;
  display: flex;
  align-items: center;
  align-self: center;
  -webkit-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
}
.input-text:enabled {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}
.input-text:enabled.valid {
  border: solid 1.2px green !important;
  padding: 1px;
}
.input-text.invalid {
  border: solid 1.2px red !important;
  padding: 1px;
}

/* .settings-container {
  width: 82vw;
  height: 64vh;
  min-width: 1180px;
  min-height: 480px;
  top: 22vh;
  left: 0;
  position: sticky;
  grid-template-columns: 15% 85%;
  grid-template-rows: 1fr 1.1fr 6.9fr 1fr;
  border-radius: 10px;
  overflow: hidden;
  display: grid;
  background-color: rgb(45, 48, 65);
  -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
} */
.options {
  display: grid;
  text-align: left;
  height: 40%;
  display: grid;
  text-align: left;
  grid-template: none;
  align-items: center;
}
.options > a {
  padding-left: 1.2vw;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: default;
  margin: 14px 0;
  color: white;
}

/* selected link */
.options > a.active {
  background-color: rgb(32, 34, 47) !important;
}
.options > a.disabled {
  color: gray;
  pointer-events: none;
  cursor: default;
}
#settings-header {
  background-color: rgb(45, 48, 65);
  height: 100%;
  min-height: 45px;
  grid-column: 1/3;
  grid-row: 1;
  z-index: 11;
  display: flex;
  align-items: center;
  padding-left: 1.2vw;
  border-bottom: solid 2px white;
}
#side-options {
  height: 100%;
  min-width: 120px;
  background-color: rgb(45, 48, 65);
  grid-row-start: 2;
  grid-row-end: 5;
  grid-column: 1;
  z-index: 10;
  position: relative;
  border-right: solid 2px white;
  display: grid;
  grid-template-rows: auto;
}

#footer {
  background-color: rgb(45, 48, 65);
  border-top: solid 0.8px gray;
  height: 100%;
  grid-column: 2/4;
  grid-row: 4;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 2;
  -webkit-box-shadow: 1px -8px 10px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 1px -8px 10px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 1px -8px 10px 0px rgba(0, 0, 0, 0.4);
}
#sr-content {
  height: 100%;
}
.grid-content {
  display: grid;
  width: 60%;
  justify-items: baseline;
  row-gap: 12px;
  column-gap: 12px;
}
.grid-content > input {
  grid-column: 2;
}
.grid-content > select {
  grid-column: 2;
}
.grid-content > h4 {
  grid-column: 1;
  margin: 0;
}
.input-group {
  display: flex;
  width: 100%;
}
.sr-heading {
  text-align: left;
  margin-bottom: 15px;
  height: 35px;
  display: flex;
}
.sr-heading > h4 {
  margin: 0;
}
.sr-heading > p {
  margin: 0;
}
.checkbox-container {
  display: grid;
  width: 100%;
  justify-items: center;
}
.sensor-box {
  height: 70px;
  text-align: left;
  border-bottom: solid 1px grey;
  display: grid;
  grid-template-columns: 2% 49% 49%;
  grid-template-rows: 50% 50%;
  padding-top: 1%;
}
.sensor-box > .sensor-head {
  margin: 0;
  font-weight: 700;
  grid-column: 2;
  grid-row: 1;
  align-self: center;
}
.sensor-box > .sensor-caption {
  margin: 0;
  grid-column: 2;
  grid-row: 2;
  align-self: center;
}

.sensor-box > input {
  grid-column: 3;
  grid-row: 2;
}

.sensor-manage-box {
  height: 80px;
  text-align: left;
  position: relative;
  border-bottom: solid 2px rgba(255, 255, 255, 0.15);
  display: grid;
  grid-template-columns: 2% 26% 54% 8% 9%;
  grid-template-rows: 50% 50%;
  padding-top: 1%;
}
.sensor-manage-eui {
  margin: 0;
  white-space: nowrap;
  font-weight: 700;
  align-self: center;
  padding-top: 1%;
  grid-column: 2;
  grid-row: 1;
}
.sensor-manage-name {
  grid-column: 2;
  grid-row: 2;
}
.threshold-field {
  grid-column: 2/4;
  grid-row: 2;
  display: flex;
  align-self: center;
}
.threshold-field > input[type="checkbox"] {
  align-self: center;
}
.threshold-field > h2 {
  font-weight: 700;
  align-self: center;
  white-space: nowrap;
  margin: 0;
  padding: 0;
}
.threshold-field > h4 {
  margin: 8px 0;
}
.threshold-field > .sensor-manage-threshold {
  background-color: rgba(0, 0, 0, 0);
  width: 150px;
  outline-width: 0;
  outline: none !important;
  /* border: none; */
  display: inline;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  margin-left: 10px;
  display: flex;
  align-items: center;
  align-self: center;
  -webkit-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
}
.threshold-field > .sensor-manage-threshold:enabled {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}
.sensor-manage-box > .sensor-manage-name:enabled {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}
.sensor-manage-box > .sensor-manage-label {
  margin: 0;
  grid-row: 1;
  grid-column: 3;
  align-self: center;
}
.sensor-manage-box > .sensor-manage-edit {
  justify-self: end;
  align-self: end;
  grid-row: 1;
  grid-column: 5;
}
.sensor-manage-box > .sensor-manage-changecolor {
  justify-self: end;
  align-self: end;
  grid-row: 1;
  grid-column: 4;
}
.side-color {
  width: 6px;
  height: 80%;
  align-self: center;
  grid-column: 1;
  background-color: green;
  grid-row-start: 1;
  grid-row-end: 3;
  display: flex;
  align-items: center;
}
.select-options {
  margin-right: 10px;
}
.disabled-select {
  background-image: none;
  background-color: rgba(255, 255, 255, 0.15) !important;
}
/* Span */
.span-name {
  grid-column: 1;
}
.span-name-field {
  grid-column: 1;
  grid-row: 2;
  grid-template-columns: 50px auto;
  display: grid;
  padding: 6px 0;
}

.span-lenght-field {
  grid-column: 1;
  grid-row: 3;
  grid-template-columns: 50px auto;
  display: grid;
  padding: 6px 0;
}

.span-box {
  height: fit-content;
  text-align: left;
  border-bottom: solid 2px rgba(255, 255, 255, 0.15);
  display: grid;
  /* grid-template-rows: 50% 50%; */
  padding-top: 1%;
  grid-template-columns: 80% 10% 10%;
}
.span-box > .input-text {
  width: 100%;
}

/* Items */
.item-box {
  height: 80px;
  text-align: left;
  border-bottom: solid 2px rgb(80, 84, 105);
  display: grid;
  grid-template-columns: 4fr 4fr 1fr 1fr;
  column-gap: 20px;
}

.item-box > .input-text {
  width: 100%;
}
.notify-heading {
  text-align: left;
  padding-bottom: 15px;
  margin-bottom: 10px;
  border-bottom: solid 2px rgb(80, 84, 105);
}
.notify-heading > h4 {
  margin: 0;
}
.notify-heading > p {
  margin: 0;
}

.notify-heading-container > .title > h4 {
  margin: 0;
}

.notify-heading-container > .title > p {
  margin: 0;
}

.notify-heading-container {
  display: flex;
  text-align: left;
  padding-bottom: 15px;
  margin-bottom: 10px;
  border-bottom: solid 2px rgb(80, 84, 105);
  align-items: center;
  justify-content: space-between;
}
.alert-sensor-types {
  grid-column: 2;
  grid-row: 1/3;
  display: grid;
  grid-template-columns: min-content auto min-content auto;
  align-items: center;
  grid-template-rows: max-content max-content;
  margin: auto 0;
}
.item-box > .switch {
  grid-column: 3;
  grid-row-start: 1;
  grid-row-end: 3;
  align-self: center;
}
.mail-edit-icon {
  min-height: 25px;
  width: 25px;
  grid-column: 4;
  grid-row-start: 1;
  grid-row-end: 3;
  justify-self: end;
  align-self: center;
}
.mail-delete-icon {
  min-height: 25px;
  width: 25px;
  grid-column: 3;
  grid-row-start: 1;
  grid-row-end: 3;
  justify-self: end;
  align-self: center;
}
.mail-name {
  grid-column: 1;
  grid-row: 1;
  font-weight: 700;
}
.mail-addr {
  grid-column: 1;
  grid-row: 2;
  margin: 0;
  color: rgb(198, 198, 198);
}
.add-box {
  display: flex;
  align-items: center;
  align-content: center;
}
.add-box > h5 {
  font-weight: 700;
  padding: 8px;
  border-radius: 5px;
  -webkit-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
  cursor: pointer;
}
.add-box > h5:hover {
  background-color: rgba(80, 84, 105, 1);
}
.add-box > .mail-new-icon {
  width: 5%;
  margin-right: 2%;
  background-color: rgba(0, 0, 0, 0) !important;
}
/* ::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-thumb {
  background-color: darkgrey;
} */
.close {
  align-self: center;
  cursor: pointer;
  right: 2%;
  width: 3%;
}
input[type="range"]::-moz-range-track {
  padding: 0 10px;
  background: repeating-linear-gradient(
    to right,
    #ccc,
    #ccc 10%,
    #000 10%,
    #000 11%,
    #ccc 11%,
    #ccc 20%
  );
}
.options-wrap {
  width: 100%;
  width: 100%;
  display: flex;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-template-rows: 40% 35%;
  grid-template-columns: 25% 25% 25% 25%;
  -webkit-box-align: end;
  -ms-flex-align: end;
  justify-items: center;
  align-self: end;
}
.compensation-opt {
  align-self: center;
}
.options-wrap > input {
  width: 100%;
  grid-row: 1;
}
.options-wrap > .container {
  grid-row: 1;
}
.options-wrap > .value {
  grid-row: 2;
  justify-self: center;
  color: rgb(198, 198, 198);
  margin-left: 5%;
}

/* Control Buttons */
.footer-btn {
  display: flex;
  height: 35px;
}
.button {
  width: 158px;
  padding: 2%;
  line-height: 100%;
  margin: auto;
  font-weight: 600;
  margin-right: 20px;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: rgb(80, 84, 105);
  border: solid 2px white;
  border-radius: 7px;
  transition: transform 0.1s linear;
}
.button:active {
  background-color: rgb(47, 112, 177);
  transform: translateY(1px);
}
.button:disabled {
  background-color: grey;
}
.button:disabled:hover {
  background-color: grey;
}
.bottom-buttons {
  position: absolute;
  top: 405px;
  left: 458px;
}
.save {
  background-color: rgb(21, 146, 230);
}
.save:hover {
  background-color: rgba(21, 146, 230, 0.8);
}
/* Color Dropdown */
.dropbtn {
  background-color: #4caf50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}
.dropdown {
  position: relative;
  grid-row: 1;
  grid-column: 4;
}
.dropdown-content {
  transition: visibility 0.2s linear, opacity 0.2s linear;
  -ms-transition: visibility 0.2s linear, opacity 0.2s linear;
  -webkit-transition: visibility 0.2s linear, opacity 0.2s linear;
  display: grid;
  visibility: hidden;
  opacity: 0;
  align-content: center;
  align-items: center;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: 33% 33% 33%;
  position: relative;
  top: -50%;
  right: 74px;
  background-color: rgb(80, 84, 105);
  width: 100px;
  height: 85px;
  border-radius: 5px;
  padding: 5px;
  transition-delay: 0.2s;
}
.dot {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-block;
  border: solid 1px white;
}
.dropdown:hover .dropdown-content {
  opacity: 1;
  visibility: visible;
}

/* Edit Icon */
.icon {
  padding: 5px;
  border-radius: 5px;
  -webkit-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
}
.icon:hover {
  background-color: rgb(80, 84, 105);
}
/* The container */
.container {
  position: relative;
  display: contents;
  cursor: pointer;
  font-size: 22px;
  user-select: text;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
/* Hide the browser's default radio button */
.container input {
  position: absolute;
  top: 50vh;
  opacity: 0;
  cursor: pointer;
}
/* Create a custom radio button */
.checkmark {
  left: 0;
  top: 0;
  height: 14px;
  position: relative;
  width: 14px;
  border-radius: 50%;
  border: solid 3px white;
  z-index: 9;
}
.checkmark.disabled {
  border: solid 3px gray;
}
/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: rgb(80, 84, 105);
}
/* When the radio button is checked, add a blue background */
/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}
/* Style the indicator (dot/circle) */
.container .checkmark:after {
  top: 3px;
  left: 3px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
label.disabled {
  color: gray;
}
.container .checkmark.disabled:after {
  background: gray;
}

#settings-outerwrap {
  z-index: 16;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

#settings-outerwrapheader {
  cursor: move;
}

/* Analog Node Shim */
#side-sensor-select {
  height: 100%;
  width: 80px;
  overflow-y: overlay;
}
.input-text:enabled {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}
.input-text:invalid {
  border: solid 1.2px red;
  padding: 1px;
}
.input-text:valid {
  border: solid 1.2px green;
  padding: 1px;
}

.input-text-username {
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  height: 30px;
  outline-width: 0;
  outline: none !important;
  border: none;
  display: inline;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  padding-bottom: 0;
  white-space: nowrap;
  display: flex;
  align-items: center;
  align-self: center;
  -webkit-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
}

.input-text-username.valid {
  border: solid 1.2px green;
  padding: 1px;
}

.input-text-username.not-valid {
  border: solid 1.2px red;
  padding: 1px;
}

.mail-addr.not-valid {
  border: solid 1.2px red;
  padding: 1px;
}

.input-text-username:invalid {
  border: solid 1.2px red;
  padding: 1px;
}

.input-text-username:enabled {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

.input-text-username:disabled {
  border: none;
}

/* selected link */
.options > a.active {
  background-color: rgb(32, 34, 47);
}
.options > a.disabled {
  color: gray;
  pointer-events: none;
  cursor: default;
}
.single-setting {
  max-width: 500px;
  display: grid;
  grid-template-columns: 140px 10fr;
  justify-items: baseline;
  align-items: center;
  padding: 5px 0;
  height: 30px;
}
.single-setting > label {
  grid-row: 1;
}
.single-setting > select {
  margin-left: 0;
  padding-bottom: 2px;
  padding-top: 2px;
}
.auto-row {
  max-width: 500px;
  height: 30px;
  padding: 5px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#analog-content {
  background-color: rgb(80, 84, 105);
  border-radius: 10px;
  padding: 0 10px;
}
.sensor-selection {
  display: flex;
  justify-content: space-between;
  padding: 14px 30px;
}
.checkbox {
  display: flex;
  align-items: center;
}
.checkbox > input[type="checkbox"] {
  margin-right: 10px;
}
input[type="checkbox"] {
  height: 18px;
  /* width: 18px; */
}
.sr-heading {
  text-align: left;
  margin-bottom: 25px;
}
.sr-heading > h4 {
  margin: 0;
}
.sr-heading > p {
  margin: 0;
}
.checkbox-container {
  display: grid;
  width: 100%;
  justify-items: center;
}
.info-container {
  grid-row: 2;
  grid-column: 2;
  display: grid;
  grid-template-columns: repeat(4, 25%);
  align-content: center;
  text-align: center;
}
.analog-box {
  height: 80px;
  text-align: left;
  border-bottom: solid 1px grey;
  display: grid;
  padding-bottom: 10px;
  grid-template-columns: 2% 98%;
  grid-template-rows: auto 60%;
}
.sensor-head {
  margin: 0;
  margin-top: 10px;
  grid-column: 2;
  grid-row: 1;
  display: flex;
}
.value {
  font-weight: 700;
}
.analog-box > .sensor-caption {
  margin: 0;
  grid-column: 2;
  grid-row: 2;
}
.full-input {
  background-color: rgba(0, 0, 0, 0);
  padding-left: 6px;
  width: 100%;
  height: 100%;
  outline-width: 0;
  box-sizing: border-box;
  outline: none !important;
  /* border: none; */
  display: inline;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  display: flex;
  align-items: center;
  align-self: center;
  -webkit-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
}
.analog-threshold-field > input[type="number"] {
  grid-column: 2;
  margin-left: 10px;
}

.analog-manage-box {
  height: fit-content;
  text-align: left;
  border-bottom: solid 2px rgba(255, 255, 255, 0.15);
  display: grid;
  grid-template-columns: 2% 60% 20% 9% 9%;
}
label {
  white-space: nowrap;
  font-weight: 500;
}
.analog-manage-box > .sensor-manage-eui {
  margin: 0;
  white-space: nowrap;
  font-weight: 700;
  align-self: center;
  padding-top: 1%;
  grid-column: 2;
  grid-row: 1;
}
.sensor-manage-name {
  grid-column: 2;
  grid-row: 2;
}
.sensor-params {
  width: fit-content;
  grid-column: 2;
  grid-row: 2;
}
.analog-threshold-field {
  display: grid;
  row-gap: 10px;
}
.analog-threshold-field > label {
  grid-column: 1;
  font-weight: 400;
  font-size: 0.8em;
  align-self: center;
  margin: 0;
  padding: 0;
}

input[type="number"]:disabled.valid-field,
input[type="text"]:disabled.valid-field {
  border: none;
}
.analog-threshold-field > input[type="number"] {
  grid-column: 2;
  margin-left: 10px;
}
input[type="number"],
input[type="text"]:enabled {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}
.analog-manage-box > .sensor-manage-label {
  margin: 0;
  grid-row: 1;
  grid-column: 3;
  align-self: center;
}
.analog-manage-box > .sensor-manage-edit {
  justify-self: end;
  align-self: end;
  grid-row: 1;
  grid-column: 5;
}
.analog-manage-box > .sensor-manage-changecolor {
  justify-self: end;
  align-self: center;
  grid-row: 1;
  grid-column: 4;
}
.side-color {
  width: 6px;
  height: 80%;
  align-self: center;
  grid-column: 1;
  background-color: green;
  grid-row-start: 1;
  grid-row-end: 3;
  display: flex;
  align-items: center;
  margin-left: -10px;
}
.multiple-control {
  display: grid;
  width: 500px;
  padding: 5px 0;
}
.sub-setting {
  display: flex;
  justify-content: space-between;
}

.sep {
  margin-top: 10px;
  border-top: solid grey 1px;
}
/* Mail */
.mail-box {
  height: 80px;
  text-align: left;
  border-bottom: solid 2px rgb(80, 84, 105);
  display: grid;
  grid-template-columns: 80% 10% 10%;
}
.mail-box > .input-text {
  width: 100%;
}
.notify-heading {
  text-align: left;
  padding-bottom: 15px;
  margin-bottom: 10px;
  border-bottom: solid 2px rgb(80, 84, 105);
}
.notify-heading > h4 {
  margin: 0;
}
.notify-heading > p {
  margin: 0;
}

/* ::-webkit-scrollbar {
  width: 0.5em;
}
::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
} */
.close {
  align-self: center;
  cursor: pointer;
  right: 2%;
  width: 3%;
}
/* input[type="range"]::-moz-range-track {
  padding: 0 10px;
  background: repeating-linear-gradient(to right, #ccc, #ccc 10%, #000 10%, #000 11%, #ccc 11%, #ccc 20%);
} */

/* Control Buttons */
.footer-btn {
  display: flex;
  height: 35px;
}
.button {
  width: 158px;
  padding: 2%;
  line-height: 100%;
  margin: auto;
  font-weight: 600;
  margin-right: 20px;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: rgb(80, 84, 105);
  border: solid 2px white;
  border-radius: 7px;
  transition: transform 0.1s linear;
}
.button:active {
  background-color: rgb(47, 112, 177);
  transform: translateY(1px);
}
.button:disabled {
  background-color: grey;
}
.button:disabled:hover {
  background-color: grey;
}
.bottom-buttons {
  position: absolute;
  top: 405px;
  left: 458px;
}
.save {
  background-color: rgb(21, 146, 230);
}
.save:hover {
  background-color: rgba(21, 146, 230, 0.8);
}
/* Color Dropdown */
.dropbtn {
  background-color: #4caf50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}
.dropdown {
  position: relative;
  grid-row: 1;
  grid-column: 4;
}
.dropdown-content {
  transition: visibility 0.2s linear, opacity 0.2s linear;
  -ms-transition: visibility 0.2s linear, opacity 0.2s linear;
  -webkit-transition: visibility 0.2s linear, opacity 0.2s linear;
  display: grid;
  visibility: hidden;
  opacity: 0;
  align-content: center;
  align-items: center;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: 33% 33% 33%;
  position: relative;
  top: -50%;
  right: 74px;
  background-color: rgb(80, 84, 105);
  width: 100px;
  height: 85px;
  border-radius: 5px;
  padding: 5px;
  transition-delay: 0.2s;
}
.dot {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-block;
  border: solid 1px white;
}
.dropdown:hover .dropdown-content {
  opacity: 1;
  visibility: visible;
}

.valid-field {
  border: solid 1.2px green;
}
.invalid-field {
  border: solid 1.2px red;
}
.no-border {
  border: none !important;
}
/* Loading Transition */
.load-enter,
.load-leave-to {
  opacity: 0;
}
.load-enter-active {
  transition: all 0.1s ease;
}
.load-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
</style>
