<i18n>
{
  "en": {
    "title": "Warmup",
    "info": {
      "message": "Set a warmup time for the probe. It is the time the probe needs to fully reach a steady value. The node will start the acquisition only after that transient."
    },
    "warmupValue": "Warmup time"
  },
  "it": {
    "title": "Warmup",
    "info": {
      "message": "Imposta il warmup della sonda. E' il tempo di cui la sonda ha bisogno per raggiungere un valore di misura stazionario. Il nodo inizierà l'acquisizione a seguito di questo transitorio."
    },
    "warmupValue": "Tempo di preaccensione / Warmup"
  }
}
</i18n>
<template>
  <div>
    <div class="d-flex align-items-center mb4">
      <move-text p b class="mr16"> {{ $t("title") }}: </move-text>
      <move-info rigth>
        <template #icon>
          <font-awesome-icon icon="fa-solid fa-circle-info" />
        </template>
        <div class="w400 p16">
          <move-text p> {{ $t("info.message") }}</move-text>
        </div>
      </move-info>
    </div>
    <div class="m-content">
      <move-text p >{{ `${$t("warmupValue")}: ${vWarmup} s` }}</move-text>
      <vue-slider
        v-model="vWarmup"
        :marks="getOptions"
        :min="getMinWarmup"
        :max="getMaxWarmup"
        :adsorb="true"
        :included="true"
        :hideLabel="false"
        width="100%"
        :disabled="getMode === 'mapped'"
      ></vue-slider>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "scn-warmup",
  computed: {
    ...mapGetters({
      getTempSensor: "settings/getTempSensor",
      getTempSensorUserConfig: "settings/getTempSensorUserConfig",
      getTempSensorChannel0: "settings/getTempSensorChannel0",
      getSensorType: "settings/getSensorType",
      getMode: "settings/getMode",
      getWarmupTime: "settings/getWarmupTime",
    }),
    /*
    Convert an array of warmup values [1, 2, 3, 4, 5 ...] to an object
    {
      1: '1',
      2: '',
      3: '',
      ...
      n: 'n'
    }
    */
    getOptions() {
      return this.getWarmupValues.reduce((accumulator, value, idx, array) => {
        return idx === 0 || idx === array.length - 1
          ? { ...accumulator, [value]: value }
          : { ...accumulator, [value]: "" };
      }, {});
    },
    getWarmupValues(){
      return [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        20,
        25,
        30,
        35,
        40,
        45,
        50,
        55,
        60,
        70,
        80,
        90,
        100,
        120,
        150,
        180
      ]
    },
    getMaxWarmup(){
      return 180
    },
    getMinWarmup(){
      return 1
    },
    vWarmup: {
      get() {
        return this.getWarmupTime || this.getWarmupValues[0];
      },
      set(value) {
        const warmupTime = Number(value)
        const sensor = this.getTempSensor;
        sensor.userConfig.channels[0].warmupTime = warmupTime;
        this.setTempSensor(sensor);
      }
    },

  },
  methods: {
    /* --------------------------------------------------------------- */
    /* VUEX ACTIONS */
    /* --------------------------------------------------------------- */
    ...mapActions({
      setTempSensor: "settings/setTempSensor"
    }),

  },
};
</script>

<style scoped>
.m-content {
  display: grid;
  grid-template-columns: minmax(200px, 4fr) minmax(400px, 9fr);
  align-items: center;
}
</style>
