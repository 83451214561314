<i18n>
  {
    "en": {
      "title": "Alarms",
      "minThreshold": "Minimum threshold",
      "maxThreshold": "Maximum threshold",
      "info":{
        "message":"You can set a band of tolerance for the data, with a lower bound and an upper bound. If the measure detected goes outside of that range, you get an alert at the emails configured on the platform."
      }
    },
    "it": {
      "title": "Allarmi",
      "minThreshold": "Soglia minima",
      "maxThreshold": "Soglia massima",
      "info":{
        "message":"Puoi settare una banda di tolleranza per i dati, con un limite inferiore e un limite superiore. Se la misura va al di fuori di quella fascia, ricevi una segnalazione alle mail configurate in piattaforma."
      }
    }
  }
</i18n>
<template>
  <div>
    <div class="d-flex align-items-center justify-content-space-between mb16">

      <div class="d-flex align-items-center">
      <move-text b p class="mr16"> {{ $t("title") }}: </move-text>
      <move-info rigth>
        <template #icon>
          <font-awesome-icon icon="fa-solid fa-circle-info" />
        </template>
        <div class="w400 p16">
          <move-text p> {{ $t("info.message") }}</move-text>
        </div>
      </move-info>
    </div>

      <div class="d-flex align-items-center">
        <move-switch v-model="vAlarmSwitch"></move-switch>
        <move-text p class="ml8">{{ vAlarmSwitch ? "On" : "Off" }}</move-text>
      </div>
    </div>
    <div class="d-flex">
      <div class="mr8">
        <move-text caption class="mb4" :class="{ opacity: !vAlarmSwitch }"
          >{{ $t("minThreshold") }}:</move-text
        >
        <move-input-number
          tertiary
          v-model="vMinThreshold"
          :disabled="!vAlarmSwitch"
          class="w300"
          :valid="getValidation.minThreshold || !vAlarmSwitch"
        >
          <move-text p>{{ this.getMesUnit }}</move-text>
        </move-input-number>
      </div>
      <div>
        <move-text caption class="mb4" :class="{ opacity: !vAlarmSwitch }"
          >{{ $t("maxThreshold") }}:</move-text
        >
        <move-input-number
          tertiary
          v-model="vMaxThreshold"
          :disabled="!vAlarmSwitch"
          class="w300"
          :valid="getValidation.maxThreshold || !vAlarmSwitch"
        >
          <move-text p>{{ this.getMesUnit }}</move-text>
        </move-input-number>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "scn-allarm",
  computed: {
    /* --------------------------------------------------------------- */
    /* VUEX GETTERS */
    /* --------------------------------------------------------------- */
    ...mapGetters({
      getTempSensor: "settings/getTempSensor",
      getTempSensorUserConfig: "settings/getTempSensorUserConfig",
      getTempSensorChannel0: "settings/getTempSensorChannel0",
      getAlerts: "settings/getAlerts",
      getMesUnit: "settings/getMesUnit"
    }),
    //sensor.userConfig.channels[0].alerts[0]
    getAlarm() {
      return this.getAlerts && this.getAlerts.length ? this.getAlerts[0] : null;
    },
    //sensor.userConfig.channels[0].alerts[0].enable
    getAlarmSwitch() {
      return this.getAlarm && this.getAlarm.enabled
        ? this.getAlarm.enabled
        : false;
    },
    //sensor.userConfig.channels[0].alerts[0].threshold
    getAllarmThreshold() {
      return this.getAlarm &&
        this.getAlarm.threshold &&
        this.getAlarm.threshold.length
        ? this.getAlarm.threshold
        : [null, null];
    },
    
    //valid if:
    //- minThreshold and maxThreshold are both number
    //- minThreshold < maxThreshold when both filled
    getValidation() {
      const isNumber = (v) => typeof v === 'number';
      return {
        minThreshold: isNumber(this.vMinThreshold) && ((this.vMinThreshold < this.vMaxThreshold) || !isNumber(this.vMaxThreshold)),
        maxThreshold: isNumber(this.vMaxThreshold)  && ((this.vMinThreshold < this.vMaxThreshold) || !isNumber(this.vMinThreshold))
      };
    },
    getEntireValidation() {
      return this.vAlarmSwitch
        ? this.getValidation.minThreshold && this.getValidation.maxThreshold
        : true;
    },
    vAlarmSwitch: {
      get() {
        return this.getAlarmSwitch;
      },
      set(value) {
        this.updateAllarm0({ enabled: value });
        !value && (!this.getValidation.minThreshold || !this.getValidation.maxThreshold) && this.updateRscConfig({ alerts: [] })
      }
    },
    vMinThreshold: {
      get() {
        return this.getAllarmThreshold[0];
      },
      set(value) {
        this.updateAllarm0({
          threshold: [this.validateField(value), this.vMaxThreshold]
        });
      }
    },
    vMaxThreshold: {
      get() {
        return this.getAllarmThreshold[1];
      },
      set(value) {
        this.updateAllarm0({
          threshold: [this.vMinThreshold, this.validateField(value)]
        });
      }
    }
  },
  methods: {
    /* --------------------------------------------------------------- */
    /* VUEX ACTIONS */
    /* --------------------------------------------------------------- */
    ...mapActions({
      setTempSensor: "settings/setTempSensor",
      updateValidationTempSensor: "settings/updateValidationTempSensor",
      updateRscConfig: "settings/updateRscConfig"
    }),
    validateField(value) {
      return value === "" ? null : value;
    },
    updateAllarm0(fields = {}) {
      const sensor = this.getTempSensor;
      let allarm0 =
        sensor.userConfig.channels[0].alerts &&
        sensor.userConfig.channels[0].alerts.length
          ? sensor.userConfig.channels[0].alerts[0]
          : {
              threshold: [null, null],
              enabled: false,
              status: false,
              level: 0
            };
      allarm0 = { ...allarm0, ...fields };
      const alerts = [allarm0];
      this.updateRscConfig({ alerts });
    }
  },
  watch: {
    getEntireValidation(valid) {
      this.updateValidationTempSensor({ field: "allarm", valid });
    }
  }
};
</script>

<style scoped>
.opacity {
  opacity: 0.5;
}
</style>
