<template>
  <div class="m-container py32">
    <div v-for="(setting, idx) in settings" :key="`${setting}-${idx}`">
      <template v-if="setting === 'header'">
        <!-- Header -->
        <Header type="acquisitionParameters" />
      </template>
      <template v-if="setting === 'cadence'">
        <!-- Cadence -->
        <move-horizontal-divider class="my32"></move-horizontal-divider>
        <Cadence />
      </template>
      <template v-if="setting === 'threshold-sampling'">
        <!-- Threshold sampling -->
        <move-horizontal-divider class="my32"></move-horizontal-divider>
        <ThresholdSampling />
      </template>
      <template v-if="setting === 'zeroing'">
        <!-- Zeroing -->
        <move-horizontal-divider class="my32"></move-horizontal-divider>
        <Zeroing />
      </template>
    </div>
  </div>
</template>

<script>
import Header from "./shared/Header.vue";
import Cadence from "./shared/Cadence.vue";
import ThresholdSampling from "./shared/ThresholdSampling.vue";
import Zeroing from "./shared/Zeroing.vue";

export default {
  name: "acquisition-parameter",
  components: {
    Cadence,
    Header,
    ThresholdSampling,
    Zeroing
  },
  props: {
    sensor: null,
    settings: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style scoped>
.width-max-400 {
  width: 400px;
  padding: 16px;
}

.m-container {
  text-align: left;
}
</style>
