<template>
  <div class="topnav noselect">
    <img id="sidebar" src="../../../public/assets/icons/sidebar.svg" @click="sidebarToggle" />
    <img id="logo" :src="imageURL" />
    <div id="language-block">
      <img id="log-out" src="../../../public/assets/icons/ita.svg" @click="setLanguage('it')" />
      <img id="log-out" src="../../../public/assets/icons/eng.svg" @click="setLanguage('en')" />
    </div>
    <div id="exit-block" @click="logout">
      <p>Exit</p>
      <img id="log-out" src="../../../public/assets/icons/log-out.svg" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "nav-bar",
  methods: {
    logout: function() {
      this.$emit("logout");
    },
    sidebarToggle: function() {
      this.$emit("sidebartoggle");
    },
    setLanguage(lang) {
      this.$root.$i18n.locale = lang;
    },
  },
  computed: {
    ...mapGetters({ customLogo: "user/getUserLogoURL" }),
    imageURL() {
      return this.customLogo || require("../../../public/assets/MoveDashLogo.svg");
    },
  },
};
</script>
<style scoped>
/* Add a color to the active/current link */
.topnav a.active {
  background-color: #4caf50;
  color: white;
}

.link-wrapper {
  height: 15px;
}
.topnav {
  background-color: rgb(40, 42, 59);
  border-bottom: solid 1px rgb(80, 84, 105);
  height: 50px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1em;
  align-items: center;
  z-index: 99999;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-template-rows: 100%;
}
#logo {
  margin: auto;
  max-height: 100%;
  image-rendering: -webkit-optimize-contrast;
  transform: translateZ(0);
}
#sidebar {
  padding: 8px;
  width: 50px;
  align-self: baseline;
  cursor: pointer;
}
.router-link-active {
  background-color: rgb(57, 60, 80);
}

.link {
  border-radius: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: 35px;
  margin-right: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#exit-block {
  cursor: pointer;
  display: flex;
  position: absolute;
  right: 70px;
}
#exit-block > p {
  margin-right: 10px;
  font-weight: 600;
  font-size: 0.8em;
}

#language-block {
  display: flex;
  position: absolute;
  right: 170px;
}
#language-block > img {
  height: 25px;
  padding: 5px;
  cursor: pointer;
}
.vert-divider {
  margin-right: 3vw;
  width: 2px;
  height: 40px;
  background-color: rgba(80, 84, 105, 1);
  position: relative;
  justify-self: center;
}
</style>
