<template>
  <div class="m-container py32">
    <!-- Header -->
    <Header type="alarms" />
    <move-horizontal-divider class="my32"></move-horizontal-divider>
    <!-- Alarm value -->
    <Alarm/>
  </div>
</template>

<script>
import Header from "./shared/Header.vue";
import Alarm from "./shared/Alarm.vue";
export default {
  name: "scn-allarm-tab-config",
  components: { Header, Alarm },
};
</script>

<style scoped>
.m-container {
  text-align: left;
}
</style>
