<template>
  <Typo5VConfig
    v-if="getSensorType === '5V'"
    :curTab="curTab"
    :sensor="sensor"
  />
  <Typo12VConfig
    v-else-if="getSensorType === '12V'"
    :curTab="curTab"
    :sensor="sensor"
  />
  <TypoMVVConfig
    v-else-if="getSensorType === 'mV/V'"
    :curTab="curTab"
    :sensor="sensor"
  />
  <TypoNTCConfig
    v-else-if="getSensorType === 'NTC'"
    :curTab="curTab"
    :sensor="sensor"
  />
  <TypoVibratingWireConfig
    v-else-if="getSensorType === 'vibrating-wire'"
    :curTab="curTab"
    :sensor="sensor"
  />
  <Typo420Config
    v-else-if="getSensorType === '4-20'"
    :curTab="curTab"
    :sensor="sensor"
  />
  <TypoPTC
    v-else-if="getSensorType === 'PTC'"
    :curTab="curTab"
    :sensor="sensor"
  />
  <TypoPotentiometricConfig
    v-else-if="getSensorType === 'potentiometric'"
    :curTab="curTab"
    :sensor="sensor"
  />
</template>

<script>
import _ from 'lodash';
import { mapActions } from "vuex";

import Typo5VConfig from "./analog-node-rev2/Typo5VConfig.vue";
import Typo12VConfig from "./analog-node-rev2/Typo12VConfig.vue";
import TypoMVVConfig from "./analog-node-rev2/TypoMVVConfig.vue";
import Typo420Config from "./analog-node-rev2/Typo420Config.vue";
import TypoNTCConfig from "./analog-node-rev2/TypoNTCConfig.vue";
import TypoVibratingWireConfig from "./analog-node-rev2/TypoVibratingWireConfig.vue";
import TypoPTC from "./analog-node-rev2/TypoPTC.vue";
import TypoPotentiometricConfig from "./analog-node-rev2/TypoPotentiometricConfig.vue";

export default {
  name: "analog-node-scn",
  props: {
    curTab: null,
    sensor: null
  },
  components: {
    Typo5VConfig,
    Typo12VConfig,
    TypoMVVConfig,
    TypoNTCConfig,
    TypoVibratingWireConfig,
    Typo420Config,
    TypoPTC,
    TypoPotentiometricConfig
  },
  data() {
    return {
      ciao: null
    };
  },
  computed: {
    getSensorType() {
      return this.sensor &&
        this.sensor.userConfig &&
        this.sensor.userConfig.channels.length
        ? this.sensor.userConfig.channels[0].nativeType
        : null;
    }
  },
  methods: {
    ...mapActions({
      setTempSensor: "settings/setTempSensor",
      setSensorsValidation: "settings/setSensorsValidation",
      setSensorsConfig: "settings/setSensorsConfig",
      cleanTempSensor: "settings/cleanTempSensor",
      setActualSensor: "settings/setActualSensor",
    }),
    updateSensor() {
      if (this.sensor && this.sensor.userConfig) {
        delete this.sensor.userConfig.editing;
        this.setActualSensor(_.cloneDeep(this.sensor))
        this.setTempSensor(_.cloneDeep(this.sensor));
      }
    },
    cleanAll(){
      this.cleanTempSensor();
      this.setActualSensor({})
      this.setSensorsValidation([])
      this.setSensorsConfig([])
    }
  },
  watch: {
    sensor: {
      handler: function() {
        this.updateSensor()
      },
      deep: true
    }
  },
  mounted() {
    this.updateSensor()
  },
  beforeDestroy() {
    this.cleanAll();
  },
};
</script>

<style scoped></style>
