<i18n>
{
  "en": {
    "title": {
      "probeConfiguration" : "Configure the probe connected to the Analog Node",
      "acquisitionParameters" : "Configure the acquisition options of the Analog Node",
      "alarms": "Set the alarm threshold"
    },
    "typo": "Type",
    "info": {
      "message": "It's the type of signal accepted by this node. Depending on the probe you connect to it, you will need a specific version of the node with the same signal type the probe has. The options are: 4-20 mA, mV/V, 5V, 12V, Potentiometric, PT100/PT1000, NTC, Vibrating Wire."
    },
    "types": {
      "5V": "5V",
      "12V": "12V",
      "mV/V": "mV/V",
      "NTC": "NTC",
      "vibrating-wire": "Vibrating Wire",
      "4-20": "4-20 mA",
      "PTC": "PT100/PT1000",
      "potentiometric": "Potentiometer"
    }
  },
  "it": {
    "title": {
      "probeConfiguration" : "Configura la sonda collegata al Nodo Analogico",
      "acquisitionParameters" : "Configura le opzioni di acquisizione del Nodo Analogico",
      "alarms": "Imposta le soglie d'allarme"
    },
    "typo": "Tipologia",
    "info": {
      "message": "E' il tipo di segnale accettato dal nodo. A seconda della sonda che ci viene collegata, avrai bisogno di una specifica versione del nodo che condivida con essa il tipo di segnale. Le opzioni sono: 4-20 mA, mV/V, 5V, 12V, Potentiometric, PT100/PT1000, NTC, Vibrating Wire."
    },
    "types": {
      "5V": "5V",
      "12V": "12V",
      "mV/V": "mV/V",
      "NTC": "NTC",
      "vibrating-wire": "Vibrating Wire",
      "4-20": "4-20 mA",
      "PTC": "PT100/PT1000",
      "potentiometric": "Potenziometro"
    }
  }
}
</i18n>
<template>
  <div>
    <move-text p secondary hint class="mb4">
      {{ $t(`title['${this.type}']`) }}:
    </move-text>
    <div class="d-flex align-items-center mb4">
      <move-text h3 class="mr4"> {{ $t("typo") }}: </move-text>
      <move-text h3 class="mr16"> {{ getRscTypes[getSensorType] }} </move-text>
      <move-info rigth class="mr32">
        <template #icon>
          <font-awesome-icon icon="fa-solid fa-circle-info" />
        </template>
        <div class="w400 p16">
          <move-text p> {{ $t("info.message") }}</move-text>
        </div>
      </move-info>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "scn-header",
  props: {
    type: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapGetters({
      getSensorType: "settings/getSensorType"
    }),
    getRscTypes() {
      return {
        "5V": this.$t("types['5V']"),
        "12V": this.$t("types['12V']"),
        "mV/V": this.$t("types['mV/V']"),
        NTC: this.$t("types['NTC']"),
        "vibrating-wire": this.$t("types['vibrating-wire']"),
        "4-20": this.$t("types['4-20']"),
        PTC: this.$t("types['PTC']"),
        potentiometric: this.$t("types['potentiometric']")
      };
    }
  }
};
</script>

<style scoped>

</style>
