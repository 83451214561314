<i18n>
{
  "en": {
    "wizardTitle": "Introduction to the Move Cloud Platform",
    "pswChangeTabTitle": "Safety first, change your login password!",
    "pswChangeTabContent": "It is highly reccomended to change your login crdentials, customize your password immediately after the first login.",
    "nextButton": "Next",
    "editPswButton": "Edit password",
    "backButton": "Back",
    "skipButton": "Skip",
    "finishButton": "Finish",
    "skippedPasswordTitle": "Sure?",
    "skippedPasswordContent": "For security reasons we reccomended that you customize your access password, however remember that you can change it any time.",
    "changedPasswordTitle": "Great!",
    "changedPasswordContent": "You have successfully changed your login password.",
    "enterPassword": "Enter the new password",
    "repeatPassword": "Repeat the new password"
  },
  "it": {
    "wizardTitle": "Introduzione alla Move Cloud Platform",
    "pswChangeTabTitle": "Per prima cosa la sicurezza, modifica la password di accesso!",
    "pswChangeTabContent": "É altamente consigliato modificare le credenziali di accesso personalizzando la propria password subito dopo il primo accesso.",
    "nextButton": "Avanti",
    "editPswButton": "Modifica password",
    "backButton": "Indietro",
    "skipButton": "Salta",
    "finishButton": "Finito",
    "skippedPasswordTitle": "Sicuro?",
    "skippedPasswordContent": "Per ragioni di sicurezza ti consigliamo di personalizzare la tua password di accesso, ad ogni modo ricorda che potrai modificarla in qualsiasi momento.",
    "changedPasswordTitle": "Ottimo!",
    "changedPasswordContent": "Hai modificato la password di accesso con successo.",
    "enterPassword": "Inserisci la nuova password",
    "repeatPassword": "Ripeti la nuova password"
  }
}
</i18n>

<template>
  <div id="wrapper">
    <!-- Loading Overlay -->
    <transition name="load">
      <SmallLoadingLogo v-if="loadingCount > 0" />
    </transition>
    <!-- Heading -->
    <form-wizard ref="wizard" shape="circle" color="rgb(7, 133, 225)" error-color="red" :title="$t('wizardTitle')" subtitle="" stepSize="sm" @on-complete="onComplete">
      <!-- Steps -->
      <template slot="step" slot-scope="props">
        <wizard-step :class="getStepClass(props.index)" :tab="filteredTabs(props.tab, props.index)" :transition="props.transition" :key="props.tab.title || Math.random()" :index="props.index">
        </wizard-step>
      </template>

      <!-- Change password init (0) -->
      <tab-content class="tab-content">
        <h2>{{ $t("pswChangeTabTitle") }}</h2>
        <p>{{ $t("pswChangeTabContent") }}</p>
      </tab-content>

      <!-- Change password form (1) -->
      <tab-content :before-change="validateAndChangePsw" :on-change="updateStep" class="column tab-content">
        <ValidationObserver ref="form">
          <form @submit.prevent="onSubmit">
            <ValidationProvider
              class="vprovider"
              name="Password"
              vid="password"
              :rules="{ required: true, regex: /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{7,}$/ }"
              v-slot="{ errors, classes }"
            >
              <label>{{ $t("enterPassword") }}:</label>
              <input v-model="psw1" name="myinput" type="password" :class="classes" @keyup="validatePsw" />
              <span class="err-span">{{ errors[0] }}</span>
            </ValidationProvider>
            <ValidationProvider class="vprovider" name="Confirm Password" vid="password2" rules="required|confirmed:password" v-slot="{ errors, classes }">
              <label>{{ $t("repeatPassword") }}:</label>
              <input v-model="psw2" name="myinput2" type="password" :class="classes" @keyup="validatePsw" />
              <span class="err-span">{{ errors[0] }}</span>
            </ValidationProvider>
          </form>
        </ValidationObserver>
      </tab-content>

      <!-- Change password finish (2) -->
      <tab-content class="tab-content">
        <h2>{{ skippedPsw ? $t("skippedPasswordTitle") : $t("changedPasswordTitle") }}</h2>
        <p>{{ skippedPsw ? $t("skippedPasswordContent") : $t("changedPasswordContent") }}</p>
      </tab-content>

      <!-- Footer -->
      <template slot="footer" slot-scope="{ activeTabIndex, isLastStep, nextTab, prevTab }">
        <button v-if="skippableStep(activeTabIndex)" @click="skipStep(activeTabIndex)" type="button">
          {{ $t("skipButton") }}
        </button>
        <button v-if="activeTabIndex > 0" @click="prevTab" type="button">
          {{ $t("backButton") }}
        </button>
        <button v-if="!isLastStep && activeTabIndex === 0" @click="nextTab" type="button">
          {{ $t("nextButton") }}
        </button>
        <button v-if="!isLastStep && activeTabIndex === 1" @click="nextTab" type="button" :class="[validationStatus ? 'blue-text' : 'dark-text']">
          {{ $t("editPswButton") }}
        </button>
        <button v-if="isLastStep" @click="nextTab" type="button">
          {{ $t("finishButton") }}
        </button>
      </template>
    </form-wizard>
  </div>
</template>
<script>
import { i18n } from "../../plugins/i18n";
import { required, regex, confirmed } from "vee-validate/dist/rules";
import { FormWizard, TabContent, WizardStep } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import SmallLoadingLogo from "../ui/SmallLoadingLogo.vue";
import { ValidationProvider, ValidationObserver, extend, configure } from "vee-validate";
import { patchUserSettings } from "../../services/api";

configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
  },
});
extend("required", { ...required, message: () => i18n.t("validation.requiredField") });
extend("regex", { ...regex, message: () => i18n.t("validation.pswRequirements") });
extend("confirmed", { ...confirmed, message: () => i18n.t("validation.pswEquals") });

export default {
  name: "PswChangeWizard",
  components: {
    FormWizard,
    WizardStep,
    TabContent,
    ValidationProvider,
    ValidationObserver,
    SmallLoadingLogo,
  },
  data: () => ({
    currentIndex: null,
    prevIndex: null,
    skippedIndexes: [],
    validationStatus: false,
    skippedPsw: false,
    logoutNeeded: false,
    loadingCount: 0,
    errorIndexes: [], // Contains the list of indexes related to the tabs to be shown in error
    psw1: "",
    psw2: "",
  }),
  methods: {
    async validateAndChangePsw() {
      const success = await this.$refs.form.validate();
      if (success) {
        this.loadingCount += 1;
        await patchUserSettings({ password: this.psw2 });
        this.loadingCount -= 1;
        this.$emit("change");
        this.logoutNeeded = true;
      }
      return success;
    },
    updateStep(prevIndex, nextIndex) {
      this.prevIndex = prevIndex;
      this.currentIndex = nextIndex;
    },
    // TODO: implementare
    getStepClass(stepIndex) {
      let stepClass = "wizard-gray-step";
      if (stepIndex <= this.currentIndex) {
        stepClass = "wizard-blue-step";
      }
      if (this.skippedIndexes.includes(stepIndex)) {
        stepClass = "wizard-red-step";
      }
      return stepClass;
    },
    validatePsw() {
      this.$refs.form.validate().then((validStatus) => (this.validationStatus = validStatus));
    },
    // This function returns the single tab (modified) and is called for each tab present
    filteredTabs(tab, index) {
      if (this.errorIndexes.includes(index)) tab.validationError = true;
      return tab;
    },
    onSubmit() {
      alert("Form has been submitted!");
    },
    onComplete() {
      if (this.logoutNeeded) {
        this.$emit("logout");
      } else {
        this.$emit("close");
      }
    },
    skipStep(currentIndex) {
      if (currentIndex === 1) {
        // Se skippo l'update setto nel localStorage "skippedPasswordChange" a true
        this.errorIndexes.push(currentIndex);
        this.$refs.wizard.changeTab(1, 2);
        this.skippedPsw = true;
      }
      if (currentIndex === 0) {
        // Se skippo l'update setto nel localStorage "skippedPasswordChange" a true
        this.errorIndexes.push(currentIndex);
        this.$refs.wizard.changeTab(0, 1);
        this.$refs.wizard.changeTab(1, 2);
        this.skippedPsw = true;
      }
      this.$emit("skip");
      this.skippedIndexes.push(currentIndex, currentIndex + 1);
    },
    skippableStep(currentIndex) {
      return currentIndex === 1 || currentIndex === 0;
    },
  },
  computed: {
    requiredMsg() {
      return this.$t("validation.requiredField");
    },
    pswReqMsg() {
      return this.$t("validation.pswRequirements");
    },
  },
  mounted() {
    setTimeout(() => {
      this.color = "green";
    }, 3000);
  },
};
</script>
<style scoped>
* {
  text-align: left;
  font-style: normal;
  font-family: "Poppins", sans-serif;
}
a {
  font-size: 0.75em;
}
p {
  color: rgb(198, 198, 198);
  padding: 0px;
}

#wrapper {
  background-color: rgb(70, 74, 94) !important;
  /* color: black; */
  width: 680px;
  /* min-height: 420px; */
  height: 100%;
  border-radius: 5px;
  /* margin: 40px; */
}
form {
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  font-size: 1em;
}
form label {
  font-size: 1.1em;
  grid-row: 1;
  grid-column: 1/2;
  text-align: left;
  color: rgb(198, 198, 198);
  margin-bottom: 4px;
  /* font-weight: 600; */
}
form input {
  grid-row: 2;
  grid-column: 1/2;
  background-color: rgb(139, 141, 151);
  border: none;
  height: 100%;
  width: 100%;
  outline: none;
  padding: 0 10px;
  font-size: 1.2em !important;
}
form .err-span {
  font-size: 0.9em;
  grid-row: 2;
  grid-column: 2/3;
  text-align: left;
  color: yellow;
  align-self: center;
}
.vprovider {
  display: grid;
  column-gap: 12px;
  grid-template-columns: 260px 10fr;
  grid-template-rows: min-content 30px;
  height: 60px;
}
.tab-content {
  padding: 20px;
}
.dark-text {
  color: rgb(139, 141, 151);
}
.blue-text {
  background-color: rgb(8, 133, 225);
}
.is-invalid {
  border: solid 2px red;
}
.is-valid {
  border: solid 2px #1bb11b;
}
</style>
