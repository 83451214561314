<i18n>
{
  "en": {
    "title": "Probe frequency range",
    "info": {
      "message": "Range of frequencies the Vibrating Wire works on. Change these parmeters according to the datasheet of your probe. When in doubt, try setting the maximum frequency slightly below double the expected or base frequency."
    },
    "freqMin": "Minimum frequency",
    "freqMax": "Maximum frequency"
  },
  "it": {
    "title": "Range di frequenza della sonda",
    "info": {
      "message": "E' il range di frequenze sul quale lavora il Vibrating Wire. Modifica questi parametri a seconda di quanto indicato nel Datasheet della sonda. In caso di perplessità, prova ad impostare la frequnza massima poco sotto il doppio della frequenza attesa, o della frequenza naturale della sonda."
    },
    "freqMin": "Frequenza minima",
    "freqMax": "Frequenza massima"
  }
}
</i18n>
<template>
  <div>
    <div class="d-flex align-items-center mb16">
      <move-text p b class="mr16">{{ $t("title") }}</move-text>
      <move-info rigth>
        <template #icon>
          <font-awesome-icon icon="fa-solid fa-circle-info" />
        </template>
        <div class="w400 p16">
          <move-text p> {{ $t("info.message") }}</move-text>
        </div>
      </move-info>
    </div>
    <div class="m-content">
      <div>
        <move-text p>{{
          `${$t("freqMin")}: ${vFrequencyValues[0]}Hz`
        }}</move-text>
        <move-text p>{{
          `${$t("freqMax")}: ${vFrequencyValues[1]}Hz`
        }}</move-text>
      </div>

      <vue-slider
        :disabled="getIsMappedMode"
        v-model="vFrequencyValues"
        :marks="getOptions"
        :min="getMinAbsoluteFreq"
        :max="getMaxAbsoluteFreq"
        :adsorb="true"
        :included="true"
        :hideLabel="false"
        width="100%"
      ></vue-slider>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "scn-frequencies",
  computed: {
    ...mapGetters({
      getTempSensor: "settings/getTempSensor",
      getTempSensorChannel0: "settings/getTempSensorChannel0",
      getTempSensorUserConfig: "settings/getTempSensorUserConfig",
      getSensorType: "settings/getSensorType",
      getMinFreq: "settings/getMinFreq",
      getMaxFreq: "settings/getMaxFreq",
      getIsMappedMode: "settings/getIsMappedMode",
    }),
    vFrequencyValues: {
      get() {
        return [this.getMinFreq, this.getMaxFreq];
      },
      set(value = [null, null]) {
        this.updateRscConfig({minFreq: Number(value[0]), maxFreq: Number(value[1])});
      }
    },
    getMinAbsoluteFreq(){
      return 400
    },
    getMaxAbsoluteFreq(){
      return 10000
    },
    getFrequencies() {
      return [
        400,
        500,
        1000,
        1500,
        2000,
        2500,
        3000,
        3500,
        4000,
        4500,
        5000,
        5500,
        6000,
        6500,
        7000,
        7500,
        8000,
        8500,
        9000,
        9500,
        10000
      ];
    },
    getOptions() {
      return this.getFrequencies.reduce((accumulator, value, idx, array) => {
        return idx === 0 || idx === array.length - 1
          ? { ...accumulator, [value]: value }
          : { ...accumulator, [value]: "" };
      }, {});
    }
  },
  methods: {
    /* --------------------------------------------------------------- */
    /* VUEX ACTIONS */
    /* --------------------------------------------------------------- */
    ...mapActions({
      updateRscConfig: "settings/updateRscConfig",
    }),
  }
};
</script>

<style scoped>
.m-content {
  display: grid;
  grid-template-columns: minmax(200px, 3fr) minmax(400px, 9fr);
  align-items: center;
}
</style>
