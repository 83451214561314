<template>
  <div class="outer-wrap">
    <Sidebar
      v-if="this.getStructureDetails"
      :show="showSidebar"
      :adminRights="userHasAdminRight"
      :sensors="this.getStructureDetails.sensors"
      :structure="getStructureDetails"
      @sidebartoggle="showSidebar = !showSidebar"
      @settingstoggle="
        showSettings = !showSettings;
        showMonitor = false;
        showSidebar = false;
        showAnalogNode = false;
      "
      @monitortoggle="
        showMonitor = !showMonitor;
        showSettings = false;
        showSidebar = false;
        showAnalogNode = false;
      "
      @analog-node-toggle="
        showAnalogNode = !showAnalogNode;
        showMonitor = false;
        showSettings = false;
        showSidebar = false;
      "
    />
    <div id="navbar">
      <NavBar v-show="true" @logout="this.logout" @sidebartoggle="showSidebar = !showSidebar" />
      <div id="general-info">
        <!-- Selezione Struttura -->
        <BridgeOverview
          id="bridge-ow"
          :bridges="this.getStructures"
          :selectedBridge="this.getStructureDetails"
          v-if="this.getStructureDetails"
          @struct-selected="selectStructure"
        />
        <slot name="nav"></slot>
      </div>
    </div>
    <!-- Settings -->
    <transition name="settings">
      <Settings2 @needToReloadEvents="$emit('needToReloadEvents')" id="set" v-if="showSettings" :selectedStructure="this.getStructureDetails" :userType="this.getUserType" @settingstoggle="showSettings = !showSettings" />
    </transition>
    <div class="page-template" id="page-wrap">
      <transition name="settings">
        <!-- Modale accettazione EULA -->
        <ModalEula v-if="!getUserEulaAccepted && !getIsHideEula" @acceptEula="acceptUserEula" @goOut="this.logout"></ModalEula>
        <ModalPswChange v-else-if="mounted && !hideTips && showPswChange" @skip="skipPswChange" @change="changePsw" @close="showPswChange = false" @logout="logout" />
        <ModalImgChange v-else-if="mounted && !hideTips && getStructureDetails && getStructureDetails.type === 'bridge' && !skippedImgSet" />
      </transition>
      <!-- Monitor -->
      <transition name="settings">
        <Monitor id="monitor" v-if="showMonitor" :selectedStructure="this.getStructureDetails" @monitortoggle="showMonitor = !showMonitor" />
      </transition>
      <!-- Analog Node -->
      <!-- <transition name="settings">
        <AnalogNode id="analog-node" v-if="this.getStructureDetails && showAnalogNode && userHasAdminRight" :selectedStructure="this.getStructureDetails" @close-me="showAnalogNode = false" @need-refresh="this.fetchStructureData" />
      </transition> -->
      <!-- Loading -->
      <transition name="load">
        <LoadingLogo v-if="!loaded || !getStructureDetails" />
      </transition>

      <!-- Notifications -->
      <NewPlatformBadge v-if="!hideTips && skippedPswChange" @action="showPswChange = true" />
      <ImgChangeBadge v-if="!hideTips && skippedImgSet" @action="skippedImgSet = false" />
      <PswChangeBadge v-if="!hideTips && skippedPswChange" @action="showPswChange = true" />

      <!-- Contenuto -->
      <slot name="content"></slot>

      <!-- Footer -->
      <Footer />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import ImgChangeBadge from "../components/ui/ImgChangeBadge.vue";
import PswChangeBadge from "../components/ui/PswChangeBadge.vue";
import NewPlatformBadge from "../components/ui/NewPlatformBadge.vue";
import BridgeOverview from "../components/ui/StructureInfo.vue";
import NavBar from "../components/ui/NavigationBar.vue";
import LoadingLogo from "../components/ui/LoadingLogo.vue";
import Sidebar from "../components/ui/Sidebar.vue";
import Settings2 from "./Settings.vue";
import Monitor from "./Monitor.vue";
import ModalPswChange from "../components/wizard/ModalPswChange.vue";
import ModalImgChange from "../components/wizard/ModalImgSet.vue";
import Footer from "../components/ui/Footer.vue";
import ModalEula from "../components/wizard/ModalEula.vue";
import $ from "jquery";

export default {
  name: "PageTemplate",
  components: {
    ImgChangeBadge,
    PswChangeBadge,
    NewPlatformBadge,
    NavBar,
    BridgeOverview,
    LoadingLogo,
    Sidebar,
    Settings2,
    Monitor,
    ModalPswChange,
    ModalImgChange,
    ModalEula,
    Footer,
  },
  // Props per forzare la selezione da route precedente
  props: {
    selectedSensorID: String,
    selectedStructureID: String,
    hideTips: Boolean,
  },
  data() {
    return {
      mounted: false,
      loaded: false,
      showSidebar: false,
      showSettings: false,
      showMonitor: false,
      showAnalogNode: false,
      skippedImgSet: false, // True se l'utente ha skippato il cambiamento all'immagine
      skippedPswChange: false, // True se l'utente ha skippato il cambiamento della password
      showPswChange: false,
    };
  },
  methods: {
    ...mapActions({
      fetchStructures: "structure/fetchStructures",
      selectStructure: "structure/selectStructure",
      fetchStructureData: "structure/fetchStructureData",
      setSelectedSensor: "lastevents/setSelectedSensor",
      logout: "auth/logout",
      fetchUserSettings: "user/fetchUserSettings",
      acceptUserEula: "user/acceptUserEula",
    }),
    saveSensorsSettings: function() {},
    setupNavigationSticky: function() {
      // Setta l'hiding automatico della Navigation Bar
      var prevScrollpos = window.pageYOffset;
      window.onscroll = function() {
        var currentScrollPos = window.pageYOffset;
        if (prevScrollpos - currentScrollPos > 0) {
          $("#navbar").css("-webkit-transform", "translateY(0px");
          $("#navbar").css("transform", "translateY(0px");
        } else {
          if (currentScrollPos > 50) {
            $("#navbar").css("-webkit-transform", "translateY(-135px");
            $("#navbar").css("transform", "translateY(-135px");
          }
        }
        prevScrollpos = currentScrollPos;
      };
    },
    skipPswChange() {
      localStorage.setItem("skippedPasswordChange", true);
      this.skippedPswChange = true;
    },
    changePsw() {
      localStorage.removeItem("skippedPasswordChange");
    },

  },
  watch: {
    getSelectedStructure: {
      // Chiamata quando chiama la struttura selezionata
      handler: async function(newValue, oldValue) {
        if (newValue && (!oldValue || oldValue._id !== newValue._id)) {
          this.loaded = false;
          await this.fetchStructureData();
          this.loaded = true;
        } else {
          this.loaded = true;
        }
      },
      deep: true,
    },
    getStructureDetails: {
      // Aggiorna le info sul sensore attualmente selezionato
      handler: async function(newValue, oldValue) {
        if (oldValue && this.getSelectedSensor) {
          let euiSelected = this.getSelectedSensor.eui;
          let newItem = newValue.sensors.find((x) => x.eui === euiSelected);
          this.setSelectedSensor(newItem);
          //this.loaded = true;
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      getStructures: "structure/getStructures",
      getSensors: "structure/getSensors",
      getSelectedStructure: "structure/getSelectedStructure",
      getStructureDetails: "structure/getStructureDetails",
      getSensorType: "lastevents/getSensorType",
      getSelectedSensor: "lastevents/getSelectedSensor",
      getUserType: "user/getUserType",
      getPasswordChanged: "user/getPasswordChanged",
      getUserEulaAccepted: "user/getUserEulaAccepted",
    }),
    userHasAdminRight() {
      return !this.getUserType || this.getUserType === "standard";
    },
    spans() {
      return this.getStructureDetails && this.getStructureDetails.userConfig ? this.getStructureDetails.userConfig.spansDetails : [];
    },
    getIsHideEula(){
      return localStorage.getItem('eula') === 'hide';
    }
  },
  async mounted() {
    this.mounted = false;
    // Viene effettuato il fetch delle strutture
    await this.fetchStructures();
    await this.fetchUserSettings();
    if (!this.getSelectedStructure) {
      await this.selectStructure(this.getStructures[0]);
    }
    await this.setupNavigationSticky();
    /* console.log("Struttura selezionata da Router", this.selectedStructureID);
    console.log("Sensore selezionato da Router", this.selectedSensorID); */
    $(document).on("keydown", function(event) {
      if (event.keyCode == 9) {
        //tab pressed
        //event.preventDefault(); // stops its action
      }
    });

    // Controllo il local storage per decidere se mostrare il wizard
    this.showPswChange = JSON.parse(this.getPasswordChanged || false) === false && JSON.parse(localStorage.getItem("skippedPasswordChange")) !== true;
    this.skippedPswChange = JSON.parse(this.getPasswordChanged || false) === false && JSON.parse(localStorage.getItem("skippedPasswordChange")) === true;

    // Idem
    this.skippedImgSet = (JSON.parse(localStorage.getItem("skippedImgStructureIDs")) || []).includes(this.getSelectedStructure._id);
    this.mounted = true;
  },
};
</script>
<style scoped>
* {
  font-size: 1em;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
}
/* NavBar */
#navbar {
  transition: transform 0.3s;
  position: fixed;
  width: 100%;
  min-width: 840px;
  z-index: 17;
  /* Transizioni usate per la Sidebar */
  -webkit-transition: transform 0.6s ease-in-out;
  -moz-transition: transform 0.6s ease-in-out;
  -o-transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
}
#general-info {
  height: 60px;
  background-color: transparent;
  width: 100%;
  top: 50px;
  left: 0;
  position: absolute;
  -webkit-transition: transform 0.6s ease-in-out;
  -moz-transition: transform 0.6s ease-in-out;
  -o-transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
}

/* #bridge-ow {
  max-width: 100vw;
} */
.page-template {
  position: absolute;
  display: grid;
  background-color: rgb(32, 34, 47);
  padding-top: 150px;
  width: 100%;
  right: 0px;
  /* Transizioni usate per la Sidebar */
  -webkit-transition: transform 0.6s ease-in-out;
  -moz-transition: transform 0.6s ease-in-out;
  -o-transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
}

/* #generic {
  margin-top: 40px;
} */
/* Tra 600 e 1499 */
@media all and (min-width: 300px) {
  .page-template {
    font-size: 0.9em;
  }
}

/* Loading Transition */
.load-enter,
.load-leave-to {
  opacity: 0;
}
.load-enter-active {
  transition: all 0.3s ease;
}
.load-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

#set {
  z-index: 12;
}

#monitor,
#analog-node {
  z-index: 12;
}
</style>
