<i18n>
{
  "en": {
    "wizardTitle": "Optimize your Move Cloud Platform",
    "imgChangeIntroTitle": "A new way of viewing your monitored site.",
    "imgChangeIntroSubTitle": "Customize the view of the monitored site and arrange the sensors installed on it as you like:",
    "imgChangeIntroStep1": "Upload the image of the monitored site",
    "imgChangeIntroStep2": "Freely place the sensors on the loaded image",
    "imgChangeIntroStep3": "View sensors exactly where they are installed",
    "imgChangeTabTitle": "Great, let’s proceed with the update!",
    "imgChangeTabContent": "This page will be updated to allow you to make changes.\nAre you sure to proceed?",
    "imgChangeWarning": "Warning: once the display mode has been updated you will not be able to go back!",
    "nextButton": "Yes, let's proceed",
    "undoButton": "Cancel",
    "skipButton": "No, for the moment",
    "finishButton": "Update"
  },
  "it": {
    "wizardTitle": "Ottimizza la tua Move Cloud Platform",
    "imgChangeIntroTitle": "Una nuova modalità di visualizzazione della tua struttura.",
    "imgChangeIntroSubTitle": "Personalizza la visualizzazione della struttura monitorata e disponi i sensori installati a tuo piacimento su di essa:",
    "imgChangeIntroStep1": "Carica l’immagine della struttura monitorata",
    "imgChangeIntroStep2": "Posiziona liberamente i sensori sull’immagine caricata",
    "imgChangeIntroStep3": "Visualizza i sensori esattamente dove sono installati",
    "imgChangeTabTitle": "Ottimo, procediamo con l’aggiornamento!",
    "imgChangeTabContent": "Questa pagina verrà aggiornata per permetterti di effettuare le modifiche.\nSei sicuro di procedere?",
    "imgChangeWarning": "Attenzione: una volta aggiornata la modalità di visualizzazione non potrai tornare indietro!",
    "nextButton": "Sì, procediamo.",
    "undoButton": "Annulla",
    "skipButton": "No, per il momento",
    "finishButton": "Aggiorna"
  }
}
</i18n>

<template>
  <div id="wrapper">
    <!-- Loading Overlay -->
    <transition name="load">
      <SmallLoadingLogo v-if="loadingCount > 0" />
    </transition>
    <!-- Heading -->
    <form-wizard ref="wizard" shape="circle" color="rgb(7, 133, 225)" error-color="red" :title="$t('wizardTitle')" subtitle="" stepSize="sm" @on-complete="onComplete">
      <!-- Steps -->
      <template slot="step" slot-scope="props">
        <wizard-step :tab="filteredTabs(props.tab, props.index)" :transition="props.transition" :key="props.tab.title || Math.random()" :index="props.index"> </wizard-step>
      </template>

      <!-- Change password init (0) -->
      <tab-content class="tab-content">
        <h2>{{ $t("imgChangeTabTitle") }}</h2>
        <p>{{ $t("imgChangeIntroSubTitle") }}</p>
        <ul>
          <li>{{ $t("imgChangeIntroStep1") }}</li>
          <li>{{ $t("imgChangeIntroStep2") }}</li>
          <li>{{ $t("imgChangeIntroStep3") }}</li>
        </ul>
      </tab-content>

      <!-- Change password finish (2) -->
      <tab-content class="tab-content">
        <h2>{{ $t("imgChangeTabTitle") }}</h2>
        <p>{{ $t("imgChangeTabContent") }}</p>
        <div class="warning">
          <p>{{ $t("imgChangeWarning") }}</p>
        </div>
      </tab-content>

      <!-- Footer -->
      <template slot="footer" slot-scope="{ activeTabIndex, nextTab, prevTab }">
        <button v-if="activeTabIndex === 0" type="button" class="dark-text" @click="skip">
          {{ $t("skipButton") }}
        </button>
        <button v-if="activeTabIndex === 1" @click="prevTab" type="button" class="dark-text">
          {{ $t("undoButton") }}
        </button>
        <button v-if="activeTabIndex === 0" @click="nextTab" type="button">
          {{ $t("nextButton") }}
        </button>
        <button v-if="activeTabIndex === 1" @click="nextTab" type="button">
          {{ $t("finishButton") }}
        </button>
      </template>
    </form-wizard>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { FormWizard, TabContent, WizardStep } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import SmallLoadingLogo from "../ui/SmallLoadingLogo.vue";
import { patchStructure } from "../../services/api";

export default {
  name: "ImgSetWizard",
  components: {
    FormWizard,
    WizardStep,
    TabContent,
    SmallLoadingLogo,
  },
  data: () => ({
    loadingCount: 0,
    errorIndexes: [], // Contains the list of indexes related to the tabs to be shown in error
  }),
  computed: {
    ...mapGetters({
      getStructureDetails: "structure/getStructureDetails",
    }),
  },
  methods: {
    // This function returns the single tab (modified) and is called for each tab present
    filteredTabs(tab, index) {
      if (this.errorIndexes.includes(index)) tab.validationError = true;
      return tab;
    },
    // Al completamento setto l'immagine
    async onComplete() {
      const structID = this.getStructureDetails._id;
      await patchStructure(structID, { type: "generic" });
      // Se avevo skippato, elimino lo skip
      const skippedImgs = JSON.parse(localStorage.getItem("skippedImgStructureIDs")) || [];
      if (skippedImgs) {
        localStorage.setItem("skippedImgStructureIDs", JSON.stringify(skippedImgs.filter((si) => si != structID)));
      }
      window.location.reload();
    },
    skip() {
      const structID = this.getStructureDetails._id;
      const skippedImgs = JSON.parse(localStorage.getItem("skippedImgStructureIDs")) || [];
      // Aggiungo l'id della struttura tra quelle da skippare
      if (!skippedImgs || !skippedImgs.includes(structID)) {
        skippedImgs.push(structID);
        localStorage.setItem("skippedImgStructureIDs", JSON.stringify(skippedImgs));
      }
      window.location.reload();
    },
  },
};
</script>
<style scoped>
* {
  text-align: left;
  font-style: normal;
  font-family: "Poppins", sans-serif;
}
h2 {
  font-weight: 500;
  font-size: 1em;
  margin-top: 24px;
}
a {
  font-size: 0.75em;
}
p {
  color: white;
  padding: 0px;
}

#wrapper {
  background-color: rgb(70, 74, 94) !important;
  width: 780px;
  height: 100%;
  border-radius: 5px;
}
.tab-content {
  padding: 20px;
  padding-bottom: 0;
}
.dark-text {
  color: rgb(139, 141, 151);
}
.dark-text:hover{
  background-color: transparent;
  color: white;
}
.is-invalid {
  border: solid 2px red;
}
.is-valid {
  border: solid 2px #1bb11b;
}
.warning {
  background-color: red;
}
.warning > p {
  color: white;
  padding: 6px;
  font-weight: 600;
}
</style>
