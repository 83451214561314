<i18n>
{
   "en":{
      "title":"Probe data conversion",
      "info":{
         "message":"Depending on the interface, specific parameters need to be set in order to convert the raw data into the actual measure. You can usually find those parameters in your probe's datasheet or instruction manual."
      },
      "conversionParameter":{
         "title":"Select the formula to apply",
         "linear-digit-formula":"Linear digit",
         "linear-in-linear-digit-formula":"Linear formula",
         "quadratic-formula":"Quadratic formula"
      },
      "conversionFormula":"Conversion formula",
      "gainValue":"Set the gain value",
      "aValue":"Parameter A",
      "bValue":"Parameter B",
      "cValue":"Parameter C"
   },
   "it":{
      "title":"Conversion parameters",
      "info":{
         "message":"A seconda dell'interfaccia, specifici parametri devono essere settati per convertire il dato grezzo nella misura effettiva. Solitamente puoi trovare quei parametri nel datasheet o del manuale istruzioni della sonda."
      },
      "conversionParameter":{
         "title":"Seleziona la formula da utilizzare",
         "linear-digit-formula":"Linear digit",
         "linear-in-linear-digit-formula":"Linear formula",
         "quadratic-formula":"Quadratic formula"
      },
      "conversionFormula":"Conversion formula",
      "gainValue":"Set the gain value",
      "aValue":"Parametro A",
      "bValue":"Parametro B",
      "cValue":"Parametro C"
   }
}
</i18n>
<template>
  <div>
    <div class="d-flex align-items-center mb16">
      <move-text p b class="mr16">{{ $t("title") }}:</move-text>
      <move-info rigth>
        <template #icon>
          <font-awesome-icon icon="fa-solid fa-circle-info" />
        </template>
        <div class="w400 p16">
          <move-text p> {{ $t("info.message") }}</move-text>
        </div>
      </move-info>
    </div>

    <move-text caption class="mb4">{{ $t("conversionParameter.title") }}:</move-text>
    <div class="d-flex mb16" :class="{disbled: getIsMappedMode}">
      <move-card
        class="card mr8"
        @click="vMode = 'linear-digit-formula'"
        :class="{ active: vMode === 'linear-digit-formula' }"
        bordered
        outlined
        rounded
      >
        <move-text p b class="my4">{{ $t("conversionParameter.linear-digit-formula") }}</move-text>
        <!-- Y = \frac{x^2}{1000} -->
        <!-- https://www.codecogs.com/eqnedit.php -->
        <img
          src="../../../../../../public/assets/fomulas/SCN-vibrating-wire-linear-formula-12pt.svg"
        />
      </move-card>
      <move-card
        class="card mr8"
        @click="vMode = 'linear-in-linear-digit-formula'"
        :class="{ active: vMode === 'linear-in-linear-digit-formula' }"
        bordered
        outlined
        rounded
      >
        <move-text p b class="my4">{{ $t("conversionParameter.linear-in-linear-digit-formula") }}</move-text>
        <!-- https://www.codecogs.com/eqnedit.php -->
        <img
          src="../../../../../../public/assets/fomulas/SCN-vibrating-wire-linear-digit-formula-12pt.svg"
        />
      </move-card>
      <move-card
        class="card mr8"
        @click="vMode = 'quadratic-formula'"
        :class="{ active: vMode === 'quadratic-formula' }"
        bordered
        outlined
        rounded
      >
        <move-text p b class="my4">{{ $t("conversionParameter.quadratic-formula") }}</move-text>
        <!-- Y =A (\frac{x^2}{1000})^2 + B (\frac{x^2}{1000})^2 + C -->
        <!-- https://www.codecogs.com/eqnedit.php -->
        <img
          src="../../../../../../public/assets/fomulas/SCN-vibrating-wire-quadratic-formula-12pt.svg"
        />
      </move-card>
    </div>

    <div v-if="vMode === 'linear-in-linear-digit-formula'">
      <move-text caption class="mb4"> {{ $t("gainValue") }} </move-text>
      <move-input-number
        tertiary
        v-model="vGain"
        class="d-flex w300"
        :valid="getValidation.gain"
        :disabled="getIsMappedMode"
      >
        <move-text p>
          {{ getUnit.gain }}
        </move-text>
      </move-input-number>
    </div>
    <div v-else-if="vMode === 'quadratic-formula'">
      <div class="d-flex">
        <div class="pr8">
          <move-text caption class="mb4">{{ $t("aValue") }}:</move-text>
          <move-input-number
            tertiary
            v-model="vA"
            :valid="getValidation.A"
            class="w250"
            :disabled="getIsMappedMode"
          ></move-input-number>
        </div>
        <div class="pr8">
          <move-text caption class="mb4">{{ $t("bValue") }}:</move-text>
          <move-input-number
            tertiary
            v-model="vB"
            :valid="getValidation.B"
            class="w250"
            :disabled="getIsMappedMode"
          ></move-input-number>
        </div>
        <div>
          <move-text caption class="mb4">{{ $t("cValue") }}:</move-text>
          <move-input-number
            tertiary
            v-model="vC"
            :valid="getValidation.C"
            class="w250"
            :disabled="getIsMappedMode"
          ></move-input-number>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const LINEAR_LINEAR_DIGIT_FORMULA = "linear-in-linear-digit-formula";
const LINEAR_DIGIT_FORMULA = "linear-digit-formula";
const QUADRATIC_FORMULA = "quadratic-formula";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "scn-data-conversion-vw",
  props: {
    rawUnit: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapGetters({
      getTempSensor: "settings/getTempSensor",
      getTempSensorUserConfig: "settings/getTempSensorUserConfig",
      getTempSensorChannel0: "settings/getTempSensorChannel0",
      getSensorType: "settings/getSensorType",
      getProbeName: "settings/getProbeName",
      getConversionFormula: "settings/getConversionFormula",
      getVWConversionParameterMode: "settings/getVWConversionParameterMode",
      getVWQuadraticFormula: "settings/getVWQuadraticFormula",
      getVWLinearDigitMode: "settings/getVWLinearDigitMode",
      getVWLinearInLinearDigitMode: "settings/getVWLinearInLinearDigitMode",
      getIsMappedMode: "settings/getIsMappedMode"
    }),
    getUnit() {
      return {
        gain: null,
        A: null,
        B: null,
        C: null
      };
    },
    getValidation() {
      const result = {
        gain: true,
        A: true,
        B: true,
        C: true
      };
      if (this.vMode === LINEAR_DIGIT_FORMULA) {
        return result;
      }
      if (this.vMode === LINEAR_LINEAR_DIGIT_FORMULA) {
        result.gain = typeof this.vGain === "number";
        return result;
      }
      if (this.vMode === QUADRATIC_FORMULA) {
        result.A = typeof this.vA === "number";
        result.B = typeof this.vB === "number";
        result.C = typeof this.vC === "number";
        return result;
      }
      result.gain = false;
      result.A = false;
      result.B = false;
      result.C = false;
      return result;
    },
    getEntireValidation() {
      if (this.vMode === LINEAR_DIGIT_FORMULA) {
        return true;
      }
      if (this.vMode === LINEAR_LINEAR_DIGIT_FORMULA) {
        return this.getValidation.gain;
      }
      if (this.vMode === QUADRATIC_FORMULA) {
        return (
          this.getValidation.A && this.getValidation.B && this.getValidation.C
        );
      }
      return false;
    },

    vMode: {
      get() {
        return this.getVWConversionParameterMode;
      },
      set(mode) {
        if (mode === LINEAR_LINEAR_DIGIT_FORMULA) {
          this.updateConversionFormula({ G: 0 }, { clean: true });
        } else if (mode === LINEAR_DIGIT_FORMULA) {
          this.updateConversionFormula({ G: null }, { clean: true });
        } else if (mode === QUADRATIC_FORMULA) {
          this.updateConversionFormula(
            { A: null, B: null, C: null },
            { clean: true }
          );
        }
      }
    },
    vGain: {
      get() {
        return this.getConversionFormula &&
          this.getConversionFormula.G !== undefined
          ? this.getConversionFormula.G
          : null;
      },
      set(v) {
        // const gain = v === '' ? 0 : v
        this.updateConversionFormula({ G: v });
      }
    },
    vA: {
      get() {
        return this.getConversionFormula &&
          this.getConversionFormula.A !== undefined
          ? this.getConversionFormula.A
          : null;
      },
      set(A) {
        this.updateConversionFormula({ A: this.validateField(A) });
      }
    },
    vB: {
      get() {
        return this.getConversionFormula &&
          this.getConversionFormula.B !== undefined
          ? this.getConversionFormula.B
          : null;
      },
      set(B) {
        this.updateConversionFormula({ B: this.validateField(B) });
      }
    },
    vC: {
      get() {
        return this.getConversionFormula &&
          this.getConversionFormula.C !== undefined
          ? this.getConversionFormula.C
          : null;
      },
      set(C) {
        this.updateConversionFormula({ C: this.validateField(C) });
      }
    }
  },
  methods: {
    ...mapActions({
      setTempSensor: "settings/setTempSensor",
      updateValidationTempSensor: "settings/updateValidationTempSensor"
    }),
    validateField(value) {
      return value === "" ? null : value;
    },
    /*
    update conversion formula.
    - if options.clean = true, clean the conversion formula
    */
    updateConversionFormula(fields = {}, options = { clean: false }) {
      const sensor = this.getTempSensor;
      let conversionFormula =
        sensor.userConfig.channels[0].conversionFormula || {};
      conversionFormula = options.clean
        ? fields
        : { ...conversionFormula, ...fields };
      sensor.userConfig.channels[0].conversionFormula = conversionFormula;
      this.setTempSensor(sensor);
    },
    updateValidation(valid) {
      this.updateValidationTempSensor({
        field: "dataConversionVW",
        valid
      });
    }
  },
  watch: {
    getEntireValidation(valid) {
      this.updateValidation(valid);
    }
  },
  mounted() {
    this.updateValidation(this.getEntireValidation);
  }
};
</script>

<style scoped>
.card {
  display: flex;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
  border: 3px solid white;
  background-color: transparent;
  max-width: 14em;
}
.card.active {
  border: 3px solid var(--primary-color);
}

.disbled{
  pointer-events: none;
  opacity: 0.5;
}
</style>
