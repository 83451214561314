<i18n>
{
  "en": {
    "title": "Probe data conversion",
    "info":{
      "message":"Depending on the interface, specific parameters need to be set in order to convert the raw data into the actual measure. You can usually find those parameters in your probe's datasheet or instruction manual."
    },
    "gain": {
        "title": "Set the Gain"
    },
    "minOutput": "Minimum output value",
    "maxOutput": "Maximum output value"
  },
  "it": {
    "title": "Conversione dati della sonda",
    "info":{
      "message":"A seconda dell'interfaccia, specifici parametri devono essere settati per convertire il dato grezzo nella misura effettiva. Solitamente puoi trovare quei parametri nel datasheet o del manuale istruzioni della sonda."
    },
    "gain": {
        "title": "Imposta il Guadagno"
    },
    "minOutput": "Valore minimo dei dati convertiti",
    "maxOutput": "Valore massimo dei dati convertiti"
  }
}
</i18n>
<template>
  <div>
    <div class="d-flex align-items-center mb16">
      <move-text p b class="mr16"> {{ $t("title") }}: </move-text>
      <move-info rigth>
        <template #icon>
          <font-awesome-icon icon="fa-solid fa-circle-info" />
        </template>
        <div class="w400 p16">
          <move-text p> {{ $t("info.message") }}</move-text>
        </div>
      </move-info>
    </div>
    <move-text caption class="mb4"> {{ $t("gain.title") }}: </move-text>
    <move-input-number
      tertiary
      v-model="vG"
      class="w300"
      :valid="getValidation"
      :disabled="getIsMappedMode"
    >
    </move-input-number>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "scn-data-conversion-ymin-ymax",
  props: {
    rawUnit:{
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapGetters({
      getTempSensor: "settings/getTempSensor",
      getConversionFormula: "settings/getConversionFormula",
      getIsMappedMode: "settings/getIsMappedMode",
    }),
    getValidation() {
      return typeof this.vG === 'number'
    },
    vG: {
      get() {
        return (
          (this.getConversionFormula && this.getConversionFormula.G)
        );
      },
      set(G) {
        this.updateConversionFormula({ G: this.validateField(G) });
      }
    },
  },
  methods: {
    ...mapActions({
      setTempSensor: "settings/setTempSensor",
      updateValidationTempSensor: "settings/updateValidationTempSensor"
    }),
    validateField(value) {
      return value === "" ? null : value;
    },
    updateConversionFormula(fields = {}) {
      const sensor = this.getTempSensor;
      let conversionFormula =
        sensor.userConfig.channels[0].conversionFormula || {};
      conversionFormula = { ...conversionFormula, ...fields };
      sensor.userConfig.channels[0].conversionFormula = conversionFormula;
      this.setTempSensor(sensor);
    },
    updateValidation(valid) {
      this.updateValidationTempSensor({
        field: "dataConversionPTC",
        valid
      });
    }
  },
  watch: {
    getValidation(valid) {
      this.updateValidation(valid)
    }
  },
  mounted() {
    this.updateValidation(this.getValidation)
  },
};
</script>

<style scoped></style>
