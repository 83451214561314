<template>
  <div id="side-options" class="sidenav">
    <div class="dark-overlay"></div>
    <a href="javascript:void(0)" class="closebtn" @click="sidebarToggle">&times;</a>
    <header>
      <slot name="header"></slot>
    </header>
    <main>
      <slot></slot>
    </main>
    <div class="horiz-divider"></div>
    <footer>
      <slot name="footer"></slot>
    </footer>
  </div>
</template>
<script>
import $ from "jquery";

export default {
  name: "side-options",
  props: {
    show: Boolean,
  },
  methods: {
    openNav: function () {
      //$("#side-options").css("width", "200px");
      // $("#page-wrap").css("-webkit-transform", "translateX(200px");
      // $("#page-wrap").css("transform", "translateX(200px");
      $("#page-wrap").addClass("translateX200");
      $("#side-options").css("-webkit-transform", "translateX(200px");
      $("#side-options").css("transform", "translateX(200px");
      $("#general-info").css("-webkit-transform", "translateX(200px");
      $("#general-info").css("transform", "translateX(200px");
      
    },
    closeNav: function () {
      //$("#side-options").css("width", "0px");
      // $("#page-wrap").css("-webkit-transform", "translateX(0px");
      // $("#page-wrap").css("transform", "translateX(0px");
      $("#page-wrap").removeClass("translateX200");
      $("#side-options").css("-webkit-transform", "translateX(0px");
      $("#side-options").css("transform", "translateX(0px");
      $("#general-info").css("-webkit-transform", "translateX(0px");
      $("#general-info").css("transform", "translateX(0px");
      
    },
    sidebarToggle: function () {
      this.$emit("sidebartoggle");
    },
    settingsToggle: function () {
      this.$emit("settingstoggle");
    },
  },
  watch: {
    show: {
      handler: function (value) {
        if (value === true) {
          this.openNav();
        } else {
          this.closeNav();
        }
      },
    },
  },
  mounted() {},
};
</script>
<style>
.translateX200{
  -webkit-transform: translateX(200px);
  transform: translateX(200px);
}
</style>

<style scoped>


.translateX0{
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.sidenav {
  height: 100%;
  width: 200px;
  position: fixed;
  top: 0;
  left: -201px;
  background-color: rgb(40, 42, 59);
  border-right: solid 1px rgb(80, 84, 105);
  overflow-x: hidden;
  -webkit-transition: 0.6s ease-in-out;
  -moz-transition: 0.6s ease-in-out;
  -o-transition: 0.6s ease-in-out;
  transition: 0.6s ease-in-out;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  padding-top: 70px;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 0.8em;
  align-items: center;
  z-index: 8;
  margin: 0;
}

.sidenav a {
  /*   padding: 8px 8px 8px 32px;
 */
  cursor: pointer;
  text-decoration: none;
  font-size: 0.9em;
  width: 100%;
  padding: 10px 10px;
  position: relative;
  color: gray;
  font-family: inherit;
  display: flex;
  -webkit-transition: 0.6s ease-in-out;
  -moz-transition: 0.6s ease-in-out;
  -o-transition: 0.6s ease-in-out;
  transition: 0.6s ease-in-out;
}

.router-link-active {
  background-color: rgb(32, 34, 47);
  color: white !important;
}

/* Icone bianche se sono su quella route */
.router-link-active > img {
  filter: brightness(0) invert(1);
}

.horiz-divider {
  margin-top: 10%;
  margin-bottom: 10%;
  border: solid 1px rgb(45, 48, 65);
  width: 100%;
}

.icon {
  padding-left: 10px;
  padding-right: 10px;
}

.sidenav .closebtn {
  position: absolute;
  top: -12px;
  left: 50%;
  font-size: 36px;
  margin-left: 50px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}
</style>
