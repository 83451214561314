var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SettingsContentTemplate',{attrs:{"readOnly":_vm.readOnly},scopedSlots:_vm._u([{key:"tabs",fn:function(){return [(_vm.getSensorRev(_vm.selectedNode) === '1.0')?[_c('a',{class:{ active: _vm.curTab === 'manage' },on:{"click":function($event){_vm.curTab = 'manage'}}},[_vm._v(_vm._s(_vm.$t("message.manage"))+" ")]),_vm._l((_vm.selectedNode &&
        _vm.selectedNode.userConfig.channels
          ? _vm.selectedNode.userConfig.channels
          : []),function(channel,index){return _c('a',{key:index,class:{
          active: _vm.curTab === 'ch_' + index,
          disabled: !channel.enabled
        },on:{"click":function($event){channel.enabled
            ? ((_vm.curTab = 'ch_' + index), (_vm.selectedChannel = index))
            : null}}},[_vm._v(_vm._s(channel.name))])})]:[_c('a',{class:{ active: _vm.curTab === 'probe-configuration' },on:{"click":function($event){_vm.curTab = 'probe-configuration'}}},[_vm._v(_vm._s(_vm.$t('tabsNodeV2.probeConfiguaration'))+" ")]),_c('a',{class:{ active: _vm.curTab === 'acquisition-parameters' },on:{"click":function($event){_vm.curTab = 'acquisition-parameters'}}},[_vm._v(_vm._s(_vm.$t('tabsNodeV2.acquisitionParameters'))+" ")]),_c('a',{class:{ active: _vm.curTab === 'alarm' },on:{"click":function($event){_vm.curTab = 'alarm'}}},[_vm._v(_vm._s(_vm.$t('tabsNodeV2.alarms'))+" ")])]]},proxy:true},{key:"side",fn:function(){return [_c('div',{staticClass:"noselect scroll-thin",attrs:{"id":"side-sensor-select"}},[_c('div',{staticClass:"options"},_vm._l((_vm.tempSensors),function(sensor){return _c('a',{key:sensor.eui,class:{ active: _vm.selectedNode && _vm.selectedNode.eui === sensor.eui },on:{"click":function($event){return _vm.onChangeEUI(sensor)}}},[_vm._v(_vm._s(sensor.eui.substr(sensor.eui.length - 5))+" ")])}),0)])]},proxy:true},{key:"content",fn:function(){return [(_vm.getSensorRev(_vm.selectedNode) === '1.0')?[_c('AnalogNodeRev1Config',{attrs:{"sensor":_vm.selectedNode,"selectedChannel":_vm.selectedChannel,"curTab":_vm.curTab},on:{"sensorChanged":function($event){_vm.unsavedChanges = true},"updateSensors":_vm.updateSensorsV1}})]:[_c('AnalogNodeRev2Config',{attrs:{"sensor":_vm.selectedNode,"curTab":_vm.curTab}})]]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }