<i18n>
  {
    "en": {
      "analogNode": "Analog Node",
      "manage": "Manage",
      "manageExp": "Manage the operating settings of the Analog Node",
      "acquisitionTime": "Acquisition time",
      "warmupTime": "Warmup time",
      "sensor": "Sensor",
      "sensorConfig": "Configure the device connected to the Analog Node",
      "freqSampleMin": "Sampling frequency"
  
    },
    "it": {
      "analogNode": "Nodo Analogico",
      "manage": "Gestisci",
      "manageExp": "Gestisci le impostazioni di funzionamento del Nodo Analogico",
      "acquisitionTime": "Tempo di acquisizione",
      "warmupTime": "Tempo di warmup",
      "sensor": "Sensore",
      "sensorConfig": "Configura il dispositivo collegato al Nodo Analogico",
      "freqSampleMin": "Freq. campionamento"
    }
  }
</i18n>
<template>
  <div class="main-container">
    <!-- Gestione -->
    <div v-if="curTab === 'manage' && selectedNode">
      <div class="sr-heading">
        <p>{{ $t("manageExp") }}:</p>
      </div>
      <div id="analog-content">
        <div class="analog-manage-box">
          <!-- Colore -->
          <div
            class="side-color"
            :style="{ backgroundColor: selectedNode.userConfig.color }"
          ></div>
          <!-- Titolo -->
          <div>
            <h5>
              {{
                $t("analogNode") +
                  " " +
                  selectedNode.eui +
                  " - " +
                  selectedNode.userConfig.name
              }}
            </h5>
          </div>
          <img class="sensor-manage-changecolor icon" :src="changeColorIcon" />
          <div class="dropdown">
            <div class="dropdown-content">
              <div
                v-for="(color, key, index) in colors"
                :key="index"
                class="dot"
                :style="{ backgroundColor: color }"
                @click="selectedNode.userConfig.color = color"
              ></div>
            </div>
          </div>
        </div>
        <!-- Selezione sotto-sensori -->
        <div class="sensor-selection">
          <div
            class="checkbox"
            v-for="(channel, index) in selectedNode.userConfig.channels"
            :key="index"
          >
            <input
              type="checkbox"
              :name="'channel' + index"
              v-model="channel.enabled"
            />
            <label>{{ $t("sensor") + " " + (index + 1) }}</label>
          </div>
        </div>
      </div>
    </div>
    <div v-if="curTab != 'manage' && selectedNode">
      <div class="sr-heading">
        <p>{{ $t("sensorConfig") + ":" }}</p>
        <h2>
          {{
            selectedNode.userConfig.name +
              " - " +
              $t("analogNode") +
              " " +
              selectedNode.eui.substr(selectedNode.eui.length - 5) +
              " (" +
              selectedNode.userConfig.channels[selectedChannel].nativeType +
              ")"
          }}:
        </h2>
      </div>
      <div class="device-settings">
        <!-- Nome canale -->
        <div class="single-setting">
          <label>Ch. name</label>
          <input
            type="text"
            class="no-border sensor-manage-threshold full-input"
            spellcheck="false"
            maxlength="12"
            v-model="selectedNode.userConfig.channels[selectedChannel].name"
          />
        </div>
        <!-- Modalità -->
        <div
          class="single-setting"
          v-if="selectedNode.userConfig.channels[selectedChannel].mode"
        >
          <label>Mode</label>
          <select
            class="select"
            v-model="selectedNode.userConfig.channels[selectedChannel].mode"
          >
            <option
              v-for="chMode in availableModesByNativeType[
                selectedNode.userConfig.channels[selectedChannel].nativeType
              ]"
              :key="chMode"
              :value="chMode"
              >{{ chMode }}</option
            >
          </select>
        </div>
        <!-- Type -->
        <div
          class="single-setting"
          v-if="!selectedNode.userConfig.channels[selectedChannel].mode"
        >
          <label>Type</label>
          <select
            class="select"
            v-model="selectedNode.userConfig.channels[selectedChannel].type"
            :disabled="!customMode"
          >
            <option
              v-for="chType in analogActualTypes"
              :key="chType"
              :value="chType"
              >{{ chType }}</option
            >
          </select>
        </div>
        <!-- Wires -->
        <!-- <div class="single-setting" v-if="!nativeMode && analogOptions[selectedNode.userConfig.channels[selectedChannel].type].wires">
            <label>Wires</label>
            <select class="select" v-model.number="selectedNode.userConfig.channels[selectedChannel].wires" :disabled="!customMode">
                <option :value="wireOption" v-for="(wireOption, index) in analogOptions[selectedNode.userConfig.channels[selectedChannel].type].wires" :key="index">
                {{ wireOption }}
                </option>
            </select>
            </div> -->
        <!-- SensorType -->
        <div
          class="single-setting"
          v-if="!nativeMode && getShowableOption('sensorType')"
        >
          <label>Sensor Type</label>
          <select
            class="select"
            v-model.number="
              selectedNode.userConfig.channels[selectedChannel].sensorType
            "
          >
            <option
              :value="sensrType"
              v-for="(sensrType, index) in ['PTC', 'NTC']"
              :key="index"
            >
              {{ sensrType }}
            </option>
          </select>
        </div>
        <!-- VCC -->
        <div class="single-setting" v-if="getShowableOption('VCC')">
          <label>VCC</label>
          <select
            class="select"
            v-model.number="
              selectedNode.userConfig.channels[selectedChannel].VCC
            "
            :disabled="true"
          >
            <option :value="0">0</option>
            <option :value="5">5</option>
            <option :value="12">12</option>
            <option :value="24">24</option>
          </select>
        </div>
        <!-- Full Scale -->
        <div
          class="single-setting"
          v-if="!nativeMode && getShowableOption('fullScale')"
        >
          <label>Full Scale</label>
          <input
            type="number"
            min="0"
            step="any"
            class="no-border sensor-manage-threshold full-input"
            spellcheck="false"
            maxlength="10e"
            v-model.number="
              selectedNode.userConfig.channels[selectedChannel].fullScale
            "
            :disabled="nativeMode"
          />
        </div>
        <!-- Offset -->
        <div
          class="single-setting"
          v-if="!nativeMode && getShowableOption('offset')"
        >
          <label>Offset</label>
          <input
            type="number"
            min="0"
            step="any"
            class="no-border sensor-manage-threshold full-input"
            spellcheck="false"
            maxlength="10e"
            v-model.number="
              selectedNode.userConfig.channels[selectedChannel].offset
            "
            :disabled="nativeMode"
          />
        </div>
        <!-- Max Value -->
        <!-- <div class="single-setting" v-if="!nativeMode && getShowableOption('maxValue')">
            <label>Max Value</label>
            <input
                type="number"
                min="0"
                step="any"
                class="no-border sensor-manage-threshold full-input"
                spellcheck="false"
                maxlength="10e"
                v-model.number="selectedNode.userConfig.channels[selectedChannel].maxValue"
                :disabled="nativeMode"
            />
            </div> -->
        <!-- Gain -->
        <div
          class="single-setting"
          v-if="!nativeMode && getShowableOption('gain')"
        >
          <label>Gain</label>
          <input
            type="number"
            min="0"
            step="any"
            class="no-border sensor-manage-threshold full-input"
            spellcheck="false"
            maxlength="10e"
            v-model.number="
              selectedNode.userConfig.channels[selectedChannel].gain
            "
            :disabled="nativeMode"
          />
        </div>
        <!-- Nominal Resistance -->
        <div
          class="single-setting"
          v-if="!nativeMode && getShowableOption('nominalResistance')"
        >
          <label>Nominal Res.</label>
          <input
            type="number"
            min="0"
            step="any"
            class="no-border sensor-manage-threshold full-input"
            spellcheck="false"
            maxlength="10e"
            v-model.number="
              selectedNode.userConfig.channels[selectedChannel]
                .nominalResistance
            "
            :disabled="nativeMode"
          />
        </div>
        <!-- Unità di Misura -->
        <div class="single-setting">
          <label>Mes. Unit</label>
          <input
            type="text"
            class="no-border sensor-manage-threshold full-input"
            spellcheck="false"
            v-model="selectedNode.userConfig.channels[selectedChannel].mesUnit"
            :disabled="nativeMode"
          />
        </div>
        <!-- Allarmi -->
        <div class="sep multiple-control">
          <h2>{{ $t("message.alert") }}</h2>
          <div class="sub-setting">
            <div class="auto-row">
              <label>{{ $t("message.activatedM") }}</label>
              <input
                type="checkbox"
                v-model="
                  selectedNode.userConfig.channels[selectedChannel].alertOn
                "
              />
            </div>
            <div class="auto-row">
              <label>{{ $t("message.absoluteValue") }}</label>
              <input
                class="checkbox"
                type="checkbox"
                v-model="
                  selectedNode.userConfig.channels[selectedChannel]
                    .alertAbsolute
                "
              />
            </div>
          </div>
          <div class="single-setting large">
            <label>{{ $t("message.threshold") }}</label>
            <input
              type="number"
              class="no-border sensor-manage-threshold full-input"
              spellcheck="false"
              v-model.number="
                selectedNode.userConfig.channels[selectedChannel].alertThreshold
              "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  validateSensorName,
  validateThreshold,
  validateActivationTh
} from "../../../helpers/validate";
export default {
  name: "analog-node-settings",

  props: {
    sensor: null,
    selectedChannel: null,
    curTab: null
  },
  data() {
    return {
      changeColorIcon: require("../../../../public/assets/icons/changecolor.svg"),
      selectedNode: null,
      oldNode: null,

      availableModesByNativeType: {
        "4-20": ["gestecno", "custom", "native"],
        "mV/V": ["custom", "native"],
        thermistor: ["PT100_620", "PT100_680", "native"]
      },
      analogActualTypes: [
        "4-20",
        "gestecno",
        "thermistorPT100_620",
        "thermistorPT100_680",
        "mV/V",
        "potentiometer",
        "voltage-unipolar",
        "voltage-bipolar"
      ], // TODO DEPRECATED
      analogOptionsByNativeType: {
        // Native
        "4-20": {
          wires: true,
          fullScale: true,
          offset: true,
          mesUnit: true
        },
        // Native
        thermistor: {
          sensorType: true,
          gain: true,
          offset: true,
          mesUnit: true
        },
        // Native
        "mV/V": {
          VCC: true,
          gain: true,
          offset: true,
          fullScale: true,
          mesUnit: true
        }
      },
      tempSensors: null,
      colors: [
        "white",
        "silver",
        "gray",
        "black",
        "red",
        "yellow",
        "green",
        "aqua",
        "blue",
        "navy",
        "fuchsia",
        "purple"
      ]
    };
  },
  methods: {
    validateSensorName: validateSensorName,
    validateThreshold: validateThreshold,
    validateActivationTh: validateActivationTh,

    getShowableOption(optionName) {
      return this.analogOptionsByNativeType[
        this.selectedNode.userConfig.channels[this.selectedChannel].nativeType
      ][optionName];
    }
  },
  computed: {
    currentChannelMode() {
      return (
        this.selectedNode &&
        this.selectedChannel !== null &&
        this.selectedNode.userConfig.channels[this.selectedChannel].mode
      );
    },
    customMode() {
      return this.currentChannelMode && this.currentChannelMode === "custom";
    },
    nativeMode() {
      return this.currentChannelMode && this.currentChannelMode === "native";
    },
    selectedEuiAndChannelIndexAndObj() {
      if (
        this.selectedNode &&
        this.selectedChannel !== null &&
        this.selectedNode.userConfig.channels[this.selectedChannel].mode
      ) {
        return [
          this.selectedNode.eui,
          this.selectedChannel,
          this.selectedNode.userConfig.channels[this.selectedChannel].mode
        ];
      } else {
        return undefined;
      }
    },
    getIsSensorChanged() {
      return !_.isEqual(this.oldNode, this.selectedNode);
    }
  },

  mounted() {
    this.selectedNode = this.sensor;
    this.oldNode = _.cloneDeep(this.selectedNode);
  },

  watch: {
    selectedNode: {
      handler: function(newValue) {
        if (
          newValue &&
          this.selectedChannel != null &&
          this.selectedChannel != undefined
        ) {
          if (
            newValue.userConfig.channels[this.selectedChannel].type === "mV/V"
          ) {
            // Se seleziono la modalità 'mV/V' setto VCC a 5 di default
            this.selectedNode.userConfig.channels[this.selectedChannel].VCC = 5;
          }
        }
        this.$emit('updateSensors', this.selectedNode)
      },
      deep: true
    },
    sensor: {
      handler: function() {
        this.selectedNode = this.sensor;
        if (this.sensor.eui !== this.oldNode.eui) {
          //ho cambiato sensore
          this.oldNode = _.cloneDeep(this.selectedNode);
        } else {
          //non ho cambiato sensore
          this.$emit('sensorChanged')
        }
      },
      deep: true
    },
    // Oggetto canale selezionato
    selectedEuiAndChannelIndexAndObj: {
      handler: function(newValue, oldValue) {
        if (!newValue || !oldValue) {
          return;
        }
        const newEui = newValue[0];
        const oldEui = oldValue[0];
        const newMode = newValue[2];
        const oldMode = oldValue[2];
        const newChannelIndex = newValue[1];
        const oldChannelIndex = oldValue[1];
        const ch = this.selectedNode.userConfig.channels[this.selectedChannel];

        if (
          newMode !== oldMode &&
          newChannelIndex === oldChannelIndex &&
          newEui === oldEui
        ) {
          if (newMode === "custom" && newMode !== oldMode && ch.nativeType) {
            // Sto andando in modalità CUSTOM
            switch (ch.nativeType) {
              case "4-20":
                ch.wires = 2;
                ch.VCC = 5;
                ch.fullScale = 100;
                ch.offset = 0;
                ch.mesUnit = "%";
                break;
              case "mV/V":
                ch.VCC = 5;
                ch.gain = 1;
                ch.offset = 0;
                ch.fullScale = 1;
                ch.mesUnit = "mV/V";
                break;
            }
          } else if (
            newMode === "native" &&
            newMode !== oldMode &&
            ch.nativeType
          ) {
            // Sto andando in modalità NATIVA
            switch (ch.nativeType) {
              case "4-20":
                ch.mesUnit = "mA";
                this.VCC = 5;
                break;
              case "mV/V":
                ch.mesUnit = "mV";
                this.VCC = 5;
                break;
              case "thermistor":
                ch.mesUnit = "Ohm";
                break;
            }
          } else if (
            newMode === "gestecno" &&
            newMode !== oldMode &&
            ch.nativeType
          ) {
            // Setto le modalità di default se passo da * a GESTECNO
            ch.wires = 2;
            ch.VCC = 5;
            ch.fullScale = 100;
            ch.offset = 0;
            ch.mesUnit = "Hz";
          } else if (
            newMode === "PT100_620" &&
            newMode !== oldValue &&
            ch.nativeType
          ) {
            // Setto le modalità di default se passo da * a PT100_620
            ch.sensorType = "PTC";
            ch.gain = 1;
            ch.offset = 0;
            ch.mesUnit = "Ohm";
          } else if (
            newMode === "PT100_680" &&
            newMode !== oldMode &&
            ch.nativeType
          ) {
            // Setto le modalità di default se passo da * a PT100_680
            ch.sensorType = "PTC";
            ch.gain = 1;
            ch.offset = 0;
            ch.mesUnit = "Ohm";
          }
        }
      },
      deep: true
    }
  }
};
</script>
<style lang="postcss" scoped>
* {
  font-style: normal;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: white;
}
input[disabled] {
  pointer-events: none;
}
.dark-overlay {
  background-color: rgba(0, 0, 0, 0.27);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 18;
}
h1 {
  font-size: 1em;
}
a {
  color: white;
  font-size: 0.75em;
}
p {
  color: rgb(198, 198, 198);
  padding: 0px;
}
.horiz-divider {
  background-color: grey;
  height: 2px;
  margin: 4px 0;
}
.settings-container {
  width: 82vw;
  height: 70vh;
  min-width: 1180px;
  min-height: 480px;
  top: 12vh;
  left: 0;
  position: sticky;
  grid-template-columns: 15% 85%;
  grid-template-rows: 45px 1.1fr 6.9fr 1fr;
  border-radius: 10px;
  overflow: hidden;
  display: grid;
  background-color: rgb(45, 48, 65);
  -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
}
.options {
  display: grid;
  text-align: left;
  display: grid;
  text-align: left;
  grid-template: none;
  align-items: center;
  max-height: 540px;
}
.options > a {
  padding-left: 1.2vw;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: default;
  margin: 14px 0;
  color: white;
}

/* selected link */
.options > a.active {
  background-color: rgb(32, 34, 47) !important;
}
.options > a.disabled {
  color: gray;
  pointer-events: none;
  cursor: default;
}



.close {
  align-self: center;
  cursor: pointer;
  right: 2%;
  width: 3%;
}
input[type="range"]::-moz-range-track {
  padding: 0 10px;
  background: repeating-linear-gradient(
    to right,
    #ccc,
    #ccc 10%,
    #000 10%,
    #000 11%,
    #ccc 11%,
    #ccc 20%
  );
}
/* Control Buttons */
.footer-btn {
  display: flex;
  height: 35px;
}
.button {
  width: 158px;
  padding: 2%;
  line-height: 100%;
  margin: auto;
  font-weight: 600;
  margin-right: 20px;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: rgb(80, 84, 105);
  border: solid 2px white;
  border-radius: 7px;
  transition: transform 0.1s linear;
}
.button:active {
  background-color: rgb(47, 112, 177);
  transform: translateY(1px);
}
.button:disabled {
  background-color: grey;
}
.button:disabled:hover {
  background-color: grey;
}
.bottom-buttons {
  position: absolute;
  top: 405px;
  left: 458px;
}
.save {
  background-color: rgb(21, 146, 230);
}
.save:hover {
  background-color: rgba(21, 146, 230, 0.8);
}


.close {
  align-self: center;
  cursor: pointer;
  right: 2%;
  width: 3%;
}
/* Loading Transition */
.load-enter,
.load-leave-to {
  opacity: 0;
}
.load-enter-active {
  transition: all 0.1s ease;
}
.load-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
/* ::-webkit-scrollbar {
  width: 0.5em;
}
::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
} */

.main-container {
  background-color: rgb(45, 48, 65);
  grid-row: 1;
  grid-column: 2;
  padding-left: 4%;
  padding-right: 4%;
  padding-top: 1.2vw;
  padding-bottom: 60px;
  overflow-y: scroll;
  overflow-x: hidden;
}





* {
  font-style: normal;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: white;
}
input[disabled] {
  pointer-events: none;
}
.dark-overlay {
  background-color: rgba(0, 0, 0, 0.27);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9;
}
h1 {
  font-size: 1em;
}
a {
  color: white;
  font-size: 0.75em;
}
p {
  color: rgb(198, 198, 198);
  padding: 0px;
}
label {
  font-size: 0.7em;
}
.horiz-divider {
  background-color: grey;
  height: 2px;
  margin: 4px 0;
}
.input-text {
  background-color: rgba(0, 0, 0, 0);
  width: 120px;
  height: 30px;
  outline-width: 0;
  outline: none !important;
  border: none;
  display: inline;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  padding-bottom: 0;
  white-space: nowrap;
  display: flex;
  align-items: center;
  align-self: center;
  -webkit-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
}
.input-text:enabled {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}
.input-text:enabled.valid {
  border: solid 1.2px green !important;
  padding: 1px;
}
.input-text.invalid {
  border: solid 1.2px red !important;
  padding: 1px;
}

/* .settings-container {
  width: 82vw;
  height: 64vh;
  min-width: 1180px;
  min-height: 480px;
  top: 22vh;
  left: 0;
  position: sticky;
  grid-template-columns: 15% 85%;
  grid-template-rows: 1fr 1.1fr 6.9fr 1fr;
  border-radius: 10px;
  overflow: hidden;
  display: grid;
  background-color: rgb(45, 48, 65);
  -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
} */
.options {
  height: 40%;
  display: grid;
  text-align: left;
  padding-top: 0.8vw;
  height: 40%;
  display: grid;
  text-align: left;
  grid-template: none;
  align-items: center;
}
.options > a {
  padding-left: 1.2vw;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: default;
  margin: 14px 0;
  color: white;
}

/* selected link */
.options > a.active {
  background-color: rgb(32, 34, 47) !important;
}
.options > a.disabled {
  color: gray;
  pointer-events: none;
  cursor: default;
}


.grid-content {
  display: grid;
  width: 60%;
  justify-items: baseline;
  row-gap: 12px;
  column-gap: 12px;
}
.grid-content > input {
  grid-column: 2;
}
.grid-content > select {
  grid-column: 2;
}
.grid-content > h4 {
  grid-column: 1;
  margin: 0;
}
.input-group {
  display: flex;
  width: 100%;
}
.sr-heading {
  text-align: left;
  margin-bottom: 15px;
  height: 35px;
  display: flex;
}
.sr-heading > h4 {
  margin: 0;
}
.sr-heading > p {
  margin: 0;
}
.checkbox-container {
  display: grid;
  width: 100%;
  justify-items: center;
}
.sensor-box {
  height: 70px;
  text-align: left;
  border-bottom: solid 1px grey;
  display: grid;
  grid-template-columns: 2% 49% 49%;
  grid-template-rows: 50% 50%;
  padding-top: 1%;
}
.sensor-box > .sensor-head {
  margin: 0;
  font-weight: 700;
  grid-column: 2;
  grid-row: 1;
  align-self: center;
}
.sensor-box > .sensor-caption {
  margin: 0;
  grid-column: 2;
  grid-row: 2;
  align-self: center;
}

.sensor-box > input {
  grid-column: 3;
  grid-row: 2;
}

.sensor-manage-box {
  height: 80px;
  text-align: left;
  position: relative;
  border-bottom: solid 2px rgba(255, 255, 255, 0.15);
  display: grid;
  grid-template-columns: 2% 60% 20% 9% 9%;
  grid-template-rows: 50% 50%;
  padding-top: 1%;
}
.sensor-manage-eui {
  margin: 0;
  white-space: nowrap;
  font-weight: 700;
  align-self: center;
  padding-top: 1%;
  grid-column: 2;
  grid-row: 1;
}
.sensor-manage-name {
  grid-column: 2;
  grid-row: 2;
}
.threshold-field {
  grid-column: 2/4;
  grid-row: 2;
  display: flex;
  align-self: center;
}
.threshold-field > input[type="checkbox"] {
  align-self: center;
}
.threshold-field > h2 {
  font-weight: 700;
  align-self: center;
  white-space: nowrap;
  margin: 0;
  padding: 0;
}
.threshold-field > h4 {
  margin: 8px 0;
}
.threshold-field > .sensor-manage-threshold {
  background-color: rgba(0, 0, 0, 0);
  width: 150px;
  outline-width: 0;
  outline: none !important;
  /* border: none; */
  display: inline;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  margin-left: 10px;
  display: flex;
  align-items: center;
  align-self: center;
  -webkit-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
}
.threshold-field > .sensor-manage-threshold:enabled {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}
.sensor-manage-box > .sensor-manage-name:enabled {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}
.sensor-manage-box > .sensor-manage-label {
  margin: 0;
  grid-row: 1;
  grid-column: 3;
  align-self: center;
}
.sensor-manage-box > .sensor-manage-edit {
  justify-self: end;
  align-self: end;
  grid-row: 1;
  grid-column: 5;
}
.sensor-manage-box > .sensor-manage-changecolor {
  justify-self: end;
  align-self: end;
  grid-row: 1;
  grid-column: 4;
}
.side-color {
  width: 6px;
  height: 80%;
  align-self: center;
  grid-column: 1;
  background-color: green;
  grid-row-start: 1;
  grid-row-end: 3;
  display: flex;
  align-items: center;
}
.select-options {
  margin-right: 10px;
}
.disabled-select {
  background-image: none;
  background-color: rgba(255, 255, 255, 0.15) !important;
}
/* Span */
.span-name {
  grid-column: 1;
}
.span-name-field {
  grid-column: 1;
  grid-row: 2;
  grid-template-columns: 50px auto;
  display: grid;
  padding: 6px 0;
}

.span-lenght-field {
  grid-column: 1;
  grid-row: 3;
  grid-template-columns: 50px auto;
  display: grid;
  padding: 6px 0;
}

.span-box {
  height: fit-content;
  text-align: left;
  border-bottom: solid 2px rgba(255, 255, 255, 0.15);
  display: grid;
  /* grid-template-rows: 50% 50%; */
  padding-top: 1%;
  grid-template-columns: 80% 10% 10%;
}
.span-box > .input-text {
  width: 100%;
}

/* Items */
.item-box {
  height: 80px;
  text-align: left;
  border-bottom: solid 2px rgb(80, 84, 105);
  display: grid;
  grid-template-columns: 4fr 4fr 1fr 1fr;
  column-gap: 20px;
}

.item-box > .input-text {
  width: 100%;
}
.notify-heading {
  text-align: left;
  padding-bottom: 15px;
  margin-bottom: 10px;
  border-bottom: solid 2px rgb(80, 84, 105);
}
.notify-heading > h4 {
  margin: 0;
}
.notify-heading > p {
  margin: 0;
}

.notify-heading-container > .title > h4 {
  margin: 0;
}

.notify-heading-container > .title > p {
  margin: 0;
}

.notify-heading-container {
  display: flex;
  text-align: left;
  padding-bottom: 15px;
  margin-bottom: 10px;
  border-bottom: solid 2px rgb(80, 84, 105);
  align-items: center;
  justify-content: space-between;
}
.alert-sensor-types {
  grid-column: 2;
  grid-row: 1/3;
  display: grid;
  grid-template-columns: min-content auto min-content auto;
  align-items: center;
  grid-template-rows: max-content max-content;
  margin: auto 0;
}
.item-box > .switch {
  grid-column: 3;
  grid-row-start: 1;
  grid-row-end: 3;
  align-self: center;
}
.mail-edit-icon {
  min-height: 25px;
  width: 25px;
  grid-column: 4;
  grid-row-start: 1;
  grid-row-end: 3;
  justify-self: end;
  align-self: center;
}
.mail-delete-icon {
  min-height: 25px;
  width: 25px;
  grid-column: 3;
  grid-row-start: 1;
  grid-row-end: 3;
  justify-self: end;
  align-self: center;
}
.mail-name {
  grid-column: 1;
  grid-row: 1;
  font-weight: 700;
}
.mail-addr {
  grid-column: 1;
  grid-row: 2;
  margin: 0;
  color: rgb(198, 198, 198);
}
.add-box {
  display: flex;
  align-items: center;
  align-content: center;
}
.add-box > h5 {
  font-weight: 700;
  padding: 8px;
  border-radius: 5px;
  -webkit-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
  cursor: pointer;
}
.add-box > h5:hover {
  background-color: rgba(80, 84, 105, 1);
}
.add-box > .mail-new-icon {
  width: 5%;
  margin-right: 2%;
  background-color: rgba(0, 0, 0, 0) !important;
}
::-webkit-scrollbar {
  width: 0.5em;
}
::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
.close {
  align-self: center;
  cursor: pointer;
  right: 2%;
  width: 3%;
}
input[type="range"]::-moz-range-track {
  padding: 0 10px;
  background: repeating-linear-gradient(
    to right,
    #ccc,
    #ccc 10%,
    #000 10%,
    #000 11%,
    #ccc 11%,
    #ccc 20%
  );
}
.options-wrap {
  width: 100%;
  width: 100%;
  display: flex;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-template-rows: 40% 35%;
  grid-template-columns: 25% 25% 25% 25%;
  -webkit-box-align: end;
  -ms-flex-align: end;
  justify-items: center;
  align-self: end;
}
.compensation-opt {
  align-self: center;
}
.options-wrap > input {
  width: 100%;
  grid-row: 1;
}
.options-wrap > .container {
  grid-row: 1;
}
.options-wrap > .value {
  grid-row: 2;
  justify-self: center;
  color: rgb(198, 198, 198);
  margin-left: 5%;
}

/* Control Buttons */
.footer-btn {
  display: flex;
  height: 35px;
}
.button {
  width: 158px;
  padding: 2%;
  line-height: 100%;
  margin: auto;
  font-weight: 600;
  margin-right: 20px;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: rgb(80, 84, 105);
  border: solid 2px white;
  border-radius: 7px;
  transition: transform 0.1s linear;
}
.button:active {
  background-color: rgb(47, 112, 177);
  transform: translateY(1px);
}
.button:disabled {
  background-color: grey;
}
.button:disabled:hover {
  background-color: grey;
}
.bottom-buttons {
  position: absolute;
  top: 405px;
  left: 458px;
}
.save {
  background-color: rgb(21, 146, 230);
}
.save:hover {
  background-color: rgba(21, 146, 230, 0.8);
}
/* Color Dropdown */
.dropbtn {
  background-color: #4caf50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}
.dropdown {
  position: relative;
  grid-row: 1;
  grid-column: 4;
}
.dropdown-content {
  transition: visibility 0.2s linear, opacity 0.2s linear;
  -ms-transition: visibility 0.2s linear, opacity 0.2s linear;
  -webkit-transition: visibility 0.2s linear, opacity 0.2s linear;
  display: grid;
  visibility: hidden;
  opacity: 0;
  align-content: center;
  align-items: center;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: 33% 33% 33%;
  position: relative;
  top: -50%;
  right: 74px;
  background-color: rgb(80, 84, 105);
  width: 100px;
  height: 85px;
  border-radius: 5px;
  padding: 5px;
  transition-delay: 0.2s;
}
.dot {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-block;
  border: solid 1px white;
}
.dropdown:hover .dropdown-content {
  opacity: 1;
  visibility: visible;
}

/* Edit Icon */
.icon {
  padding: 5px;
  border-radius: 5px;
  -webkit-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
}
.icon:hover {
  background-color: rgb(80, 84, 105);
}
/* The container */
.container {
  position: relative;
  display: contents;
  cursor: pointer;
  font-size: 22px;
  user-select: text;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
/* Hide the browser's default radio button */
.container input {
  position: absolute;
  top: 50vh;
  opacity: 0;
  cursor: pointer;
}
/* Create a custom radio button */
.checkmark {
  left: 0;
  top: 0;
  height: 14px;
  position: relative;
  width: 14px;
  border-radius: 50%;
  border: solid 3px white;
  z-index: 9;
}
.checkmark.disabled {
  border: solid 3px gray;
}
/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: rgb(80, 84, 105);
}
/* When the radio button is checked, add a blue background */
/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}
/* Style the indicator (dot/circle) */
.container .checkmark:after {
  top: 3px;
  left: 3px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
label.disabled {
  color: gray;
}
.container .checkmark.disabled:after {
  background: gray;
}




.input-text:enabled {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}
.input-text:invalid {
  border: solid 1.2px red;
  padding: 1px;
}
.input-text:valid {
  border: solid 1.2px green;
  padding: 1px;
}

.input-text-username {
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  height: 30px;
  outline-width: 0;
  outline: none !important;
  border: none;
  display: inline;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  padding-bottom: 0;
  white-space: nowrap;
  display: flex;
  align-items: center;
  align-self: center;
  -webkit-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
}

.input-text-username.valid {
  border: solid 1.2px green;
  padding: 1px;
}

.input-text-username.not-valid {
  border: solid 1.2px red;
  padding: 1px;
}

.input-text-username:invalid {
  border: solid 1.2px red;
  padding: 1px;
}

.input-text-username:enabled {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

.input-text-username:disabled {
  border: none;
}

/* selected link */
.options > a.active {
  background-color: rgb(32, 34, 47);
}
.options > a.disabled {
  color: gray;
  pointer-events: none;
  cursor: default;
}
.single-setting {
  max-width: 500px;
  display: grid;
  grid-template-columns: 140px 10fr;
  justify-items: baseline;
  align-items: center;
  padding: 5px 0;
  height: 30px;
}
.single-setting > label {
  grid-row: 1;
}
.single-setting > select {
  margin-left: 0;
  padding-bottom: 2px;
  padding-top: 2px;
}
.auto-row {
  max-width: 500px;
  height: 30px;
  padding: 5px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sensor-selection {
  display: flex;
  justify-content: space-between;
  padding: 14px 30px;
}
.checkbox {
  display: flex;
  align-items: center;
}
.checkbox > input[type="checkbox"] {
  margin-right: 10px;
}
input[type="checkbox"] {
  height: 18px;
  /* width: 18px; */
}
.sr-heading {
  text-align: left;
  margin-bottom: 25px;
}
.sr-heading > h4 {
  margin: 0;
}
.sr-heading > p {
  margin: 0;
}
.checkbox-container {
  display: grid;
  width: 100%;
  justify-items: center;
}
.info-container {
  grid-row: 2;
  grid-column: 2;
  display: grid;
  grid-template-columns: repeat(4, 25%);
  align-content: center;
  text-align: center;
}
.analog-box {
  height: 80px;
  text-align: left;
  border-bottom: solid 1px grey;
  display: grid;
  padding-bottom: 10px;
  grid-template-columns: 2% 98%;
  grid-template-rows: auto 60%;
}
.sensor-head {
  margin: 0;
  margin-top: 10px;
  grid-column: 2;
  grid-row: 1;
  display: flex;
}
.value {
  font-weight: 700;
}
.analog-box > .sensor-caption {
  margin: 0;
  grid-column: 2;
  grid-row: 2;
}
.full-input {
  background-color: rgba(0, 0, 0, 0);
  padding-left: 6px;
  width: 100%;
  height: 100%;
  outline-width: 0;
  box-sizing: border-box;
  outline: none !important;
  /* border: none; */
  display: inline;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  display: flex;
  align-items: center;
  align-self: center;
  -webkit-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
}
.analog-threshold-field > input[type="number"] {
  grid-column: 2;
  margin-left: 10px;
}

.analog-manage-box {
  height: fit-content;
  text-align: left;
  border-bottom: solid 2px rgba(255, 255, 255, 0.15);
  display: grid;
  grid-template-columns: 2% 60% 20% 9% 9%;
}
label {
  white-space: nowrap;
  font-weight: 500;
}
.analog-manage-box > .sensor-manage-eui {
  margin: 0;
  white-space: nowrap;
  font-weight: 700;
  align-self: center;
  padding-top: 1%;
  grid-column: 2;
  grid-row: 1;
}
.sensor-manage-name {
  grid-column: 2;
  grid-row: 2;
}
.sensor-params {
  width: fit-content;
  grid-column: 2;
  grid-row: 2;
}
.analog-threshold-field {
  display: grid;
  row-gap: 10px;
}
.analog-threshold-field > label {
  grid-column: 1;
  font-weight: 400;
  font-size: 0.8em;
  align-self: center;
  margin: 0;
  padding: 0;
}

input[type="number"]:disabled.valid-field,
input[type="text"]:disabled.valid-field {
  border: none;
}
.analog-threshold-field > input[type="number"] {
  grid-column: 2;
  margin-left: 10px;
}
input[type="number"],
input[type="text"]:enabled {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}
.analog-manage-box > .sensor-manage-label {
  margin: 0;
  grid-row: 1;
  grid-column: 3;
  align-self: center;
}
.analog-manage-box > .sensor-manage-edit {
  justify-self: end;
  align-self: end;
  grid-row: 1;
  grid-column: 5;
}
.analog-manage-box > .sensor-manage-changecolor {
  justify-self: end;
  align-self: center;
  grid-row: 1;
  grid-column: 4;
}
.side-color {
  width: 6px;
  height: 80%;
  align-self: center;
  grid-column: 1;
  background-color: green;
  grid-row-start: 1;
  grid-row-end: 3;
  display: flex;
  align-items: center;
  margin-left: -10px;
}
.multiple-control {
  display: grid;
  width: 500px;
  padding: 5px 0;
}
.sub-setting {
  display: flex;
  justify-content: space-between;
}

.sep {
  margin-top: 10px;
  border-top: solid grey 1px;
}
/* Mail */
.mail-box {
  height: 80px;
  text-align: left;
  border-bottom: solid 2px rgb(80, 84, 105);
  display: grid;
  grid-template-columns: 80% 10% 10%;
}
.mail-box > .input-text {
  width: 100%;
}
.notify-heading {
  text-align: left;
  padding-bottom: 15px;
  margin-bottom: 10px;
  border-bottom: solid 2px rgb(80, 84, 105);
}
.notify-heading > h4 {
  margin: 0;
}
.notify-heading > p {
  margin: 0;
}

/* ::-webkit-scrollbar {
  width: 0.5em;
}
::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
} */
.close {
  align-self: center;
  cursor: pointer;
  right: 2%;
  width: 3%;
}
/* input[type="range"]::-moz-range-track {
  padding: 0 10px;
  background: repeating-linear-gradient(to right, #ccc, #ccc 10%, #000 10%, #000 11%, #ccc 11%, #ccc 20%);
} */

/* Control Buttons */
.footer-btn {
  display: flex;
  height: 35px;
}
.button {
  width: 158px;
  padding: 2%;
  line-height: 100%;
  margin: auto;
  font-weight: 600;
  margin-right: 20px;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: rgb(80, 84, 105);
  border: solid 2px white;
  border-radius: 7px;
  transition: transform 0.1s linear;
}
.button:active {
  background-color: rgb(47, 112, 177);
  transform: translateY(1px);
}
.button:disabled {
  background-color: grey;
}
.button:disabled:hover {
  background-color: grey;
}
.bottom-buttons {
  position: absolute;
  top: 405px;
  left: 458px;
}
.save {
  background-color: rgb(21, 146, 230);
}
.save:hover {
  background-color: rgba(21, 146, 230, 0.8);
}
/* Color Dropdown */
.dropbtn {
  background-color: #4caf50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}
.dropdown {
  position: relative;
  grid-row: 1;
  grid-column: 4;
}
.dropdown-content {
  transition: visibility 0.2s linear, opacity 0.2s linear;
  -ms-transition: visibility 0.2s linear, opacity 0.2s linear;
  -webkit-transition: visibility 0.2s linear, opacity 0.2s linear;
  display: grid;
  visibility: hidden;
  opacity: 0;
  align-content: center;
  align-items: center;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: 33% 33% 33%;
  position: relative;
  top: -50%;
  right: 74px;
  background-color: rgb(80, 84, 105);
  width: 100px;
  height: 85px;
  border-radius: 5px;
  padding: 5px;
  transition-delay: 0.2s;
}
.dot {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-block;
  border: solid 1px white;
}
.dropdown:hover .dropdown-content {
  opacity: 1;
  visibility: visible;
}

.valid-field {
  border: solid 1.2px green;
}
.invalid-field {
  border: solid 1.2px red;
}
.no-border {
  border: none !important;
}
/* Loading Transition */
.load-enter,
.load-leave-to {
  opacity: 0;
}
.load-enter-active {
  transition: all 0.1s ease;
}
.load-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
</style>
