<i18n>
{
  "en": {
    "title": "Select a pre-configured probe",
    "manufacturer": "Manufacturer",
    "infoManufacturer":{
      "message": "It is the probe's manufacturer."
    },
    "modelNumber": "Model number",
    "infoModelNumber":{
      "message": "It is the Serial Number corresponding to the model of the probe to be integrated into the system. Once selected, the conversion and configuration parameters will be automatically set."
    }
  },
  "it": {
    "title": "Seleziona una sonda pre-configurata",
    "manufacturer": "Produttore",
    "infoManufacturer":{
      "message": "E' azienda produttrice della sonda da integrare nel sistema."
    },
    "modelNumber": "Numero di modello",
    "infoModelNumber":{
      "message": "E' il codice corrispondente al modello di sonda da integrare nel sistema. Una volta selezionato i parametri di conversione e configurazione saranno automaticamente impostati."
    }
  }
}
</i18n>
<template>
  <div>
    <div class="d-flex align-items-center mb4">
      <move-text p b class="mr16"> {{ $t("title") }} :</move-text>
    </div>

    <div class="d-flex align-items-center">
      <div class="pr8">
        <div class="d-flex align-items-center mb8">
          <move-text caption class="mr8">
            {{ $t("manufacturer") }}:
          </move-text>
          <move-info rigth>
            <template #icon>
              <font-awesome-icon icon="fa-solid fa-circle-info" />
            </template>
            <div class="w400 p16">
              <move-text p> {{ $t("infoManufacturer.message") }}</move-text>
            </div>
          </move-info>
        </div>
        <move-select
          :updateScroll="getIsUpdateModalScroll"
          reduceHeight
          class="w300"
          :options="getUniqueManufacturers"
          v-model="vManufacturer"
          :valid="getValidation.manufacturer"
        ></move-select>
      </div>
      <div>
        <div class="d-flex align-items-center mb8">
          <move-text caption class="mr8"> {{ $t("modelNumber") }}:</move-text>
          <move-info rigth>
            <template #icon>
              <font-awesome-icon icon="fa-solid fa-circle-info" />
            </template>
            <div class="w400 p16">
              <move-text p> {{ $t("infoModelNumber.message") }}</move-text>
            </div>
          </move-info>
        </div>
        <move-select
          :updateScroll="getIsUpdateModalScroll"
          reduceHeight
          class="w300"
          :options="getUniqueModelNumbersForvManufacturer"
          v-model="vModelNumber"
          :valid="getValidation.modelNumber"
        ></move-select>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";
export default {
  name: "scn-mapped-data",
  /* --------------------------------------------------------------- */
  /* VUEX GETTERS */
  /* --------------------------------------------------------------- */
  computed: {
    ...mapGetters({
      getTempSensor: "settings/getTempSensor",
      getTempSensorUserConfig: "settings/getTempSensorUserConfig",
      getTempSensorChannel0: "settings/getTempSensorChannel0",
      getSensorType: "settings/getSensorType",
      getMode: "settings/getMode",
      getManifetList: "settings/getManifetList",
      getSelectedManifest: "settings/getSelectedManifest",
      getManufacturer: "settings/getManufacturer",
      getModelNumber: "settings/getModelNumber",
      getIsUpdateModalScroll: "settings/getIsUpdateModalScroll"
    }),
    getValidation() {
      return {
        manufacturer: Boolean(this.vManufacturer),
        modelNumber: Boolean(this.vModelNumber)
      };
    },
    getEntireValidation() {
      return Boolean(this.getSelectedManifestId);
    },
    getSelectedManifestId() {
      return this.getSelectedManifest && this.getSelectedManifest._id;
    },
    //ritorna un elenco univoco di manufacturer estratto da una lista di manifest
    getUniqueManufacturers() {
      return [
        ...new Set(this.getManifetList.map(manifest => manifest.manufacturer))
      ];
    },
    //ritorna un elenco di modelNumber per manufacturer selezionato
    getUniqueModelNumbersForvManufacturer() {
      if (!this.vManufacturer) return [];
      return this.getManifetList
        .filter(manifest => manifest.manufacturer === this.vManufacturer)
        .map(manifest => manifest.modelNumber);
    },
    vManufacturer: {
      get() {
        return this.getManufacturer;
      },
      set(manufacturer = null) {
        this.vModelNumber = null;
        this.updateRscConfig({ manufacturer });
      }
    },
    vModelNumber: {
      get() {
        return this.getModelNumber;
      },
      set(modelNumber = null) {
        this.updateRscConfig({ modelNumber });
      }
    }
  },
  methods: {
    /* --------------------------------------------------------------- */
    /* VUEX ACTIONS */
    /* --------------------------------------------------------------- */
    ...mapActions({
      setTempSensor: "settings/setTempSensor",
      updateValidationRawMode: "settings/updateValidationRawMode",
      updateRscConfig: "settings/updateRscConfig",
      updateValidationTempSensor: "settings/updateValidationTempSensor",
      setLoading: "settings/setLoading",
      fetchAvailableMappedConfigurations:
        "settings/fetchAvailableMappedConfigurations",
      deleteRscConfigFields: "settings/deleteRscConfigFields"
    }),
    updateValidation(valid) {
      this.updateValidationTempSensor({
        field: "mappedData",
        valid
      });
    },
    updateExternalNtc(
      fields = {},
      options = {
        clean: false
      }
    ) {
      const sensor = this.getTempSensor;
      let externalNtc = sensor.userConfig.channels[0].externalNtc || {};
      externalNtc = options.clean
        ? fields
        : {
            ...externalNtc,
            ...fields
          };
      sensor.userConfig.channels[0].externalNtc = externalNtc;
      this.setTempSensor(sensor);
    }
  },
  watch: {
    getEntireValidation(valid) {
      this.updateValidation(valid);
    },
    getSelectedManifestId(id) {
      if (!id) {
        return;
      }
      const manifest = _.cloneDeep(this.getSelectedManifest);
      delete manifest._id;
      delete manifest.manifestType;
      delete manifest.rscType;
      this.updateRscConfig(manifest);
    }
  },
  async mounted() {
    const externalNtc = this.getTempSensorChannel0.externalNtc;
    this.setLoading({ fetchAvailableMappedConfigurations: true });
    await this.fetchAvailableMappedConfigurations();
    this.setLoading({ fetchAvailableMappedConfigurations: false });
    if (this.getSelectedManifestId) {
      const manifest = _.cloneDeep(this.getSelectedManifest);
      delete manifest._id;
      delete manifest.manifestType;
      delete manifest.rscType;
      this.updateRscConfig(manifest);
      if (
        this.getTempSensorChannel0.nativeType === "vibrating-wire" &&
        externalNtc
      ) {
        this.updateExternalNtc({
          compensationEnabled: externalNtc.compensationEnabled
        });
      }
    }
    this.updateValidation(this.getEntireValidation)
  }
};
</script>

<style scoped></style>
