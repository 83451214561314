function checkIfOnline(gw) {
  if ((gw.lastData === null || gw.lastData === undefined) && (gw.lastPong === null || gw.lastPong === undefined)) return false;
  const maxOffset = 12; // Massimo delta ammissibile in minuti
  const diffData = Math.abs(new Date() - new Date(gw.lastData)); // Differenza in ms. dall'ultima trasmissione
  const diffPong = Math.abs(new Date() - new Date(gw.lastPong)); // Differenza in ms. dall'ultimo pong
  return (Math.floor(diffData / 1000 / 60) <= maxOffset) || (Math.floor(diffPong / 1000 / 60) <= maxOffset);
}
module.exports = {
  checkIfOnline,
};
