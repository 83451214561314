<template>
  <modal-template :contentOnly="true" :minHeight="400 + 'px'">
    <template v-slot:content>
      <ImgChange @close="closeMe" @logout="logout" />
    </template>
  </modal-template>
</template>
<script>
import modalTemplate from "../modules_V2/ModalTemplate.vue";
import ImgChange from "./ImgSet.vue";

export default {
  name: "ModalImgSet",
  components: {
    modalTemplate,
    ImgChange,
  },
  methods: {
    closeMe() {
      this.$emit("close");
    },
    logout() {
      this.$emit("logout");
    },
  },
  mounted() {},
};
</script>
<style scoped></style>
