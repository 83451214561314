<template>
  <modal-template :contentOnly="true" :minHeight="400 + 'px'">
    <template v-slot:content>
      <PswChange v-if="true" @close="closeMe" @logout="logout" @skip="skip" @change="change" />
    </template>
  </modal-template>
</template>
<script>
import modalTemplate from "../modules_V2/ModalTemplate.vue";
import PswChange from "./PswChange.vue";
export default {
  name: "ModalImgUpload",
  components: {
    modalTemplate,
    PswChange,
  },
  methods: {
    closeMe() {
      this.$emit("close");
    },
    logout() {
      this.$emit("logout");
    },
    skip() {
      this.$emit("skip");
    },
    change() {
      this.$emit("change");
    },
  },
  mounted() {},
};
</script>
<style scoped></style>
