<i18n>
{
  "en": {
    "analogNode": "Analog Node",
    "manage": "Manage",
    "manageExp": "Manage the operating settings of the Analog Node",
    "acquisitionTime": "Acquisition time",
    "warmupTime": "Warmup time",
    "sensor": "Sensor",
    "sensorConfig": "Configure the device connected to the Analog Node",
    "freqSampleMin": "Sampling frequency"

  },
  "it": {
    "analogNode": "Nodo Analogico",
    "manage": "Gestisci",
    "manageExp": "Gestisci le impostazioni di funzionamento del Nodo Analogico",
    "acquisitionTime": "Tempo di acquisizione",
    "warmupTime": "Tempo di warmup",
    "sensor": "Sensore",
    "sensorConfig": "Configura il dispositivo collegato al Nodo Analogico",
    "freqSampleMin": "Freq. campionamento"
  }
}
</i18n>
<template>
  <SettingsContentTemplate :readOnly="readOnly">
    <template v-slot:tabs>
      <a @click="curTab = 'manage'" :class="{ active: curTab === 'manage' }" v-if="availableTabs.includes('manage')">{{ $t("message.manage") }} </a>
      <a @click="curTab = 'cadence'" :class="{ active: curTab === 'cadence' }" v-if="availableTabs.includes('cadence')">{{ $t("message.cadence") }}</a>
      <a @click="curTab = 'threshold'" :class="{ active: curTab === 'threshold' }" v-if="availableTabs.includes('threshold')">{{ $t("message.alert") }} </a>
      <a @click="curTab = 'resolution'" :class="{ active: curTab === 'resolution' }" v-if="availableTabs.includes('resolution')">{{ $t("message.resolution") }} </a>
      <a @click="curTab = 'activationThreshold'" :class="{ active: curTab === 'activationThreshold' }" v-if="availableTabs.includes('activationThreshold')">{{ $t("message.activationThreshold") }} </a>
      <a @click="curTab = 'acq-type'" :class="{ active: curTab === 'acq-type' }" v-if="availableTabs.includes('acq-type')">{{ $t("message.acqType") }}</a>
      <a @click="curTab = 'fsample'" :class="{ active: curTab === 'fsample' }" v-if="availableTabs.includes('fsample')">{{ $t("freqSampleMin") }}</a>
    </template>
    <template v-slot:content>
      <!-- Gestione -->
      <div v-if="curTab === 'manage'">
        <div class="sr-heading">
          <p>{{ $t("message.manageDevices") }}:</p>
        </div>
        <div id="sr-content">
          <div class="sensor-manage-box" v-for="sensor in tempSensors" :key="sensor.eui">
            <div class="side-color" :style="{ backgroundColor: sensor.userConfig.color }"></div>
            <h4 class="sensor-manage-eui">
              {{ sensor.type + " - " + sensor.eui.substr(sensor.eui.length - 5) }}
            </h4>
            <input
              type="text"
              :class="[validateSensorName(sensor.userConfig.name) ? 'valid' : 'invalid', 'sensor-manage-name input-text']"
              :ref="sensor.eui"
              spellcheck="false"
              maxlength="16"
              minlength="1"
              v-model="sensor.userConfig.name"
              :disabled="!sensor.userConfig.editing"
            />
            <img class="sensor-manage-edit icon" :src="editIcon" @click="editUserConfig(sensor)" />
            <img class="sensor-manage-changecolor icon" :src="changeColorIcon" />
            <div class="dropdown">
              <div class="dropdown-content">
                <div v-for="(color, key, index) in colors" :key="index" class="dot" :style="{ backgroundColor: color }" @click="sensor.userConfig.color = color"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Cadenza di campionamento -->
      <div v-if="curTab === 'cadence'">
        <div class="sr-heading">
          <p>{{ $t("message.setCadence") }}:</p>
        </div>
        <div id="sr-content">
          <div class="sensor-box" v-for="sensor in tempSensors" :key="sensor.eui">
            <div class="side-color" :style="{ backgroundColor: sensor.userConfig.color }"></div>
            <h4 class="sensor-manage-eui">
              {{ sensor.type + " " + sensor.eui.substr(sensor.eui.length - 5) + " - " + sensor.userConfig.name }}
            </h4>
            <p class="sensor-caption">{{ $t("message.cadence") }}:</p>
            <div class="options-wrap">
              <div class="checkbox-container" v-for="sr in getCadences(sensor)" :key="sr.value">
                <label class="container">
                  <input v-if="sensor.rev === '2.0'" type="radio" :name="'radio_' + sensor.eui" :value="sr.value" v-model.number="sensor.userConfig.sampleRate" />
                  <input v-else-if="sensor.rev === '3.0' || sensor.rev === '4.0'" type="radio" :name="'radio_' + sensor.eui" :value="sr.value" v-model.number="sensor.userConfig.timeSwitch" /> <span class="checkmark"></span>
                </label>
                <label class="value">{{ sr.label }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Soglia di Allarme -->
      <div v-if="curTab === 'threshold'">
        <div class="sr-heading">
          <p>{{ $t("message.alertDevices") }}:</p>
        </div>
        <div id="sr-content">
          <div class="sensor-manage-box" v-for="sensor in tempSensors" :key="sensor.eui">
            <div class="side-color" :style="{ backgroundColor: sensor.userConfig.color }"></div>
            <h4 class="sensor-manage-eui">
              {{ sensor.type + " - " + sensor.eui.substr(sensor.eui.length - 5) }}
            </h4>
            <div class="threshold-field">
              <h2>{{ "[" + (mesaurementUnit || " - ") + "]" }}</h2>
              <input
                type="number"
                min="0"
                step="any"
                :ref="sensor.eui"
                :class="[validateThreshold(sensor, sensor.userConfig.threshold) ? 'valid' : 'invalid', 'sensor-manage-threshold input-text']"
                spellcheck="false"
                maxlength="10e"
                v-model.number="sensor.userConfig.threshold"
                :disabled="!sensor.userConfig.editing"
                @keydown.enter="editUserConfig(sensor)"
              />
            </div>
            <img class="sensor-manage-edit icon" :src="editIcon" @click="editUserConfig(sensor)" />
          </div>
        </div>
      </div>
      <!-- Risoluzione -->
      <div v-if="curTab === 'resolution'">
        <div class="sr-heading">
          <p>{{ $t("message.setResolution") }}:</p>
        </div>
        <div id="sr-content">
          <div class="sensor-box" v-for="sensor in tempSensors" :key="sensor.eui">
            <div class="side-color" :style="{ backgroundColor: sensor.userConfig.color }"></div>
            <h4 class="sensor-manage-eui">
              {{ sensor.type + " " + sensor.eui.substr(sensor.eui.length - 5) + " (" + sensor.rev + ")" + " - " + sensor.userConfig.name }}
            </h4>
            <p class="sensor-caption">Resolution [{{ mesaurementUnit }}] / Full Scale ({{ mesaurementUnit }}-pp):</p>
            <div class="options-wrap" v-if="sensor.rev != '1.0'">
              <div class="checkbox-container" v-for="sr in getResolutions(sensor)" :key="sr.value">
                <label class="container">
                  <input type="radio" :name="'radio_' + sensor.eui" :value="sr.value" v-model.number="sensor.userConfig.resolution" />
                  <span class="checkmark"></span>
                </label>
                <label class="value">{{ sr.label }}</label>
              </div>
            </div>
            <div class="options-wrap" v-else>
              <p><i>Funzione non supportata da questa versione</i></p>
            </div>
          </div>
        </div>
      </div>
      <!-- Soglia attivazione -->
      <div v-if="curTab === 'activationThreshold'">
        <div class="sr-heading">
          <p>{{ $t("message.setActivationThreshold") }}:</p>
        </div>
        <div id="sr-content">
          <div class="sensor-manage-box" v-for="sensor in tempSensors" :key="sensor.eui">
            <div class="side-color" :style="{ backgroundColor: sensor.userConfig.color }"></div>
            <h4 class="sensor-manage-eui">
              {{ sensor.type + " - " + sensor.eui.substr(sensor.eui.length - 5) }}
            </h4>
            <div class="threshold-field">
              <h2>{{ "[" + (mesaurementUnit || " - ") + "]" }}</h2>
              <input
                type="number"
                min="0"
                step="any"
                :ref="sensor.eui"
                class="sensor-manage-threshold"
                :class="[validateActivationTh(sensor, sensor.userConfig.activationThreshold) ? 'valid-field' : 'invalid-field']"
                spellcheck="false"
                maxlength="10e"
                v-model.number="sensor.userConfig.activationThreshold"
                :disabled="!sensor.userConfig.editing"
                @keydown.enter="editUserConfig(sensor)"
              />
            </div>
            <img class="sensor-manage-edit icon" :src="editIcon" @click="editUserConfig(sensor)" />
          </div>
        </div>
      </div>
      <!-- Freq. sample -->
      <div v-if="curTab === 'fsample'">
        <div class="sr-heading">
          <p>{{ $t("message.setSampleRate") }}:</p>
        </div>
        <div id="sr-content">
          <div class="sensor-box" v-for="sensor in tempSensors.filter((sn) => getFSamples(sn).length > 0)" :key="sensor.eui">
            <div class="side-color" :style="{ backgroundColor: sensor.userConfig.color }"></div>
            <h4 class="sensor-manage-eui">
              {{ sensor.type + " " + sensor.eui.substr(sensor.eui.length - 5) + " - " + sensor.userConfig.name }}
            </h4>
            <p class="sensor-caption">{{ $t("freqSampleMin") }}:</p>
            <div class="options-wrap">
              <div class="checkbox-container" v-for="sr in getFSamples(sensor)" :key="sr.value">
                <label class="container">
                  <input type="radio" :name="'radio_' + sensor.eui" :value="sr.value" v-model.number="sensor.userConfig.fSample" />
                  <span class="checkmark"></span>
                </label>
                <label class="value">{{ sr.label }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Type Acqu -->
      <div v-if="curTab === 'acq-type'">
        <div class="sr-heading">
          <p>Set acquisition type:</p>
        </div>
        <div id="sr-content">
          <div class="sensor-box" v-for="sensor in tempSensors.filter((sn) => getTypeAcqu(sn).length > 0)" :key="sensor.eui">
            <div class="side-color" :style="{ backgroundColor: sensor.userConfig.color }"></div>
            <h4 class="sensor-manage-eui">
              {{ sensor.type + " " + sensor.eui.substr(sensor.eui.length - 5) + " - " + sensor.userConfig.name }}
            </h4>
            <p class="sensor-caption">{{ $t("message.acqType") }}:</p>
            <div class="options-wrap">
              <div class="checkbox-container" v-for="sr in getTypeAcqu(sensor)" :key="sr.value">
                <label class="container">
                  <input type="radio" :name="'radio_' + sensor.eui" :value="sr.value" v-model.number="sensor.userConfig.typeAcqu" />
                  <span class="checkmark"></span>
                </label>
                <label class="value">{{ sr.label }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </SettingsContentTemplate>
</template>
<script>
import SettingsContentTemplate from "./SettingsContentTemplate.vue";
import { validateEntireSensor, validateSensorName, validateThreshold, validateActivationTh } from "../../../helpers/validate";
export default {
  name: "accelerometer-settings",
  components: {
    SettingsContentTemplate,
  },
  props: {
    sensors: Array,
    readOnly: Boolean
  },
  data() {
    return {
      editIcon: require("../../../../public/assets/icons/edit.svg"),
      changeColorIcon: require("../../../../public/assets/icons/changecolor.svg"),
      mesaurementUnit: "mg",
      tempSensors: null,
      curTab: "",
      colors: ["white", "silver", "gray", "black", "red", "yellow", "green", "aqua", "blue", "navy", "fuchsia", "purple"],
    };
  },
  methods: {
    validateSensorName: validateSensorName,
    validateThreshold: validateThreshold,
    validateActivationTh: validateActivationTh,
    // Emette un evento con lo stato di validazione di tutti i sensori
    updateValidation() {
      this.$emit("validation", !this.tempSensors.some((sensor) => !validateEntireSensor(sensor)));
    },
    // Se non ci sono item invalidi, abilita/disabilita editing
    editUserConfig(sensor) {
      let someInvalid = this.tempSensors.some((sensor) => !validateEntireSensor(sensor));
      if (!someInvalid) {
        if (sensor.userConfig.editing) {
          sensor.userConfig.editing = false;
        } else {
          this.tempSensors.forEach((sensor) => {
            sensor.userConfig.editing = false;
          });
          sensor.userConfig.editing = true;
          setTimeout(() => {
            this.$refs[sensor.eui][0].focus();
          }, 0);
        }
      }
    },
    // Restituisce le varie cadenze possibili in basse alla revision
    getCadences(sensor) {
      if (sensor.rev === "2.0") {
        return [
          { value: 1, label: "1 hr." },
          { value: 2, label: "2 hr." },
          { value: 6, label: "6 hr." },
          { value: 12, label: "12 hr." },
          { value: 24, label: "24 hr." },
        ];
      }
      if (sensor.rev === "3.0" || sensor.rev === "4.0") {
        return [
          { value: 1, label: "1 hr." },
          { value: 2, label: "2 hr." },
          { value: 6, label: "6 hr." },
          { value: 12, label: "12 hr." },
          { value: 24, label: "24 hr." },
        ];
      }
      return [];
    },
    // Restituisce le varie risoluzioni possibili in basse alla revision
    getResolutions(sensor) {
      if (sensor.rev === "2.0") {
        return [
          { value: 0.25, label: "0.25/64" },
          { value: 0.5, label: "0.5/128" },
          { value: 1, label: "1/256" },
        ];
      }
      if (sensor.rev === "3.0" || sensor.rev === "4.0") {
        return [
          { value: 0.03125, label: "0.03125/1024" },
          { value: 0.0625, label: "0.0625/2048" },
          { value: 0.125, label: "0.125/4096" },
        ];
      }
      return [];
    },
    // Restituisce le varie freq. di campionamento possibili in basse alla revision
    getFSamples(sensor) {
      if (sensor.rev === "3.0" || sensor.rev === "4.0") {
        return [
          { value: 40, label: "40 Hz" },
          { value: 80, label: "80 Hz" },
          { value: 160, label: "160 Hz" },
          { value: 320, label: "320 Hz" },
          { value: 640, label: "640 Hz" },
        ];
      }
      return [];
    },
    // Restituisce i vari tipi di acquisizione possibili in basse alla revision
    getTypeAcqu(sensor) {
      if (sensor.rev === "4.0") {
        return [
          { value: 0, label: "Trigger std" },
          { value: 1, label: "Trigger_pk_pk" },
          { value: 2, label: "Sync" },
          { value: 3, label: "Sync + trigger_std" },
        ];
      } else if (sensor.rev === "3.0"){
        return [
          { value: 0, label: "Trigger std" },
          { value: 2, label: "Sync" },
          { value: 3, label: "Sync + trigger_std" },
        ];
      } 
      else return [];
    },
  },
  mounted() {
    // All'avvio seleziono la prima tab disponibile
    this.curTab = "manage";
    // Deep copy del sensore
    const sensorsFromParent = JSON.parse(JSON.stringify(this.sensors));
    // Aggiungo il campo 'editing'
    this.tempSensors = (sensorsFromParent || []).map((sensor) => ({
      eui: sensor.eui,
      type: sensor.type,
      rev: sensor.rev,
      userConfig: {
        ...sensor.userConfig,
        editing: false,
      },
    }));
  },
  computed: {
    // In base alle revision presenti inietto delle eventuali opzioni addizionali da mostrare
    availableTabs() {
      let tabs = ["manage", "threshold", "resolution", "activationThreshold", "cadence"];
      if (this.tempSensors && this.tempSensors.some((sn) => sn.rev >= "3.0")) {
        tabs.push("acq-type");
        tabs.push("fsample");
      }
      return tabs;
    },
  },
  watch: {
    /* curTab: function() {
      // Quando cambio tab disattivo l'editing dei sensori
      this.tempSensors.map((a) => (a.userConfig.editing = false));
    }, */
    tempSensors: {
      handler: function(newSensors, oldSensors) {
        if (newSensors && oldSensors) {
          this.updateValidation();
          // Se non ci sono sensori in stato 'NON VALIDO' emetto un evento con il nuovo array di sensori
          let someInvalid = this.tempSensors.some((sensor) => !validateEntireSensor(sensor));
          if (!someInvalid) {
            // Elimino il campo 'editing' creato in fase di mount
            const sensorsToEmit = JSON.parse(JSON.stringify(newSensors));
            sensorsToEmit.map((sensor) => {
              delete sensor.userConfig.editing;
              return sensor;
            });
            this.$emit("update", sensorsToEmit);
          }
        }
      },
      deep: true,
    },
  },
  /* beforeDestroy() {
    // Prima del del destroy disattivo editing dei sensori
    this.tempSensors.map((a) => (a.userConfig.editing = false));
  }, */
};
</script>
