<i18n>
{
  "en": {
    "title": "Probe name"
  },
  "it": {
    "title": "Nome della sonda"
  }
}
</i18n>
<template>
  <div>
    <move-text p b class="mb4">{{ $t("title") }}:</move-text>
    <move-input
      tertiary
      v-model="vProbeName"
      :valid="getValidationName"
      class="w300"
    ></move-input>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "scn-probe-name",
  computed: {
    ...mapGetters({
      getTempSensor: "settings/getTempSensor",
      getProbeName: "settings/getProbeName"
    }),
    getValidationName() {
      return this.vProbeName !== null;
    },
    vProbeName: {
      get() {
        return this.getProbeName;
      },
      set: function(value) {
        this.updateRscConfig({name: value});
      }
    }
  },
  methods: {
    ...mapActions({
      setTempSensor: "settings/setTempSensor",
      updateValidationTempSensor: "settings/updateValidationTempSensor",
      updateRscConfig: "settings/updateRscConfig",
    })
  },
  watch:{
    getValidationName(valid){
      this.updateValidationTempSensor({field: 'probeName', valid})
    }
  }
};
</script>

<style scoped></style>
