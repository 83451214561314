<i18n>
{
  "en": {
    "title": "Terms and Conditions",
    "subtitle": "Last updated on May 2022",
    "mainTextContent": "These terms and conditions of use apply to the Move Solutions Cloud Platform and have the purpose of regulating access and use. Users who use the services offered by Move Solutions declare that they have read, understood and accepted these general terms and conditions of use. If you do not agree with any of these terms, you are prohibited from using or accessing Move Cloud Platform.",
    "preLinkText": "Read full",
    "linkEulaTxt": "Terms & Conditions",
    "labelAgreeFirstCheckbox": "By checking this box I declare to have read and fully understand all Terms and Conditions and agreeing to all the conditions expressed.",
    "labelAgreeSecondCheckbox": "I have read and agree to the Move Solutions Cloud Terms and Conditions",
    "labelAgreeCheckboxHint": "These fields are required",
    "btnAcceptEula": "Accept",
    "btnDeclineEula": "Decline",
    "btnComeBack": "Come back"
  },
  "it": {
    "title": "Termini e Condizioni",
    "subtitle": "Ultimo aggiornamento Maggio 2022",
    "mainTextContent": "Questi termini e condizioni d'uso si applicano alla piattaforma Move Solutions Cloud e hanno lo scopo di regolamentare l'accesso e l'utilizzo. Gli utenti che utilizzano i servizi offerti da Move Solutions dichiarano di aver letto, compreso e accettato le presenti condizioni generali di utilizzo. Se non sei d'accordo con alcuni di questi termini, non potrai utilizzare o accedere a Move Cloud Platform.",
    "preLinkText": "Leggi il documento",
    "linkEulaTxt": "Termini & Condizioni",
    "labelAgreeFirstCheckbox":"Spuntando questa casella dichiaro di aver letto e comprendere appieno tutti i Termini e Condizioni e di accettare tutte le condizioni espresse.",
    "labelAgreeSecondCheckbox": "Ho letto e accetto i Termini e condizioni di Move Solutions Cloud",
    "labelAgreeCheckboxHint": "Campi obbligatori",
    "btnAcceptEula": "Accetta",
    "btnDeclineEula": "Rifiuta",
    "btnComeBack": "Torna indietro"
  }
}
</i18n>

<template>
  <modalTemplate contentOnly>
    <template v-slot:content>
      <div class="modal-title">
        <div class="title-wrapper">
          <div class="flex-item icon">
            <img color="white" src="../../../public/assets/icons/document.svg" />
          </div>
          <div class="flex-item">
            <move-text h2 class="text-align-left">{{ $t("title") }}</move-text>
            <move-text color="var(--text-secondary-color)" paragraph class="text-align-left">{{ $t("subtitle") }}</move-text>
          </div>
        </div>
        <div id="language-block">
          <img src="../../../public/assets/icons/ita.svg" @click="setLanguage('it')" />
          <img src="../../../public/assets/icons/eng.svg" @click="setLanguage('en')" />
        </div>
      </div>
      <div class="modal-content">
        <!-- main text content -->
        <move-text paragraph class="text-align-left">
          {{ $t("mainTextContent") }}
          <br />
          <br />
          <!-- link text -->
          {{ $t("preLinkText") }}  
          <move-text-link error="true" errorLabel="ciao" :href="eulaLink" underlineColor="var(--primary-color)"
            >{{ $t("linkEulaTxt") }}
          </move-text-link>
          <br />
          <br />
          <move-checkbox class="checkbox" @change="onChangFirsteAgreeCheckBox" :value="agreeFirstCheckBoxCheckd"><move-text paragraph>{{ $t("labelAgreeFirstCheckbox") }}</move-text></move-checkbox>
          <move-checkbox class="checkbox" @change="onChangeSecondAgreeCheckBox" :value="agreeSecondCheckBoxCheckd"><move-text paragraph>{{ $t("labelAgreeSecondCheckbox") }}</move-text></move-checkbox>
          <move-text color="var(--error-primary-color)" v-if="!agreeAllCheckbox" paragraph>{{  $t("labelAgreeCheckboxHint")}}</move-text>
          <br v-else/>
        </move-text>
      </div>
      <div class="modal-footer text-align-right">
        <move-btn secondary class="btn-item-footer" outlined rounded @click="onClickDecline" >{{ $t("btnDeclineEula").toUpperCase() }}</move-btn>
        <move-btn primary class="btn-item-footer" outlined  rounded @click="onClickAccept" :disabled="!agreeAllCheckbox">{{ $t("btnAcceptEula").toUpperCase() }}</move-btn>
      </div>
    </template>
  </modalTemplate>
</template>

<script>
import modalTemplate from "../modules_V2/ModalTemplate.vue";
import { EULA_LINK } from "../../config";
export default {
  name: "ModalEulaAcceptView",
  components: {
    modalTemplate,
  },
  data() {
    return {
      eulaLink: EULA_LINK,
      agreeFirstCheckBoxCheckd: false,
      agreeSecondCheckBoxCheckd: false,
    };
  },
  computed:{
    agreeAllCheckbox(){
      return this.agreeFirstCheckBoxCheckd && this.agreeSecondCheckBoxCheckd
    }
  },
  methods: {
    onClickAccept() {
      if (this.agreeAllCheckbox) {
        this.$emit("acceptEula");
        return;
      }
    },
    onClickDecline() {
      this.$emit("declineEula");
    },
    onChangFirsteAgreeCheckBox(evt){
      this.agreeFirstCheckBoxCheckd = evt.target.checked
    },
    onChangeSecondAgreeCheckBox(evt){
      this.agreeSecondCheckBoxCheckd = evt.target.checked
    },
    setLanguage(lang) {
      this.$root.$i18n.locale = lang;
    },
  },
};
</script>

<style scoped>
.modal-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid var(--secondary-color-light-3);
  margin: 24px 24px 0px 24px;
  padding-bottom: 8px;

}
.title-wrapper{
  display: flex;
  flex-direction: row;
}
.icon {
  padding-right: 4px;
}
.modal-content {
  max-width: 600px;
  min-height: max-content;
  padding: 8px 24px 24px 24px;
}
.text-align-left {
  text-align: left;
}
.text-align-right {
  text-align: right;
}
.btn-item-footer {
  margin-left: 8px;
}
.modal-footer {
  padding: 24px;
}

.checkbox{
  margin-top: 8px;
}

#language-block > img {
  height: 25px;
  padding: 5px;
  cursor: pointer;
}
</style>
