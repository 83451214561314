<template>
  <SettingsContentTemplate>
    <template v-slot:tabs>
      <a @click="curTab = 'struct-info'" :class="{ active: curTab === 'struct-info' }">{{ $t("message.general") }} </a>
      <a @click="curTab = 'spans'" :class="{ active: curTab === 'spans' }">{{ $t("message.spans") }} </a>
    </template>
    <template v-slot:content>
      <!-- Info struttura -->
      <div v-if="curTab === 'struct-info' && tempStructure && tempSensors">
        <div class="sr-heading">
          <p>{{ $t("message.setStructInfos") }}:</p>
        </div>
        <div id="sr-content" class="grid-content">
          <h4>{{ $t("message.name") }}:</h4>
          <input
            type="text"
            :class="[validateStructName(tempStructure.name) ? 'valid' : 'invalid', 'sensor-manage-threshold input-text']"
            spellcheck="false"
            maxlength="24e"
            v-model.trim="tempStructure.name"
          />
          <h4>{{ $t("message.coordinates") + " [lat/lng]: " }}</h4>
          <div class="input-group">
            <input
              type="number"
              :class="[validateCoordinate(tempStructure.coordinates.lat) ? 'valid' : 'invalid', 'sensor-manage-threshold input-text']"
              min="0"
              step="any"
              spellcheck="false"
              maxlength="10e"
              v-model.number="tempStructure.coordinates.lat"
            />
            <input
              type="number"
              :class="[validateCoordinate(tempStructure.coordinates.lng) ? 'valid' : 'invalid', 'sensor-manage-threshold input-text']"
              min="0"
              step="any"
              spellcheck="false"
              maxlength="10e"
              v-model.number="tempStructure.coordinates.lng"
            />
          </div>
        </div>
      </div>
      <div v-if="curTab === 'spans'">
        <div class="sr-heading">
          <p>{{ $t("message.setSpans") }}:</p>
        </div>
        <div id="sr-content">
          <div class="span-box" v-for="(span, index) in tempStructure.userConfig.spansDetails" :key="'span_' + index + 1">
            <h4 class="sensor-manage-eui span-name">
              {{ index + 1 }}
            </h4>
            <div class="threshold-field span-name-field">
              <h2>{{ $t("message.name") }}</h2>
              <input
                type="text"
                min="0"
                step="any"
                :ref="'span_' + index"
                :class="[validateSpanName(span.name) ? 'valid' : 'invalid', 'sensor-manage-threshold input-text']"
                spellcheck="false"
                maxlength="23e"
                v-model.trim="span.name"
                :disabled="editingIndex !== index"
                @keydown.enter="editSpanConfig(index)"
              />
            </div>
            <div class="threshold-field span-lenght-field">
              <h2>{{ "[m]" }}</h2>
              <input
                type="number"
                min="0"
                step="any"
                :class="[validateSpanLength(span.length) ? 'valid' : 'invalid', 'sensor-manage-threshold input-text']"
                spellcheck="false"
                maxlength="10e"
                v-model.number="span.length"
                :disabled="editingIndex !== index"
                @keydown.enter="editSpanConfig(index)"
              />
            </div>
            <img v-if="editingIndex === index" class="mail-edit-icon icon" src="../../../../public/assets/icons/accept.svg" @click="editSpanConfig(index)" />
            <img v-else class="mail-edit-icon icon" src="../../../../public/assets/icons/edit.svg" @click="editSpanConfig(index)" />
            <img v-if="editingIndex === index" class="mail-delete-icon icon" src="../../../../public/assets/icons/trash.svg" @click="deleteSpan(span, index)" />
          </div>
          <div class="add-box">
            <h5 @click="insertNewSpan">
              {{ $t("message.addSpan") }}
            </h5>
          </div>
        </div>
      </div>
    </template>
  </SettingsContentTemplate>
</template>
<script>
import SettingsContentTemplate from "./SettingsContentTemplate.vue";
import { validateEntireStructure, validateStructName, validateCoordinate, validateNameNoSpaceBeginning, validateSpanName, validateSpanLength } from "../../../helpers/validate";
export default {
  name: "structure-settings",
  components: {
    SettingsContentTemplate,
  },
  props: {
    structure: Object,
    sensors: Array,
  },
  data() {
    return {
      curTab: "struct-info",
      tempStructure: null,
      tempSensors: null,
      editingIndex: null,
    };
  },
  methods: {
    validateCoordinate: validateCoordinate,
    validateStructName: validateStructName,
    validateNameNoSpaceBeginning: validateNameNoSpaceBeginning,
    validateSpanName: validateSpanName,
    validateSpanLength: validateSpanLength,
    // Emette un evento con lo stato di validazione di tutta la struttura
    updateValidation() {
      this.$emit("validation", validateEntireStructure(this.tempStructure));
    },
    // Inserisce una nuova campata
    insertNewSpan() {
      let emptySpan = {
        name: (this.tempStructure.userConfig.spansDetails.length + 1).toString(),
        length: 0,
      };
      this.editingIndex = this.tempStructure.userConfig.spansDetails.length;
      this.tempStructure.userConfig.spansDetails.push(emptySpan);
    },
    // Se non ci sono item invalidi, abilita/disabilita editing delle campata
    editSpanConfig(index) {
      console.log(index, this.editingIndex);
      let someInvalid = this.tempStructure.userConfig.spansDetails.some((span) => !(validateSpanName(span.name) && validateSpanLength(span.length)));
      if (!someInvalid) {
        if (this.editingIndex === index) {
          this.editingIndex = null;
        } else {
          this.editingIndex = index;
        }
      }
    },
    // Elimina una campata ed aggiorna eventuali sensori presenti su di essa
    deleteSpan(span) {
      // Elimina span
      if (this.tempStructure.userConfig.spansDetails.length > 0) {
        const index = this.tempStructure.userConfig.spansDetails.indexOf(span);
        if (index > -1) {
          this.tempStructure.userConfig.spansDetails.splice(index, 1);
          // Verifico che le impostazioni delle campate siano coerenti
          for (let i = 0; i < this.tempSensors.length; i++) {
            if (this.tempSensors[i].userConfig.spanNumber === index + 1) {
              this.tempSensors[i].userConfig.spanNumber = null;
              this.tempSensors[i].userConfig.spanDistance = null;
            }
            if (this.tempSensors[i].userConfig.spanNumber > index + 1) {
              this.tempSensors[i].userConfig.spanNumber -= 1;
            }
          }
        }
        this.$emit("update-sensors", this.tempSensors);
      }
    },
    // Ritorna lo stato di validazione di una campata
    checkInputSpan(spanLength, spanNumber) {
      return (!isNaN(spanLength) && spanLength > 0) || spanNumber === null;
    },
  },
  mounted() {
    // Deep copy
    this.tempStructure = JSON.parse(JSON.stringify(this.structure));
    this.tempSensors = JSON.parse(JSON.stringify(this.sensors));
  },
  watch: {
    tempStructure: {
      handler: function(newStructure, oldStructure) {
        if (newStructure && oldStructure) {
          this.updateValidation();
          if (validateEntireStructure(newStructure)) {
            let structureToEmit = JSON.parse(JSON.stringify(newStructure));
            this.$emit("update", structureToEmit);
          }
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.sensor-manage-threshold {
  width: 100%;
}
</style>
