<template>
  <SettingsContentTemplate>
    <template v-slot:tabs>
      <a @click="curTab = 'manage'" :class="{ active: curTab === 'manage' }">{{ $t("message.manage") }} </a>
      <a @click="curTab = 'threshold'" :class="{ active: curTab === 'threshold' }">{{ $t("message.alert") }} </a>
      <a @click="curTab = 'compensation'" :class="{ active: curTab === 'compensation' }">{{ $t("message.compensation") }} </a>
    </template>
    <template v-slot:content>
      <!-- Gestione -->
      <div v-if="curTab === 'manage'">
        <div class="sr-heading">
          <p>{{ $t("message.manageDevices") }}:</p>
        </div>
        <div id="sr-content">
          <div class="sensor-manage-box" v-for="sensor in tempSensors" :key="sensor.eui">
            <div class="side-color" :style="{ backgroundColor: sensor.userConfig.color }"></div>
            <h4 class="sensor-manage-eui">
              {{ sensor.type + " - " + sensor.eui.substr(sensor.eui.length - 5) }}
            </h4>
            <input
              type="text"
              :class="[validateSensorName(sensor.userConfig.name) ? 'valid' : 'invalid', 'sensor-manage-name input-text']"
              :ref="sensor.eui"
              spellcheck="false"
              maxlength="16"
              minlength="1"
              v-model="sensor.userConfig.name"
              :disabled="!sensor.userConfig.editing"
            />
            <img class="sensor-manage-edit icon" :src="editIcon" @click="editUserConfig(sensor)" />
            <img class="sensor-manage-changecolor icon" :src="changeColorIcon" />
            <div class="dropdown">
              <div class="dropdown-content">
                <div v-for="(color, key, index) in colors" :key="index" class="dot" :style="{ backgroundColor: color }" @click="sensor.userConfig.color = color"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Soglia di Allarme -->
      <div v-if="curTab === 'threshold'">
        <div class="sr-heading">
          <p>{{ $t("message.alertDevices") }}:</p>
        </div>
        <div id="sr-content">
          <div class="sensor-manage-box" v-for="sensor in tempSensors" :key="sensor.eui">
            <div class="side-color" :style="{ backgroundColor: sensor.userConfig.color }"></div>
            <h4 class="sensor-manage-eui">
              {{ sensor.type + " - " + sensor.eui.substr(sensor.eui.length - 5) }}
            </h4>
            <div class="threshold-field">
              <h2>{{ "[" + (mesaurementUnit || " - ") + "]" }}</h2>
              <input
                type="number"
                min="0"
                step="any"
                :ref="sensor.eui"
                :class="[validateThreshold(sensor, sensor.userConfig.threshold) ? 'valid' : 'invalid', 'sensor-manage-threshold input-text']"
                spellcheck="false"
                maxlength="10e"
                v-model.number="sensor.userConfig.threshold"
                :disabled="!sensor.userConfig.editing"
                @keydown.enter="editUserConfig(sensor)"
              />
            </div>
            <img class="sensor-manage-edit icon" :src="editIcon" @click="editUserConfig(sensor)" />
          </div>
        </div>
      </div>
      <!-- Compensazione -->
      <div v-if="curTab === 'compensation'">
        <div class="sr-heading">
          <p>{{ $t("message.resetCompensation") }}:</p>
          <button type="button" class="button" @click="$emit('reset-offset')">
            {{ $t("message.resetAll") }}
          </button>
        </div>
        <div id="sr-content">
          <div class="sensor-box" v-for="sensor in tempSensors" :key="sensor.eui">
            <div class="side-color" :style="{ backgroundColor: sensor.userConfig.color }"></div>
            <h4 class="sensor-manage-eui">
              {{ sensor.type + " " + sensor.eui.substr(sensor.eui.length - 5) + " - " + sensor.userConfig.name }}
            </h4>
            <p class="sensor-caption">
              {{ !sensor.baseValue && !sensor.baseTilt ? $t("message.notCompensated") : $t("message.compensated") }}
            </p>
            <div class="options-wrap compensation-opt">
              <button type="button" class="button" @click="$emit('reset-offset', sensor.eui)">Reset Offset</button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </SettingsContentTemplate>
</template>
<script>
import SettingsContentTemplate from "./SettingsContentTemplate.vue";
import { validateEntireSensor, validateSensorName, validateThreshold, validateActivationTh } from "../../../helpers/validate";
export default {
  name: "crackmeter-settings",
  components: {
    SettingsContentTemplate,
  },
  props: {
    sensors: Array,
  },
  data() {
    return {
      editIcon: require("../../../../public/assets/icons/edit.svg"),
      changeColorIcon: require("../../../../public/assets/icons/changecolor.svg"),
      mesaurementUnit: "mm",
      tempSensors: null,
      curTab: "",
      colors: ["white", "silver", "gray", "black", "red", "yellow", "green", "aqua", "blue", "navy", "fuchsia", "purple"],
    };
  },
  methods: {
    validateSensorName: validateSensorName,
    validateThreshold: validateThreshold,
    validateActivationTh: validateActivationTh,
    // Emette un evento con lo stato di validazione di tutti i sensori
    updateValidation() {
      this.$emit("validation", !this.tempSensors.some((sensor) => !validateEntireSensor(sensor)));
    },
    // Se non ci sono item invalidi, abilita/disabilita editing
    editUserConfig(sensor) {
      let someInvalid = this.tempSensors.some((sensor) => !validateEntireSensor(sensor));
      if (!someInvalid) {
        if (sensor.userConfig.editing) {
          sensor.userConfig.editing = false;
        } else {
          this.tempSensors.forEach((sensor) => {
            sensor.userConfig.editing = false;
          });
          sensor.userConfig.editing = true;
          setTimeout(() => {
            this.$refs[sensor.eui][0].focus();
          }, 0);
        }
      }
    },
  },
  mounted() {
    // All'avvio seleziono la prima tab disponibile
    this.curTab = "manage";
    // Deep copy del sensore
    const sensorsFromParent = JSON.parse(JSON.stringify(this.sensors));
    // Aggiungo il campo 'editing'
    this.tempSensors = (sensorsFromParent || []).map((sensor) => ({
      eui: sensor.eui,
      type: sensor.type,
      rev: sensor.rev,
      userConfig: {
        ...sensor.userConfig,
        editing: false,
      },
    }));
  },
  watch: {
    /* curTab: function() {
      // Quando cambio tab disattivo l'editing dei sensori
      this.tempSensors.map((a) => (a.userConfig.editing = false));
    }, */
    tempSensors: {
      handler: function(newSensors, oldSensors) {
        if (newSensors && oldSensors) {
          this.updateValidation();
          // Se non ci sono sensori in stato 'NON VALIDO' emetto un evento con il nuovo array di sensori
          let someInvalid = this.tempSensors.some((sensor) => !validateEntireSensor(sensor));
          if (!someInvalid) {
            // Elimino il campo 'editing' creato in fase di mount
            const sensorsToEmit = JSON.parse(JSON.stringify(newSensors));
            sensorsToEmit.map((sensor) => {
              delete sensor.userConfig.editing;
              return sensor;
            });
            this.$emit("update", sensorsToEmit);
          }
        }
      },
      deep: true,
    },
  },
  /* beforeDestroy() {
    // Prima del del destroy disattivo editing dei sensori
    this.tempSensors.map((a) => (a.userConfig.editing = false));
  }, */
};
</script>
