<i18n>
{
  "en": {
    "group": "Group",
    "cadenceBanner": {
      "title": "Do you want to change the cadence?",
      "content": "You are changing the cadence of a sensor belonging so a section. For proper operation, all the sensors inside the Rail Tool must have a 2 min cadence. If you go on, data from this sensor may not be elaborated in the Rail Tool.",
      "btn": "I understand"
    }
  },
  "it": {
    "group": "Gruppo",
    "cadenceBanner": {
      "title": "Modificare la cadenza?",
      "content": "Stai modificando la cadenza di un sensore che appartiene ad una Sezione del Tool Rail. Per un corretto funzionamento, i sensori utilizzati nel Tool Rail devono avere cadenza pari a 2 minuti. Se procedi, i dati di questo sensore potrebbero non essere elaborati nel Tool Rail.",
      "btn": "Ho capito"
    }
  }
}
</i18n>
<template>
  <div>
    <SettingsContentTemplate :readOnly="readOnly">
      <template v-slot:tabs>
        <a @click="curTab = 'manage'" :class="{ active: curTab === 'manage' }"
          >{{ $t("message.manage") }}
        </a>
        <a @click="curTab = 'cadence'" :class="{ active: curTab === 'cadence' }">{{
          $t("message.cadence")
        }}</a>
        <a @click="curTab = 'threshold'" :class="{ active: curTab === 'threshold' }"
          >{{ $t("message.alert") }}
        </a>
        <a @click="curTab = 'compensation'" :class="{ active: curTab === 'compensation' }"
          >{{ $t("message.compensation") }}
        </a>
        <a @click="curTab = 'unit'" :class="{ active: curTab === 'unit' }"
          >{{ $t("message.mesUnit") }}
        </a>
        <a @click="curTab = 'position'" :class="{ active: curTab === 'position' }"
          >{{ $t("message.positioning") }}
        </a>
      </template>
      <template v-slot:content>
        <!-- Gestione -->
        <div v-if="curTab === 'manage'">
          <div class="sr-heading">
            <p>{{ $t("message.manageDevices") }}:</p>
          </div>
          <div id="sr-content">
            <div
              class="sensor-manage-box"
              v-for="sensor in tempSensors"
              :key="sensor.eui"
            >
              <div
                class="side-color"
                :style="{ backgroundColor: sensor.userConfig.color }"
              ></div>
              <h4 class="sensor-manage-eui">
                {{`${sensor.type}: ${getShortEui(sensor.eui)} ${getGroupsString(sensor.groupIDs) ? `- ${$t('group')}: ${getGroupsString(sensor.groupIDs)}`: ''}`}}
              </h4>
              <input
                type="text"
                :class="[
                  validateSensorName(sensor.userConfig.name) ? 'valid' : 'invalid',
                  'sensor-manage-name input-text',
                ]"
                :ref="sensor.eui"
                spellcheck="false"
                maxlength="16"
                minlength="1"
                v-model="sensor.userConfig.name"
                :disabled="!sensor.userConfig.editing"
              />
              <img
                class="sensor-manage-edit icon"
                :src="editIcon"
                @click="editUserConfig(sensor)"
              />
              <img class="sensor-manage-changecolor icon" :src="changeColorIcon" />
              <div class="dropdown">
                <div class="dropdown-content">
                  <div
                    v-for="(color, key, index) in colors"
                    :key="index"
                    class="dot"
                    :style="{ backgroundColor: color }"
                    @click="sensor.userConfig.color = color"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Soglia di Allarme -->
        <div v-if="curTab === 'threshold'">
          <div class="sr-heading">
            <p>{{ $t("message.alertTiltmeterDevices") }}:</p>
          </div>
          <div id="sr-content">
            <div
              class="sensor-manage-box"
              v-for="sensor in tempSensors"
              :key="sensor.eui"
            >
              <div
                class="side-color"
                :style="{
                  backgroundColor: sensor.userConfig.color,
                }"
              ></div>
              <h4 class="sensor-manage-eui">
                {{`${sensor.type}: ${getShortEui(sensor.eui)} ${getGroupsString(sensor.groupIDs) ? `- ${$t('group')}: ${getGroupsString(sensor.groupIDs)}`: ''}`}}
              </h4>
              <div class="threshold-field">
                <input
                  type="checkbox"
                  name="alarmPhi"
                  id="alarmPhi"
                  v-model="sensor.userConfig.alertOnPhi"
                />
                <h2>
                  {{ "Phi [" + (mesaurementUnit || " - ") + "]" }}
                </h2>
                <input
                  type="number"
                  min="0"
                  step="any"
                  :ref="sensor.eui"
                  :class="[
                    validateThreshold(sensor, sensor.userConfig.alertThresholdPhi)
                      ? 'valid'
                      : 'invalid',
                    'sensor-manage-threshold input-text',
                  ]"
                  spellcheck="false"
                  maxlength="10e"
                  :value="convertedFrontMes(sensor.userConfig.alertThresholdPhi)"
                  @input="
                    sensor.userConfig.alertThresholdPhi = parseFloat(
                      convertedBackMes($event.target.value)
                    )
                  "
                  :disabled="!sensor.userConfig.editing"
                  @keydown.enter="editUserConfig(sensor)"
                />
                <input
                  type="checkbox"
                  name="alarmTheta"
                  id="alarmTheta"
                  v-model="sensor.userConfig.alertOnTheta"
                />
                <h2>
                  {{ "Theta [" + (mesaurementUnit || " - ") + "]" }}
                </h2>
                <input
                  type="number"
                  min="0"
                  step="any"
                  :class="[
                    validateThreshold(sensor, sensor.userConfig.alertThresholdTheta)
                      ? 'valid'
                      : 'invalid',
                    'sensor-manage-threshold input-text',
                  ]"
                  spellcheck="false"
                  maxlength="10e"
                  :value="convertedFrontMes(sensor.userConfig.alertThresholdTheta)"
                  @input="
                    sensor.userConfig.alertThresholdTheta = parseFloat(
                      convertedBackMes($event.target.value)
                    )
                  "
                  :disabled="!sensor.userConfig.editing"
                  @keydown.enter="editUserConfig(sensor)"
                />
              </div>
              <img
                class="sensor-manage-edit icon"
                :src="editIcon"
                @click="editUserConfig(sensor)"
              />
            </div>
          </div>
        </div>
        <!-- Cadenza di campionamento -->
        <div v-if="curTab === 'cadence'">
          <div class="sr-heading">
            <p>{{ $t("message.setCadence") }}:</p>
          </div>
          <div id="sr-content">
            <div class="sensor-box" v-for="sensor in tempSensors" :key="sensor.eui">
              <div
                class="side-color"
                :style="{ backgroundColor: sensor.userConfig.color }"
              ></div>
              <h4 class="sensor-manage-eui">
                {{`${sensor.type}: ${getShortEui(sensor.eui)} ${getGroupsString(sensor.groupIDs) ? `- ${$t('group')}: ${getGroupsString(sensor.groupIDs)}`: ''}`}}
              </h4>
              <p class="sensor-caption">{{ $t("message.cadence") }}:</p>
              <div class="options-wrap">
                <div class="checkbox-container" v-for="sr in cadences" :key="sr.value">
                  <label class="container">
                    <input
                      type="radio"
                      :name="'radio_' + sensor.eui"
                      :value="sr.value"
                      @input="onChangeCadence($event, sensor)"
                      v-model.number="sensor.userConfig.sampleRate"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label class="value">{{ sr.label }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Compensazione -->
        <div v-if="curTab === 'compensation'">
          <div class="sr-heading">
            <p>{{ $t("message.resetCompensation") }}:</p>
            <button type="button" class="button" @click="$emit('reset-offset')">
              {{ $t("message.resetAll") }}
            </button>
          </div>
          <div id="sr-content">
            <div class="sensor-box" v-for="sensor in tempSensors" :key="sensor.eui">
              <div
                class="side-color"
                :style="{ backgroundColor: sensor.userConfig.color }"
              ></div>
              <h4 class="sensor-manage-eui">
                {{`${sensor.type}: ${getShortEui(sensor.eui)} ${getGroupsString(sensor.groupIDs) ? `- ${$t('group')}: ${getGroupsString(sensor.groupIDs)}`: ''}`}}
              </h4>
              <p class="sensor-caption">
                {{
                  !sensor.baseValue &&
                  !sensor.baseTilt &&
                  !compensatedEuis.includes(sensor.eui)
                    ? $t("message.notCompensated")
                    : $t("message.compensated")
                }}
              </p>
              <div class="options-wrap compensation-opt">
                <button
                  type="button"
                  class="button"
                  @click="
                    $emit('reset-offset', sensor.eui);
                    compensatedEuis.push(sensor.eui);
                  "
                >
                  Reset Offset
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Unità di misura -->
        <div v-if="curTab === 'unit'">
          <div class="sr-heading">
            <p>{{ $t("message.mesUnitSelect") }}:</p>
          </div>
          <div id="sr-content">
            <div class="radio-box">
              <div class="options-wrap">
                <div
                  class="checkbox-container"
                  v-for="unit in mesUnits"
                  :key="unit.value"
                >
                  <label class="container">
                    <input
                      v-if="interfaceSettings && interfaceSettings.dimensions"
                      type="radio"
                      :value="unit.value"
                      :name="'radio_unit_' + unit.value"
                      v-model="interfaceSettings.dimensions.degrees"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label class="value">{{ unit.label }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Position -->
        <div v-if="curTab === 'position'">
          <div class="sr-heading">
            <p>{{ $t("message.setSensorSpan") }}:</p>
          </div>
          <div id="sr-content">
            <div
              class="sensor-manage-box"
              v-for="sensor in tempSensors"
              :key="sensor.eui"
            >
              <div
                class="side-color"
                :style="{ backgroundColor: sensor.userConfig.color }"
              ></div>
              <h4 class="sensor-manage-eui">
                {{`${sensor.type}: ${getShortEui(sensor.eui)} ${getGroupsString(sensor.groupIDs) ? `- ${$t('group')}: ${getGroupsString(sensor.groupIDs)}`: ''}`}}
              </h4>
              <div class="threshold-field">
                <h2>{{ $t("message.span") }}</h2>
                <select
                  id="type"
                  v-model.number="sensor.userConfig.spanNumber"
                  :class="[
                    sensor.userConfig.editing ? '' : 'disabled-select',
                    'select-options',
                  ]"
                  @change="
                    sensor.userConfig.spanDistance =
                      sensor.userConfig.spanNumber === null
                        ? null
                        : sensor.userConfig.spanDistance
                  "
                >
                  <option :value="null" :disabled="!sensor.userConfig.editing">
                    None
                  </option>
                  <option
                    :value="index + 1"
                    v-for="(span, index) in structure.userConfig.spansDetails"
                    :key="index"
                    :disabled="!sensor.userConfig.editing"
                  >
                    {{ index + 1 }}
                  </option>
                </select>
                <h2>{{ "[m]" }}</h2>
                <input
                  type="number"
                  min="0"
                  step="any"
                  :ref="sensor.eui"
                  :class="[
                    checkInputSpan(
                      sensor.userConfig.spanDistance,
                      sensor.userConfig.spanNumber
                    )
                      ? 'valid'
                      : 'invalid',
                    'sensor-manage-threshold input-text',
                  ]"
                  spellcheck="false"
                  maxlength="10e"
                  v-model.number="sensor.userConfig.spanDistance"
                  :disabled="!sensor.userConfig.editing"
                  @keydown.enter="editUserConfig(sensor)"
                />
              </div>
              <img
                class="sensor-manage-edit icon"
                :src="editIcon"
                @click="editUserConfig(sensor)"
              />
            </div>
          </div>
        </div>
      </template>
    </SettingsContentTemplate>
    <move-banner
      class="cadence-modal"
      v-if="cadenceBanner"
      @confirm="cadenceBanner = false"
      :title="$t('cadenceBanner.title')"
      :msg="$t('cadenceBanner.content')"
      :btnText="$t('cadenceBanner.btn')"
      width="400px"
    >
    </move-banner>
  </div>
</template>
<script>
import math from "../../../helpers/math";
import SettingsContentTemplate from "./SettingsContentTemplate.vue";
import {
  validateEntireSensor,
  validateSensorName,
  validateThreshold,
  validateActivationTh,
} from "../../../helpers/validate";
export default {
  name: "tiltmeter-settings",
  components: {
    SettingsContentTemplate,
  },
  props: {
    sensors: Array,
    interfaceSettings: Object,
    structure: Object,
    readOnly: Boolean
  },
  data() {
    return {
      editIcon: require("../../../../public/assets/icons/edit.svg"),
      changeColorIcon: require("../../../../public/assets/icons/changecolor.svg"),
      cadences: [
        { value: 2, label: "2 min.", default: true },
        { value: 5, label: "5 min." },
        { value: 15, label: "15 min." },
        { value: 30, label: "30 min." },
      ],
      compensatedEuis: [],
      tempSensors: null,
      curTab: "",
      mesUnits: [
        { label: "degrees", value: "degrees" },
        { label: "mm/m", value: "mm/m" },
      ],
      colors: [
        "white",
        "silver",
        "gray",
        "black",
        "red",
        "yellow",
        "green",
        "aqua",
        "blue",
        "navy",
        "fuchsia",
        "purple",
      ],

      //modals
      cadenceBanner: false,
    };
  },
  methods: {
    validateSensorName: validateSensorName,
    validateThreshold: validateThreshold,
    validateActivationTh: validateActivationTh,
    // Emette un evento con lo stato di validazione di tutti i sensori
    updateValidation() {
      this.$emit(
        "validation",
        !this.tempSensors.some((sensor) => !validateEntireSensor(sensor))
      );
    },
    // abilita/disabilita editing
    editUserConfig(sensor) {
      // abilita editing
      if (sensor.userConfig.editing) {
        sensor.userConfig.editing = false;
      } else {
        this.tempSensors.forEach((sensor) => {
          sensor.userConfig.editing = false;
        });
        sensor.userConfig.editing = true;
        setTimeout(() => {
          this.$refs[sensor.eui][0].focus();
        }, 0);
      }
    },
    // Converte il valore dall'unità di misura nativa a quella impostata
    convertedFrontMes(value) {
      if (
        this.interfaceSettings.dimensions &&
        this.interfaceSettings.dimensions.degrees === "mm/m"
      ) {
        return math.toFixedIfNecessary(math.degreesToMM(value), 4);
      } else {
        return value;
      }
    },
    // Converte il valore dall'unità di misura impostata a quella nativa
    convertedBackMes(value) {
      if (
        this.interfaceSettings &&
        this.interfaceSettings.dimensions.degrees === "mm/m"
      ) {
        return math.toFixedIfNecessary(math.mmTODegrees(value), 6);
      } else {
        return value;
      }
    },
    // Ritorna lo stato di validazione dei parametri della campata
    checkInputSpan(spanLength, spanNumber) {
      return (!isNaN(spanLength) && spanLength > 0) || spanNumber === null;
    },

    getGroups(IDs = []) {
      return this.structure.groups.filter((g) => IDs.includes(g._id));
    },

    getGroupsString(IDs = []) {
      const groups = this.getGroups(IDs);
      return groups.length ? groups.map((g) => g.name).join(", ") : null;
    },
    getShortEui(eui) {
      return eui.substr(eui.length - 5);
    },
    onChangeCadence(event, sensor) {
      const cadence = parseInt(event.target.value);
      const groups = this.getGroups(sensor.groupIDs);
      const exsistRailGroup = groups.some((g) => g.type === "rail-section");
      if (exsistRailGroup && cadence !== 2) {
        this.cadenceBanner = true;
      }
    },
  },
  computed: {
    // Ritorna l'attuale unità di misura da utilizzare per gli inclinometri
    mesaurementUnit() {
      return this.interfaceSettings.dimensions &&
        this.interfaceSettings.dimensions.degrees === "mm/m"
        ? "mm/m"
        : "degrees";
    },
  },
  mounted() {
    // All'avvio seleziono la prima tab disponibile
    this.curTab = "manage";
    // Deep copy del sensore
    const sensorsFromParent = JSON.parse(JSON.stringify(this.sensors));
    // Aggiungo il campo 'editing'
    this.tempSensors = (sensorsFromParent || []).map((sensor) => ({
      ...sensor,
      userConfig: {
        ...{ alertThresholdPhi: null, alertThresholdTheta: null },
        ...sensor.userConfig,
        editing: false,
      },
    }));
    //this.updateValidation();
  },
  watch: {
    /* curTab: function() {
      // Quando cambio tab disattivo l'editing dei sensori
      this.tempSensors.map((a) => (a.userConfig.editing = false));
    }, */
    tempSensors: {
      handler: function (newSensors, oldSensors) {
        if (newSensors && oldSensors) {
          this.updateValidation();
          // Se non ci sono sensori in stato 'NON VALIDO' emetto un evento con il nuovo array di sensori
          let someInvalid = this.tempSensors.some(
            (sensor) => !validateEntireSensor(sensor)
          );
          if (!someInvalid) {
            // Elimino il campo 'editing' creato in fase di mount
            const sensorsToEmit = JSON.parse(JSON.stringify(newSensors));
            sensorsToEmit.map((sensor) => {
              delete sensor.userConfig.editing;
              return sensor;
            });
            this.$emit("update", sensorsToEmit);
          }
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
</style>
