<i18n>
{
  "en": {
    "insertName": "Full Name",
    "insertMail": "Email address"

  },
  "it": {
    "insertName": "Nome",
    "insertMail": "Indirizzo email"
  }
}
</i18n>
<template>
  <SettingsContentTemplate>
    <template v-slot:tabs>
      <a @click="curTab = 'mail'" :class="{ active: curTab === 'mail' }">{{ $t("message.mailNotifications") }} </a>
    </template>
    <template v-slot:content>
      <!-- Notifications Tab -->
      <div>
        <div class="notify-heading">
          <h4>{{ $t("message.mailAddresses") }}</h4>
          <p>
            {{ $t("message.mailAddressesExplain") }}
          </p>
        </div>
        <div id="sr-content">
          <div class="item-box" v-for="(mail, index) in tempMails" :key="index">
            <input
              tabindex="0"
              type="text"
              pattern="(?=.{4,24}$)^[a-zA-Z0-9_]+( [a-zA-Z0-9_]+)*$"
              class="input-text mail-name"
              spellcheck="false"
              :placeholder="$t('insertName')"
              minlength="4"
              v-model="mail.name"
              :disabled="editingIndex !== index"
              required
            />
            <input
              tabindex="1"
              type="email"
              class="input-text mail-addr"
              spellcheck="false"
              :placeholder="$t('insertMail')"
              minlength="4"
              v-model="mail.mail"
              :disabled="editingIndex !== index"
              required
            />
            <div class="alert-sensor-types">
              <input type="checkbox" name="deck" id="" v-model="mail.sensorTypes" :value="'deck'" />
              <label for="deck">{{ $t("message.decks") }}</label>
              <input type="checkbox" name="Accelerometer" id="" v-model="mail.sensorTypes" :value="'accelerometer'" />
              <label for="deck">{{ $t("message.accelerometers") }}</label>
              <input type="checkbox" name="Tiltmeters" id="" v-model="mail.sensorTypes" :value="'tiltmeter'" />
              <label for="deck">{{ $t("message.tiltmeters") }}</label>
              <input type="checkbox" name="Crackmeters" id="" v-model="mail.sensorTypes" :value="'crackmeter'" />
              <label for="deck">{{ $t("message.crackmeters") }}</label>
              <input type="checkbox" name="AnalogNodes" id="" v-model="mail.sensorTypes" :value="'analog-node'" />
              <label for="AnalogNodes">{{ $t("message.analogNodes") }}</label>
              <input type="checkbox" name="Vibrometers" id="" v-model="mail.sensorTypes" :value="'vibrometer'" />
              <label for="Vibrometers">{{ $t("message.vibrometers") }}</label>
            </div>
            <img v-if="editingIndex === index" class="mail-edit-icon icon" src="../../../../public/assets/icons/accept.svg" @click="editMail(index)" />
            <img v-else class="mail-edit-icon icon" src="../../../../public/assets/icons/edit.svg" @click="editMail(index)" />
            <img v-if="editingIndex === index" class="mail-delete-icon icon" src="../../../../public/assets/icons/trash.svg" @click="deleteMail(mail)" />
            <label class="switch" v-else
              ><input type="checkbox" v-model="mail.alertsOn" />
              <span class="slider round"></span>
            </label>
          </div>
          <div class="add-box">
            <img class="mail-new-icon icon" src="../../../../public/assets/icons/newavatar.svg" @click="insertNewMail" />
            <h5 @click="insertNewMail">
              {{ $t("message.newMailAddress") }}
            </h5>
          </div>
        </div>
      </div>
    </template>
  </SettingsContentTemplate>
</template>
<script>
import SettingsContentTemplate from "./SettingsContentTemplate.vue";
import { validateEmail, validateName, validateEntireMail } from "../../../helpers/validate";
export default {
  name: "notifications-settings",
  components: {
    SettingsContentTemplate,
  },
  props: {
    mails: Array,
  },
  data() {
    return {
      curTab: "mail",
      tempMails: null,
      editingIndex: null,
    };
  },
  methods: {
    // Emette un evento con lo stato di validazione di tutti gli account di notifica
    updateValidation() {
      this.$emit("validation", !this.tempMails.some((mail) => !validateEntireMail(mail)));
    },
    // Se non ci sono item invalidi, inserisce una nuovo account di notifica
    insertNewMail() {
      let someInvalid = this.tempMails.some((mail) => !validateEntireMail(mail));
      if (!someInvalid) {
        let emptyMail = {
          name: "",
          mail: "",
          sensorTypes: ["deck", "accelerometer", "tiltmeter", "crackmeter", "analog-node", "vibrometer"],
          alertsOn: false,
        };
        this.editingIndex = this.tempMails.length;
        this.tempMails.push(emptyMail);
      }
    },
    // Se non ci sono item invalidi, abilita/disabilita editing
    editMail(index) {
      let someInvalid = this.tempMails.some((mail) => !validateEntireMail(mail));
      if (!someInvalid) {
        if (this.editingIndex === index) {
          this.editingIndex = null;
        } else {
          this.editingIndex = index;
        }
      }
    },
    // Ritorna lo stato di validazione di un account di notifica
    checkInputEmail(mail) {
      return validateEmail(mail.mail) && validateName(mail.name);
    },
    // Elimina un account di notifica
    deleteMail(mail) {
      const index = this.tempMails.indexOf(mail);
      if (index > -1) {
        this.tempMails.splice(index, 1);
      }
      this.editingIndex = null;
    },
  },
  mounted() {
    // Deep copy
    this.tempMails = JSON.parse(JSON.stringify(this.mails));
  },
  watch: {
    tempMails: {
      handler: function(newMails, oldMails) {
        if (newMails && oldMails) {
          this.updateValidation();
          if (!newMails.some((mail) => !validateEntireMail(mail))) {
            const mailsToEmit = JSON.parse(JSON.stringify(newMails));
            this.$emit("update", mailsToEmit);
          }
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.sensor-manage-threshold {
  width: 100%;
}
</style>
