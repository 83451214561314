<i18n>
{
   "en":{
      "titleZeroing":"Zeroing",
      "infoZeroing":{
        "message":"It is possible to Perform a 'Zeroing' of the measurement. With this option, the device will classify the last value detected as a reference value providing, from that moment on, a differential measurement with respect to it. Zeroing applies retroactively to all the data acquired by the node up to that point. If you do not want previously acquired data to be modified, download it as a .csv with the 'Download summary' option on the events page."
      },
      "subtitleZeroing":"Zeroing the sensor measurement",
      "lastReset":"Last reset",
      "btnZeroing":"Zero the measure",
      "modalZeroing":{
        "title":"Reset compensation offset?",
        "content":"You are resetting the offset compensation. If you proceed, this sensor's data will be reset.",
        "backBtn":"Back",
        "acceptBtn":"Reset Offset"
      },
      "titleZeroingCanceling":"Zeroing canceling",
      "infoZeroingCanceling":{
        "message":"It is possible to reverse the Zeroing of the measurement. With this option the device will go back to having no reference value and the measurement provided will be absolute instead of differential. It applies retroactively to all the data acquired by the node up to that point. If you do not want previously acquired data to be modified, download it as a .csv with the 'Download summary' option on the events page."
      },
      "subtitleZeroingCanceling":{
        "part1":"Return the sensor to the original measurement",
        "part2":"Caution: This action is irreversible and will affect all the data collected by the sensor"
      },
      "btnZeroingCanceling":"Undo the zeroing",
      "modalZeroingCanceling":{
        "title":"Undo the zeroing?",
        "content":"You are permanently resetting this device. This action is irreversible and will affect all the data collected by the sensor. Once started it cannot be undone. If you proceed, you will see past and future data without any offsets. Are you sure you want to proceed?",
        "backBtn":"Back",
        "acceptBtn":"Undo the zeroing"
      }
   },
   "it":{
      "titleZeroing":"Azzeramento",
      "infoZeroing":{
        "message":"E' possibile effetture un 'Azzeramento' della misura. Con questa opzione il dispositivo classificherà l'ultimo valore acquisito come valore di riferimento, fornendo, da quel momento in poi, una misura differenziale rispetto ad esso. L'azzeramento si applica retroattivamente su tutti i dati acquisiti dal nodo fino a quel momento. Se non vuoi che dati aquisiti in precedenza vengano modificati, scaricali sotto forma di .csv con l'opzione 'Download riepilogo' in pagina eventi."
      },
      "subtitleZeroing":"Azzeramento della misura del sensore",
      "lastReset":"Ultimo azzeramento",
      "btnZeroing":"Azzera la misura",
      "modalZeroing":{
         "title":"Azzerare l'offset di compensazione?",
         "content":"Stai reimpostando la compensazione dell'offset. Se procedi, i dati di questo sensore verranno azzerati.",
         "backBtn":"Indietro",
         "acceptBtn":"Azzera offset"
      },
      "titleZeroingCanceling":"Annullamento dell'azzeramento",
      "infoZeroingCanceling":{
         "message":"E' possibile annullare il precedente Azzeramento della misura. Con questa opzione il dispositivo tornerà a non avere alcun valore di riferimento e la misura fornita non sarà differenziale ma assoluta. L'annullamento si applica retroattivamente su tutti i dati acquisiti dal nodo fino a quel momento. Se non vuoi che dati aquisiti in precedenza vengano modificati, scaricali sotto forma di .csv con l'opzione 'Download riepilogo' in pagina eventi."
      },
      "subtitleZeroingCanceling":{
         "part1":"Riporta il sensore alla misura originale",
         "part2":"Attenzione: questa azione è irreversibile e influenzerà tutti i dati raccolti dal sensore"
      },
      "btnZeroingCanceling":"Annulla l'azzeramento",
      "modalZeroingCanceling":{
         "title":"Annulla l'azzeramento?",
         "content":"Stai ripristinando definitivamente questo dispositivo. Questa azione è irreversibile e influenzerà tutti i dati raccolti dal sensore. Una volta avviato non può essere annullato. Se procedi, visualizzerai tutti i dati raccolti sia passati che futuri senza alcun offset. Sei sicuro di voler procedere?",
         "backBtn":"Indietro",
         "acceptBtn":"Annulla l'azzeramento"
      }
   }
}
</i18n>
<template>
  <div>
    <!-- Zeroing -->
    <div class="mb32">
      <div class="d-flex align-items-center mb8">
        <move-text p b class="mr16"> {{ $t("titleZeroing") }} </move-text>
        <move-info rigth>
          <template #icon>
            <font-awesome-icon icon="fa-solid fa-circle-info" />
          </template>
          <div class="w400 p16">
            <move-text p> {{ $t("infoZeroing.message") }}</move-text>
          </div>
        </move-info>
      </div>

      <div class="d-flex align-items-center justify-content-space-between">
        <div class="mr32">
          <move-text p> {{ $t("subtitleZeroing") }}: </move-text>
          <move-text p secondary>
            {{ `${$t("lastReset")}: ${getDateFormatted}` }}
          </move-text>
        </div>
        <move-btn
          primary
          outlined
          rounded
          @click="showModalZeroing = true"
          :disabled="
            getDisableResetCompensationBtn || getDisableUndoResetCompensationBtn
          "
        >
          <move-text p b> {{ $t("btnZeroing") }} </move-text>
        </move-btn>
      </div>
      <!-- Modal Confirm Zeroing -->
      <move-modal v-if="showModalZeroing">
        <template #header>
          <move-text h3 b>{{ $t("modalZeroing.title") }}</move-text>
        </template>
        <div class="h200">
          <move-text p class="mt16 w500">{{
            $t("modalZeroing.content")
          }}</move-text>
        </div>

        <template #footer>
          <div class="d-flex justify-content-end">
            <move-btn
              primary
              outlined
              rounded
              class="mr8"
              @click="showModalZeroing = false"
            >
              <move-text p b> {{ $t("modalZeroing.backBtn") }} </move-text>
            </move-btn>
            <move-btn
              secondary
              outlined
              rounded
              @click="confirmResetCompensation"
            >
              <move-text p b> {{ $t("modalZeroing.acceptBtn") }} </move-text>
            </move-btn>
          </div>
        </template>
      </move-modal>
    </div>

    <!-- Zeroing canceling -->
    <div>
      <div class="d-flex align-items-center justify-content-space-between">
        <div class="d-flex align-items-center mb8">
          <move-text p b class="mr16">
            {{ $t("titleZeroingCanceling") }}
          </move-text>
          <move-info rigth>
            <template #icon>
              <font-awesome-icon icon="fa-solid fa-circle-info" />
            </template>
            <div class="w400 p16">
              <move-text p> {{ $t("infoZeroingCanceling.message") }}</move-text>
            </div>
          </move-info>
        </div>
        <move-btn icon @click="showAdvancedOptions = !showAdvancedOptions">
          <font-awesome-icon
            :icon="
              showAdvancedOptions
                ? 'fa-solid fa-angle-up'
                : 'fa-solid fa-angle-down'
            "
          />
        </move-btn>
      </div>

      <div
        v-if="showAdvancedOptions"
        class="d-flex align-items-center justify-content-space-between"
      >
        <div class="mr32">
          <move-text p> {{ $t("subtitleZeroingCanceling.part1") }}: </move-text>
          <move-text p>
            {{ $t("subtitleZeroingCanceling.part2") }}
          </move-text>
        </div>
        <move-btn
          primary
          outlined
          rounded
          @click="showModalZeroingCanceling = true"
          :disabled="
            getDisableResetCompensationBtn || getDisableUndoResetCompensationBtn
          "
        >
          <move-text p b> {{ $t("btnZeroingCanceling") }} </move-text>
        </move-btn>
      </div>

      <!-- Modal Confirm Undo Zeroing -->
      <move-modal v-if="showModalZeroingCanceling">
        <template #header>
          <move-text h3 b>{{ $t("modalZeroingCanceling.title") }}</move-text>
        </template>
        <div class="h200">
          <move-text p class="mt16 w500">{{
            $t("modalZeroingCanceling.content")
          }}</move-text>
        </div>

        <template #footer>
          <div class="d-flex justify-content-end">
            <move-btn
              primary
              outlined
              rounded
              class="mr8"
              @click="showModalZeroingCanceling = false"
            >
              <move-text p b>
                {{ $t("modalZeroingCanceling.backBtn") }}
              </move-text>
            </move-btn>
            <move-btn secondary outlined rounded @click="onClickUndo">
              <move-text p b>
                {{ $t("modalZeroingCanceling.acceptBtn") }}
              </move-text>
            </move-btn>
          </div>
        </template>
      </move-modal>
    </div>
  </div>
</template>

<script>
import { ddmmyyyyFormat } from "../../../../../helpers/dates";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "scn-zeroing",
  props: {
    lastResetCompensationDate: {
      type: String,
      default: "12/11/2022"
    }
  },
  data() {
    return {
      showModalZeroing: false,
      showModalZeroingCanceling: false,
      showAdvancedOptions: false
    };
  },
  computed: {
    ...mapGetters({
      getResetCompensationDateChannel0:
        "settings/getResetCompensationDateChannel0",
      getLoading: "settings/getLoading"
    }),
    getDisableResetCompensationBtn() {
      return this.getLoading.resetCompensation;
    },
    getDisableUndoResetCompensationBtn() {
      return this.getLoading.undoResetCompensation;
    },
    getDateFormatted() {
      return ddmmyyyyFormat(new Date(this.getResetCompensationDateChannel0));
    }
  },
  methods: {
    ...mapActions({
      resetCompensation: "settings/resetCompensation",
      fetchSensors: "settings/fetchSensors",
      setResetCompensationLoading: "settings/setResetCompensationLoading",
      setUndoResetCompensationLoading: "settings/setUndoResetCompensationLoading",
      undoCompensation: "settings/undoCompensation"
    }),
    async confirmResetCompensation() {
      this.showModalZeroing = false;
      this.setResetCompensationLoading(true);
      await this.resetCompensation();
      await this.fetchSensors({ resetValidation: true });
      this.setResetCompensationLoading(false);
    },
    async onClickUndo() {
      this.showModalZeroingCanceling = false;
      this.setUndoResetCompensationLoading(true);
      await this.undoCompensation();
      await this.fetchSensors({ resetValidation: true });
      this.setUndoResetCompensationLoading(false);
    }
  }
};
</script>

<style scoped></style>
