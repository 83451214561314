<template>
  <div class="m-container py32">
    <move-card rounded class="header-card">
      <!-- Header -->
      <Header
        type="probeConfiguration"
        v-if="getSettings.includes('header')"
        class="my32"
      />
      <!-- Probe name: -->
      <ProbeName v-if="getSettings.includes('probe-name')" class="my32" />
      <!-- Setting Mode -->
      <SettingMode v-if="getSettings.includes('setting-mode')" class="my32"/>
      <!-- Mapped data -->
      <MappedData  v-if="getMode === 'mapped'"/>
    </move-card>

    <!-- config -->
    <template v-if="getMode === 'raw' || getMode === 'custom' || getSelectedManifest">
      <!-- Warmup -->
      <template v-if="getSettings.includes('warmup')">
        <move-horizontal-divider class="my32"></move-horizontal-divider>
        <Warmup />
      </template>

      <!-- Unit of measurement -->
      <template v-if="getSettings.includes('unit-of-measurement')">
        <move-horizontal-divider class="my32"></move-horizontal-divider>
        <UnitOfMeasurement />
      </template>

      <!-- Data conversion 4 params -->
      <template v-if="getSettings.includes('data-conversion-4-params')">
        <move-horizontal-divider class="my32"></move-horizontal-divider>
        <DataConversion4Params :rawUnit="getOptions.rawUnit" />
      </template>

      <!-- Data conversion ymin ymax -->
      <template v-if="getSettings.includes('data-conversion-ymin-ymax')">
        <move-horizontal-divider class="my32"></move-horizontal-divider>
        <DataConversionYMinYMax :rawUnit="getOptions.rawUnit" />
      </template>

      <!-- Data conversion mV/V -->
      <template v-if="getSettings.includes('data-conversion-mvv')">
        <move-horizontal-divider class="my32"></move-horizontal-divider>
        <DataConversionMVV :rawUnit="getOptions.rawUnit" />
      </template>

      <!-- Data conversion vibrating-wire -->
      <template v-if="getSettings.includes('data-conversion-vw')">
        <move-horizontal-divider class="my32"></move-horizontal-divider>
        <DataConversionVibratingWire :rawUnit="getOptions.rawUnit" />
      </template>

      <!-- Frequencies -->
      <template v-if="getSettings.includes('frequencies')">
        <move-horizontal-divider class="my32"></move-horizontal-divider>
        <Frequencies />
      </template>

      <!-- Data conversion ptc  -->
      <template v-if="getSettings.includes('data-conversion-ptc')">
        <move-horizontal-divider class="my32"></move-horizontal-divider>
        <DataConversionPTC :rawUnit="getOptions.rawUnit" />
      </template>

      <!-- Data conversion-ntc -->
      <template v-if="getSettings.includes('data-conversion-ntc')">
        <move-horizontal-divider class="my32"></move-horizontal-divider>
        <DataConversionNTC :rawUnit="getOptions.rawUnit" />
      </template>

      <!-- Data conversion vibrating-wire -->
      <template v-if="getSettings.includes('probe-type')">
        <move-horizontal-divider class="my32"></move-horizontal-divider>
        <ProbeType />
      </template>

      <!-- NTC -->
      <template v-if="getSettings.includes('ntc')">
        <move-horizontal-divider class="my32"></move-horizontal-divider>
        <Ntc
          :options="{
            disableABCFormula: getOptions.disableABCFormula,
            disableNtcCompensation: getOptions.disableNtcCompensation
          }"
        />
      </template>

      <template v-if="getSettings.includes('advanced-options')">
        <move-horizontal-divider class="my32"></move-horizontal-divider>
        <AdvancedOptions/>
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Header from "./shared/Header.vue";
import SettingMode from "./shared/SettingMode.vue";
import MappedData from "./shared/MappedData.vue";
import Warmup from "./shared/Warmup.vue";
import ProbeName from "./shared/ProbeName.vue";
import UnitOfMeasurement from "./shared/UnitOfMeasurement.vue";
import Ntc from "./shared/Ntc.vue";
import DataConversion4Params from "./shared/DataConversion4Params.vue";
import DataConversionYMinYMax from "./shared/DataConversionYMinYMax.vue";
import DataConversionMVV from "./shared/DataConversionMVV.vue";
import DataConversionVibratingWire from "./shared/DataConversionVW.vue";
import ProbeType from "./shared/ProbeType.vue";
import DataConversionPTC from "./shared/DataConversionPTC.vue";
import DataConversionNTC from "./shared/DataConversionNTC.vue";
import AdvancedOptions from "./shared/AdvancedOptions.vue";
import Frequencies from "./shared/Frequencies.vue";

export default {
  name: "probe-configuration",
  components: {
    Header,
    SettingMode,
    MappedData,
    Warmup,
    ProbeName,
    UnitOfMeasurement,
    Ntc,
    DataConversion4Params,
    DataConversionYMinYMax,
    DataConversionMVV,
    DataConversionVibratingWire,
    ProbeType,
    DataConversionPTC,
    DataConversionNTC,
    AdvancedOptions,
    Frequencies
  },
  props: {
    sensor: null,
    rawDataModeSettings: {
      type: Array,
      default: () => []
    },
    rawDataModeOptions: {
      type: Object,
      default: () => {}
    },
    customDataModeSettings: {
      type: Array,
      default: () => []
    },
    customDataModeOptions: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    /* --------------------------------------------------------------- */
    /* VUEX GETTERS */
    /* --------------------------------------------------------------- */
    ...mapGetters({
      getMode: "settings/getMode",
      getSelectedManifest: "settings/getSelectedManifest"
    }),

    getSettings() {
      return this.getMode === "raw"
        ? this.rawDataModeSettings
        : this.customDataModeSettings;
    },
    getOptions() {
      return this.getMode === "raw"
        ? this.rawDataModeOptions
        : this.customDataModeOptions;
    }
  },
  methods: {
    /* --------------------------------------------------------------- */
    /* VUEX ACTIONS */
    /* --------------------------------------------------------------- */
    ...mapActions({
      setTempSensor: "settings/setTempSensor"
    })
  }
};
</script>

<style scoped>
.m-container {
  text-align: left;
}

.header-card {
  background-color: var(--secondary-color-light);
}
</style>
