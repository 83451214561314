import { render, staticRenderFns } from "./Typo12VConfig.vue?vue&type=template&id=698f3ed0&scoped=true&"
import script from "./Typo12VConfig.vue?vue&type=script&lang=js&"
export * from "./Typo12VConfig.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "698f3ed0",
  null
  
)

export default component.exports