<i18n>
    {
      "en": {
        "title": "Probe type",
        "info": {
          "message": "According to the probe's datasheet, select one of the following options."
        }
      },
      "it": {
        "title": "Tipologia sonda",
        "info": {
          "message": "Seleziona una delle opzioni disponibili in accordo con quanto indicato dal datasheet della sonda."
        }
      }
    }
    </i18n>
<template>
  <div>
    <div class="d-flex align-items-center mb8">
      <move-text p b class="mr16">{{ $t("title") }}</move-text>
      <move-info rigth>
        <template #icon>
          <font-awesome-icon icon="fa-solid fa-circle-info" />
        </template>
        <div class="w400 p16">
          <move-text p> {{ $t("info.message") }}</move-text>
        </div>
      </move-info>
    </div>

    <move-select
      :options="getProbeTypes"
      label="label"
      v-model="vProbeType"
      class="w300"
      :valid="getValidation"
    ></move-select>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "scn-probe-type",
  computed: {
    ...mapGetters({
      getTempSensor: "settings/getTempSensor",
      getTempSensorUserConfig: "settings/getTempSensorUserConfig",
      getTempSensorChannel0: "settings/getTempSensorChannel0",
      getProbeType: "settings/getProbeType"
    }),
    getProbeTypes() {
      return [
        {
          label: "PT100",
          value: "pt100"
        },
        {
          label: "PT1000",
          value: "pt1000"
        }
      ];
    },
    getNullProbeType() {
      return {
        label: null,
        value: null
      };
    },
    getValidation() {
      return !_.isNil(this.vProbeType && this.vProbeType.value);
    },
    vProbeType: {
      get() {
        return (
          this.getProbeTypes.find(item => item.value === this.getProbeType) ||
          this.getNullProbeType
        );
      },
      set(probeTypeOption = { label: null, value: null }) {
        this.updateRscConfig({ probeType: probeTypeOption.value });
      }
    }
  },
  methods: {
    /* --------------------------------------------------------------- */
    /* VUEX ACTIONS */
    /* --------------------------------------------------------------- */
    ...mapActions({
      updateValidationTempSensor: "settings/updateValidationTempSensor",
      updateRscConfig: "settings/updateRscConfig"
    }),
    updateValidation(valid) {
      this.updateValidationTempSensor({
        field: "probeType",
        valid
      });
    }
  },
  watch: {
    getValidation(valid) {
      this.updateValidation(valid);
    }
  },
  mounted() {
    this.updateValidation(this.getValidation);
  }
};
</script>

<style scoped></style>
