<i18n>
{
  "en": {
    "title": "Terms and Conditions",
    "subtitle": "Last updated on May 2022",
    "mainTextContentTitle": "We are sorry!",
    "mainTextContent": "You have declined the Terms and Conditions of the Move Cloud Platform.\nIf you disagree with any of these terms, you will be prohibited from using or accessing the Move Cloud Platform.",
    "btnComeBack": "Come back",
    "btnGoOut": "Exit"
  },
  "it": {
    "title": "Termini e Condizioni",
    "subtitle": "Ultimo aggiornamento, Maggio 2022",
    "mainTextContentTitle": "Siamo spiacenti!",
    "mainTextContent": "Hai rifiutato i Termini e condizioni della piattaforma Move Cloud.\nSe non sei d'accordo con uno qualsiasi di questi termini, ti sarà proibito utilizzare o accedere alla piattaforma Move CLoud.",
    "btnComeBack": "Torna indietro",
    "btnGoOut": "Esci"
  }
}
</i18n>

<template>
  <modalTemplate contentOnly>
    <template v-slot:content>
      <div class="modal-title">
        <div class="title-wrapper">
          <div class="flex-item icon">
            <img color="white" src="../../../public/assets/icons/document.svg" />
          </div>
          <div class="flex-item">
            <move-text h2 class="text-align-left">{{ $t("title") }}</move-text>
            <move-text color="var(--text-secondary-color)" paragraph class="text-align-left">{{ $t("subtitle") }}</move-text>
          </div>
        </div>
        <div id="language-block">
          <img src="../../../public/assets/icons/ita.svg" @click="setLanguage('it')" />
          <img src="../../../public/assets/icons/eng.svg" @click="setLanguage('en')" />
        </div>
      </div>
      <div class="modal-content">
        <move-text paragraph class="text-align-left">
          <b>{{ $t("mainTextContentTitle") }}</b>
          <br />
          {{ $t("mainTextContent") }}
        </move-text>
      </div>
      <div class="modal-footer text-align-right">
        <div class="btn-groups">
          <move-btn class="btn-with-img" outlined primary rounded @click="onClickComeBack"><img src="../../../public/assets/icons/arrow-left.png" />{{ $t("btnComeBack").toUpperCase() }}</move-btn>
          <move-btn outlined secondary rounded @click="onClickGoOut">{{ $t("btnGoOut").toUpperCase() }}</move-btn>
        </div>
      </div>
    </template>
  </modalTemplate>
</template>

<script>
import modalTemplate from "../modules_V2/ModalTemplate.vue";
import { EULA_LINK } from "../../config";
export default {
  name: "ModalEulaRejectView",
  components: {
    modalTemplate,
  },
  data() {
    return {
      eulaLink: EULA_LINK,
    };
  },
  methods: {
    onClickComeBack() {
      this.$emit("comeBack");
    },
    onClickGoOut() {
      this.$emit("goOut");
    },
    setLanguage(lang) {
      this.$root.$i18n.locale = lang;
    },
  },
};
</script>

<style scoped>
.modal-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid var(--secondary-color-light-3);
  margin: 24px 24px 0px 24px;
  padding-bottom: 8px;

}
.title-wrapper{
  display: flex;
  flex-direction: row;
}
.icon {
  padding-right: 4px;
}
.modal-content {
  max-width: 600px;
  min-height: max-content;
  padding: 8px 24px 24px 24px;
}
.text-align-left {
  text-align: left;
}
.text-align-right {
  text-align: right;
}

.modal-footer {
  padding: 24px;
}
.btn-groups{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.btn-with-img{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 4px 10px 4px 4px;
}

#language-block > img {
  height: 25px;
  padding: 5px;
  cursor: pointer;
}
</style>
