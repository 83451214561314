<i18n>
{
  "en":{
    "title":"Probe data conversion",
    "info":{
      "message":"Depending on the interface, specific parameters need to be set in order to convert the raw data into the actual measure. You can usually find those parameters in your probe's datasheet or instruction manual."
    },
    "minRawValue":"Minimum value of the raw data",
    "minOutputValue":"Minimum value of the converted data",
    "maxRawValue":"Maximum value of the raw data",
    "maxOutputValue":"Maximum value of the converted data"
  },
  "it":{
    "title":"Conversione dati della sonda",
    "info":{
      "message":"A seconda dell'interfaccia, specifici parametri devono essere settati per convertire il dato grezzo nella misura effettiva. Solitamente puoi trovare quei parametri nel datasheet o del manuale istruzioni della sonda."
    },
    "minRawValue":"Valore minimo dei dati grezzi",
    "minOutputValue":"Valore minimo dei dati convertiti",
    "maxRawValue":"Valore massimo dei dati grezzi",
    "maxOutputValue":"Valore massimo dei dati convertiti"
  }
}
</i18n>
<template>
  <div>
    <div class="d-flex align-items-center mb16">
      <move-text b p class="mr16"> {{ $t("title") }}: </move-text>
      <move-info rigth>
        <template #icon>
          <font-awesome-icon icon="fa-solid fa-circle-info" />
        </template>
        <div class="w400 p16">
          <move-text p> {{ $t("info.message") }}</move-text>
        </div>
      </move-info>
    </div>

    <div class="d-flex align-items-center pb16">
      <div>
        <move-text caption> {{ $t("minRawValue") }} </move-text>
        <move-input-number
          tertiary
          v-model="vMinRaw"
          :valid="getValidation.minRaw"
          class="w300"
          :disabled="getIsMappedMode"
        >
          <move-text p> {{ getUnit.minRaw }} </move-text>
        </move-input-number>
      </div>
      <font-awesome-icon class="px16 mt16" icon="fa-solid fa-arrow-right" />
      <div>
        <move-text caption> {{ $t("minOutputValue") }} </move-text>
        <move-input-number
          tertiary
          v-model="vMinOutput"
          :valid="getValidation.minOutput"
          class="w300"
          :disabled="getIsMappedMode"
          ><move-text p> {{ getUnit.minOutput }} </move-text></move-input-number
        >
      </div>
    </div>
    <div class="d-flex align-items-center">
      <div>
        <move-text caption>{{ $t("maxRawValue") }}</move-text>
        <move-input-number
          tertiary
          v-model="vMaxRaw"
          :valid="getValidation.maxRaw"
          class="w300"
          :disabled="getIsMappedMode"
          ><move-text p> {{ getUnit.maxRaw }} </move-text></move-input-number
        >
      </div>
      <font-awesome-icon class="px16 mt16" icon="fa-solid fa-arrow-right" />
      <div>
        <move-text caption>{{ $t("maxOutputValue") }}</move-text>
        <move-input-number
          tertiary
          v-model="vMaxOutput"
          :valid="getValidation.maxOutput"
          class="w300"
          :disabled="getIsMappedMode"
          ><move-text p> {{ getUnit.maxOutput }} </move-text></move-input-number
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "scn-data-conversion-4-params",
  props: {
    rawUnit: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapGetters({
      getTempSensor: "settings/getTempSensor",
      getTempSensorUserConfig: "settings/getTempSensorUserConfig",
      getTempSensorChannel0: "settings/getTempSensorChannel0",
      getSensorType: "settings/getSensorType",
      getProbeName: "settings/getProbeName",
      getConversionFormula: "settings/getConversionFormula",
      getMesUnit: "settings/getMesUnit",
      getIsMappedMode: "settings/getIsMappedMode",
    }),

    getUnit() {
      return {
        minRaw: this.rawUnit,
        maxRaw: this.rawUnit,
        minOutput: this.getMesUnit,
        maxOutput: this.getMesUnit
      };
    },
    getValidation() {
      return {
        minRaw: typeof this.vMinRaw === "number",
        maxRaw: typeof this.vMaxRaw === "number",
        minOutput: typeof this.vMinOutput === "number",
        maxOutput: typeof this.vMaxOutput === "number"
      };
    },
    getEntireValidation() {
      return (
        this.getValidation.minRaw &&
        this.getValidation.maxRaw &&
        this.getValidation.minOutput &&
        this.getValidation.maxOutput
      );
    },
    vMinRaw: {
      get() {
        return this.getConversionFormula && this.getConversionFormula.xMin;
      },
      set(v) {
        this.updateConversionFormula({ xMin: this.validateField(v) });
      }
    },
    vMinOutput: {
      get() {
        return this.getConversionFormula && this.getConversionFormula.yMin;
      },
      set(v) {
        this.updateConversionFormula({ yMin: this.validateField(v) });
      }
    },
    vMaxRaw: {
      get() {
        return this.getConversionFormula && this.getConversionFormula.xMax;
      },
      set(v) {
        this.updateConversionFormula({ xMax: this.validateField(v) });
      }
    },
    vMaxOutput: {
      get() {
        return this.getConversionFormula && this.getConversionFormula.yMax;
      },
      set(v) {
        this.updateConversionFormula({ yMax: this.validateField(v)});
      }
    }
  },
  methods: {
    ...mapActions({
      setTempSensor: "settings/setTempSensor",
      updateValidationTempSensor: "settings/updateValidationTempSensor"
    }),
    validateField(value) {
      return value === "" ? null : value;
    },
    updateConversionFormula(fields = {}) {
      const sensor = this.getTempSensor;
      let conversionFormula =
        sensor.userConfig.channels[0].conversionFormula || {};
      conversionFormula = { ...conversionFormula, ...fields };
      sensor.userConfig.channels[0].conversionFormula = conversionFormula;
      this.setTempSensor(conversionFormula);
    },
    updateValidation(valid) {
      this.updateValidationTempSensor({
        field: "dataConversion4Params",
        valid
      });
    }
  },
  watch: {
    getEntireValidation(valid) {
      this.updateValidation(valid);
    }
  },
  mounted() {
    this.updateValidation(this.getEntireValidation);
  }
};
</script>

<style scoped></style>
