<i18n>
  {
    "en": {
      "title": "Programmed sampling",
      "subtitle": "Cadence",
      "info":{
        "message":"The node samples with a fixed cadence. You can change that cadence, remebering that the lower it is, the longer batteries will last."
      }
    },
    "it": {
      "title": "Campionamento programmato",
      "subtitle": "Cadenza",
      "info":{
        "message":"Il nodo campiona con una cadenza fissa. Puoi modificare quella cadenza ricordando che più bassa è, maggiore sarà la durata delle batterie."
      }
    }
  }
</i18n>
<template>
  <div>
    <div class="d-flex align-items-center">
      <move-text b p class="mr16"> {{ $t("title") }}: </move-text>
      <move-info rigth>
        <template #icon>
          <font-awesome-icon icon="fa-solid fa-circle-info" />
        </template>
        <div class="w400 p16">
          <move-text p> {{ $t("info.message") }}</move-text>
        </div>
      </move-info>
    </div>

    <div class="d-flex align-items-center justify-content-space-between">
      <move-text p> {{ $t("subtitle") }}: </move-text>
      <div class="radio-button-container">
        <move-radio-button
          v-for="(cadenceOpt, index) in getCadenceOptions"
          :key="index"
          :option="String(cadenceOpt)"
          v-model="vCadenceOption"
        >
          <move-text p class="text-align-center">{{ getCadenceLabel(cadenceOpt) }}</move-text>
        </move-radio-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "scn-cadence",
  computed: {
    /* --------------------------------------------------------------- */
    /* VUEX GETTERS */
    /* --------------------------------------------------------------- */
    ...mapGetters({
      getTempSensor: "settings/getTempSensor",
      getTempSensorUserConfig: "settings/getTempSensorUserConfig",
      getTempSensorChannel0: "settings/getTempSensorChannel0",
      getCadence: "settings/getCadence"
    }),
    getCadenceOptions() {
      return [2, 10, 20, 30, 60, 120, 360, 720];
    },
    vCadenceOption: {
      get() {
        return String(this.getCadence);
      },
      set(value) {
        this.updateUserConfig({cadence: Number(value)})
      }
    }
  },
  methods: {
    /* --------------------------------------------------------------- */
    /* VUEX ACTIONS */
    /* --------------------------------------------------------------- */
    ...mapActions({
      setTempSensor: "settings/setTempSensor",
      updateUserConfig: "settings/updateUserConfig"
    }),
    getCadenceLabel(v) {
      return v < 60 ? `${v} min` : `${v / 60} h`;
    }
  }
};
</script>

<style scoped>
.radio-button-container {
  width: 60%;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
}
</style>
