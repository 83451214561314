<template>
  <div class="bridge-ow-wrapper">
    <div class="bridge-overview noselect">
      <p>{{ selectedBridge.address }}</p>
      <div class="divider"></div>
      <p>
        {{ $t("message.coordinates") }}: {{ selectedBridge.coordinates.lat }},
        {{ selectedBridge.coordinates.lng }}
      </p>
      <div class="divider"></div>
      <p>N° {{ $t("message.installedDevices") }}: {{ selectedBridge.sensors.length }}</p>
      <div class="divider"></div>
      <p>{{ $t("message.installDate") }}: {{ formattedInstallDate }}</p>
    </div>
  </div>
</template>
<script>
import dates from "../../helpers/dates";
export default {
  name: "bridge-overview",
  props: {
    bridges: Array,
    selectedBridge: Object,
  },
  methods: {
    selectHandler: function (struct) {
      this.$emit("struct-selected", struct);
    },
  },
  computed: {
    formattedInstallDate: function () {
      return dates.ddmmyyyyFormat(this.selectedBridge.installDate);
    },
  },
};
</script>
<style scoped>
.bridge-ow-wrapper {
  display: flex;
}
.bridge-overview {
  padding-left: 20px;
  width: 100%;
  position: relative;
  display: flex;
  font-weight: lighter;
  height: 40px;
  align-content: center;
  align-items: center;
  background-color: rgb(40, 42, 59);
  /*   -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75); */
  border-bottom: solid 1px rgb(80, 84, 105);
  overflow: hidden;
  justify-content: space-between;
  padding: 0 60px;
  white-space: nowrap;
}

.bridge-overview > p {
  padding-right: 10px;
}

/* Tra 600 e 1499 */
@media all and (max-width: 1499px) and (min-width: 300px) {
  .bridge-overview {
    overflow-x: auto;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 12x;
    background-color: rgba(32, 34, 47, 1);
  }

  ::-webkit-scrollbar {
    height: 10px;
    padding-top: 5px;
    background-color: rgba(32, 34, 47, 1);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgba(80, 84, 105, 1);
  }
}

p {
  color: rgba(198, 198, 198, 1);
  font-weight: 500;
}
h4 {
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 4%;
}
.divider {
  align-self: center;
  position: relative;
  margin-left: 1%;
  margin-right: 1%;
  height: 30px;
  border-left: 2px solid rgb(80, 84, 105);
  opacity: 0.6;
}
</style>
