<i18n>
{
   "en":{
      "title":"View data mode",
      "info":{
         "message":"In 'Raw' mode, the platform displays the raw data provided by the probe. In 'Converted' mode, the platform applies a conversion to the raw data, transforming it into the actual measurement of the probe. The parameters to be set  change according to the selected mode."
      },
      "optionRaw":"Raw",
      "optionConverted":"Converted",
      "convertedCardsTitle":"Conversion data",
      "cardCustom":{
         "title":"Custom conversion",
         "subtitle":"Freely configure the conversion parameter of the probe"
      },
      "cardMapped":{
         "title":"Pre-configurated conversion",
         "subtitle":"Select a probe present in the Move system"
      }
   },
   "it":{
      "title":"Modalità visualizzazione dati",
      "info":{
         "message":"In modalità 'Grezzi' la piattaforma mostra il dato grezzo fornito dalla sonda. In modalità 'Convertiti' la piattaforma applica una conversione al dato grezzo, trasformandolo nella misura vera e propria della sonda. I parametri da impostare variano a seconda della modalità selezionata."
      },
      "optionRaw":"Grezzi",
      "optionConverted":"Convertiti",
      "convertedCardsTitle":"Conversione dati",
      "cardCustom":{
         "title":"Conversione Custom",
         "subtitle":"Configura liberamente I parametri di conversione della sonda"
      },
      "cardMapped":{
         "title":"Conversione Pre-configurata",
         "subtitle":"Seleziona una sonda presente nel Sistema Move"
      }
   }
}
</i18n>
<template>
  <div>
    <!-------------------------------------------------------------------------------->
    <!-- Data mode -->
    <!-------------------------------------------------------------------------------->

    <!-- Data mode title -->
    <div class="d-flex align-items-center mb4">
      <move-text p b class="mr16"> {{ $t("title") }} :</move-text>
      <move-info rigth>
        <template #icon>
          <font-awesome-icon icon="fa-solid fa-circle-info" />
        </template>
        <div class="w400 p16">
          <move-text p> {{ $t("info.message") }}</move-text>
        </div>
      </move-info>
    </div>

    <!-- Data mode select compoenent -->
    <move-btn-list class="w-fit mb32">
      <move-btn-item
        class="w100"
        @click="vMode = 'raw'"
        :active="vMode === 'raw'"
      >
        <move-text p> {{ $t('optionRaw') }} </move-text>
      </move-btn-item>
      <move-btn-item
        class="w100"
        @click="vMode = 'custom'"
        :active="vMode === 'custom' || vMode === 'mapped'"
      >
        <move-text p> {{ $t('optionConverted') }} </move-text>
      </move-btn-item>
    </move-btn-list>

    <!-------------------------------------------------------------------------------->
    <!-- Converted data -->
    <!-------------------------------------------------------------------------------->

    <template v-if="vMode !== 'raw'">

      <!-- Title -->
      <div class="d-flex align-items-center mb16">
        <move-text p b > {{ $t('convertedCardsTitle') }}: </move-text>
      </div>

      <!-- Converted data select component -->
      <div class="d-flex">
        <move-card
          class="card mr8"
          @click="vMode = 'custom'"
          :class="{ active: vMode === 'custom' }"
          bordered
          outlined
          rounded
        >
          <move-text p b class="my4"> {{ $t('cardCustom.title') }} </move-text>
          <move-text p secondary
            >{{ $t('cardCustom.subtitle') }}</move-text
          >
        </move-card>
        <move-card
          class="card mr8"
          @click="vMode = 'mapped'"
          :class="{ active: vMode === 'mapped' }"
          bordered
          outlined
          rounded
        >
          <move-text p b class="my4">{{ $t('cardMapped.title') }}</move-text>
          <move-text p secondary
            >{{ $t('cardMapped.subtitle') }}</move-text
          >
        </move-card>
      </div>

    </template>

    
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  getTypo5VRaw,
  getTypo5VCustom,
  getTypo5VMapped,
  getTypo420Raw,
  getTypo420Custom,
  getTypo420Mapped,
  getTypo12VRaw,
  getTypo12VCustom,
  getTypo12VMapped,
  getTypoMVVRaw,
  getTypoMVVCustom,
  getTypoMVVMapped,
  getTypoNTCRaw,
  getTypoNTCCustom,
  getTypoNTCMapped,
  getTypoPTCRaw,
  getTypoPTCCustom,
  getTypoPTCMapped,
  getTypoPotentiometricCustom,
  getTypoPotentiometricRaw,
  getTypoPotentiometricMapped,
  getTypoVibratingWireRaw,
  getTypoVibratingWireCustom,
  getTypoVibratingWireMapped
} from "../../../../../helpers/scn/scn-defaults";
import _ from "lodash";
export default {
  name: "scn-setting-mode",
  /* --------------------------------------------------------------- */
  /* VUEX GETTERS */
  /* --------------------------------------------------------------- */
  computed: {
    ...mapGetters({
      getTempSensor: "settings/getTempSensor",
      getTempSensorUserConfig: "settings/getTempSensorUserConfig",
      getTempSensorChannel0: "settings/getTempSensorChannel0",
      getSensorType: "settings/getSensorType",
      getMode: "settings/getMode",
      getSelectedManifest: "settings/getSelectedManifest",
    }),
    vMode: {
      get() {
        return this.getMode;
      },
      set(mode) {
        if (this.getMode === mode) {
          return
        }
        //se passo da modalità mappata a modalità custom
        if (this.getMode === 'mapped' && mode === 'custom') {
          this.updateMappedValidation(true)
        }
        //se passo da modalità mappata a modalità custom e ho un manifest selezionato
        if (this.getMode === 'mapped' && mode === 'custom' && this.getSelectedManifest) {
          this.deleteRscConfigFields(['manufacturer', 'modelNumber', 'manifestType', 'rscType']);
          this.updateRscConfig({mode: 'custom'})
          if (this.getSensorType === 'vibrating-wire') {
            this.updateExternalNtcVW()
          }
          return
        }

        let sensor = _.cloneDeep(this.getTempSensor);
        sensor = this.getDefaultConfig(mode, this.getSensorType);
        this.setTempSensor(sensor);
      }
    }
  },
  methods: {
    /* --------------------------------------------------------------- */
    /* VUEX ACTIONS */
    /* --------------------------------------------------------------- */
    ...mapActions({
      setTempSensor: "settings/setTempSensor",
      updateValidationRawMode: "settings/updateValidationRawMode",
      updateRscConfig: "settings/updateRscConfig",
      deleteRscConfigFields: "settings/deleteRscConfigFields",
      updateValidationTempSensor: "settings/updateValidationTempSensor",
      updateValidationCustomFromMappedMode: "settings/updateValidationCustomFromMappedMode",
    }),

    updateMappedValidation(valid) {
      this.updateValidationTempSensor({
        field: "mappedData",
        valid
      });
    },


    updateExternalNtcVW(){
      let rscConfig = this.getTempSensorChannel0;
      let externalNtc = rscConfig.externalNtc
      if (externalNtc && rscConfig.conversionFormula && rscConfig.conversionFormula.G) {
        rscConfig.externalNtc = {...externalNtc, ...{compensationEnabled: false}}
        this.updateRscConfig(rscConfig)
      }
    },

    getDefaultConfig(mode = null, type = null,) {
      switch (type) {
        case "4-20":
          if (mode === "raw") {
            return getTypo420Raw(this.getTempSensor);
          } else if (mode === "custom") {
            return getTypo420Custom(this.getTempSensor);
          } else {
            return getTypo420Mapped(this.getTempSensor);
          }
        case "5V":
          if (mode === "raw") {
            return getTypo5VRaw(this.getTempSensor);
          } else if (mode === "custom") {
            return getTypo5VCustom(this.getTempSensor);
          } else {
            return getTypo5VMapped(this.getTempSensor);
          }
        case "12V":
          if (mode === "raw") {
            return getTypo12VRaw(this.getTempSensor);
          } else if (mode === "custom") {
            return getTypo12VCustom(this.getTempSensor);
          } else {
            return getTypo12VMapped(this.getTempSensor);
          }
        case "mV/V":
          if (mode === "raw") {
            return getTypoMVVRaw(this.getTempSensor);
          } else if (mode === "custom") {
            return getTypoMVVCustom(this.getTempSensor);
          } else {
            return getTypoMVVMapped(this.getTempSensor);
          }
        case "NTC":
          if (mode === "raw") {
            return getTypoNTCRaw(this.getTempSensor);
          } else if (mode === "custom") {
            return getTypoNTCCustom(this.getTempSensor);
          } else {
            return getTypoNTCMapped(this.getTempSensor);
          }
        case "PTC":
          if (mode === "raw") {
            return getTypoPTCRaw(this.getTempSensor);
          } else if (mode === "custom") {
            return getTypoPTCCustom(this.getTempSensor);
          } else {
            return getTypoPTCMapped(this.getTempSensor);
          }
        case "potentiometric":
          if (mode === "raw") {
            return getTypoPotentiometricRaw(this.getTempSensor);
          } else if (mode === "custom") {
            return getTypoPotentiometricCustom(this.getTempSensor);
          } else {
            return getTypoPotentiometricMapped(this.getTempSensor);
          }
        case "vibrating-wire":
          if (mode === "raw") {
            return getTypoVibratingWireRaw(this.getTempSensor);
          } else if (mode === "custom") {
            return getTypoVibratingWireCustom(this.getTempSensor);
          } else {
            return getTypoVibratingWireMapped(this.getTempSensor);
          }
        default:
          break;
      }
    }
  },
  watch: {
    vMode:{
      handler: async function (mode) {
        if (mode === "raw") {
          this.updateValidationRawMode();
        }
      }
    }
  }
};
</script>

<style scoped>
.card {
  cursor: pointer;
  border: 3px solid white;
  background-color: transparent;
  max-width: 200px;
}
.card.active {
  border: 3px solid var(--primary-color);
}
</style>
