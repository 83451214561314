<i18n>
{
  "en": {
    "title": "NTC",
    "info": {
      "message": "Some probes have a NTC sensor integrated to provide the temperature. Both data (probe and NTC) are detected every time you get a sampling and two separate graphs are shown on the platform."
    },
    "conversionFormula": "NTC conversion formula",
    "aValue": "Parameter A",
    "bValue": "Parameter B",
    "cValue": "Parameter C",
    "externalCompensation" :{
      "title": "Do you want to compensate the data with the NTC?",
      "parameter": "Compensation parameter set"
    },
    "infoExtCompensation": {
      "message": "Depending on where the probe is installed, you can decide whether or not to compensate the conversion formula with a temperature factor. For example, it's usually done if you are installing a strain gauge on concrete, because of the difference in thermal expansion. This option is available only if an NTC is present (NTC enabled)."
    }
  },
  "it": {
    "title": "NTC",
    "info": {
      "message": "Alcune sonde hanno un NTC integrato per fornire un dato di temperatura. Entrambi i dati (Sonda e NTC) sono rilevati ogni volta che avviene una acquisizione è due grafici separati vengono mostrati in piattaforma."
    },
    "conversionFormula": "Formula di conversione NTC",
    "aValue": "Parametro A",
    "bValue": "Parametro B",
    "cValue": "Parametro C",
    "externalCompensation" :{
      "title": "Vuoi compensare i dati con l'NTC?",
      "parameter": "Imposta i parametri di compensazione"
    },
    "infoExtCompensation": {
      "message": "A seconda di dove la sonda viene installata, puoi decidere se compensare o meno la formula di conversione con un fattore di temperatura. Ad esempio, viene solitamente fatto se si installa uno strain gauge sul calcestruzzo, a causa della differenza nel coefficiente di dilatazione termica. Questa opzione è disponibile solo se l'NTC è presente (NTC abilitato)."
    }
  }
}
</i18n>

<template>
  <div>
    <div class="d-flex align-items-center justify-content-space-between mb16">
      <div class="d-flex align-items-center">
        <move-text b p class="mr16"> {{ $t("title") }}:</move-text>
        <move-info rigth>
          <template #icon>
            <font-awesome-icon icon="fa-solid fa-circle-info" />
          </template>
          <div class="w400 p16">
            <move-text p> {{ $t("info.message") }}</move-text>
          </div>
        </move-info>
      </div>
      <div class="d-flex align-items-center">
        <move-switch v-model="vNtcSwitch" class="mr8" :disabled="getIsMappedMode"></move-switch>
        <move-text p>{{ vNtcSwitch ? "On" : "Off" }}</move-text>
      </div>
    </div>
    <div v-if="!options.disableABCFormula" class="mb32">
      <div
        class="d-flex align-items-center pb16"
        :class="{ opacity: !vNtcSwitch }"
      >
        <move-text p class="mr16">{{ $t("conversionFormula") }}:</move-text>
        <!-- https://www.codecogs.com/eqnedit.php -->
        <!-- T = \frac{1}{A + B(ln(R)) + C(ln(R))^{3}}-273.2 -->
        <img
          src="../../../../../../public/assets/fomulas/SCN-ntc-conversion-formula-12pt.svg"
        />
      </div>

      <div class="d-flex">
        <div class="pr8">
          <move-text caption class="mb4" :class="{ opacity: !vNtcSwitch }"
            >{{ $t("aValue") }}:</move-text
          >
          <move-input-number
            tertiary
            :disabled="!vNtcSwitch || getIsMappedMode"
            v-model="vA"
            :valid="getValidation.conversionFormula.A"
            class="w250"
          ></move-input-number>
        </div>
        <div class="pr8">
          <move-text caption class="mb4" :class="{ opacity: !vNtcSwitch }"
            >{{ $t("bValue") }}:</move-text
          >
          <move-input-number
            tertiary
            :disabled="!vNtcSwitch || getIsMappedMode"
            v-model="vB"
            :valid="getValidation.conversionFormula.B"
            class="w250"
          ></move-input-number>
        </div>
        <div>
          <move-text caption class="mb4" :class="{ opacity: !vNtcSwitch }"
            >{{ $t("cValue") }}:</move-text
          >
          <move-input-number
            tertiary
            :disabled="!vNtcSwitch || getIsMappedMode"
            v-model="vC"
            :valid="getValidation.conversionFormula.C"
            class="w250"
          ></move-input-number>
        </div>
      </div>
    </div>
    <div v-if="getNtcCompensationVisible">
      <div class="d-flex align-items-center justify-content-space-between mb8">
        <div class="d-flex align-items-center">
          <move-text b p class="mr16">
            {{ $t("externalCompensation.title") }}
          </move-text>
          <move-info rigth>
            <template #icon>
              <font-awesome-icon icon="fa-solid fa-circle-info" />
            </template>
            <div class="w400 p16">
              <move-text p> {{ $t("infoExtCompensation.message") }}</move-text>
            </div>
          </move-info>
        </div>
        <div class="d-flex align-items-center">
          <move-switch
            v-model="vNtcCompensationSwitch"
            :disabled="getNtcCompensationDisabled"
            class="mr8"
          ></move-switch>
          <move-text p>{{
            vNtcCompensationSwitch ? "On" : "Off"
          }}</move-text>
        </div>
      </div>
      <div>
        <move-text caption class="mb4" :class="{ opacity: !vNtcCompensationSwitch }"
          >{{ $t("externalCompensation.parameter") }}:</move-text
        >
        <move-input-number
          tertiary
          :disabled="!vNtcCompensationSwitch || getIsMappedMode"
          :valid="getValidation.compensationFactor"
          v-model="vCompensationFactor"
          class="w300"
        ></move-input-number>
      </div>
    </div>
  </div>
</template>

<script>
// const LINEAR_LINEAR_DIGIT_FORMULA = "linear-in-linear-digit-formula";
const LINEAR_DIGIT_FORMULA = "linear-digit-formula";
// const QUADRATIC_FORMULA = "quadratic-formula";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "scn-ntc",
  props: {
    options: {
      type: Object,
      default: () => {
        return {
          disableABCFormula: false,
          disableNtcCompensation: false
        };
      }
    }
  },
  computed: {
    ...mapGetters({
      getTempSensor: "settings/getTempSensor",
      getExternalNTC: "settings/getExternalNTC",
      getVWConversionParameterMode: "settings/getVWConversionParameterMode",
      getIsMappedMode: "settings/getIsMappedMode",
    }),
    getNtcCompensationVisible() {
      return (
        !this.options.disableNtcCompensation &&
        this.getVWConversionParameterMode &&
        this.getVWConversionParameterMode !== LINEAR_DIGIT_FORMULA
      );
    },
    getNtcCompensationDisabled() {
      return !this.vNtcSwitch;
    },
    getNtcConversionFormula() {
      return this.getExternalNTC && this.getExternalNTC.conversionFormula;
    },
    getValidation() {
      let result = {
        compensationFactor: true,
        conversionFormula: {
          A: true,
          B: true,
          C: true
        }
      };
      if (this.options.disableABCFormula) {
        return result;
      }
      if (this.vNtcSwitch) {
        result.conversionFormula.A = typeof this.vA === 'number';
        result.conversionFormula.B = typeof this.vB === 'number';
        result.conversionFormula.C = typeof this.vC === 'number';
      }
      if (this.vNtcCompensationSwitch) {
        result.compensationFactor = typeof this.vCompensationFactor === 'number';
      }

      return result;
    },
    getEntireValidation() {
      return (
        this.getValidation.compensationFactor &&
        this.getValidation.conversionFormula.A &&
        this.getValidation.conversionFormula.B &&
        this.getValidation.conversionFormula.C
      );
    },
    /* NTC Switch */
    vNtcSwitch: {
      get() {
        return this.getExternalNTC && this.getExternalNTC.enabled
          ? true
          : false;
      },
      set(enabled) {
        this.updateExternalNtc(
          {
            enabled: enabled,
          },
        );
        if (!enabled && !this.options.disableNtcCompensation) {
          this.updateExternalNtc({
            compensationEnabled: false
          });
        }
      }
    },
    /* NTC Compensation Switch */
    vNtcCompensationSwitch: {
      get() {
        return this.getExternalNTC && this.getExternalNTC.compensationEnabled
      },
      set(enabled) {
        this.updateExternalNtc({
          compensationEnabled: enabled
        });
      }
    },
    /* A value, conversion formula, NTC */
    vA: {
      get() {
        return this.getNtcConversionFormula && this.getNtcConversionFormula.A;
      },
      set(A) {
        this.updateNtcConversionFormula({
          A: this.validateField(A)
        });
      }
    },
    /* B value, conversion formula, NTC */
    vB: {
      get() {
        return this.getNtcConversionFormula && this.getNtcConversionFormula.B;
      },
      set(B) {
        this.updateNtcConversionFormula({
          B: this.validateField(B)
        });
      }
    },
    /* C value, conversion formula, NTC */
    vC: {
      get() {
        return this.getNtcConversionFormula && this.getNtcConversionFormula.C;
      },
      set(C) {
        this.updateNtcConversionFormula({
          C: this.validateField(C)
        });
      }
    },
    /* compensation factor, compensation NTC */
    vCompensationFactor: {
      get() {
        return this.getExternalNTC.compensationFactor;
      },
      set(compensationFactor) {
        this.updateExternalNtc({
          compensationFactor: this.validateField(compensationFactor)
        });
      }
    }
  },
  methods: {
    ...mapActions({
      setTempSensor: "settings/setTempSensor",
      updateValidationTempSensor: "settings/updateValidationTempSensor"
    }),
    validateField(value) {
      return value === "" ? null : value;
    },
    updateNtcConversionFormula(
      fields = {},
      options = {
        clean: false
      }
    ) {
      const sensor = this.getTempSensor;
      let conversionFormula =
        (sensor.userConfig.channels[0].externalNtc &&
          sensor.userConfig.channels[0].externalNtc.conversionFormula !== undefined ) ? sensor.userConfig.channels[0].externalNtc.conversionFormula :
        {};
      conversionFormula = {
        ...conversionFormula,
        ...fields
      };
      this.updateExternalNtc(
        {
          conversionFormula
        },
        options
      );
    },
    /*
    update conversion formula.
    - if options.clean = true, clean the conversion formula
    */
    updateExternalNtc(
      fields = {},
      options = {
        clean: false
      }
    ) {
      const sensor = this.getTempSensor;
      let externalNtc = sensor.userConfig.channels[0].externalNtc || {};
      externalNtc = options.clean
        ? fields
        : {
            ...externalNtc,
            ...fields
          };
      sensor.userConfig.channels[0].externalNtc = externalNtc;
      this.setTempSensor(sensor);
    },
    updateValidation(valid) {
      this.updateValidationTempSensor({
        field: "ntc",
        valid
      });
    }
  },
  watch: {
    getEntireValidation(valid) {
      this.updateValidation(valid);
    },
    getNtcCompensationVisible(visible){
      if (this.getIsMappedMode) {
        return
      }
      if (visible) {
         this.updateExternalNtc({compensationFactor: null, compensationEnabled: false})
      }
      else{
        const externalNtc = this.getExternalNTC;
        delete externalNtc.compensationFactor
        delete externalNtc.compensationEnabled
        this.updateExternalNtc(externalNtc)
      }
    } 
  },
  mounted() {
    this.updateValidation(this.getEntireValidation);
  }
};
</script>

<style scoped>
.opacity {
  opacity: 0.6;
}
</style>
