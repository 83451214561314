<i18n>
{
  "en": {
    "analogNode": "Analog Node",
    "manage": "Manage",
    "manageExp": "Manage the operating settings of the Analog Node",
    "acquisitionTime": "Acquisition time",
    "warmupTime": "Warmup time",
    "sensor": "Sensor",
    "sensorConfig": "Configure the device connected to the Analog Node",
    "freqSampleMin": "Sampling frequency",
    "tabsNodeV2": {
      "probeConfiguaration": "Probe configuration",
      "acquisitionParameters": "Acquisition parameters",
      "alarms": "Alarms"
    }

  },
  "it": {
    "analogNode": "Nodo Analogico",
    "manage": "Gestisci",
    "manageExp": "Gestisci le impostazioni di funzionamento del Nodo Analogico",
    "acquisitionTime": "Tempo di acquisizione",
    "warmupTime": "Tempo di warmup",
    "sensor": "Sensore",
    "sensorConfig": "Configura il dispositivo collegato al Nodo Analogico",
    "freqSampleMin": "Freq. campionamento",
    "tabsNodeV2": {
      "probeConfiguaration": "Configurazione sonda",
      "acquisitionParameters": "Parametri di acquisizione",
      "alarms": "Allarmi"
    }
  }
}
</i18n>
<template>
  <SettingsContentTemplate :readOnly="readOnly">
    <template v-slot:tabs>
      <template v-if="getSensorRev(selectedNode) === '1.0'">
        <a @click="curTab = 'manage'" :class="{ active: curTab === 'manage' }"
          >{{ $t("message.manage") }}
        </a>
        <a
          v-for="(channel, index) in selectedNode &&
          selectedNode.userConfig.channels
            ? selectedNode.userConfig.channels
            : []"
          @click="
            channel.enabled
              ? ((curTab = 'ch_' + index), (selectedChannel = index))
              : null
          "
          :key="index"
          :class="{
            active: curTab === 'ch_' + index,
            disabled: !channel.enabled
          }"
          >{{ channel.name }}</a
        >
      </template>
      <template v-else>
        <a
          @click="curTab = 'probe-configuration'"
          :class="{ active: curTab === 'probe-configuration' }"
          >{{ $t('tabsNodeV2.probeConfiguaration') }}
        </a>
        <a
          @click="curTab = 'acquisition-parameters'"
          :class="{ active: curTab === 'acquisition-parameters' }"
          >{{ $t('tabsNodeV2.acquisitionParameters') }}
        </a>
        <a @click="curTab = 'alarm'" :class="{ active: curTab === 'alarm' }"
          >{{ $t('tabsNodeV2.alarms') }}
        </a>
      </template>
    </template>
    <template v-slot:side>
      <!-- Opzioni sulla sinistra -->
      <div id="side-sensor-select" class="noselect scroll-thin">
        <div class="options">
          <a
            v-for="sensor in tempSensors"
            :key="sensor.eui"
            @click="onChangeEUI(sensor)"
            :class="{ active: selectedNode && selectedNode.eui === sensor.eui }"
            >{{ sensor.eui.substr(sensor.eui.length - 5) }}
          </a>
        </div>
      </div>
    </template>
    <template v-slot:content>
      <template v-if="getSensorRev(selectedNode) === '1.0'">
        <AnalogNodeRev1Config
          :sensor="selectedNode"
          :selectedChannel="selectedChannel"
          :curTab="curTab"
          @sensorChanged="unsavedChanges = true"
          @updateSensors="updateSensorsV1"
        />
      </template>
      <template v-else>
        <AnalogNodeRev2Config :sensor="selectedNode" :curTab="curTab" />
      </template>
    </template>
  </SettingsContentTemplate>
</template>
<script>
import _ from "lodash";
import SettingsContentTemplate from "./SettingsContentTemplate.vue";
import AnalogNodeRev1Config from "./AnalogNodeRev1Config.vue";
import AnalogNodeRev2Config from "./AnalogNodeRev2Config.vue";
import { mapGetters } from "vuex";
import {
  validateEntireSensor,
  validateSensorName,
  validateThreshold,
  validateActivationTh
} from "../../../helpers/validate";
export default {
  name: "analog-node-settings",
  components: {
    SettingsContentTemplate,
    AnalogNodeRev1Config,
    AnalogNodeRev2Config
  },
  props: {
    sensors: Array,
    readOnly: Boolean
  },
  data() {
    return {
      unsavedChanges: false,
      changeColorIcon: require("../../../../public/assets/icons/changecolor.svg"),
      selectedNode: null,
      selectedChannel: null,
      availableModesByNativeType: {
        "4-20": ["gestecno", "custom", "native"],
        "mV/V": ["custom", "native"],
        thermistor: ["PT100_620", "PT100_680", "native"]
      },
      analogActualTypes: [
        "4-20",
        "gestecno",
        "thermistorPT100_620",
        "thermistorPT100_680",
        "mV/V",
        "potentiometer",
        "voltage-unipolar",
        "voltage-bipolar"
      ], // TODO DEPRECATED
      analogOptionsByNativeType: {
        // Native
        "4-20": {
          wires: true,
          fullScale: true,
          offset: true,
          mesUnit: true
        },
        // Native
        thermistor: {
          sensorType: true,
          gain: true,
          offset: true,
          mesUnit: true
        },
        // Native
        "mV/V": {
          VCC: true,
          gain: true,
          offset: true,
          fullScale: true,
          mesUnit: true
        }
      },
      tempSensors: null,
      curTab: "",
      colors: [
        "white",
        "silver",
        "gray",
        "black",
        "red",
        "yellow",
        "green",
        "aqua",
        "blue",
        "navy",
        "fuchsia",
        "purple"
      ]
    };
  },
  methods: {
    validateSensorName: validateSensorName,
    validateThreshold: validateThreshold,
    validateActivationTh: validateActivationTh,
    // Emette un evento con lo stato di validazione di tutti i sensori
    onChangeEUI(sensor = {}) {
      this.selectedNode = sensor;
      this.curTab = sensor.rev === "2.0" ? "probe-configuration" : "manage";
    },
    updateValidation() {
      this.$nextTick(() => {
        let validV1 = this.tempSensors.every(sensor =>
          validateEntireSensor(sensor)
        );
        const nodeV2List = _.cloneDeep(this.getSensorsV2Validation);
        let validV2 = nodeV2List.every(s => {
          return Object.values(s.validation).every(s => s === true);
        });
        this.$emit("validation", validV1 && validV2);
      });
    },
    getSensorRev(sensor) {
      return sensor && sensor.rev ? sensor.rev : null;
    },
    emitSensors() {
      this.updateValidation();
      // Se non ci sono sensori in stato 'NON VALIDO' emetto un evento con il nuovo array di sensori
      let someInvalidV1 = this.tempSensors.some(
        sensor => !validateEntireSensor(sensor)
      );
      let someInvalidV2 = !this.getAnalogNodeV2Validation;
      if (!someInvalidV1 || !someInvalidV2) {
        // Elimino il campo 'editing' creato in fase di mount
        const sensorsToEmit = JSON.parse(JSON.stringify(this.tempSensors));
        sensorsToEmit.map(sensor => {
          delete sensor.userConfig.editing;
          return sensor;
        });
        this.$emit("update", sensorsToEmit, this.unsavedChanges);
      }
    },
    updateSensorsV1(updatedSensor){
      let tempSensors = _.cloneDeep(this.tempSensors);
      const idx = tempSensors.findIndex(s => s.eui === updatedSensor.eui);
      tempSensors[idx] = updatedSensor;
      this.tempSensors = _.cloneDeep(tempSensors);
    }
  },
  computed: {
    ...mapGetters({
      getTempSensor: "settings/getTempSensor",
      getIsSensorChanged: "settings/getIsSensorChanged"
    }),
    getSensorsV2Validation() {
      return this.$store.state.settings.sensorsValidation;
    }
  },
  mounted() {
    this.unsavedChanges = false;
    // All'avvio seleziono la prima tab disponibile
    this.curTab = "manage";
    // Deep copy del sensore.
    const sensorsFromParent = JSON.parse(JSON.stringify(this.sensors));
    // Aggiungo il campo 'editing'
    this.tempSensors = (sensorsFromParent || []).map(sensor => ({
      eui: sensor.eui,
      type: sensor.type,
      rev: sensor.rev,
      userConfig: {
        ...sensor.userConfig,
        editing: false
      }
    }));
    // Se non ho mai settato gli allarmi -> imposto valore assoluto a true,
    // NON PUÒ FUNZIONARE!!
    // this.tempSensors
    //   .filter(sn => sn.userConfig.channels && sn.rev === "1.0")
    //   .map(ts =>
    //     ts.userConfig.channels.map(ch => {
    //       if (ch.alertAbsolute === null || ch.alertAbsolute === undefined) {
    //         ch.alertAbsolute = true;
    //       }
    //     })
    //   );
    this.selectedNode = this.tempSensors[0];
    if (this.tempSensors[0].rev === "2.0") {
      this.curTab = "probe-configuration";
    }
  },
  watch: {
    tempSensors: {
      handler: function(newSensors, oldSensors) {
        if (newSensors && oldSensors) {
          this.emitSensors()
        }
      },
      deep: true
    },
    getSensorsV2Validation: {
      handler: function() {
        this.updateValidation();
      },
      deep: true
    },
    getTempSensor: {
      handler: function(updatedSensor) {
        let newSensors = updatedSensor;
        let tempSensors = _.cloneDeep(this.tempSensors);
        const idx = tempSensors.findIndex(s => s.eui === newSensors.eui);
        tempSensors[idx] = newSensors;
        this.tempSensors = _.cloneDeep(tempSensors);
      },
      deep: true
    },
    unsavedChanges(changed){
      if (changed) {
        this.emitSensors()
      }
    },
    getIsSensorChanged() {
      this.unsavedChanges = true;
    }
  }
};
</script>
