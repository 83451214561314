<i18n>
  {
    "en": {
      "title": "Advanced options",
      "subTitle": "Caution: this requires some technical knowledge of this product",
      "profile": "Stimulus profile",
      "info": {
        "message": "It can be used to adapt the node to the probe when the readings are not corresponding to the expectations. In case of anomalous readings, you can try and modify the advanced options to find the one that matches the probe."
      },
      "profiles": {
        "default": "Default",
        "profileA": "Profile A",
        "profileB": "Profile B",
        "profileC": "Profile C",
        "profileD": "Profile D"
      }
    },
    "it": {
      "title": "Impostazioni avanzate",
      "subTitle": "Attenzione: questo richiede una certa conoscenza tecnica di questo prodotto",
      "profile": "Profili di stimolo",
      "info": {
        "message": "Può essere utilizzato per adattare il nodo alla sonda quando i dati non corrispondono a quanto atteso. In caso di letture anomale, prova a modificare le opzioni avanzate per cercare quella  che meglio si adatta alla sonda."
      },
      "profiles": {
        "default": "Default",
        "profileA": "Profilo A",
        "profileB": "Profilo B",
        "profileC": "Profilo C",
        "profileD": "Profilo D"
      }
    }
  }
</i18n>
<template>
  <div>
    <div class="d-flex align-items-center justify-content-space-between mb16">
      <div>
        <move-text p b class="mr16 mb4">{{ $t("title") }}:</move-text>
        <move-text p class="mr16">{{ $t("subTitle") }}:</move-text>
      </div>
      <move-btn icon @click="showAdvancedOptions = !showAdvancedOptions">
        <font-awesome-icon
          :icon="
            showAdvancedOptions
              ? 'fa-solid fa-angle-up'
              : 'fa-solid fa-angle-down'
          "
        />
      </move-btn>
    </div>

    <div v-if="showAdvancedOptions">
      <div class="d-flex align-items-center mb8">
        <move-text p class="mr16">{{ $t("profile") }}:</move-text>
        <move-info rigth>
          <template #icon>
            <font-awesome-icon icon="fa-solid fa-circle-info" />
          </template>
          <div class="w400 p16">
            <move-text p> {{ $t("info.message") }}</move-text>
          </div>
        </move-info>
      </div>

      <move-select
        :disabled="getIsMappedMode"
        :options="getProfiles"
        label="label"
        v-model="vSelectedOption"
        class="w300"
      ></move-select>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "scn-advanced-options",
  props: {
    options: null,
    value: null
  },
  data() {
    return {
      showAdvancedOptions: false
    };
  },
  computed: {
    ...mapGetters({
      getTempSensor: "settings/getTempSensor",
      getResponseType: "settings/getResponseType",
      getIsMappedMode: "settings/getIsMappedMode",
    }),
    getNullProfile(){
      return {
        value: null,
        label: null
      }
    },
    getProfiles() {
      return [
        {
          value: 0,
          label: this.$t("profiles.default")
        },
        {
          value: 1,
          label: this.$t("profiles.profileA")
        },
        {
          value: 2,
          label: this.$t("profiles.profileB")
        },
        {
          value: 3,
          label: this.$t("profiles.profileC")
        },
        {
          value: 4,
          label: this.$t("profiles.profileD")
        },
      ];
    },
    getProfile() {
      return this.getProfiles.find(p => p.value === this.getResponseType) || this.getNullProfile
    },
    vSelectedOption: {
      get() {
        return this.getProfile;
      },
      set(option = { label: null, value: null }) {
        this.updateRscConfig({ responseType: option.value });
      }
    },
  },
  methods: {
    ...mapActions({
      updateRscConfig: "settings/updateRscConfig"
    })
  }
};
</script>

<style scoped></style>
