<i18n>
  {
    "en": {
      "title": "Threshold sampling",
      "subTitle": "Trigger threshold",
      "info": {
        "message": "You can set the node to work in trigger mode. When the internal accelerometer detects an acceleration greater than the threshold, an acquisition is triggered. Scheduled acquisitions will still be taken. Keep in mind that enabling the accelerometer reduces the battery life, even if the threshold is never exceeded."
      },
      "hint": "Set an integer number"
    },
    "it": {
      "title": "Campionamento a soglia",
      "subTitle": "Soglia di attivazione",
      "info": {
        "message": "Puoi attivare nel nodo la modalità a soglia. Quando l'accelerometro interno rileva un'accelerazione maggiore della soglia viene innescata un'acquisizione. Tieni presente che attivare l'accelerometro riduce la vita delle batterie, anche se la soglia non viene mai superata."
      },
      "hint": "Imposta un numero intero"
    }
  }
</i18n>
<template>
  <div>
    <div class="d-flex align-items-center justify-content-space-between mb8">
      <div class="d-flex align-items-center">
        <move-text p b class="mr16"> {{ $t("title") }}: </move-text>
        <move-info rigth class="mr32">
          <template #icon>
            <font-awesome-icon icon="fa-solid fa-circle-info" />
          </template>
          <div class="w400 p16">
            <move-text p> {{ $t("info.message") }}</move-text>
          </div>
        </move-info>
      </div>
      <div class="d-flex align-items-center">
        <move-switch v-model="vThresholdSamplingSwitch"></move-switch>
        <move-text p class="ml8">{{
          vThresholdSamplingSwitch ? "On" : "Off"
        }}</move-text>
      </div>
    </div>
    <move-text
      caption
      class="mb4"
      :class="{ opacity: !vThresholdSamplingSwitch }"
    >
      {{ $t("subTitle") }} :
    </move-text>
    <move-input-number
      tertiary
      v-model="vAccelerometerTrigger"
      :valid="getValidation.accThreshold || !vThresholdSamplingSwitch"
      :disabled="!vThresholdSamplingSwitch"
      class="w300"
    >
    <template #hint>
      <move-text caption>{{ $t('hint') }}</move-text>
    </template>
      <move-text p> {{ getUnit.accThreshold }} </move-text>
    </move-input-number>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "scn-threshold-sampling",
  props: {
    accelerometerTriggerUnit: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapGetters({
      getTempSensor: "settings/getTempSensor",
      getTempSensorUserConfig: "settings/getTempSensorUserConfig",
      getTempSensorChannel0: "settings/getTempSensorChannel0",
      getAccThreshold: "settings/getAccThreshold",
      getAccTriggerMode: "settings/getAccTriggerMode"
    }),
    getUnit() {
      return {
        accThreshold: "mg"
      };
    },
    getValidation() {
      return {
        accThreshold: null || typeof this.vAccelerometerTrigger === 'number' && this.vAccelerometerTrigger > 0 && Number.isInteger(this.vAccelerometerTrigger)
      };
    },
    getEntireValidation() {
      return this.vThresholdSamplingSwitch
        ? this.getValidation.accThreshold
        : true;
    },
    vThresholdSamplingSwitch: {
      get() {
        return this.getAccTriggerMode;
      },
      set(value) {
        this.updateUserConfig({ accTriggerMode: value });
        !value && !this.getValidation.accThreshold && this.updateUserConfig({ accThreshold: null });
      }
    },
    vAccelerometerTrigger: {
      get() {
        return this.getAccThreshold;
      },
      set(value) {
        this.updateUserConfig({ accThreshold: this.validateField(value) });
      }
    }
  },
  methods: {
    /* --------------------------------------------------------------- */
    /* VUEX ACTIONS */
    /* --------------------------------------------------------------- */
    ...mapActions({
      setTempSensor: "settings/setTempSensor",
      updateValidationTempSensor: "settings/updateValidationTempSensor",
      updateUserConfig: "settings/updateUserConfig"
    }),
    validateField(value) {
      return value === "" ? null : value;
    }
  },
  watch: {
    getEntireValidation(valid) {
      this.updateValidationTempSensor({ field: "thresholdSampling", valid });
    }
  }
};
</script>

<style scoped>
.opacity {
  opacity: 0.5;
}
</style>
