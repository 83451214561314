<i18n>
{
  "en": {
    "text": "Discover MyMove IoT Platform! Explore new features and an all-new design.",
    "learnMoreLinkText": "Learn more",
    "tryNowButtonText": "TRY NOW"
  },
  "it": {
    "text": "Scopri MyMove IoT Platform! Esplora le nuove funzionalità e un design tutto nuovo.",
    "learnMoreLinkText": "Scopri di più",
    "tryNowButtonText": "PROVA SUBITO"
  }
}
</i18n>

<template>
  <div>
    <div class="badge-root">
      <div class="start-section">
        <img
          src="../../../public/assets/my-move-logo-white.svg"
          alt=""
          width="100px"
        />
        <p>{{ $t("text") }}</p>
      </div>

      <div class="end-section">
        <a :href="learnMoreHref" target="_blank"
          >{{ $t("learnMoreLinkText") }}
          <font-awesome-icon icon="fa-solid fa-up-right-from-square"
        /></a>
        <a
          :href="tryNowButtonHref"
          target="_blank"
          class="button"
          @click="$emit('action')"
          >{{ $t("tryNowButtonText") }}</a
        >
      </div>
    </div>
  </div>
</template>
<script>
import {
  NEW_PLATFORM_WEBAPP_URL,
  NEW_PLATFORM_WEBSITE_URL
} from "../../config";
export default {
  name: "new-platform-badge",
  data() {
    return {
      learnMoreHref: NEW_PLATFORM_WEBSITE_URL,
      tryNowButtonHref: NEW_PLATFORM_WEBAPP_URL
    };
  }
};
</script>

<style scoped>
.badge-root {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 700px;
  width: 96%;
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  min-height: 30px;
  border: solid 3px white;
  -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  padding: 0 40px;
  box-sizing: border-box;
  background-color: rgb(46, 48, 64);
}
a {
  color: white;
  cursor: pointer;
  outline: none;
  text-decoration: none;
}
.end-section {
  display: flex;
  align-items: center;
  gap: 4rem;
}

.end-section a {
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
}
.start-section {
  display: flex;
  align-items: center;
  gap: 1rem;
}
p {
  font-weight: 600;
}
.button {
  padding: 6px 8px;
  height: min-content;
  border: none;
  background-color: var(--primary-color);
  color: white;
  font-family: inherit;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  outline: none;
  text-decoration: none;
}
</style>
