<i18n>
  {
    "en": {
      "title": "NTC",
      "info":{
        "message":"Depending on the interface, specific parameters need to be set in order to convert the raw data into the actual measure. You can usually find those parameters in your probe's datasheet or instruction manual."
      },
      "conversionFormula": "NTC conversion formula",
      "aValue": "Parameter A",
      "bValue": "Parameter B",
      "cValue": "Parameter C"
    },
    "it": {
      "title": "NTC",
      "info":{
        "message":"A seconda dell'interfaccia, specifici parametri devono essere settati per convertire il dato grezzo nella misura effettiva. Solitamente puoi trovare quei parametri nel datasheet o del manuale istruzioni della sonda."
      },
      "conversionFormula": "Formula di conversione NTC",
      "aValue": "Parametro A",
      "bValue": "Parametro B",
      "cValue": "Parametro C"
    }
  }
  </i18n>

<template>
  <div>
    <div class="d-flex align-items-center mb16">
      <move-text b p class="mr8"> {{ $t("title") }}:</move-text>
      <move-info rigth>
        <template #icon>
          <font-awesome-icon icon="fa-solid fa-circle-info" />
        </template>
        <div class="w400 p16">
          <move-text p> {{ $t("info.message") }}</move-text>
        </div>
      </move-info>
    </div>
    <div class="mb32">
      <div class="d-flex align-items-center mb16">
        <move-text p class="mr16">{{ $t("conversionFormula") }}:</move-text>
        <!-- https://www.codecogs.com/eqnedit.php -->
        <!-- T = \frac{1}{A + B(ln(R)) + C(ln(R))^{3}}-273.2 -->
        <img
          src="../../../../../../public/assets/fomulas/SCN-ntc-conversion-formula-12pt.svg"
        />
      </div>

      <div class="d-flex">
        <div class="pr8">
          <move-text caption class="mb4">{{ $t("aValue") }}:</move-text>
          <move-input-number
            tertiary
            v-model="vA"
            :valid="getValidation.A"
            class="w250"
            :disabled="getIsMappedMode"
          ></move-input-number>
        </div>
        <div class="pr8">
          <move-text caption class="mb4">{{ $t("bValue") }}:</move-text>
          <move-input-number
            tertiary
            v-model="vB"
            :valid="getValidation.B"
            class="w250"
            :disabled="getIsMappedMode"
          ></move-input-number>
        </div>
        <div>
          <move-text caption class="mb4">{{ $t("cValue") }}:</move-text>
          <move-input-number
            tertiary
            v-model="vC"
            :valid="getValidation.C"
            class="w250"
            :disabled="getIsMappedMode"
          ></move-input-number>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "scn-ntc",
  props: {
    options: {
      type: Object,
      default: () => {
        return {
          disableABCFormula: false,
          disableNtcCompensation: false
        };
      }
    }
  },
  computed: {
    ...mapGetters({
      getTempSensor: "settings/getTempSensor",
      getConversionFormula: "settings/getConversionFormula",
      getIsMappedMode: "settings/getIsMappedMode",
    }),
    getValidation() {
      return {
        A: typeof this.vA === "number",
        B: typeof this.vB === "number",
        C: typeof this.vC === "number"
      };
    },
    getEntireValidation() {
      return (
        this.getValidation.A && this.getValidation.B && this.getValidation.C
      );
    },
    /* A value, conversion formula, NTC */
    vA: {
      get() {
        return this.getConversionFormula && this.getConversionFormula.A;
      },
      set(A) {
        this.updateConversionFormula({
          A: this.validateField(A)
        });
      }
    },
    /* B value, conversion formula, NTC */
    vB: {
      get() {
        return this.getConversionFormula && this.getConversionFormula.B;
      },
      set(B) {
        this.updateConversionFormula({
          B: this.validateField(B)
        });
      }
    },
    /* C value, conversion formula, NTC */
    vC: {
      get() {
        return this.getConversionFormula && this.getConversionFormula.C;
      },
      set(C) {
        this.updateConversionFormula({
          C: this.validateField(C)
        });
      }
    }
  },
  methods: {
    ...mapActions({
      setTempSensor: "settings/setTempSensor",
      updateValidationTempSensor: "settings/updateValidationTempSensor"
    }),
    validateField(value) {
      return value === "" ? null : value;
    },
    updateConversionFormula(fields = {}) {
      const sensor = this.getTempSensor;
      let conversionFormula =
        sensor.userConfig.channels[0].conversionFormula || {};
      conversionFormula = { ...conversionFormula, ...fields };
      sensor.userConfig.channels[0].conversionFormula = conversionFormula;
      this.setTempSensor(conversionFormula);
    },
    updateValidation(valid) {
      this.updateValidationTempSensor({
        field: "dataConversionNTC",
        valid
      });
    }
  },
  watch: {
    getEntireValidation(valid) {
      this.updateValidation(valid);
    }
  },
  mounted() {
    this.updateValidation(this.getEntireValidation);
  }
};
</script>

<style scoped></style>
