var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"wrapper"}},[_c('transition',{attrs:{"name":"load"}},[(_vm.loadingCount > 0)?_c('SmallLoadingLogo'):_vm._e()],1),_c('form-wizard',{ref:"wizard",attrs:{"shape":"circle","color":"rgb(7, 133, 225)","error-color":"red","title":_vm.$t('wizardTitle'),"subtitle":"","stepSize":"sm"},on:{"on-complete":_vm.onComplete},scopedSlots:_vm._u([{key:"step",fn:function(props){return [_c('wizard-step',{key:props.tab.title || Math.random(),class:_vm.getStepClass(props.index),attrs:{"tab":_vm.filteredTabs(props.tab, props.index),"transition":props.transition,"index":props.index}})]}},{key:"footer",fn:function(ref){
var activeTabIndex = ref.activeTabIndex;
var isLastStep = ref.isLastStep;
var nextTab = ref.nextTab;
var prevTab = ref.prevTab;
return [(_vm.skippableStep(activeTabIndex))?_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.skipStep(activeTabIndex)}}},[_vm._v(" "+_vm._s(_vm.$t("skipButton"))+" ")]):_vm._e(),(activeTabIndex > 0)?_c('button',{attrs:{"type":"button"},on:{"click":prevTab}},[_vm._v(" "+_vm._s(_vm.$t("backButton"))+" ")]):_vm._e(),(!isLastStep && activeTabIndex === 0)?_c('button',{attrs:{"type":"button"},on:{"click":nextTab}},[_vm._v(" "+_vm._s(_vm.$t("nextButton"))+" ")]):_vm._e(),(!isLastStep && activeTabIndex === 1)?_c('button',{class:[_vm.validationStatus ? 'blue-text' : 'dark-text'],attrs:{"type":"button"},on:{"click":nextTab}},[_vm._v(" "+_vm._s(_vm.$t("editPswButton"))+" ")]):_vm._e(),(isLastStep)?_c('button',{attrs:{"type":"button"},on:{"click":nextTab}},[_vm._v(" "+_vm._s(_vm.$t("finishButton"))+" ")]):_vm._e()]}}])},[_c('tab-content',{staticClass:"tab-content"},[_c('h2',[_vm._v(_vm._s(_vm.$t("pswChangeTabTitle")))]),_c('p',[_vm._v(_vm._s(_vm.$t("pswChangeTabContent")))])]),_c('tab-content',{staticClass:"column tab-content",attrs:{"before-change":_vm.validateAndChangePsw,"on-change":_vm.updateStep}},[_c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('ValidationProvider',{staticClass:"vprovider",attrs:{"name":"Password","vid":"password","rules":{ required: true, regex: /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{7,}$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('label',[_vm._v(_vm._s(_vm.$t("enterPassword"))+":")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.psw1),expression:"psw1"}],class:classes,attrs:{"name":"myinput","type":"password"},domProps:{"value":(_vm.psw1)},on:{"keyup":_vm.validatePsw,"input":function($event){if($event.target.composing){ return; }_vm.psw1=$event.target.value}}}),_c('span',{staticClass:"err-span"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('ValidationProvider',{staticClass:"vprovider",attrs:{"name":"Confirm Password","vid":"password2","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('label',[_vm._v(_vm._s(_vm.$t("repeatPassword"))+":")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.psw2),expression:"psw2"}],class:classes,attrs:{"name":"myinput2","type":"password"},domProps:{"value":(_vm.psw2)},on:{"keyup":_vm.validatePsw,"input":function($event){if($event.target.composing){ return; }_vm.psw2=$event.target.value}}}),_c('span',{staticClass:"err-span"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('tab-content',{staticClass:"tab-content"},[_c('h2',[_vm._v(_vm._s(_vm.skippedPsw ? _vm.$t("skippedPasswordTitle") : _vm.$t("changedPasswordTitle")))]),_c('p',[_vm._v(_vm._s(_vm.skippedPsw ? _vm.$t("skippedPasswordContent") : _vm.$t("changedPasswordContent")))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }