<i18n>
{
  "en": {
    "regulation": "Regulation",
    "monitoringType": "Monitoring type",
    "buildingCategory": "Building category",
    "vibrationsType": "Vibrations type",
    "axes": "Axes",
    "threshold": "Alert Threshold",
    "sqrtMode": "PPV Mode",
    "lastChange": "Last change"
  },
  "it": {
    "regulation": "Normativa",
    "monitoringType": "Tipo di monitoraggio",
    "buildingCategory": "Categoria di edificio",
    "vibrationsType": "Tipi di vibrazioni",
    "axes": "Assi",
    "threshold": "Soglia di allarme",
    "sqrtMode": "Modalità PPV",
    "lastChange": "Ultimo cambiamento"
  }
}
</i18n>
<template>
  <div id="root">
    <div id="form-wrapper" class="col-1">
      <!-- Normativa -->
      <label class="col-1" for="normative">{{ $t("regulation") }}:</label>
      <select
        class="col-2"
        id="normative"
        v-model="selectedNormative"
        :class="[editing ? '' : 'disabled-select', 'select-options']"
        :disabled="!editing"
      >
        <option v-for="norm in normativeList" :value="norm" :key="norm.name">
          {{ norm.name }}
        </option>
      </select>
      <!-- Monitoraggio -->
      <label v-if="!isCustomNormative" class="col-1" for="monitoring"
        >{{ $t("monitoringType") }}:</label
      >
      <select
        v-if="!isCustomNormative"
        class="col-2"
        id="monitoring"
        v-model="selectedMonitoringType"
        :class="[editing ? '' : 'disabled-select', 'select-options']"
        :disabled="!editing"
      >
        <option
          v-for="monit in monitoringTypes"
          :value="monit"
          :key="monit.monitoringName"
        >
          {{ monit.monitoringName || " - " }}
        </option>
      </select>
      <!-- Build Cat. -->
      <label v-if="!isCustomNormative && hasCategoryName" class="col-1" for="building"
        >{{ $t("buildingCategory") }}:</label
      >
      <select
        v-if="!isCustomNormative && hasCategoryName"
        class="col-2"
        id="building"
        v-model="selectedBuildingCat"
        :class="[editing ? '' : 'disabled-select', 'select-options']"
        :disabled="!editing"
      >
        <option
          v-for="cat in buildingsCategories"
          :value="cat"
          :key="cat.categoryName"
        >
          {{ cat.categoryName || " - " }}
        </option>
      </select>
      <!-- Vibration type -->
      <label v-if="!isCustomNormative && hasVibrationsTypeName" class="col-1" for="vibration"
        >{{ $t("vibrationsType") }}:</label
      >
      <select
        v-if="!isCustomNormative && hasVibrationsTypeName"
        class="col-2"
        id="vibration"
        v-model="selectedVibrationsType"
        :class="[editing ? '' : 'disabled-select', 'select-options']"
        :disabled="!editing"
      >
        <option
          v-for="vib in vibrationsTypes"
          :value="vib"
          :key="vib.vibrationTypeName"
        >
          {{ vib.vibrationTypeName || " - " }}
        </option>
      </select>
      <!-- Assi custom -->
      <label v-if="isCustomNormative" class="col-1" for="custom-axes"
        >{{ $t("axes") }}:</label
      >
      <div v-if="isCustomNormative" class="custom-axes-settings">
        <label :class="[validCustomAxes ? '' : 'red-text', 'check-container']"
          >X
          <input
            type="checkbox"
            v-model="customAxes"
            :value="'x'"
            :disabled="isTheLastSelectedAxis('x')"
          />
          <span class="checkmark"></span>
        </label>
        <label :class="[validCustomAxes ? '' : 'red-text', 'check-container']"
          >Y
          <input
            type="checkbox"
            v-model="customAxes"
            :value="'y'"
            :disabled="isTheLastSelectedAxis('y')"
          />
          <span class="checkmark"></span>
        </label>
        <label :class="[validCustomAxes ? '' : 'red-text', 'check-container']"
          >Z
          <input
            type="checkbox"
            v-model="customAxes"
            :value="'z'"
            :disabled="isTheLastSelectedAxis('z')"
          />
          <span class="checkmark"></span>
        </label>
      </div>
      <label v-if="isCustomNormative" class="col-1" for="custom-value"
        >{{ $t("sqrtMode") }}:</label
      >
      <div id="module-switch" v-if="isCustomNormative">
        <label
          class="switch"
          :class="{ textdisabled: !isSqrtSettingAvailable }"
        >
          <input
            type="checkbox"
            v-model="sqrtOperationMode"
            :disabled="!isSqrtSettingAvailable"
          />
          <span
            class="slider round"
            :class="{ colordisabled: !isSqrtSettingAvailable }"
          ></span>
        </label>
      </div>
      <!-- Full Scale -->
      <label
        v-if="isCustomNormative && sensor.rev >= '2.0'"
        class="col-1"
        for="custom-value"
        >Fullscale [{{ getMesUnit }}]:</label
      >
      <div v-if="isCustomNormative && sensor.rev >= '2.0'" :id="`full-scale-${uuid}`">
        <span v-for="(fullScale_mms, i) in fullScaleList_mms" :key="i">
          <input
            type="radio"
            :id="`full-scale-${fullScale_mms}`"
            :value="fullScale_mms"
            v-model.number="selectedFullScale_mms"
          />
          <label :for="`full-scale-${fullScale_mms}`">{{
            getActualFullScale(fullScale_mms)
          }}</label>
        </span>
      </div>
      <!-- Soglia custom -->
      <label v-if="isCustomNormative" class="col-1" for="custom-value"
        >{{ $t("threshold") }} [{{ getMesUnit }}]:</label
      >
      <input
        v-if="isCustomNormative"
        type="number"
        min="0"
        step="any"
        :class="[
          validateActivationTh(sensor, customThreshold_mms) ? 'valid' : 'invalid',
          'input-text'
        ]"
        spellcheck="false"
        @input="onChangeThreshold"
        :value="customThreshold"
        :maxlength="2"
        :disabled="!editing"
      />
      <label class="col-1" for="custom-value">{{ $t("lastChange") }}:</label>
      <p class="col-2">{{ lastChangeDate }}</p>
    </div>
    <div id="plot-wrapper" class="col-2">
      <!-- Plot valori reali norma -->
      <VibrometerNormPlot
        v-if="!isCustomNormative && selectedVibrationsType"
        :yTitle="`[${getMesUnit}]`"
        :axisNames="selectedVibrationsType.axes[0].axisName"
        :axisValues="getAlertPoints"
      />
      <!-- Plot valori custom TODO: rendere dinamici e visualizzare gli assi coinvolti -->
      <VibrometerNormPlot
        v-if="isCustomNormative"
        :yTitle="`[${getMesUnit}]`"
        :axisNames="['x', 'y', 'z']"
        :axisValues="customAlertPoints"
      />
    </div>
  </div>
</template>
<script>
import VibrometerNormPlot from "./VibrometerNormPlot.vue";
import { validateActivationTh } from "../../../helpers/validate";
import { truncateVibrometerThreshold } from "../../../helpers/sensors";
import { inchToMm, mmToInch } from "../../../helpers/math";
import { v4 as uuidv4 } from 'uuid';

export default {
  name: "vibrometer-threshold-manage",
  components: {
    VibrometerNormPlot
  },
  props: {
    value: Object, // Valore attuale dei settings, sensor.userConfig.threshold
    normatives: Array, // Lista di normative prese dal manifest
    sensor: Object, // Oggetto sensore in questione
    editing: Boolean, // Editing abilitato/disabilitato,
    interfaceSettings: Object
  },
  data() {
    return {
      // Stato attuale
      selectedNormative: null,
      selectedMonitoringType: null,
      selectedBuildingCat: null,
      selectedVibrationsType: null,
      customThreshold: null,
      customAxes: null,
      customOperation: null,
      selectedFullScale_mms: null,
      fullScaleList_mms: [50, 100],
      uuid: uuidv4()
    };
  },
  computed: {
    lastChangeDate() {
      if (this.sensor && this.sensor.userConfig.threshold.lastChange) {
        return new Date(
          this.sensor.userConfig.threshold.lastChange
        ).toLocaleDateString();
      } else {
        return " - ";
      }
    },

    // Se l'utente abilità la modalità custom SQRT adeguo lo stato
    sqrtOperationMode: {
      // getter
      get: function() {
        return this.customOperation === "sqrt";
      },
      // setter
      set: function(newValue) {
        if (newValue) {
          this.customOperation = "sqrt";
        } else {
          this.customOperation = "max";
        }
      }
    },
    // Ritorna true se l'utente può settare la modalità ABS per l'allarme custom
    isSqrtSettingAvailable() {
      return this.customAxes && this.customAxes.length > 1;
    },
    // Ritorna true se c'è almeno un asse abilitato
    validCustomAxes() {
      return this.customAxes && this.customAxes.length > 0;
    },
    // Ritorna true se la normativa selezionata è custom
    isCustomNormative() {
      return this.selectedNormative && this.selectedNormative.name === "custom";
    },
    // Lista delle normative complete disponibili TODO FILTRARE per sensor rev
    normativeList() {
      return this.normatives;
    },
    // Lista dei tipi di monitoraggio completi disponibli
    monitoringTypes() {
      if (!this.selectedNormative) {
        return [];
      }
      return this.selectedNormative.options || [];
    },
    // Lista delle categorie di edifici completi disponibili
    buildingsCategories() {
      if (!this.selectedNormative || !this.selectedMonitoringType) {
        return [];
      }
      return this.selectedMonitoringType.buildingsCategories || [];
    },
    hasCategoryName(){
      return this.buildingsCategories.filter(buildingsCat => buildingsCat.categoryName !== null).length ? true : false; 
    },
    // Lista dei tipi di vibrazioni completi disponibli
    vibrationsTypes() {
      if (
        !this.selectedNormative ||
        !this.selectedMonitoringType ||
        !this.selectedBuildingCat
      ) {
        return [];
      }
      return this.selectedBuildingCat.vibrationsType || [];
    },
    hasVibrationsTypeName(){
      return this.vibrationsTypes.filter(vibrationsType => vibrationsType.vibrationTypeName !== null).length ? true : false; 
    },
    // Ritorna la lista di punti da plottare sul graico in caso di soglia custom
    customAlertPoints() {
      return [
        {
          freq: 0,
          amplitude: this.customThreshold
        },
        {
          freq: 100,
          amplitude: this.customThreshold
        }
      ];
    },
    getAlertPoints(){
      const alertPoints = JSON.parse(JSON.stringify(this.selectedVibrationsType.axes [0].alertPoints || [])) ;
      const isInch = this.getMesUnit === 'inch/s';
      return alertPoints.map(alertPoint_mm => {
        const alertPoint_inch = {...alertPoint_mm, amplitude: mmToInch(alertPoint_mm.amplitude, 5)}
        return isInch ? alertPoint_inch : alertPoint_mm
      });
    },
    getMesUnit() {
      return this.interfaceSettings.velocity;
    },
    customThreshold_mms(){
      return this.getMesUnit === 'inch/s'
       ? inchToMm(this.customThreshold, 3)
       : this.customThreshold
    }
  },
  methods: {
    validateActivationTh: validateActivationTh,
    // Ritorna true se è l'ultimo asse selezionato (così da poter disattivare la checkbox e mantenerne almeno una selezionata)
    isTheLastSelectedAxis(value) {
      return (
        this.customAxes &&
        this.customAxes.length === 1 &&
        this.customAxes.includes(value)
      );
    },
    // Inietta nello stato i valori completi di tutti gli attributi presi dal manifest in base ad i settings del sensore
    setNormativeDetailsFromProp() {
      const normative = this.normatives.find(
        nm => nm.name === this.value.normativeName
      );
      this.selectedNormative = normative;
      if (this.value.normativeName !== "custom") {
        // Prendo dal manifest i parametri completi della norma selezioanta
        const monitoringType = normative.options.find(
          opt => opt.monitoringName === this.value.monitoringTypeName
        );
        const buildingCategory = monitoringType.buildingsCategories.find(
          bc => bc.categoryName === this.value.buildingCategoryName
        );
        const vibrationsType = buildingCategory.vibrationsType.find(
          vt => vt.vibrationTypeName === this.value.vibrationsTypeName
        );
        this.selectedMonitoringType = monitoringType;
        this.selectedBuildingCat = buildingCategory;
        this.selectedVibrationsType = vibrationsType;
      } else {
        // Inietto i parametri custom del sensore
        this.customThreshold = this.getMesUnit === 'inch/s'
          ? mmToInch(this.value.customThreshold, 5)
          : this.value.customThreshold;
        this.customAxes = this.value.customAxes;
        this.customOperation = this.value.customOperation;
      }
      if (this.sensor.userConfig.fullScale) {
        this.selectedFullScale_mms = this.sensor.userConfig.fullScale;
      }
      else if (normative.fullScaleNorm) {
        this.selectedFullScale_mms = normative.fullScaleNorm;
      }
    },
    // Aggiorna lo stato dei settings del sensore del padre
    sendUpdate() {
      this.$emit("input", {
        normativeName: this.selectedNormative.name,
        monitoringTypeName: (this.selectedMonitoringType || {}).monitoringName,
        buildingCategoryName: (this.selectedBuildingCat || {}).categoryName,
        vibrationsTypeName: (this.selectedVibrationsType || {})
          .vibrationTypeName,
        customAxes: this.customAxes,
        customThreshold: this.customThreshold_mms, 
        customOperation: this.customOperation
      });
      this.$emit("fullScale", this.selectedFullScale_mms);
    },
    getActualFullScale(value) {
      return this.getMesUnit === "inch/s" ? mmToInch(value, 5) : value;
    },
    onChangeThreshold(evt){
      const value = parseFloat(evt.target.value);
      this.customThreshold = truncateVibrometerThreshold(value, this.getMesUnit)
      this.$forceUpdate()
    },
  },
  // All'avvio inizalizzo lo stato in base ad i settings del sensore
  beforeMount() {
    this.setNormativeDetailsFromProp();
  },
  watch: {
    // Quando cambio valori adeguo le possibili scelte ed invio update al padre
    selectedNormative: function(newValue, oldValue) {
      if (
        newValue != null &&
        newValue != undefined &&
        oldValue != null &&
        oldValue != undefined
      ) {
        if (this.selectedNormative.name === "custom") {
          this.customAxes = ["x", "y", "z"];
          this.customOperation = "sqrt";
          this.customThreshold = this.getMesUnit === 'inch/s'
            ? mmToInch(45, 5)
            : 45;
          this.selectedMonitoringType = null;
          this.selectedBuildingCat = null;
          this.selectedVibrationsType = null;
        } else {
          this.selectedMonitoringType = this.monitoringTypes[0];
          this.selectedBuildingCat = this.buildingsCategories[0];
          this.selectedVibrationsType = this.vibrationsTypes[0];
        }
        if (this.selectedNormative.fullScaleNorm) {
          this.selectedFullScale_mms = this.selectedNormative.fullScaleNorm;
        }
        this.sendUpdate();
      }

    },
    selectedMonitoringType: function(newValue, oldValue) {
      if (
        newValue != null &&
        newValue != undefined &&
        oldValue != null &&
        oldValue != undefined
      ) {
        this.selectedBuildingCat = this.buildingsCategories[0];
        this.selectedVibrationsType = this.vibrationsTypes[0];
        this.sendUpdate();
      }
    },
    selectedBuildingCat: function(newValue, oldValue) {
      if (
        newValue != null &&
        newValue != undefined &&
        oldValue != null &&
        oldValue != undefined
      ) {
        this.selectedVibrationsType = this.vibrationsTypes[0];
        this.sendUpdate();
      }
    },
    selectedVibrationsType: function(newValue, oldValue) {
      if (
        newValue != null &&
        newValue != undefined &&
        oldValue != null &&
        oldValue != undefined
      ) {
        this.sendUpdate();
      }
    },
    selectedFullScale_mms: function(newValue, oldValue) {
      if (
        newValue != null &&
        newValue != undefined &&
        oldValue != null &&
        oldValue != undefined
      ) {
        this.sendUpdate();
      }
    },
    customThreshold: function(newValue, oldValue) {
      if (
        newValue != null &&
        newValue != undefined &&
        oldValue != null &&
        oldValue != undefined
      ) {
        this.sendUpdate();
      }
    },
    customAxes: function(newValue, oldValue) {
      if (
        newValue != null &&
        newValue != undefined &&
        oldValue != null &&
        oldValue != undefined
      ) {
        this.sendUpdate();
      }
    },
    customOperation: function(newValue, oldValue) {
      if (
        newValue != null &&
        newValue != undefined &&
        oldValue != null &&
        oldValue != undefined
      ) {
        this.sendUpdate();
      }
    }
  }
};
</script>
<style scoped>
#root {
  display: grid;
  align-items: center;
  grid-template-columns: 10fr 320px;
  column-gap: 10px;
}
#form-wrapper {
  padding: 10px 0;
  display: grid;
  align-items: center;
  column-gap: 10px;
}
#plot-wrapper {
  padding: 20px;
  box-sizing: border-box;
  position: relative;
}
label {
  font-size: 14px;
}
select {
  margin: 6px 0;
}
.col-1 {
  grid-column: 1;
  min-width: 150px;
}
.col-2 {
  grid-column: 2;
  min-width: 372px;
}

.input-text {
  color: white;
  background-color: rgb(32, 34, 47);
  border-radius: 5px;
  width: 120px;
  height: 30px;
  padding-left: 6px;
  outline-width: 0;
  outline: none !important;
  border: none;
  display: inline;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  padding-bottom: 0;
  white-space: nowrap;
  display: flex;
  align-items: center;
  align-self: center;
  border: solid 1.2px transparent !important;
  -webkit-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
}
.input-text:enabled {
  background-color: rgba(0, 0, 0, 0.4);
}
.input-text:enabled.valid {
  border: solid 1.2px green !important;
}
.input-text.invalid {
  border: solid 1.2px red !important;
}

.custom-axes-settings {
  display: flex;
  justify-content: space-between;
}
#module-switch {
  display: flex;
  height: 40px;
}
#full-scale {
  margin-bottom: 10px;
}
input[type="checkbox"] {
  background-color: red;
}
</style>
