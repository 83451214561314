<template>
  <div class="footer">
    <div class="head">
      <div id="sect1">
        <img id="logo-footer" :src="imageURL" />
      </div>
      <div id="sect2">
        <h2>{{ $t("action.contactUs") }}</h2>
        <a href="mailto:support@movesolutions.it">support@movesolutions.it</a>
      </div>
      <div id="sect3">
        <h2>{{ $t("action.callUs") }}</h2>
        <a href="tel:+393426486115">+39 342 648 6115</a>
        <p>{{ $t("day.monday").substring(0, 3) }}. - {{ $t("day.friday").substring(0, 3) }}. | 9am - 6pm</p>
      </div>
    </div>
    <div class="bottom">
      <p><strong>© move solutions 2019 </strong>all rights reserved</p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "footer-move",
  computed: {
    ...mapGetters({ customLogo: "user/getUserLogoURL" }),
    imageURL() {
      return this.customLogo || require("../../../public/assets/footerlogo.svg");
    },
  },
};
</script>
<style scoped>
p {
  margin: 0;
}

a {
  color: white;
}

.head {
  grid-row: 1;
  display: grid;
  grid-template-columns: 33% 33% 33%;
}
.bottom {
  border-top: solid 2px rgba(80, 84, 105, 1);
  width: 90%;
  display: flex;
  justify-self: center;
  grid-row-start: 1;
  grid-row-end: 4;
  grid-row: 2;
  justify-content: center;
  align-items: center;
}

.bottom > p {
  font-size: 0.7rem;
}

.footer {
  background-color: rgb(45, 48, 65);
  display: grid;
  grid-template-rows: 80% 20%;
  width: 100%;
  height: 150px;
  margin-top: 20px;
  text-align: left;
  font-size: 0.82em;
  align-content: center;
}
#sect1 {
  grid-column: 1;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
}
#logo-footer {
  /* width: 80px; */
  height: 80%;
  max-height: 70px;
  padding-top: 20px;
  padding-bottom: 40px;
  image-rendering: -webkit-optimize-contrast;
  transform: translateZ(0);
}

#sect2 {
  grid-column: 2;
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 30%;
}

#sect3 {
  grid-column: 3;
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 30%;
}
</style>
