import _ from "lodash";

/*****************************************************************************************/
/* 5v */
/*****************************************************************************************/
export function getTypo5VRaw(sensor = {}) {
  const newSensor = _.cloneDeep(sensor);
  const name = sensor.userConfig.channels[0].name;
  const nativeType = sensor.userConfig.channels[0].nativeType;
  const alerts = sensor.userConfig.channels[0].alerts;
  newSensor.userConfig.channels[0] = {
    ...{
      mesUnit: "V",
      warmupTime: 1,
      mode: "raw",
      externalNtc: {
        enabled: false
      }
    },
    ...{ name, nativeType, alerts }
  };
  return newSensor;
}

export function getTypo5VCustom(sensor = {}) {
  const newSensor = _.cloneDeep(sensor);
  const name = sensor.userConfig.channels[0].name;
  const nativeType = sensor.userConfig.channels[0].nativeType;
  const alerts = sensor.userConfig.channels[0].alerts;
  newSensor.userConfig.channels[0] = {
    ...{
      mesUnit: null,
      warmupTime: 1,
      mode: "custom",
      externalNtc: {
        enabled: false
      }
    },
    ...{ name, nativeType, alerts }
  };
  return newSensor;
}

export function getTypo5VMapped(sensor = {}) {
  const newSensor = _.cloneDeep(sensor);
  const name = sensor.userConfig.channels[0].name;
  const nativeType = sensor.userConfig.channels[0].nativeType;
  const alerts = sensor.userConfig.channels[0].alerts;
  newSensor.userConfig.channels[0] = {
    ...{
      manufacturer: null,
      modelNumber: null,
      mode: "mapped",
    },
    ...{ name, nativeType, alerts }
  };
  return newSensor;
}

/*****************************************************************************************/
/* 12v */
/*****************************************************************************************/
export function getTypo12VRaw(sensor = {}) {
  const newSensor = _.cloneDeep(sensor);
  const name = sensor.userConfig.channels[0].name;
  const nativeType = sensor.userConfig.channels[0].nativeType;
  const alerts = sensor.userConfig.channels[0].alerts;
  newSensor.userConfig.channels[0] = {
    ...{
      mesUnit: "V",
      warmupTime: 1,
      mode: "raw",
      externalNtc: {
        enabled: false
      }
    },
    ...{ name, nativeType, alerts }
  };
  return newSensor;
}

export function getTypo12VCustom(sensor = {}) {
  const newSensor = _.cloneDeep(sensor);
  const name = sensor.userConfig.channels[0].name;
  const nativeType = sensor.userConfig.channels[0].nativeType;
  const alerts = sensor.userConfig.channels[0].alerts;
  newSensor.userConfig.channels[0] = {
    ...{
      mesUnit: null,
      warmupTime: 1,
      mode: "custom",
      externalNtc: {
        enabled: false
      }
    },
    ...{ name, nativeType, alerts }
  };
  return newSensor;
}

export function getTypo12VMapped(sensor = {}) {
  const newSensor = _.cloneDeep(sensor);
  const name = sensor.userConfig.channels[0].name;
  const nativeType = sensor.userConfig.channels[0].nativeType;
  const alerts = sensor.userConfig.channels[0].alerts;
  newSensor.userConfig.channels[0] = {
    ...{
      manufacturer: null,
      modelNumber: null,
      mode: "mapped",
    },
    ...{ name, nativeType, alerts }
  };
  return newSensor;
}

/*****************************************************************************************/
/* 420 */
/*****************************************************************************************/
export function getTypo420Raw(sensor = {}) {
  const newSensor = _.cloneDeep(sensor);
  const name = sensor.userConfig.channels[0].name;
  const nativeType = sensor.userConfig.channels[0].nativeType;
  const alerts = sensor.userConfig.channels[0].alerts;
  newSensor.userConfig.channels[0] = {
    ...{
      mesUnit: "mA",
      warmupTime: 1,
      mode: "raw",
      externalNtc: {
        enabled: false
      }
    },
    ...{ name, nativeType, alerts }
  };
  return newSensor;
}

export function getTypo420Custom(sensor = {}) {
  const newSensor = _.cloneDeep(sensor);
  const name = sensor.userConfig.channels[0].name;
  const nativeType = sensor.userConfig.channels[0].nativeType;
  const alerts = sensor.userConfig.channels[0].alerts;
  newSensor.userConfig.channels[0] = {
    ...{
      mesUnit: null,
      warmupTime: 1,
      mode: "custom",
      externalNtc: {
        enabled: false
      }
    },
    ...{ name, nativeType, alerts }
  };
  return newSensor;
}

export function getTypo420Mapped(sensor = {}) {
  const newSensor = _.cloneDeep(sensor);
  const name = sensor.userConfig.channels[0].name;
  const nativeType = sensor.userConfig.channels[0].nativeType;
  const alerts = sensor.userConfig.channels[0].alerts;
  newSensor.userConfig.channels[0] = {
    ...{
      manufacturer: null,
      modelNumber: null,
      mode: "mapped",
    },
    ...{ name, nativeType, alerts }
  };
  return newSensor;
}

/*****************************************************************************************/
/* MVV */
/*****************************************************************************************/
export function getTypoMVVRaw(sensor = {}) {
  const newSensor = _.cloneDeep(sensor);
  const name = sensor.userConfig.channels[0].name;
  const nativeType = sensor.userConfig.channels[0].nativeType;
  const alerts = sensor.userConfig.channels[0].alerts;
  newSensor.userConfig.channels[0] = {
    ...{
      mesUnit: "mV/V",
      warmupTime: 1,
      mode: "raw",
      externalNtc: {
        enabled: false
      }
    },
    ...{ name, nativeType, alerts }
  };
  return newSensor;
}

export function getTypoMVVCustom(sensor = {}) {
  const newSensor = _.cloneDeep(sensor);
  const name = sensor.userConfig.channels[0].name;
  const nativeType = sensor.userConfig.channels[0].nativeType;
  const alerts = sensor.userConfig.channels[0].alerts;
  newSensor.userConfig.channels[0] = {
    ...{
      mesUnit: null,
      warmupTime: 1,
      mode: "custom",
      externalNtc: {
        enabled: false
      }
    },
    ...{ name, nativeType, alerts }
  };
  return newSensor;
}

export function getTypoMVVMapped(sensor = {}) {
  const newSensor = _.cloneDeep(sensor);
  const name = sensor.userConfig.channels[0].name;
  const nativeType = sensor.userConfig.channels[0].nativeType;
  const alerts = sensor.userConfig.channels[0].alerts;
  newSensor.userConfig.channels[0] = {
    ...{
      manufacturer: null,
      modelNumber: null,
      mode: "mapped",
    },
    ...{ name, nativeType, alerts }
  };
  return newSensor;
}

/*****************************************************************************************/
/* NTC */
/*****************************************************************************************/
export function getTypoNTCRaw(sensor = {}) {
  const newSensor = _.cloneDeep(sensor);
  const name = sensor.userConfig.channels[0].name;
  const nativeType = sensor.userConfig.channels[0].nativeType;
  const alerts = sensor.userConfig.channels[0].alerts;
  newSensor.userConfig.channels[0] = {
    ...{
      mesUnit: "Ohm",
      warmupTime: 1,
      mode: "raw",
    },
    ...{ name, nativeType, alerts }
  };
  return newSensor;
}

export function getTypoNTCCustom(sensor = {}) {
  const newSensor = _.cloneDeep(sensor);
  const name = sensor.userConfig.channels[0].name;
  const nativeType = sensor.userConfig.channels[0].nativeType;
  const alerts = sensor.userConfig.channels[0].alerts;
  newSensor.userConfig.channels[0] = {
    ...{
      mesUnit: null,
      warmupTime: 1,
      mode: "custom",
    },
    ...{ name, nativeType, alerts }
  };
  return newSensor;
}

export function getTypoNTCMapped(sensor = {}) {
  const newSensor = _.cloneDeep(sensor);
  const name = sensor.userConfig.channels[0].name;
  const nativeType = sensor.userConfig.channels[0].nativeType;
  const alerts = sensor.userConfig.channels[0].alerts;
  newSensor.userConfig.channels[0] = {
    ...{
      manufacturer: null,
      modelNumber: null,
      mode: "mapped",
    },
    ...{ name, nativeType, alerts }
  };
  return newSensor;
}

/*****************************************************************************************/
/* Potentiometric */
/*****************************************************************************************/
export function getTypoPotentiometricRaw(sensor = {}) {
  const newSensor = _.cloneDeep(sensor);
  const name = sensor.userConfig.channels[0].name;
  const nativeType = sensor.userConfig.channels[0].nativeType;
  const alerts = sensor.userConfig.channels[0].alerts;
  newSensor.userConfig.channels[0] = {
    ...{
      mesUnit: "%",
      warmupTime: 1,
      mode: "raw",
      externalNtc: {
        enabled: false
      }
    },
    ...{ name, nativeType, alerts }
  };
  return newSensor;
}

export function getTypoPotentiometricCustom(sensor = {}) {
  const newSensor = _.cloneDeep(sensor);
  const name = sensor.userConfig.channels[0].name;
  const nativeType = sensor.userConfig.channels[0].nativeType;
  const alerts = sensor.userConfig.channels[0].alerts;
  newSensor.userConfig.channels[0] = {
    ...{
      mesUnit: null,
      warmupTime: 1,
      mode: "custom",
      externalNtc: {
        enabled: false
      }
    },
    ...{ name, nativeType, alerts }
  };
  return newSensor;
}

export function getTypoPotentiometricMapped(sensor = {}) {
  const newSensor = _.cloneDeep(sensor);
  const name = sensor.userConfig.channels[0].name;
  const nativeType = sensor.userConfig.channels[0].nativeType;
  const alerts = sensor.userConfig.channels[0].alerts;
  newSensor.userConfig.channels[0] = {
    ...{
      manufacturer: null,
      modelNumber: null,
      mode: "mapped"
    },
    ...{ name, nativeType, alerts }
  };
  return newSensor;
}

/*****************************************************************************************/
/* PTC */
/*****************************************************************************************/
export function getTypoPTCRaw(sensor = {}) {
  const newSensor = _.cloneDeep(sensor);
  const name = sensor.userConfig.channels[0].name;
  const nativeType = sensor.userConfig.channels[0].nativeType;
  const alerts = sensor.userConfig.channels[0].alerts;
  newSensor.userConfig.channels[0] = {
    ...{
      mesUnit: "Ohm",
      warmupTime: 1,
      mode: "raw",
      probeType: "pt100"
    },
    ...{ name, nativeType, alerts }
  };
  return newSensor;
}

export function getTypoPTCCustom(sensor = {}) {
  const newSensor = _.cloneDeep(sensor);
  const name = sensor.userConfig.channels[0].name;
  const nativeType = sensor.userConfig.channels[0].nativeType;
  const alerts = sensor.userConfig.channels[0].alerts;
  newSensor.userConfig.channels[0] = {
    ...{
      mesUnit: null,
      warmupTime: 1,
      mode: "custom",
      probeType: "pt100"
    },
    ...{ name, nativeType, alerts }
  };
  return newSensor;
}

export function getTypoPTCMapped(sensor = {}) {
  const newSensor = _.cloneDeep(sensor);
  const name = sensor.userConfig.channels[0].name;
  const nativeType = sensor.userConfig.channels[0].nativeType;
  const alerts = sensor.userConfig.channels[0].alerts;
  newSensor.userConfig.channels[0] = {
    ...{
      manufacturer: null,
      modelNumber: null,
      mode: "mapped"
    },
    ...{ name, nativeType, alerts }
  };
  return newSensor;
}

/*****************************************************************************************/
/* Vibrating wire */
/*****************************************************************************************/
export function getTypoVibratingWireRaw(sensor = {}) {
  const newSensor = _.cloneDeep(sensor);
  const name = sensor.userConfig.channels[0].name;
  const nativeType = sensor.userConfig.channels[0].nativeType;
  const alerts = sensor.userConfig.channels[0].alerts;
  const minFreq = sensor.userConfig.channels[0].minFreq;
  const maxFreq = sensor.userConfig.channels[0].maxFreq;
  const responseType = sensor.userConfig.channels[0].responseType;
  newSensor.userConfig.channels[0] = {
    ...{
      mesUnit: "Hz",
      mode: "raw",
      externalNtc: {
        enabled: false
      }
    },
    ...{ name, nativeType, alerts, minFreq, maxFreq, responseType }
  };
  return newSensor;
}

export function getTypoVibratingWireCustom(sensor = {}) {
  const newSensor = _.cloneDeep(sensor);
  const name = sensor.userConfig.channels[0].name;
  const nativeType = sensor.userConfig.channels[0].nativeType;
  const alerts = sensor.userConfig.channels[0].alerts;
  const minFreq = sensor.userConfig.channels[0].minFreq;
  const maxFreq = sensor.userConfig.channels[0].maxFreq;
  const responseType = sensor.userConfig.channels[0].responseType;
  newSensor.userConfig.channels[0] = {
    ...{
      mesUnit: null,
      mode: "custom",
      externalNtc: {
        enabled: false
      }
    },
    ...{ name, nativeType, alerts, minFreq, maxFreq, responseType }
  };
  return newSensor;
}

export function getTypoVibratingWireMapped(sensor = {}) {
  const newSensor = _.cloneDeep(sensor);
  const name = sensor.userConfig.channels[0].name;
  const nativeType = sensor.userConfig.channels[0].nativeType;
  const alerts = sensor.userConfig.channels[0].alerts;
  const minFreq = sensor.userConfig.channels[0].minFreq;
  const maxFreq = sensor.userConfig.channels[0].maxFreq;
  const responseType = sensor.userConfig.channels[0].responseType;
  newSensor.userConfig.channels[0] = {
    ...{
      manufacturer: null,
      modelNumber: null,
      mode: "mapped",
      externalNtc: {
        enabled: false
      }
    },
    ...{ name, nativeType, alerts, minFreq, maxFreq, responseType }
  };
  return newSensor;
}
