<i18n>
{
  "en": {
    "caption": "Tips: Update your login password.",
    "action": "LET'S PROCEED"
  },
  "it": {
    "caption": "Consigli: Aggiorna la password di login.",
    "action": "PROCEDIAMO"
  }
}
</i18n>

<template>
  <div>
    <BadgeNotificationTemplate @action="$emit('action')">
      <template v-slot:caption>
        {{ $t("caption") }}
      </template>
      <template v-slot:action>
        {{ $t("action") }}
      </template>
    </BadgeNotificationTemplate>
  </div>
</template>
<script>
import BadgeNotificationTemplate from "./BadgeNotificationTemplate.vue";
export default {
  name: "psw-change-badge",
  components: {
    BadgeNotificationTemplate,
  },
};
</script>
