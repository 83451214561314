var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.curTab === 'probe-configuration')?_c('ProbeConfiguration',{attrs:{"rawDataModeSettings":[
    'header',
    'probe-name',
    'setting-mode',
    'probe-type',
    'warmup',
    'unit-of-measurement' ],"rawDataModeOptions":{
    disableMesUnitInput: true,
    disableABCFormula: true,
    disableNtcCompensation: true
  },"customDataModeSettings":[
    'header',
    'probe-name',
    'setting-mode',
    'probe-type',
    'warmup',
    'unit-of-measurement',
    'data-conversion-ptc' ],"customDataModeOptions":{
    disableMesUnitInput: false,
    disableABCFormula: false,
    disableNtcCompensation: true,
    rawUnit: 'Ohm'
  },"curTab":_vm.curTab,"sensor":_vm.sensor}}):(_vm.curTab === 'acquisition-parameters')?_c('AcquisitionParameter',{attrs:{"curTab":_vm.curTab,"sensor":_vm.sensor,"settings":[
    'header',
    'cadence',
    'threshold-sampling',
    'advance-options',
    'zeroing'
  ]}}):(_vm.curTab === 'alarm')?_c('Alarm',{attrs:{"curTab":_vm.curTab,"sensor":_vm.sensor}}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }