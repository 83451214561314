<template>
  <div class="outer-wrap">
    <div class="dark-overlay"></div>
    <div id="settings-outerwrap">
      <div class="settings-container">
        <!-- Loading Overlay -->
        <transition name="load">
          <SmallLoadingLogo v-if="!this.loaded" />
        </transition>
        <!-- Heading -->
        <div id="settings-header" class="noselect">
          <h1>{{ $t("message.settings") }}</h1>
          <a @click="closeMe" class="close"></a>
        </div>
        <!-- Opzioni sulla sinistra -->
        <div id="side-options" class="noselect scroll-thin">
          <div class="options ">
            <a @click="curOption = 'deck'" :class="{ active: curOption === 'deck', disabled: !checkSensType('deck'), invalid: !this.validDecks }">{{ $t("message.decks") }} </a>
            <a @click="curOption = 'tiltmeter'" :class="{ active: curOption === 'tiltmeter', disabled: !checkSensType('tiltmeter'), invalid: !this.validTiltmeters }">{{ $t("message.tiltmeters") }} </a>
            <a @click="curOption = 'accelerometer'" :class="{ active: curOption === 'accelerometer', disabled: !checkSensType('accelerometer'), invalid: !this.validAccelerometers }">{{ $t("message.accelerometers") }} </a>
            <a @click="curOption = 'crackmeter'" :class="{ active: curOption === 'crackmeter', disabled: !checkSensType('crackmeter'), invalid: !this.validCrackmeters }">{{ $t("message.crackmeters") }} </a>
            <a @click="curOption = 'analog-node'" :class="{ active: curOption === 'analog-node', disabled: !checkSensType('analog-node'), invalid: !this.validAnalogNodes  }">{{ $t("message.analogNode") }} </a>
            <a @click="curOption = 'vibrometer'" :class="{ active: curOption === 'vibrometer', disabled: !checkSensType('vibrometer'), invalid: !this.validVibrometers }">{{ $t("message.vibrometer") }} </a>
            <div class="horiz-divider"></div>
            <a @click="curOption = 'structure'" :class="{ active: curOption === 'structure', invalid: !this.validStructure }">{{ $t("message.structure") }} </a>
            <div class="horiz-divider"></div>
            <a @click="curOption = 'notifications'" :class="{ active: curOption === 'notifications', invalid: !this.validNotifications }">{{ $t("message.notifications") }} </a>
            <a @click="curOption = 'users'" :class="{ active: curOption === 'users',invalid: !this.validSubUsers }">{{ $t("message.users") }} </a>
          </div>
        </div>
        <!-- Contento principale   -->
        <div id="main-content">
          <Deck
            v-if="curOption === 'deck' && filteredSensors && filteredSensors.length > 0"
            :sensors="filteredSensors"
            :readOnly="isDisabledSensorsSettings"
            @validation="updateSensorsValidation('deck', ...arguments)"
            @update="updateSensors"
          />
          <Tiltmeter
            v-if="curOption === 'tiltmeter' && filteredSensors && filteredSensors.length > 0"
            :sensors="filteredSensors"
            :structure="structure"
            :interfaceSettings="interfaceSettings"
            :readOnly="isDisabledSensorsSettings"
            @validation="updateSensorsValidation('tiltmeter', ...arguments)"
            @update="updateSensors"
            @reset-offset="offsetReset('tiltmeter', ...arguments)"
          />
          <Crackmeter
            v-if="curOption === 'crackmeter' && filteredSensors && filteredSensors.length > 0"
            :sensors="filteredSensors"
            :readOnly="isDisabledSensorsSettings"
            @validation="updateSensorsValidation('crackmeter', ...arguments)"
            @update="updateSensors"
          />
          <Accelerometer
            v-if="curOption === 'accelerometer' && filteredSensors && filteredSensors.length > 0"
            :sensors="filteredSensors"
            :readOnly="isDisabledSensorsSettings"
            @validation="updateSensorsValidation('accelerometer', ...arguments)"
            @update="updateSensors"
          />
          <AnalogNode
            v-if="curOption === 'analog-node' && filteredSensors && filteredSensors.length > 0"
            :sensors="filteredSensors"
            :readOnly="isDisabledSensorsSettings"
            @validation="updateSensorsValidation('analog-node', ...arguments)"
            @update="updateSensors"
            @reset-offset="offsetReset('analog-node', ...arguments)"
          />
          <Vibrometer
            v-if="curOption === 'vibrometer' && filteredSensors && filteredSensors.length > 0"
            :sensors="filteredSensors"
            :interfaceSettings="interfaceSettings"
            :readOnly="isDisabledSensorsSettings"
            @validation="updateSensorsValidation('vibrometer', ...arguments)"
            @update="updateSensors"
            @needToReloadEvents="onNeedToReloadEvents"
          />

          <Structure
            v-if="curOption === 'structure' && structure"
            @validation="updateStructureValidation"
            @update="updateStructure"
            :structure="structure"
            :sensors="sensors"
            @update-sensors="updateSensors"
          />
          <Notifications v-if="curOption === 'notifications'" @validation="updateNotificationsValidation" @update="updateNotifications" :mails="mails" />
          <SubUsers v-if="curOption === 'users'" @validation="updateSubUsersValidation" @update="updateSubUsers" :subUsers="subUsers" />
        </div>
        <!-- Footer -->
        <div id="footer">
          <div id="warning-message">
            <WarningMessage v-show="unsavedChanges" />
          </div>
          <div class="footer-btn">
            <button type="button" class="button" @click="closeMe">
              {{ $t("message.undo") }}
            </button>
            <button type="button" class="button save" @click="saveAllSettings" :disabled="!validate">
              {{ $t("message.saveChanges") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash"
import api from "../services/api";
import SmallLoadingLogo from "../components/ui/SmallLoadingLogo.vue";
import Deck from "../components/ui/settings/Deck.vue";
import Tiltmeter from "../components/ui/settings/Tiltmeter.vue";
import Crackmeter from "../components/ui/settings/Crackmeter.vue";
import Accelerometer from "../components/ui/settings/Accelerometer.vue";
import AnalogNode from "../components/ui/settings/AnalogNode.vue";
import Vibrometer from "../components/ui/settings/Vibrometer.vue";
import Structure from "../components/ui/settings/Structure.vue";
import Notifications from "../components/ui/settings/Notifications.vue";
import SubUsers from "../components/ui/settings/SubUsers.vue";
import WarningMessage from "../components/ui/settings/WarningMessage.vue";
import {validateEntireSensor} from "../helpers/validate"
import {DISABLE_SENSORS_SETTINGS} from "../config"

import { mapGetters, mapActions } from "vuex";
import $ from "jquery";

export default {
  name: "settings",
  props: {
    selectedStructure: Object,
    userType: String,
  },
  components: {
    SmallLoadingLogo,
    Deck,
    Tiltmeter,
    Crackmeter,
    Accelerometer,
    AnalogNode,
    Vibrometer,
    Structure,
    Notifications,
    SubUsers,
    WarningMessage,
  },
  data() {
    return {
      validDecks: true,
      validTiltmeters: true,
      validAccelerometers: true,
      validCrackmeters: true,
      validAnalogNodes: true,
      validVibrometers: true,
      validStructure: true,
      validNotifications: true,
      validSubUsers: true,
      unsavedChanges: false,
      loaded: false,
      curOption: "deck",
      needPageRefresh: false,
      needToReloadEvents: false,
      sensors: [],
      structure: null,
      mails: [],
      subUsers: [],
      interfaceSettings: {},
      sensorPriority: ["deck", "tiltmeter", "accelerometer", "crackmeter", "analog-node", "vibrometer"],
      isDisabledSensorsSettings: DISABLE_SENSORS_SETTINGS === "true",
    };
  },
  methods: {
    ...mapActions({
      fetchStructures: "structure/fetchStructures",
      fetchStructureData: "structure/fetchStructureData",
      saveUserSettings: "user/saveUserSettings",
      fetchSensors: "settings/fetchSensors",
    }),
    updateSensors(sensors, unsavedChanges = true) {
      // Ricevo un nuovo array di oggetti 'sensore' del tipo selezionato con la nuova config
      // Aggiorno 'sensors' di conseguenza
      this.unsavedChanges = unsavedChanges;
      for (let i = 0; i < sensors.length; i++) {
        for (let j = 0; j < this.sensors.length; j++) {
          if (sensors[i].eui === this.sensors[j].eui) {
            this.sensors[j] = JSON.parse(JSON.stringify(sensors[i]));
          }
        }
      }
    },
    // Riceve un nuovo oggetto 'structure' ed aggiorna la struttura attuale
    updateStructure(structure) {
      this.unsavedChanges = true;
      this.structure = JSON.parse(JSON.stringify(structure));
    },
    // Ricevo un nuovo array di sotto-utenti ed aggiorna i sotto-utenti attuali
    updateSubUsers(subUsers) {
      this.unsavedChanges = true;
      this.subUsers = JSON.parse(JSON.stringify(subUsers));
    },
    // Ricevo un nuovo array di mail di notifica ed aggiorna le mail attuali
    updateNotifications(mails) {
      this.unsavedChanges = true;
      this.mails = JSON.parse(JSON.stringify(mails));
    },
    // Aggiorna lo stato di validità dei sensori
    updateSensorsValidation(type, valid) {
      switch (type) {
        case "deck":
          this.validDecks = valid;
          break;
        case "tiltmeter":
          this.validTiltmeters = valid;
          break;
        case "accelerometer":
          this.validAccelerometers = valid;
          break;
        case "crackmeter":
          this.validCrackmeters = valid;
          break;
        case "analog-node":
          this.validAnalogNodes = valid;
          break;
        case "vibrometer":
          this.validVibrometers = valid;
          break;
      }
    },
    checkSensorValidation() {
      const validate = type =>
        this.sensors
          .filter(sn => sn.type === type)
          .every(sn => validateEntireSensor(sn));

      this.validDecks = validate("deck");
      this.validTiltmeters = validate("tiltmeter");
      this.validAccelerometers = validate("accelerometer");
      this.validCrackmeters = validate("crackmeter");
      this.validAnalogNodes = validate("analog-node");
      this.validVibrometers = validate("vibrometer");
    },
    // Aggiorna lo stato di validità della struttura
    updateStructureValidation(valid) {
      this.validStructure = valid;
    },
    // Aggiorna lo stato di validità degli utente per le notifiche
    updateNotificationsValidation(valid) {
      this.validNotifications = valid;
    },
    // Aggiorna lo stato di validità dei sub users
    updateSubUsersValidation(valid) {
      this.validSubUsers = valid;
    },
    // Offset reset
    async offsetReset(type, eui) {
      if (confirm(this.$t("message.irreversibleWarning"))) {
        if (eui) {
          // Se ho l'eui reset solo quel sensore
          console.log("Resetto", eui);
          await api.resetOffset([eui]);
        } else {
          // Altrimenti li resetto tutti
          const euis = this.filteredSensors.map((s) => s.eui);
          console.log("Resetto", euis);
          await api.resetOffset(euis);
        }
        this.tilmeterModuleKey = this.tilmeterModuleKey + 1;
        this.needPageRefresh = true;
      }
    },
    getAnalogNodeV2Fields(sensor){
      const sn = _.cloneDeep(sensor);
      let rscConfig = sn.userConfig && sn.userConfig.channels && sn.userConfig.channels[0];
      if(!rscConfig){
        return
      }
      //delete alertAbsolute field
      delete rscConfig.alertAbsolute;
      //delete lastBaseRawValueResetDate
      delete rscConfig.lastBaseRawValueResetDate;
      if(rscConfig.mode === 'mapped'){
        const compensationEnabled = rscConfig.externalNtc && rscConfig.externalNtc.compensationEnabled ? true : false;
        const externalNtc = {compensationEnabled}
        rscConfig = {
          name: rscConfig.name,
          nativeType: rscConfig.nativeType,
          alerts: rscConfig.alerts,
          mode: rscConfig.mode,
          manufacturer: rscConfig.manufacturer,
          modelNumber: rscConfig.modelNumber,
        }
        if (rscConfig.nativeType === 'vibrating-wire') {
          rscConfig = {...rscConfig, externalNtc}
        }
      }
      sn.userConfig.channels[0] = rscConfig;
      return sn
    },
    /* Salva TUTTE le impostazioni */
    async saveAllSettings() {
      this.loaded = false;
      let promises = [];
      promises.push(
        this.saveUserSettings({
          mails: this.mails.map((mail) => ({
            mail: mail.mail,
            sensorTypes: mail.sensorTypes,
            name: mail.name,
            alertsOn: mail.alertsOn,
          })),
          interface: this.interfaceSettings,
        })
      );
      if (this.sensors.length > 0 && !this.isDisabledSensorsSettings) {
        //remove test sensors

        promises.push(
          api.saveSensorsSettings({
            sensors: this.sensors.map((sn) => {
              if (sn.userConfig.threshold && sn.userConfig.threshold.lastChange) delete sn.userConfig.threshold.lastChange;
              //remove invalid fields in analog-node rev 2.0
              if (sn.type === 'analog-node' && sn.rev === '2.0') {
                sn = this.getAnalogNodeV2Fields(sn)
              }
              return {
                eui: sn.eui,
                type: sn.type,
                rev: sn.rev,
                userConfig: {
                  ...sn.userConfig,
                  color: sn.color || 'red'
                },
              };
            }),
          })
        );
      }
      promises.push(
        api.saveStructSettings({
          structureID: this.selectedStructure._id,
          coordinates: this.structure.coordinates,
          name: this.structure.name,
          userConfig: {
            ...this.structure.userConfig,
            spansDetails: this.structure.userConfig.spansDetails.map((sd) => ({ name: sd.name, length: sd.length })),
          },
        })
      );
      promises.push(this.saveSubUsers());
      await Promise.all(promises);
      this.needPageRefresh = false; // Refresh già qui direttamente
      this.needToReloadEvents = false; // Refresh già qui direttamente
      await Promise.all([this.fetchStructureData(), this.fetchStructures()]);
      this.loaded = true;
      this.$emit('needToReloadEvents')
      this.closeMe();
    },
    async saveSubUsers() {
      // Discrimo nuovi e vecchi utenti da modificare in base all'esistenza del campo _id
      this.exsistingUsers = await api.getSubUsers();
      let newUsers = this.subUsers.filter((su) => !su._id);
      let oldUsers = this.subUsers.filter((su) => su._id);
      let deletedUsers = this.exsistingUsers.filter((us) => !this.subUsers.find((sub) => sub._id === us._id));
      let userPromises = [];
      userPromises.push(newUsers.map((newUser) => api.insertNewSubUser(newUser.username, newUser.password)));
      userPromises.push(oldUsers.map((oldUser) => api.updateSubUser(oldUser._id, oldUser.username, oldUser.password)));
      userPromises.push(deletedUsers.map((delUser) => api.deleteSubUser(delUser._id)));
      await Promise.all(userPromises);
    },
    checkSensType(type) {
      // Ritorna true se esiste almeno un sensore di tipo 'type'
      return this.sensors.some((sensor) => sensor.type === type);
    },
    closeMe() {
      this.$emit("settingstoggle");
    },
    onNeedToReloadEvents(){
      this.needToReloadEvents = true;
      this.unsavedChanges = true;
    }
  },
  computed: {
    ...mapGetters({
      getUserMails: "user/getMails",
      getUserInterface: "user/getInterface",
    }),
    // Ritorna i sensori relativi al tipo selezionato
    filteredSensors() {
      return this.sensors.filter((sensor) => sensor.type === this.curOption);
    },
    validate() {
      let subUserCheck = this.validSubUsers;
      let mailCheck = this.validNotifications;
      let sensorsCheck = this.validDecks && this.validTiltmeters && this.validAccelerometers && this.validCrackmeters && this.validAnalogNodes && this.validVibrometers;
      let structureCheck = this.validStructure;
      return subUserCheck && mailCheck && sensorsCheck && structureCheck;
    },
  },
  async mounted() {
    // Blocco scorrimento body
    $(document.body).addClass("modal-open");

    // Deep copy delle mail
    if (this.getUserMails) {
      this.mails = JSON.parse(JSON.stringify(this.getUserMails));
    }

    // Deep copy user settings
    if (this.getUserInterface) {
      this.interfaceSettings = JSON.parse(JSON.stringify(this.getUserInterface));
    }
    // Deep copy sotto utenti
    this.subUsers = JSON.parse(JSON.stringify(await api.getSubUsers()));

    // Deep copy della struttura
    this.structure = JSON.parse(JSON.stringify(this.selectedStructure));
    console.log("get structure", this.structure)

    // Deep copy dei sensor
    let sensors = JSON.parse(JSON.stringify(await api.getSensorsSettings(this.selectedStructure._id)));
    this.sensors = sensors

    /* this.sensors = this.sensors
      ? this.sensors.map((sensor) => ({
          eui: sensor.eui,
          type: sensor.type,
          rev: sensor.rev,
          baseValue: sensor.baseValue,
          baseTilt: sensor.baseTilt,
          subType: sensor.subType,
          userConfig: {
            ...sensor.userConfig,
            spanNumber: sensor.userConfig.spanNumber || null,
            editing: false,
            valid: true,
          },
        }))
      : []; */

    /*
    Seleziono la prima tab disponibile in base alla priorità stabilita
    */
    this.curOption = this.sensorPriority.find(type => this.sensors.some(s => s.type === type))
    this.loaded = true;
    await this.fetchSensors({resetValidation: true});
    this.checkSensorValidation();
  },
  async beforeDestroy() {
    $(document.body).removeClass("modal-open"); // Consento al body di scorrere di nuovo
    if (this.needPageRefresh) {
      await this.fetchStructureData();
    }
  },
};
</script>
<style lang="postcss" scoped>

* {
  font-style: normal;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: white;
}
input[disabled] {
  pointer-events: none;
}
.dark-overlay {
  background-color: rgba(0, 0, 0, 0.27);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 18;
}
h1 {
  font-size: 1em;
}
a {
  color: white;
  font-size: 0.75em;
}
p {
  color: rgb(198, 198, 198);
  padding: 0px;
}
.horiz-divider {
  background-color: grey;
  height: 2px;
  margin: 4px 0;
}
.settings-container {
  width: 90vw;
  height: 90vh;
  top: 5vh;
  left: 0;
  position: sticky;
  grid-template-columns: 15% 85%;
  grid-template-rows: 50px 50px 1fr 60px;  
  border-radius: 10px;
  overflow: hidden;
  display: grid;
  background-color: rgb(45, 48, 65);
  -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
}
.options {
  display: grid;
  text-align: left;
  display: grid;
  text-align: left;
  grid-template: none;
  align-items: center;
  max-height: 540px;
}
.options > a {
  padding-left: 1.2vw;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: default;
  margin: 14px 0;
  color: white;
}
#settings-header {
  background-color: rgb(45, 48, 65);
  height: 50px;
  /* min-height: 45px; */
  grid-column: 1/3;
  grid-row: 1;
  z-index: 11;
  display: flex;
  align-items: center;
  padding-left: 1.2vw;
  border-bottom: solid 2px white;
  box-sizing: border-box;
}
/* selected link */
.options > a.active {
  background-color: rgb(32, 34, 47) !important;
}
.options > a.disabled {
  color: gray;
  pointer-events: none;
  cursor: default;
}

.options > a.invalid {
  color: red;
}
#side-options {
  height: 100%;
  min-width: 120px;
  background-color: rgb(45, 48, 65);
  grid-row-start: 2;
  grid-row-end: 5;
  grid-column: 1;
  z-index: 10;
  position: relative;
  border-right: solid 2px white;
  display: grid;
  grid-template-rows: auto;
  overflow-y: auto;
}
#main-content {
  background-color: rgb(45, 48, 65);
  grid-row: 2/4;
  grid-column: 2;
  overflow-x: hidden;
  overflow-y: hidden;

}
#footer {
  background-color: rgb(45, 48, 65);
  border-top: solid 0.8px gray;
  height: 60px;
  max-height: 60px;
  grid-column: 2/4;
  grid-row: 4;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 2;
  -webkit-box-shadow: 1px -8px 10px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 1px -8px 10px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 1px -8px 10px 0px rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
}
.close {
  align-self: center;
  cursor: pointer;
  right: 2%;
  width: 3%;
}
input[type="range"]::-moz-range-track {
  padding: 0 10px;
  background: repeating-linear-gradient(to right, #ccc, #ccc 10%, #000 10%, #000 11%, #ccc 11%, #ccc 20%);
}
/* Control Buttons */
.footer-btn {
  display: flex;
  height: 35px;
}
.button {
  width: 158px;
  padding: 2%;
  line-height: 100%;
  margin: auto;
  font-weight: 600;
  margin-right: 20px;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: rgb(80, 84, 105);
  border: solid 2px white;
  border-radius: 7px;
  transition: transform 0.1s linear;
}
.button:active {
  background-color: rgb(47, 112, 177);
  transform: translateY(1px);
}
.button:disabled {
  background-color: grey;
}
.button:disabled:hover {
  background-color: grey;
}
.bottom-buttons {
  position: absolute;
  top: 405px;
  left: 458px;
}
.save {
  background-color: rgb(21, 146, 230);
}
.save:hover {
  background-color: rgba(21, 146, 230, 0.8);
}
#settings-outerwrap {
  z-index: 19;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
#settings-outerwrapheader {
  cursor: move;
}
#warning-message {
  position: relative;
  padding: 8px;
  width: 100%;
}
.close {
  align-self: center;
  cursor: pointer;
  right: 2%;
  width: 3%;
}
/* Loading Transition */
.load-enter,
.load-leave-to {
  opacity: 0;
}
.load-enter-active {
  transition: all 0.1s ease;
}
.load-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
::-webkit-scrollbar {
  width: 0.5em;
}
::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

/* width */
.scroll-thin::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: transparent;
  border-radius: 0px;
  outline: none;
}

/* Track */
.scroll-thin::-webkit-scrollbar-track {
  background: transparent; 
  border-radius: 0px;
  outline: none;
}
 
/* Handle */
.scroll-thin::-webkit-scrollbar-thumb {
  background: var(--primary-border-color); 
  border-radius: 0px;
  outline: none;
}
</style>
