<i18n>
{
  "en": {
    "caption": "Tips: Update the way of viewing your monitored site site.",
    "action": "LET'S PROCEED"
  },
  "it": {
    "caption": "Consigli: Aggiorna la modalità di visualizzazione del sito monitorato.",
    "action": "PROCEDIAMO"
  }
}
</i18n>

<template>
  <div>
    <BadgeNotificationTemplate @action="$emit('action')">
      <template v-slot:caption>
        {{ $t("caption") }}
      </template>
      <template v-slot:action>
        {{ $t("action") }}
      </template>
    </BadgeNotificationTemplate>
  </div>
</template>
<script>
import BadgeNotificationTemplate from "./BadgeNotificationTemplate.vue";
export default {
  name: "img-change-badge",
  components: {
    BadgeNotificationTemplate,
  },
};
</script>
