/**
 * @param {string} strDate - date in following string format "dd/mm/yyyy",
 */
function validateDate(strDate = ""){
  const regExp = /^(?:(?:31(\/)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/g
  const found = strDate.match(regExp);
  const isValid = (found && found.length) ? true : false ;
  return isValid;
}

/**
 * @param {string} strDate - date in following string format "dd/mm/yyyy",
 */
function strToDate(strDate = ""){
  const isValid = validateDate(strDate);
  if (!isValid) {
    return undefined
  }
  const d = strDate.split("/");
  if (d[2].length === 2) {
    return undefined
  }
  const day = Number(d[0]);
  const monthIndex = Number(d[1]);
  const year = Number(d[2]);
  return new Date(year, monthIndex, day)
}

function ddmmyyyyFormat(date) {
  var today = new Date(date);
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  return dd + "/" + mm + "/" + yyyy;
}

function toDateStringFormat(date, {divider= "", ISO_8601= false} = {} ){
  const day = new Date(date);
  let dd = day.getDate();
  let mm = day.getMonth() + 1;
  let yyyy = day.getFullYear();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  let format = dd + divider + mm + divider + yyyy;
  if (ISO_8601) {
    format = yyyy + divider + mm + divider + dd; 
  }

  return format;
}

function toTimeStringFormat(date, {diveder="", addMillisecons = false} = {}){
  const day = new Date(date);
  let hours = day.getHours();
  let minutes = day.getMinutes();
  let seconds = day.getSeconds(); 

  if (hours < 10) hours = "0" + hours;
  if (minutes < 10) minutes = "0" + minutes;
  if (seconds < 10) seconds = "0" + seconds;

  let format = `${hours}${diveder}${minutes}${diveder}${seconds}`;

  if (addMillisecons) {
    const milliseconds = day.getMilliseconds()
    format += `${diveder}${milliseconds}`
  }


  return format
}

function formatDate(date){
  const dateString = toDateStringFormat(date, {divider: '/'});
  const timeString = toTimeStringFormat(date, {diveder: ':'});
  return `${dateString} - ${timeString}`;
}

function formatDateVerbose(_date){
  const date = new Date(_date)
  let day = date.getDate();
  let year = date.getFullYear();
  let month = date.toLocaleString("default", { month: "short" });
  return day + " " + month + " " + year;
}


function getEntireDateRange(start, end) {
  let firstDate = new Date(start);
  firstDate.setHours(0);
  firstDate.setMinutes(0);
  firstDate.setSeconds(0);
  firstDate.setMilliseconds(0);
  let lastDate = new Date(end);
  lastDate.setHours(23);
  lastDate.setMinutes(59);
  lastDate.setSeconds(59);
  lastDate.setMilliseconds(999);
  return [firstDate, lastDate];
}


// Restituisce la differenza temporale tra due date, tenendo solo conto di anno/mese/giorno
function daysBetween(first, second) {

  // Copy date parts of the timestamps, discarding the time parts.
  var one = new Date(first.getFullYear(), first.getMonth(), first.getDate());
  var two = new Date(second.getFullYear(), second.getMonth(), second.getDate());

  // Do the math.
  var millisecondsPerDay = 1000 * 60 * 60 * 24;
  var millisBetween = two.getTime() - one.getTime();
  var days = millisBetween / millisecondsPerDay;

  // Round down.
  return Math.floor(days);
}

module.exports = {
  ddmmyyyyFormat,
  getEntireDateRange,
  toDateStringFormat,
  toTimeStringFormat,
  validateDate,
  strToDate,
  daysBetween,
  formatDate,
  formatDateVerbose
};
