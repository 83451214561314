<i18n>
{
  "en": {
      "dashboard": "Dashboard",
      "events": "Events",
      "statistics": "Statistics",
      "vibrational": "Vibrational",
      "deflection": "Deflection"
  },
  "it": {
      "dashboard": "Dashboard",
      "events": "Eventi",
      "statistics":"Statistiche",
      "vibrational": "Vibrazionale",
      "deflection": "Deformata"
  }
}
</i18n>

<template>
  <div class="sidebar">
    <SideTemplate :show="show" @sidebartoggle="sidebarToggle">
      <template v-slot:default>
        <div class="flex nowrap standard-link">
          <router-link class="link" to="/dashboard"><img class="icon" src="../../../public/assets/icons/dashboard.svg" />{{ $t("dashboard") }}</router-link>
        </div>
        <div class="flex nowrap standard-link">
          <router-link class="link" :to="{ name: 'details' }"><img class="icon" src="../../../public/assets/icons/events.svg" />{{ $t("events") }}</router-link>
        </div>
        <!-- <div class="flex nowrap standard-link">
          <router-link class="link" :to="{ name: 'download' }"><img class="icon" src="../../../public/assets/icons/events.svg" />Download</router-link>
        </div> -->
        <!-- <div class="flex nowrap standard-link">
          <router-link class="link" to="/stats"><img class="icon" src="../../../public/assets/icons/stats.svg" />{{ $t("statistics") }}</router-link>
        </div> -->
        <div class="submenu noselect-hard">
          <div class="submenu-root">
            <a @click="showStatsSubmenu = !showStatsSubmenu">
              <svg xmlns="http://www.w3.org/2000/svg" width="14.695" height="7.348" viewBox="0 0 14.695 7.348" :class="[showStatsSubmenu ? 'down' : 'up']">
                <path d="M7.348,0,14.7,7.348H0Z" transform="translate(14.695 7.348) rotate(180)" fill="#9699a2" />
              </svg>
              <img class="icon" src="../../../public/assets/icons/tools.svg" />
              Tools
            </a>
          </div>
          <transition name="slide">
            <div class="submenu-items" v-if="showStatsSubmenu">
              <div class="submenu-item">
                <router-link class="link" :to="{ name: 'stats' }">{{ $t("statistics") }}</router-link>
              </div>
              <div class="submenu-item" v-if="hasRailTool">
                <router-link class="link" :to="{ name: 'rails' }">Rails</router-link>
              </div>
              <div class="submenu-item" v-if="hasOverlaysTool">
                <router-link class="link" :to="{ name: 'overlays' }">Overlays</router-link>
              </div>
              <div class="submenu-item">
                <router-link class="link" :to="{ name: 'trend' }">Trend</router-link>
              </div>
              <div class="submenu-item">
                <router-link class="link" :to="{ name: 'vibrational' }">{{ $t("vibrational") }}</router-link>
              </div>
              <div class="submenu-item">
                <router-link class="link" :to="{ name: 'deflection' }">{{ $t("deflection") }}</router-link>
              </div>
            </div>
          </transition>
        </div>
      </template>

      <template v-slot:footer>
        <div class="flex nowrap" @click="monitorToggle">
          <a class="noselect flex"><img class="icon" src="../../../public/assets/icons/status.svg" />{{ $t("message.statusMonitor") }}</a>
        </div>
        <!-- <div class="flex nowrap" @click="analogNodeToggle" v-if="adminRights && hasAnalogNode">
          <a class="noselect flex"><img class="icon" src="../../../public/assets/icons/settings.svg" />Analog Node</a>
        </div> -->
        <div class="flex nowrap" @click="settingsToggle" v-if="adminRights">
          <a class="noselect flex"><img class="icon" src="../../../public/assets/icons/settings.svg" />{{ $t("message.settings") }}</a>
        </div>
      </template>
    </SideTemplate>
  </div>
</template>
<script>
import SideTemplate from "./SideTemplate.vue";
export default {
  name: "sidebar",
  components: {
    SideTemplate,
  },
  data() {
    return {
      showStatsSubmenu: false,
    };
  },
  methods: {
    sidebarToggle: function() {
      this.$emit("sidebartoggle");
    },
    settingsToggle: function() {
      this.$emit("settingstoggle");
    },
    analogNodeToggle: function() {
      this.$emit("analog-node-toggle");
    },
    monitorToggle: function() {
      this.$emit("monitortoggle");
    },
  },
  props: {
    show: Boolean,
    adminRights: Boolean,
    sensors: Array,
    structure: Object
  },
  computed: {
    hasAnalogNode() {
      return this.sensors && this.sensors.find((s) => s.type === "analog-node");
    },
    hasTiltmeter() {
      return this.sensors && this.sensors.find((s) => s.type === "tiltmeter");
    },
    hasRailTool(){
      return this.structure && this.structure.railEnabled ? true : false   
    },
    hasOverlaysTool(){
      return this.structure && this.structure.overlaysEnabled ? true : false   
    }
  },
};
</script>
<style scoped>
.router-link-active {
  background-color: rgb(32, 34, 47);
  color: white !important;
}

/* Icone bianche se sono su quella route */
.router-link-active > img {
  filter: brightness(0) invert(1);
}
a {
  cursor: pointer;
  text-decoration: none;
  width: 100%;
  padding: 10px 10px;
  position: relative;
  color: gray;
  font-family: inherit;
  display: flex;
  -webkit-transition: 0.6s ease-in-out;
  -moz-transition: 0.6s ease-in-out;
  -o-transition: 0.6s ease-in-out;
  transition: 0.6s ease-in-out;
}
.icon {
  padding-left: 10px;
  padding-right: 10px;
  height: 18px;
}
.submenu-item a {
  padding: 4px 0;
  padding-left: 38%;
}
ul {
  cursor: pointer;
}
/* Freccia */
i {
  border: solid gray;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 5px;
  position: relative;
  margin-bottom: auto;
  margin-top: 4px;
  bottom: -4px;
  margin-left: 40%;
  transition: all 0.3s cubic-bezier(0.42, 0, 0.1, 0.96);
}
svg {
  transition: all 0.3s cubic-bezier(0.42, 0, 0.1, 0.96);
  margin: auto 2px auto 5px;
}
.up {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}

.down {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
}

/* Transizione */
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 200px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
